import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useAuth } from "../../useAuth";
import { doctorNav, adminNav, patientNav, clinicNav } from "./navData";
import {
  axiosInstance,
  notificationBadgeType,
  userType,
} from "../../common/constants";
import SidenavBadge from "./SidenavBadge";

const style = {
  title: `font-semibold mx-4 text-sm`,
  active: `bg-gradient-to-r border-r-4 border-blue-500 border-r-4 border-blue-500 from-white to-blue-100 text-blue-500`,
  link: `duration-200 flex font-thin items-center justify-start mb-2 p-2 transition-colors text-gray-500 w-full lg:hover:text-blue-500`,
};

const SidenavItems = (props) => {
  const [loading, setLoading] = useState(true);
  const [navData, setNavData] = useState(null);
  // const [showSubMenu, setShowSubMenu] = useState(false)
  // const [hideShowClass, setHideShowClass] = useState("")
  const { isValidRole, onNavItemClick } = props;
  const { pathname } = useLocation();
  const { userData } = useAuth();

  useEffect(() => {
    if (userData && isValidRole) {
      if (userData.role === userType.DOCTOR && userData.verified) {
        setNavData(doctorNav);
      } else if (userData.role === userType.ADMIN) {
        setNavData(adminNav);
      } else if (userData.role === userType.PATIENT) {
        setNavData(patientNav);
      } else if (userData.role === userType.CLINIC_ADMIN) {
        setNavData(clinicNav);
      } else {
        setNavData([]);
      }
      setLoading(false);
    } else {
      setLoading(true);
      setNavData([]);
    }
  }, [isValidRole]);

  const setToggleSubMenu = (event) => {
    const elements = document.querySelectorAll(".show-sub-menu");
    elements.forEach((element) => {
      element.classList.remove("show-sub-menu");
    });
    const elementId = event.target.id;
    const element = document.getElementById("add_" + elementId);
    if (element) {
      element.classList.add("show-sub-menu");
    }
  };

  const is_active = (event) => {
    let elementId = event.target.id;
    const element = document.getElementById(elementId);
    if (element) {
      element.classList.add("active");
    }
  };

  return (
    <>
      {!loading ? (
        <ul>
          {navData.map((item, index) => (
            <li key={item.link}>
              <Link to={item.link} key={item.title} onClick={setToggleSubMenu}>
                {/* <Link to={item.link} key={item.title} onClick={() => {setToggleSubMenu, onNavItemClick}}> */}
                <span
                  id={index}
                  className={`${style.link} ${
                    item.link === pathname && style.active
                  }`}
                >
                  <span className="h-6 w-6">{item.icon}</span>
                  <span className={style.title}>{item.title}</span>
                  {item.badgeType && (
                    <SidenavBadge badgeType={item.badgeType} />
                  )}
                </span>
                {item.subMenu ? (
                  <ul
                    id={"add_" + index}
                    className={
                      "bg-gradient-to-r text-gray-500 submenu__main__list"
                    }
                  >
                    {item.subMenu.map((subItem, subIndex) => (
                      <li>
                        <Link
                          to={subItem.link}
                          key={subItem.title}
                          className="submenu__list"
                          onClick={is_active}
                        >
                          {" "}
                          <span
                            id={"is_active_" + index + subIndex}
                            className="sidebar__submenu__list__item"
                          >
                            <span className="h-5 w-5 mr-3">{subItem.icon}</span>{" "}
                            {subItem.title}
                          </span>
                        </Link>
                      </li>
                    ))}
                  </ul>
                ) : (
                  ""
                )}
              </Link>
            </li>
          ))}
        </ul>
      ) : (
        <></>
      )}
    </>
  );
};

export default SidenavItems;
