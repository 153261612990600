import React, { useEffect, useRef, useState } from "react";
import { useAuth } from "../useAuth";
import DefaultAvatar from "./DefaultAvatar";
import Button from "./Button";
import AvatarEditor from "react-avatar-editor";
import { ZoomInIcon, ZoomOutIcon } from "@heroicons/react/solid";
import Slider from "rc-slider";
import SmallLoadingSpinner from "./SmallLoadingSpinner";
import classnames from "classnames";
import ChangePasswordForm from "./ChangePasswordForm";
import {
  getPatientProfile,
  updatePatientProfile,
  updatePatientProfileImage,
} from "../services/patient/services";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useHistory } from "react-router-dom";
import { servicePostFileUpload } from "../services/clinic/clinicApiService";

const GeneralAccountPatientSettings = () => {
  // const { register: registerName, handleSubmit: handleNameSubmit, formState: { errors: nameErrors } } = useForm();
  // const { register: registerContact, handleSubmit: handleContactSubmit, formState: { errors: contactErrors } } = useForm();
  const [submitting, setSubmitting] = useState(false);
  const [loading, setLoading] = useState(false);
  const [userProfile, setUserProfile] = useState({});
  const [avatarImage, setAvatarImage] = useState();
  const [avatarImageScale, setAvatarImageScale] = useState(1.2);
  const [salutation, setSalutation] = useState("Mr");

  const avatarImageInput = useRef(null);
  const avatarImageEditor = useRef(null);
  const [error, setError] = useState(false);
  const { user } = useAuth();

  const validationSchema = Yup.object({
    firstName: Yup.string()
      .required("First name is required")
      .max(25, "Max limit exceeded"),
    lastName: Yup.string()
      .required("Last name is required")
      .max(25, "Max limit exceeded"),
    gender: Yup.mixed()
      .oneOf(["male", "female", "other"], "Invalid gender")
      .defined()
      .required(),
    dob: Yup.date()
      .nullable()
      .min(new Date(1900, 0, 1), "Invalid Date")
      .max(new Date(), "Invalid Date")
      .optional(),
  });

  const formik = useFormik({
    initialValues: {
      firstName: "",
      middleName: "",
      lastName: "",
      gender: "",
      dob: "",
    },
    validationSchema,
    onSubmit: (values) => {
      webHandleSubmit(values);
    },
  });

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await getPatientProfile();
      setLoading(false);
      if (response.status == 0) {
        toast.error(response.message);
      }
      if (response.status == 1) {
        //toast.success(response.message);
        setUserProfile(response.data);
        const userPro = response.data;
        setSalutation(userPro?.user?.salutation);
        const newValues = {
          salutation: salutation,
          firstName: userPro?.user?.firstName,
          middleName: userPro?.user?.middleName || "",
          lastName: userPro?.user?.lastName,
          gender: userPro?.user?.gender,
          dob: userPro?.user?.dob,
        };
        formik.setValues(newValues);
      }
    } catch (error) {
      setLoading(false);
      toast.error(error);
    }
  };

  function onScaleChange(value) {
    setAvatarImageScale(value);
  }

  async function uploadAvatarImage() {
    setSubmitting(true);
    let avatarImageUrl = "";
    if (avatarImageEditor.current) {
      // const avatarDataUrl = avatarImageEditor.current
      //   .getImageScaledToCanvas()
      //   .toDataURL();
      //upload file
      let fileData = {
        file: avatarImage,
        destination: "/avatar",
      };
      avatarImageUrl = await servicePostFileUpload(fileData);

      let data = {
        avatarImageUrl: avatarImageUrl?.data?.filePath,
      };
      const registerData = await updatePatientProfileImage(data);

      if (registerData.status == 0) {
        setSubmitting(false);
        toast.error(registerData.message);
      }
      if (registerData.status == 1) {
        setSubmitting(false);
        setAvatarImage(null);
        toast.success(registerData.message);
        window.location.reload();
      }
    } else {
      return null;
    }
  }

  async function webHandleSubmit(_values) {
    try {
      setError("");
      setLoading(true);
      let data = _values;
      const registerData = await updatePatientProfile(data);

      if (registerData.status == 0) {
        toast.error(registerData.message);
      }
      if (registerData.status == 1) {
        toast.success(registerData.message);
      }
      setLoading(false);
    } catch (e) {
      setError(e.message);
      setLoading(false);
    }
  }

  const handleSalutationChange = (event) => {
    setSalutation(event.target.value);
  };

  return (
    <div>
      {/* <SectionTitle>Account Settings</SectionTitle> */}
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <div className="profile__image__box">
              {!avatarImage ? (
                userProfile?.user?.avatarImageUrl ? (
                  <img
                    src={userProfile?.user.avatarImageUrl}
                    className="margin-auto rounded-full col-span-3 profile__img__setting"
                    alt="Avatar"
                  />
                ) : (
                  <DefaultAvatar className="m-auto profile__img__setting" />
                )
              ) : (
                <>
                  <AvatarEditor
                    ref={avatarImageEditor}
                    className="justify-self-center w-full"
                    color={[0, 0, 0, 0.6]} // RGBA
                    scale={avatarImageScale}
                    rotate={0}
                    image={avatarImage}
                  />
                  <div className="flex items-center mt-2 w-[250px]">
                    <ZoomOutIcon className="h-5 w-5 text-gray-600 " />
                    <Slider
                      className="mx-3"
                      min={1}
                      max={2}
                      step={0.01}
                      defaultValue={1.2}
                      onChange={onScaleChange}
                    />
                    <ZoomInIcon className="h-5 w-5 text-gray-600" />
                  </div>
                </>
              )}
              {avatarImage ? (
                <>
                  <Button onClick={() => setAvatarImage(null)} layout="neutral">
                    Cancel
                  </Button>
                  <Button
                    onClick={uploadAvatarImage}
                    layout="submit"
                    disabled={submitting}
                  >
                    {submitting && (
                      <SmallLoadingSpinner className="h-5 w-5 mr-2 -ml-1" />
                    )}
                    Save
                  </Button>
                </>
              ) : (
                <>
                  <Button
                    className="btn__profile__change"
                    onClick={() => avatarImageInput.current.click()}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="w-6 h-6"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
                      />
                    </svg>
                  </Button>
                  <input
                    type="file"
                    accept="image/*"
                    className="hidden"
                    ref={avatarImageInput}
                    onChange={(e) => setAvatarImage(e.target.files[0])}
                  />
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="gap-3 setting__form">
        <div className="py-3 border-gray-400 col-span-3 grid grid-cols-2 gap-y-3 items-center">
          <form
            onSubmit={formik.handleSubmit}
            action="#"
            method="POST"
            className="col-span-2"
          >
            <div className="form__box__container">
              <div className="grid grid-cols-4 gap-3">
                <div className="col-span-2 sm:col-span-1">
                  <label className="form-label">Salutation</label>
                  <select
                    class="form-control cust__form"
                    name="salutation"
                    value={salutation}
                    onChange={handleSalutationChange}
                  >
                    <option value="Mr">Mr</option>
                    <option value="Mrs">Mrs</option>
                    <option value="Ms">Ms</option>
                  </select>
                </div>

                <div className="col-span-2 sm:col-span-1">
                  <label className="form-label">First Name</label>
                  <input
                    type="text"
                    name="firstName"
                    id="firstName"
                    style={{ textTransform: "capitalize" }}
                    placeholder="First Name"
                    className={classnames("form-control cust__form", {
                      "border-red-500":
                        formik.touched.firstName && formik.errors.firstName,
                    })}
                    {...formik.getFieldProps("firstName")}
                  />
                  {formik.touched.firstName && formik.errors.firstName && (
                    <p className="block text-sm font-medium text-red-700 mt-2">
                      Last name is required
                    </p>
                  )}
                </div>
                <div className="col-span-2 sm:col-span-1">
                  <label className="form-label">Middle Name</label>
                  <input
                    type="text"
                    name="middleName"
                    id="middleName"
                    placeholder="Middle Name"
                    style={{ textTransform: "capitalize" }}
                    className="form-control cust__form"
                    {...formik.getFieldProps("middleName")}
                  />
                </div>
                <div className="col-span-2 sm:col-span-1">
                  <label className="form-label">Last Name</label>
                  <input
                    type="text"
                    name="lastName"
                    id="lastName"
                    placeholder="Last Name"
                    style={{ textTransform: "capitalize" }}
                    className={classnames("form-control cust__form", {
                      "border-red-500":
                        formik.touched.lastName && formik.errors.lastName,
                    })}
                    {...formik.getFieldProps("lastName")}
                  />
                  {formik.touched.lastName && formik.errors.lastName && (
                    <p className="block text-sm font-medium text-red-700 mt-2">
                      Last name is required
                    </p>
                  )}
                </div>

                <div className="col-span-2 sm:col-span-1">
                  <label className="form-label">Email</label>
                  <input
                    type="text"
                    name="email"
                    placeholder="Email"
                    className={classnames("form-control cust__form")}
                    disabled={true}
                    defaultValue={userProfile?.user?.email}
                  />
                </div>
                <div className="col-span-2 sm:col-span-1">
                  <label className="form-label">Mobile</label>
                  <input
                    type="text"
                    name="contactNumber"
                    placeholder="Mobile"
                    className={classnames("form-control cust__form")}
                    disabled={true}
                    defaultValue={userProfile?.user?.contactNumber}
                  />
                </div>

                <div className="col-span-2 sm:col-span-1">
                  <label className="form-label">Gender</label>
                  <select
                    name="gender"
                    id="gender"
                    placeholder="Gender"
                    {...formik.getFieldProps("gender")}
                    className={classnames("form-control cust__form", {
                      "border-red-500":
                        formik.touched.gender && formik.errors.gender,
                    })}
                  >
                    <option value="">Please select gender</option>
                    <option value={"male"}>Male</option>
                    <option value={"female"}>Female</option>
                  </select>
                  {formik.touched.gender && formik.errors.gender && (
                    <p className="block text-sm font-medium text-red-700 mt-2">
                      {formik.errors.gender}
                    </p>
                  )}
                </div>
                <div className="col-span-2 sm:col-span-1">
                  <label className="form-label">Date of Birth</label>
                  <input
                    type="date"
                    name="dob"
                    id="dob"
                    placeholder="dd-mm-yyyy"
                    max={new Date()}
                    className={classnames("form-control cust__form", {
                      "border-red-500": formik.touched.dob && formik.errors.dob,
                    })}
                    {...formik.getFieldProps("dob")}
                  />
                  {formik.touched.dob && formik.errors.dob && (
                    <p className="block text-sm font-medium text-red-700 mt-2">
                      {formik.errors.dob}
                    </p>
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <Button type="submit" disabled={loading}>
                    {loading && (
                      <SmallLoadingSpinner className="h-5 w-5 mr-2 -ml-1" />
                    )}
                    Update
                  </Button>
                </div>
              </div>
            </div>
          </form>
        </div>
        {/* <div className='form__box__container mb-3'>
                    <div className='row'>
                        <div className='col-md-4'>
                            <label className="form-label">
                            Add Documents
                            </label>
                            <input
                                type="file"
                                name="Specialization"
                                placeholder="Add Documents"
                                className={classnames("form-control cust__form",
                                )}
                            />
                        </div>
                    </div>
                </div> */}
        {/* <div class="upcoming__head"><h2>Contact Number</h2></div>
                <form  className="form__box__container">
                
                        <div className='row'>
                            <div className='col-md-4'>
                                <input
                                    type="tel"
                                    name="contact-number"
                                    id="contact-number"
                                    placeholder="Enter contact number"
                                    className={classnames("form-control cust__form",
                                        {
                                            'border-red-500': false
                                        })}
                                    defaultValue={userProfile?.user?.contactNumber}
                                />
                            </div>
                            <div className='col-md-4'>
                                <div className="">
                                    <Button
                                        type="submit"
                                        layout="submit"
                                        disabled={submitting}>{submitting &&
                                        
                                        <SmallLoadingSpinner className="h-5 w-5 mr-2 -ml-1"/>}Update </Button>
                                </div>
                            </div>
                        </div>
                    </form> */}

        <div className="py-3 border-b-2 border-gray-400 items-center">
          <div class="upcoming__head">
            <h2>Change password</h2>
          </div>
          <div className="form__box__container">
            <ChangePasswordForm />
          </div>
        </div>
      </div>
    </div>
  );
};

export default GeneralAccountPatientSettings;
