const apiCall = require("../../Api");

const DoctorRatingAndReview = {};

DoctorRatingAndReview.getRatingsAndReviews = async (page, search) => {
  const doctorID = localStorage.getItem("uid");
  let _response = await apiCall.getData(
    `/api/V1/doctor/reviews?doctorID=${doctorID}&page=${page}&search=${search}`
  );
  return _response;
};

export default DoctorRatingAndReview;
