const apiCall = require('../../Api')

const getReviewList = async (skip, take, uid = "", keyword = "") => {
    try {
        let _response = await apiCall.getData('/api/V1/admin/get-patient-reviews?skip=' + skip + '&take=' + take + '&id=' + uid.trim() + '&search=' + keyword)

        return _response
    } catch (error) {
        console.error('Error posting data:', error);
        throw error;
    }
};

const hideReview = async (data) => {
    try {
        let _response = await apiCall.postRawJsonData('/api/V1/admin/hide-review?uid=' + data.uid, data)
        return _response
    } catch (error) {
        console.error('Error posting data:', error);
        throw error;
    }
};

const toggleReview = async (data) => {
    try {
        let _response = await apiCall.postRawJsonData('/api/V1/reviews/toggleReview', data)
        return _response
    } catch (error) {
        console.error('Error posting data:', error);
        throw error;
    }
};

const getDoctorsList = async (search = "") => {
    try {
        let _response = await apiCall.getData('/api/V1/admin/get-doctors?search=' + search)
        return _response
    } catch (error) {
        console.error('Error posting data:', error);
        throw error;
    }
};

export { 
    getReviewList, 
    hideReview, 
    getDoctorsList, 
    toggleReview 
}