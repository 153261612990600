import React, {useState} from 'react';
import BloodSugarLineGraph from "../../components/BloodSugarLineGraph";
import {Tab} from "@headlessui/react";
import classNames from "classnames";
import PatientBloodSugarTable from "../../components/patient/PatientBloodSugarTable";

const DoctorBloodSugarView = (props) => {

    const [selectedIndex, setSelectedIndex] = useState(0)

    return (
        <div>
            {/*<BloodSugarLineGraph patientUid={props.patientUid}/>*/}
            <Tab.Group selectedIndex={selectedIndex} onChange={setSelectedIndex}>
                <div className="w-full flex justify-center items-center justify-between">

                    <Tab.List
                        className="bg-gray-200 hover:bg-gray-300 rounded-lg flex max-w-xs grow">
                        <Tab className={({selected}) =>
                            classNames(
                                'w-full py-1 text-sm leading-5 font-medium rounded-lg',
                                'focus-visible::outline-none focus-visible:ring-2 ring-offset-2 ring-offset-blue-400 ring-white ring-opacity-60',
                                selected
                                    ? 'bg-white shadow'
                                    : ''
                            )
                        }>Graph</Tab>
                        <Tab className={({selected}) =>
                            classNames(
                                'w-full py-1 text-sm leading-5 font-medium rounded-lg',
                                'focus-visible:outline-none focus-visible:ring-2 ring-offset-2 ring-offset-blue-400 ring-white ring-opacity-60',
                                selected
                                    ? 'bg-white shadow'
                                    : ''
                            )
                        }>Table</Tab>
                    </Tab.List>

                </div>

                <Tab.Panels>
                    <Tab.Panel><BloodSugarLineGraph patientUid={props.patientUid}/></Tab.Panel>
                    <Tab.Panel><PatientBloodSugarTable patientUid={props.patientUid}/></Tab.Panel>
                </Tab.Panels>
            </Tab.Group>
        </div>
    );
};

export default DoctorBloodSugarView;