import React, {useRef, useState} from 'react';
import {useForm} from "react-hook-form";
import SectionTitle from "../../components/SectionTitle";
import Button from "../../components/Button";
import {CheckCircleIcon, XCircleIcon} from "@heroicons/react/solid";

const DoctorAsthmaFreedQuestionnaire = (props) => {
    const {register, formState: {errors}, handleSubmit} = useForm();

    const [hasSubmitted, setHasSubmitted] = useState(false)
    const [isPatientEligible, setIsPatientEligible] = useState(false)
    const [questionnaireData, setQuestionnaireData] = useState()
    const resultsRef = useRef(null)

    function onSubmit(data) {

        setQuestionnaireData(data)


        const isEligible = Object.values(data).some(answer => answer === "yes")
        resultsRef.current.scrollIntoView()
        setIsPatientEligible(isEligible)
        setHasSubmitted(true)
    }

    function onCreateAsthmaReferralMemo() {

        let descriptionString = ""
        descriptionString += "Dear Dr. Yeo CT, I am referring the abovementioned patient with Severe Asthma to you for further evaluation under the FREED program.\n" +
            "\n" +
            "Their Asthma ID is as follows:\n\n"
        descriptionString += "- This asthma patient " + (questionnaireData.systemic_corticosteroids === "yes" ? "HAS" : "HAS NOT") + " required ≥2 systemic corticosteroids in a year.\n"
        descriptionString += "- This asthma patient " + (questionnaireData.unscheduled_visits === "yes" ? "HAS" : "HAS NOT") + " had ≥2 unscheduled visits to me or to the A&E.\n"
        descriptionString += "- This asthma patient " + (questionnaireData.intubated_or_icu === "yes" ? "HAS" : "HAS NOT") + " been intubated or admitted to ICU due to asthma.\n"
        descriptionString += "- This asthma patient " + (questionnaireData.frequent_inhalers === "yes" ? "DOES" : "DOES NOT") + " frequently require SABA inhalers (≥3 inhalers/year).\n\n"
        descriptionString += "Thank you for your expertise."

        const formData = {

            diagnoses: [{code: "CA23", title: "Asthma"}],
            description: descriptionString,
            isAsthmaFreed: true
        }


        props.onSubmitMemo(formData)
    }

    return (
        <div className="max-w-3xl">
            <SectionTitle className="mb-2">AsthmaID Questionnaire</SectionTitle>

            <p className="text-gray-500 mb-3 text-sm max-w-3xl">AsthmaID is a clinically validated scoring system to
                identify asthma patients that are at high risk of adverse outcomes and severe asthma exacerbation.
                Asthma FREED is a public health initiative lead by GP Plus Co-op, HealthLink and AstraZeneca to
                facilitate work-up of patients with high risk asthma.

            </p>

            <p className="text-gray-500 text-sm mb-6">Answer the following questions then press the "Submit" button.</p>

            <form onSubmit={handleSubmit(onSubmit)}>

                <fieldset className="mt-4">
                    <label className="text-base font-medium text-gray-900">Has your asthma patient required ≥2 systemic
                        corticosteroids in a year?</label>

                    <div className="space-y-2">
                        <div className="flex items-center">
                            <input id="yes" name="systemic_corticosteroids" type="radio" value="yes"
                                   className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                   {...register("systemic_corticosteroids", {required: true})}/>
                            <label htmlFor="yes"
                                   className="ml-3 block text-sm font-medium text-gray-700">Yes</label>
                        </div>

                        <div className="flex items-center">
                            <input id="no" name="systemic_corticosteroids" type="radio" value="no"
                                   className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                   {...register("systemic_corticosteroids", {required: true})}/>
                            <label htmlFor="no" className="ml-3 block text-sm font-medium text-gray-700">No</label>
                        </div>

                    </div>
                </fieldset>

                <fieldset className="mt-4">
                    <label className="text-base font-medium text-gray-900">Has your asthma patient had ≥2 unscheduled
                        visits to you or to the A&E?</label>

                    <div className="space-y-2">
                        <div className="flex items-center">
                            <input id="yes" name="unscheduled_visits" type="radio" value="yes"
                                   className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                   {...register("unscheduled_visits", {required: true})}/>
                            <label htmlFor="yes"
                                   className="ml-3 block text-sm font-medium text-gray-700">Yes</label>
                        </div>

                        <div className="flex items-center">
                            <input id="no" name="unscheduled_visits" type="radio" value="no"
                                   className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                   {...register("unscheduled_visits", {required: true})}/>
                            <label htmlFor="no" className="ml-3 block text-sm font-medium text-gray-700">No</label>
                        </div>

                    </div>
                </fieldset>

                <fieldset className="mt-4">
                    <label className="text-base font-medium text-gray-900">Has your asthma patient been intubated or
                        admitted to ICU due to asthma?</label>

                    <div className="space-y-2">
                        <div className="flex items-center">
                            <input id="yes" name="intubated_or_icu" type="radio" value="yes"
                                   className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                   {...register("intubated_or_icu", {required: true})}/>
                            <label htmlFor="yes"
                                   className="ml-3 block text-sm font-medium text-gray-700">Yes</label>
                        </div>

                        <div className="flex items-center">
                            <input id="no" name="intubated_or_icu" type="radio" value="no"
                                   className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                   {...register("intubated_or_icu", {required: true})}/>
                            <label htmlFor="no" className="ml-3 block text-sm font-medium text-gray-700">No</label>
                        </div>

                    </div>
                </fieldset>

                <fieldset className="mt-4">
                    <label className="text-base font-medium text-gray-900">Does your asthma patient frequently require
                        SABA inhalers? (≥3 inhalers/year)</label>

                    <div className="space-y-2">
                        <div className="flex items-center">
                            <input id="yes" name="frequent_inhalers" type="radio" value="yes"
                                   className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                   {...register("frequent_inhalers", {required: true})}/>
                            <label htmlFor="yes"
                                   className="ml-3 block text-sm font-medium text-gray-700">Yes</label>
                        </div>

                        <div className="flex items-center">
                            <input id="no" name="frequent_inhalers" type="radio" value="no"
                                   className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                   {...register("frequent_inhalers", {required: true})}/>
                            <label htmlFor="no" className="ml-3 block text-sm font-medium text-gray-700">No</label>
                        </div>

                    </div>
                </fieldset>
                {(errors.frequent_inhalers || errors.intubated_or_icu || errors.unscheduled_visits || errors.systemic_corticosteroids) &&
                    <p className="block text-sm font-medium text-red-700 mt-2">Please answer all questions.</p>}
                <Button type="submit" className="my-3"
                        disabled={hasSubmitted}>{hasSubmitted ? <>Submitted <CheckCircleIcon
                    className="h-5 w-5 ml-2 -mr-1"/></> : "Submit"}</Button>


            </form>
            <div ref={resultsRef}>
                {(hasSubmitted && isPatientEligible) &&
                    <div className="rounded-md bg-green-100 p-4 mb-8">
                        <div className="flex">
                            <div className="flex-shrink-0">
                                <CheckCircleIcon className="h-5 w-5 text-green-400"/>

                            </div>
                            <div className="ml-3">
                                <h3 className="text-sm font-medium text-green-800">This patient is <b>ELIGIBLE</b> for
                                    the Asthma
                                    FREED program
                                    based on your responses above. <br/><br/>Please follow these steps for them to
                                    receive
                                    complimentary asthma investigations:</h3>
                                <div className="mt-2 text-sm text-green-700">
                                    <p className="mb-3">1. Fill up and print out the following form for
                                        the
                                        patient to bring with them: <a className="text-blue-700"
                                                                       href="https://form.jotform.com/SGRespi/GPFREED"
                                                                       target="_blank">https://form.jotform.com/SGRespi/GPFREED</a>
                                    </p>
                                    <p className="">2. Issue a new memo auto-populated with this
                                        questionnaire's results by clicking the below button. Please ensure Dr. Yeo Chor
                                        Tzein is referred on the memo.</p>


                                </div>
                                <div className="mt-4">
                                    <div className="-mx-2 -my-1.5 flex">
                                        <button type="button" onClick={onCreateAsthmaReferralMemo}
                                                className="rounded-md bg-green-50 px-2 py-1.5 text-sm font-bold text-lg text-green-800 hover:bg-green-100 focus:outline-none focus:ring-2 focus:ring-green-600 focus:ring-offset-2 focus:ring-offset-green-50">
                                            Submit Memo <span aria-hidden="true"> &rarr;</span>
                                        </button>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                {(hasSubmitted && !isPatientEligible) &&

                    <div className="rounded-md bg-red-50 p-4">
                        <div className="flex">
                            <div className="flex-shrink-0">
                                <XCircleIcon className="h-5 w-5 text-red-400"/>
                            </div>
                            <div className="ml-3">
                                <h3 className="text-sm font-medium text-red-800">This patient is NOT eligible for the
                                    Asthma
                                    FREED program based on your responses above, as they do not have high-risk asthma.
                                    Congrats on managing this patient well! :)</h3>

                            </div>
                        </div>
                    </div>
                }
            </div>
        </div>
    );
};

export default DoctorAsthmaFreedQuestionnaire;