import React from 'react';
import GeneralAccountClinicSettings from '../../components/GenearalAccountClinicSettings';

const ClinicProfile = () => {
    return (
        <div className="w-100">
            <GeneralAccountClinicSettings />
        </div>
    );
};

export default ClinicProfile;