import React, { useEffect, useState } from "react";
import Table from "../../components/table/Table";
import TableHeader from "../../components/table/TableHeader";
import TableCell from "../../components/table/TableCell";
import TableBody from "../../components/table/TableBody";
import TableRow from "../../components/table/TableRow";
import TableFooter from "../../components/table/TableFooter";
import TableContainer from "../../components/table/TableContainer";
import LoadingDots from "../../components/LoadingDots";
import Pagination from "../../components/Pagination";
import SectionTitle from "../../components/SectionTitle";
import SearchIcon from "../../dashboard/sidenavigation/icons/search";
import RecordPlaceholder from "../../components/RecordPlaceholder";
import { useAuth } from "../../useAuth";
import {
  serviceListDoctorClinics,
  serviceListDoctorPatients,
} from "../../services/doctor/services";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";

const DoctorPatientList = () => {
  const { userData } = useAuth();
  const [loading, setLoading] = useState(true);
  const [clinicLoading, setClinicLoading] = useState(true);
  const [dataTable, setDataTable] = useState(true);
  const [pageTable, setPageTable] = useState(0);
  const [totalResults, setTotalResults] = useState(0);
  const [clinicID, setClinicID] = useState("");
  const [clinics, setClinics] = useState([]);
  const history = useHistory();
  const resultsPerPage = 10;

  function onPageChangeTable(p) {
    setPageTable(p - 1);
  }

  useEffect(() => {
    getDoctorClinicList();
  }, []);

  useEffect(() => {
    fetchPatientList("");
  }, [pageTable]);

  useEffect(() => {
    fetchPatientList();
  }, [clinicID]);

  async function fetchPatientList(search = "") {
    setLoading(false);
    setTotalResults(20);
    const res = await serviceListDoctorPatients(
      userData.uid,
      pageTable,
      clinicID,
      search
    );
    if (res.status == 1) {
      setDataTable([...res.data]);
      setTotalResults(res.totalCount);
      setLoading(false);
    } else {
      toast.error("Failed to list patients. Please try again!");
    }
  }

  async function getDoctorClinicList() {
    let clinicListResponse = await serviceListDoctorClinics(
      localStorage.getItem("uid"),
      true
    );
    if (clinicListResponse?.status == 1) {
      setClinics(clinicListResponse.data);
    } else {
      toast.error("Failed to load clinics. Please try again!");
    }
    setClinicLoading(false);
  }

  const searchPatient = (event) => {
    let search = document.getElementById("search").value;
    setLoading(true);
    fetchPatientList(search);
  };

  return (
    <>
      <SectionTitle>Patients </SectionTitle>
      <div className="container-fluid">
        <div className="row">
          <div class="col-6 ps-0 d-flex">
            <div style={{ width: "100%" }}>
              <select
                id="clinic"
                class="form-control form-control"
                onChange={(e) => setClinicID(e.target.value)}
                disabled={clinicLoading}
              >
                {clinicLoading ? (
                  <option>Loading...</option>
                ) : (
                  <option value="">Select Clinic</option>
                )}
                {clinics && clinics.length > 0
                  ? clinics.map((el) => (
                      <option value={el.clinicID}>{el?.clinic?.name}</option>
                    ))
                  : ""}
              </select>
            </div>
          </div>
          <div class="col-6 ps-0 d-flex">
            <input
              type="search"
              class="form-control cust__form"
              id="search"
              placeholder="Search Patient"
            />
            <button
              type="button"
              class="btn d-flex ms-3"
              style={{ background: "#4F46E5", color: "white" }}
              onClick={() => searchPatient()}
            >
              <SearchIcon />
              <p className="m-0">Search</p>
            </button>
          </div>
        </div>
      </div>
      {!loading ? (
        <TableContainer className="form__box__container mb-8 mt-3">
          <Table>
            <TableHeader>
              <TableRow>
                <TableCell>Sr.</TableCell>
                <TableCell>Patient Name</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Contact Number</TableCell>
                <TableCell>Age</TableCell>
                <TableCell>Gender</TableCell>
              </TableRow>
            </TableHeader>
            <TableBody>
              {dataTable && dataTable.length > 0 ? (
                dataTable.map((el, i) => (
                  <TableRow
                    key={i}
                    onClick={() => history.push(`patientDetails/${el?.uid}`)}
                    style={{ cursor: "pointer" }}
                    className="active-hover"
                  >
                    <TableCell>
                      <p className="font-semibold text-sm">
                        {pageTable * resultsPerPage + (i + 1)}
                      </p>
                    </TableCell>
                    <TableCell>
                      <p className="font-semibold text-sm">
                        {el.firstName} {el.lastName}
                      </p>
                    </TableCell>
                    <TableCell>
                      <p className="font-semibold text-sm">{el.email}</p>
                    </TableCell>
                    <TableCell>
                      <p className="font-semibold text-sm">
                        {el.contactNumber}
                      </p>
                    </TableCell>
                    <TableCell>
                      <p className="font-semibold text-sm">{el.age}</p>
                    </TableCell>
                    <TableCell>
                      <p className="font-semibold text-sm">{el.gender}</p>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <RecordPlaceholder table="true" />
              )}
            </TableBody>
          </Table>
          {totalResults > 0 && (
            <TableFooter>
              <Pagination
                totalResults={totalResults}
                resultsPerPage={resultsPerPage}
                onChange={onPageChangeTable}
                label="Table navigation"
              />
            </TableFooter>
          )}
        </TableContainer>
      ) : (
        <LoadingDots />
      )}
    </>
  );
};

export default DoctorPatientList;
