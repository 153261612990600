import React, {useEffect, useState} from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    TimeScale,
} from 'chart.js';
import annotationPlugin from 'chartjs-plugin-annotation';
import {Line} from 'react-chartjs-2';
import 'chartjs-adapter-luxon'
import {axiosInstance} from "../common/constants";
import LoadingSpinner from "./LoadingSpinner";
import {DateTime} from "luxon";
import {DatePickerInput} from "@mantine/dates";
import {RadioGroup} from "@headlessui/react";
import LoadingDots from "./LoadingDots";
import zoomPlugin from 'chartjs-plugin-zoom';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    TimeScale,
    annotationPlugin,
    zoomPlugin
);

const periodTypes = ["Daily", "Date Range"]
const beforeMealLow = 3.2
const beforeMealHigh = 7
const afterMealLow = 3.2
const afterMealHigh = 11

function formatMealType(value) {
    switch (value) {
        case "BEFORE_MEAL":
            return "Before Meal"
        case "AFTER_MEAL":
            return "After Meal"
        default:
            return ""
    }
}

const levelType = {
    LOW: "Low",
    HIGH: "High",
    NORMAL: "Normal"
}

function calculateSugarLevel(entry) {
    if (entry.mealType === "BEFORE_MEAL") {

        if (entry.bloodSugarValue < beforeMealLow) {
            return levelType.LOW
        } else if (entry.bloodSugarValue > beforeMealHigh) {
            return levelType.HIGH
        } else {
            return levelType.NORMAL
        }
    } else if (entry.mealType === "AFTER_MEAL") {
        if (entry.bloodSugarValue < afterMealLow) {
            return levelType.LOW
        } else if (entry.bloodSugarValue > afterMealHigh) {
            return levelType.HIGH
        } else {
            return levelType.NORMAL
        }
    } else {
        if (entry.bloodSugarValue < beforeMealLow) {
            return levelType.LOW
        } else if (entry.bloodSugarValue > afterMealHigh) {
            return levelType.HIGH
        } else {
            return levelType.NORMAL
        }
    }

}

const lowBloodSugar = (ctx, value) => ctx.p0.raw.levelType === levelType.LOW ? value : undefined
const highBloodSugar = (ctx, value) => ctx.p0.raw.levelType === levelType.HIGH ? value : undefined
const highColour = "#DC2626"
const lowColour = "#CA8A04"
const defaultColour = "#4f46e5"


const BloodSugarLineGraph = (props) => {

    const [loading, setLoading] = useState(true)
    const [hasData, setHasData] = useState(false)
    const [graphLoading, setGraphLoading] = useState(true)
    const [graphData, setGraphData] = useState({
        datasets: [{
            label: "Blood Sugar",
            data: {},
            tension: 0.1,
            borderColor: defaultColour,
            pointRadius: 3,
            pointHoverRadius: 10,
            // pointBackgroundColor: "#4f46e5",
            pointBackgroundColor: function (context) {
                let value = context.dataset.data[context.dataIndex];

                if (value.mealType === "BEFORE_MEAL") {

                    if (value.y < beforeMealLow) {
                        return lowColour
                    } else if (value.y > beforeMealHigh) {
                        return highColour
                    } else {
                        return defaultColour
                    }
                } else if (value.mealType === "AFTER_MEAL") {
                    if (value.y < afterMealLow) {
                        return lowColour
                    } else if (value.y > afterMealHigh) {
                        return highColour
                    } else {
                        return defaultColour
                    }
                } else {
                    if (value.y < beforeMealLow) {
                        return lowColour
                    } else if (value.y > afterMealHigh) {
                        return highColour
                    } else {
                        return defaultColour
                    }
                }

                // switch (value.mealType) {
                //     case "BEFORE_MEAL":
                //         return "#EA580C"
                //     case "AFTER_MEAL":
                //         return "#CA8A04"
                //     default:
                //         return "#4f46e5"
                // }
            },
            segment: {
                borderColor: ctx => lowBloodSugar(ctx, lowColour) || highBloodSugar(ctx, highColour)
            }
        }]

    })
    const [graphOptions, setGraphOptions] = useState({
        interaction: {
            intersect: false,
            mode: 'index',
        },
        scales: {
            x: {
                type: 'time',
                time: {
                    unit: 'hour',
                    displayFormats: {
                        hour: 'd/L t'
                    }
                },
                ticks: {
                    maxTicksLimit: 12
                }
            },
            y: {
                ticks: {
                    callback: function (value, index, ticks) {
                        return value + ' mmol/L';
                    }
                },
                suggestedMin: 0,
                suggestedMax: 15
            }
        },
        plugins: {
            tooltip: {callbacks: {footer: {}}},
            zoom: {
                pan: {
                    enabled: true,
                    mode: 'x'
                }
            },
            legend: {
                display: false,
            }
        },
        responsive: true,
        maintainAspectRatio: false
    })
    const [graphDate, setGraphDate] = useState()
    const [graphDateRange, setGraphDateRange] = useState()
    const [graphPeriodType, setGraphPeriodType] = useState(periodTypes[0])

    useEffect(() => {

        fetchLatestData()

    }, [props.pendingRefresh])

    async function fetchLatestData() {
        setLoading(true)
        setGraphLoading(true)
        let res
        if (props.patientUid == null) {
            res = await axiosInstance.get(`/patient/getLatestBloodSugar`)
        } else {
            res = await axiosInstance.get(`/doctor/getPatientLatestBloodSugar?patientUid=${props.patientUid}`)
        }
        if (res.data.records === null) {
            setHasData(false)
        } else {
            setHasData(true)

            graphData.datasets[0].data = res.data.records.map(function (entry) {
                return {
                    x: entry.entryTime,
                    y: entry.bloodSugarValue,
                    mealType: entry.mealType,
                    levelType: calculateSugarLevel(entry)
                }
            })


            const startDate = DateTime.fromISO(res.data.dateStr).set({hour: 0, minute: 0, second: 0})
            const endDate = startDate.plus({day: 1})

            graphOptions.scales.x.min = startDate.toString()
            graphOptions.scales.x.max = endDate.toString()
            graphOptions.plugins.tooltip.callbacks.footer = (context) => {
                return formatMealType(context[0].dataset.data[context[0].dataIndex].mealType)
            }

            setGraphDate(startDate.toJSDate())
            setGraphDateRange([startDate.toJSDate(), startDate.plus({days: 1}).toJSDate()])
        }

        setLoading(false)
        setGraphLoading(false)
    }

    async function getDateRangeBloodSugar(newGraphDateRange) {
        setGraphDateRange(newGraphDateRange)
        if (newGraphDateRange[1] == null) {
            return
        }

        setGraphLoading(true)

        let res
        if (props.patientUid == null) {
            res = await axiosInstance.get(`/patient/getDateBloodSugar?startDate=${newGraphDateRange[0].toISOString()}&endDate=${newGraphDateRange[1].toISOString()}`)
        } else {
            res = await axiosInstance.get(`/doctor/getPatientDateBloodSugar?patientUid=${props.patientUid}&startDate=${newGraphDateRange[0].toISOString()}&endDate=${newGraphDateRange[1].toISOString()}`)
        }

        if (res.data.length <= 0) {
            setHasData(false)
        } else {
            setHasData(true)

            graphData.datasets[0].data = res.data.map(function (entry) {
                return {
                    x: entry.entryTime,
                    y: entry.bloodSugarValue,
                    mealType: entry.mealType,
                    levelType: calculateSugarLevel(entry)
                }
            })

            graphOptions.scales.x.min = newGraphDateRange[0].toISOString()
            graphOptions.scales.x.max = DateTime.fromJSDate(newGraphDateRange[1]).plus({days: 1}).toISO()
        }

        setGraphLoading(false)

    }

    async function getDateBloodSugar(newDate) {
        setGraphLoading(true)
        setGraphDate(newDate)
        let res
        if (props.patientUid == null) {
            res = await axiosInstance.get(`/patient/getDateBloodSugar?startDate=${newDate.toISOString()}`)
        } else {
            res = await axiosInstance.get(`/doctor/getPatientDateBloodSugar?patientUid=${props.patientUid}&startDate=${newDate.toISOString()}`)
        }

        // const res = await axiosInstance.get(`/patient/getDateBloodSugar?startDate=${graphDate.toISOString()}`)
        if (res.data.length <= 0) {
            setHasData(false)
        } else {
            setHasData(true)

            graphData.datasets[0].data = res.data.map(function (entry) {
                return {
                    x: entry.entryTime,
                    y: entry.bloodSugarValue,
                    mealType: entry.mealType,
                    levelType: calculateSugarLevel(entry)
                }
            })

            const startDate = DateTime.fromJSDate(newDate).set({hour: 0, minute: 0, second: 0})
            const endDate = startDate.plus({day: 1})

            graphOptions.scales.x.min = startDate.toISO()
            graphOptions.scales.x.max = endDate.toISO()
        }

        setGraphLoading(false)
    }


    return (
        <div className="h-full">{
            loading ?
                <LoadingDots/> :
                <>
                    <div className="flex justify-center w-full space-x-2">

                        <div className="items-center space-x-2">
                            <RadioGroup className="my-3" value={graphPeriodType} onChange={setGraphPeriodType}>
                                <RadioGroup.Label className="sr-only">Period Type</RadioGroup.Label>
                                <div className="space-x-2 flex">
                                    {periodTypes.map((period) => (
                                        <RadioGroup.Option
                                            key={period}
                                            value={period}
                                            className={({active, checked}) =>
                                                `${
                                                    active
                                                        ? 'focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500' : ''}
                                    ${checked ? 'bg-indigo-600 text-white' : 'bg-white'}
                                    align-bottom whitespace-nowrap items-center justify-center cursor-pointer px-2 py-2 rounded-md text-sm text-white bg-white-600 border border-slate-500`}>
                                            {({active, checked}) => (
                                                <>
                                                    <RadioGroup.Label
                                                        as="p"
                                                        className={`font-medium  ${
                                                            checked ? 'text-white' : 'text-gray-500'
                                                        }`}
                                                    >
                                                        {period}
                                                    </RadioGroup.Label>
                                                </>
                                            )}
                                        </RadioGroup.Option>
                                    ))}
                                </div>
                            </RadioGroup>

                        </div>

                        <div className="basis-full sm:basis-auto flex space-x-2 w-auto items-center justify-center">
                            {graphPeriodType === periodTypes[0] &&
                                <>
                                    <DatePickerInput
                                        placeholder="Enter date"
                                        value={graphDate}
                                        onChange={getDateBloodSugar}
                                        clearable={false}
                                        className="w-full sm:w-60"
                                    />
                                </>
                            }
                            {graphPeriodType === periodTypes[1] &&
                                <>
                                    <DatePickerInput
                                        placeholder="Enter date range"
                                        type="range"
                                        value={graphDateRange}
                                        onChange={getDateRangeBloodSugar}
                                        clearable={false}
                                        className="w-full sm:w-60"
                                    />
                                </>}
                        </div>


                    </div>
                    <div className="flex items-center space-x-2 text-sm text-gray-600">
                        <span>Legend:</span>
                        <div className="flex items-center space-x-1">
                            <div style={{
                                backgroundColor: lowColour,
                            }} className="rounded-full h-3 w-3 border border-black"/>
                            <span>Low</span>
                        </div>
                        <div className="flex items-center space-x-1">
                            <div style={{
                                backgroundColor: defaultColour,
                            }} className="rounded-full h-3 w-3 border border-black"/>
                            <span>Normal</span>
                        </div>
                        <div className="flex items-center space-x-1">
                            <div style={{
                                backgroundColor: highColour,
                            }} className="rounded-full h-3 w-3 border border-black"/>
                            <span>High</span>
                        </div>
                    </div>
                    {graphLoading ?
                        <LoadingSpinner/>
                        :
                        <div className="h-[70vh]">
                            {
                                hasData ?
                                    <Line data={graphData} type="line"
                                          options={graphOptions}/>
                                    :
                                    <div className="text-center my-6">
                                        No records found.
                                    </div>
                            }
                        </div>
                    }
                </>
        }
        </div>
    );
};

export default BloodSugarLineGraph;