import React, { useEffect, useState } from "react";
import Table from "../../components/table/Table";
import TableHeader from "../../components/table/TableHeader";
import TableCell from "../../components/table/TableCell";
import TableBody from "../../components/table/TableBody";
import TableRow from "../../components/table/TableRow";
import TableFooter from "../../components/table/TableFooter";
import TableContainer from "../../components/table/TableContainer";
import LoadingDots from "../../components/LoadingDots";
import { Link, useRouteMatch } from "react-router-dom";
import Pagination from "../../components/Pagination";
import SectionTitle from "../../components/SectionTitle";
import {
  listClinicInvitations,
  serviceGetDoctorList,
  serviceListClinicDoctors,
  serviceRemoveClinicDoctors,
  serviceSendClinicInvite,
} from "../../services/clinic/clinicApiService";
import {
  servicePatientGetClinicDoctors,
  servicePostUpdateAppointmentStatus,
} from "../../services/apiService";
import { toast } from "react-toastify";
import ToastConfirmation from "../../components/toastrConfirmation";
import SearchIcon from "../../dashboard/sidenavigation/icons/search";
import RecordPlaceholder from "../../components/RecordPlaceholder";
import { useAuth } from "../../useAuth";
import { capitalizeFirstChar } from "../../helper/utils";
import TrashIcon from "../../dashboard/sidenavigation/icons/trash";

const ClinicDoctors = () => {
  const [loading, setLoading] = useState(true);
  const [dataTable, setDataTable] = useState([]);
  // const [selectedStatus, setSelectedStatus] = useState(false);
  const [pageTable, setPageTable] = useState(0);
  const [totalResults, setTotalResults] = useState(0);
  const [email, setEmail] = useState("");
  const clinicData = useAuth();
  const [isValid, setIsValid] = useState(true);
  const [validateEmail, setValidateEmail] = useState(false);
  const { userData } = useAuth();

  const resultsPerPage = 10;

  function onPageChangeTable(p) {
    setPageTable(p - 1);
  }

  useEffect(() => {
    fetchDoctorList();
  }, [pageTable]);

  async function fetchDoctorList(search = "") {
    // const res = await serviceGetDoctorList(pageTable, search);
    const res = await serviceListClinicDoctors(
      pageTable,
      search,
      userData.uid,
      true
    );
    setDataTable(res.data);
    setTotalResults(res.totalCount);
    setLoading(false);
  }

  async function statusChange(status, appointmentId) {
    let data = {
      appointmentID: appointmentId,
      accept: status,
    };
    // servicePostUpdateAppointmentStatus
    let _updateAppointmentRes = await servicePostUpdateAppointmentStatus(data);
    if (_updateAppointmentRes.status == 1) {
      try {
        setLoading(true);
        fetchDoctorList();
        setLoading(false);
        return toast.success("Appointment status updated successfully");
      } catch (e) {
        setLoading(false);
        return toast.error("Something went wrong,please try again");
      }
    }
  }

  const sendInvite = async (_doctorId, isCustomInvite) => {
    let data = "";
    setValidateEmail(false);
    setIsValid(true);
    if (isCustomInvite) {
      if (email == "") {
        setValidateEmail(true);
        return;
      }
      data = {
        email: email,
      };
    }

    // Validate email
    if (email.trim() != "") {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      let _validate = emailRegex.test(email);
      if (_validate == false) {
        setIsValid(emailRegex.test(email));
        return;
      }
    }

    if (!isCustomInvite) {
      data = {
        doctorID: _doctorId,
      };
    }

    setLoading(true);
    // servicePostUpdateAppointmentStatus
    let response = await serviceSendClinicInvite(data);
    if (response.status == 1) {
      try {
        setLoading(false);
        fetchDoctorList();
        setLoading(false);
        return toast.success("Invitation sent !");
      } catch (e) {
        setLoading(false);
        return toast.error("Something went wrong,please try again");
      }
    } else {
      setLoading(false);
      return toast.error("Something went wrong,please try again");
    }
  };

  const removeDoctor = async (doctorID) => {
    try {
      setLoading(true);
      const res = await serviceRemoveClinicDoctors(doctorID);
      if (!res || res.status != 1) {
        throw "Failed to remove doctor";
      }
      toast.success("Doctor removed successfully.");
      return fetchDoctorList();
    } catch (error) {
      toast.error("Failed to remove doctor.");
    } finally {
      setLoading(false);
    }
  };

  const handleConfirmation = (event, _doctorId) => {
    const _value = JSON.stringify({
      doctorID: _doctorId,
    });
    toast(
      <ToastConfirmation
        prop1={_value}
        prop2={handleConfirm}
        prop3={handleCancel}
      />,
      { position: toast.POSITION.TOP_CENTER, closeButton: false }
    );
  };

  const handleConfirm = async (event) => {
    toast.dismiss();
    const data = JSON.parse(event.target.dataset.userid);
    removeDoctor(data.doctorID);
  };

  const handleCancel = () => {
    toast.dismiss();
  };

  const searchAppointment = (event) => {
    let search = document.getElementById("search").value;
    setLoading(true);
    fetchDoctorList(search);
  };

  function checkClinic(_clinicsArr) {
    let isExist = false;
    //  _clinicsArr.filter(obj => obj.uid != clinicData.userData.uid)
    _clinicsArr.map((obj) => {
      if (clinicData.userData.uid == obj.uid) {
        isExist = true;
      }
    });

    return isExist;
  }

  function checkClinicStatus(obj) {
    let status = false;
    if (obj.isAccepted == "0" || obj.isAccepted == null) {
      status = `<p class="pending">Pending</p>`;
    } else if (obj.isAccepted == "1") {
      status = `<p class="success" >Accepted</p>`;
    } else if (obj.isAccepted == "2") {
      status = `<p class="fail">Rejected</p>`;
    }
    status = <div dangerouslySetInnerHTML={{ __html: status }} />;
    return status;
  }

  return (
    <>
      <SectionTitle>Doctors </SectionTitle>
      <div className="container-fluid">
        <div className="row">
          <div class="col-4 ps-0">
            <input
              type="search"
              class="form-control cust__form"
              id="search"
              placeholder="Search"
              // onKeyUp={(event) => searchReview(event)}
            />
          </div>
          <div class="col-2">
            <button
              type="button"
              class="btn d-flex"
              style={{ background: "#4F46E5", color: "white" }}
              onClick={() => searchAppointment()}
            >
              <SearchIcon />
              <p className="m-0">Search</p>
            </button>
          </div>
          <div class="col-4 ps-0">
            <input
              type="email"
              class="form-control cust__form"
              id="email"
              placeholder="Enter Email"
              onChange={(event) => setEmail(event.target.value)}
            />
            {isValid ? null : (
              <div className="form_errors">Please enter a valid email</div>
            )}
            {validateEmail ? (
              <div className="form_errors">Please enter email</div>
            ) : (
              ""
            )}
          </div>
          <div class="col-2">
            <button
              type="button"
              class="btn d-flex"
              style={{ background: "#4F46E5", color: "white" }}
              onClick={() => sendInvite("", true)}
            >
              <p className="m-0">Invite</p>
            </button>
          </div>
        </div>
      </div>
      {!loading ? (
        <TableContainer className="form__box__container mb-8 mt-3">
          <Table>
            <TableHeader>
              <TableRow>
                <TableCell>Sr. No</TableCell>
                <TableCell>&nbsp;&nbsp; Doctor Name & Email</TableCell>
                <TableCell>Specialization</TableCell>
                <TableCell>Experience (In Years)</TableCell>
                <TableCell>Contact</TableCell>
                {/* <TableCell>Status</TableCell> */}
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHeader>
            <TableBody>
              {dataTable && dataTable.length > 0 ? (
                dataTable.map((el, i) => (
                  <TableRow key={i}>
                    <TableCell>
                      <p className="font-semibold text-sm">{i + 1}</p>
                    </TableCell>

                    <TableCell>
                      <div className="flex items-center">
                        <div className="profile__history__img__mh">
                          {el.user?.avatarImageUrl ? (
                            <img
                              src={`${el.user.avatarImageUrl}`}
                              alt="Profile Image"
                              style={{
                                borderRadius: "50%",
                                width: "50px",
                                height: "50px",
                              }}
                            />
                          ) : (
                            <div
                              style={{
                                width: "50px",
                                height: "50px",
                                borderRadius: "50%",
                                backgroundColor: "#007BFF",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <span
                                style={{
                                  fontSize: "16px",
                                  fontWeight: "bold",
                                  color: "#FFFFFF",
                                }}
                              >
                                {el.user?.firstName.charAt(0)}
                                {el.user?.lastName.charAt(0)}
                              </span>
                            </div>
                          )}
                        </div>

                        {/* Name and Email */}
                        <div className="ml-4">
                          <p className="font-semibold text-sm">
                            <br />
                            Dr. {el.user.firstName} {el.user.lastName}
                            <br />
                            {el.user.email}
                          </p>
                        </div>
                      </div>
                    </TableCell>

                    <TableCell>
                      <p className="font-semibold text-sm">
                        {capitalizeFirstChar(
                          el.specialities[0]?.masterSpeciality.name
                        ) || "N/A"}
                      </p>
                    </TableCell>

                    <TableCell>
                      <p className="font-semibold text-sm">
                        {el.experinceInYears || "N/A"}
                      </p>
                    </TableCell>

                    <TableCell>
                      <p className="font-semibold text-sm">
                        {el.user.contactNumber}
                      </p>
                    </TableCell>
                    <TableCell>
                      <button
                        className="btn btn-outline-danger"
                        onClick={(event) => handleConfirmation(event, el.uid)}
                      >
                        <TrashIcon></TrashIcon>
                      </button>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <RecordPlaceholder table="true" />
              )}
            </TableBody>
          </Table>
          {totalResults > 0 && (
            <TableFooter>
              <Pagination
                totalResults={totalResults}
                resultsPerPage={resultsPerPage}
                onChange={onPageChangeTable}
                label="Table navigation"
              />
            </TableFooter>
          )}
        </TableContainer>
      ) : (
        <LoadingDots />
      )}
    </>
  );
};

export default ClinicDoctors;
