import React, {Fragment} from 'react';
import {Dialog, Transition} from '@headlessui/react'
import PatientBloodSugarEntry from "../../components/patient/PatientBloodSugarEntry";
import {XIcon} from "@heroicons/react/outline";
import Button from "../../components/Button";
import {toast} from "react-toastify";

const PatientBloodSugarPopup = (props) => {

    function dataSubmissionSuccess() {
        if (props.entry != null) {
            toast.success("Blood sugar entry added")
        } else {
            toast.success("Blood sugar entry updated")
        }
        props.onClose(true)
    }

    function dataSubmissionError() {
        props.onClose(true)
    }


    return (
        <Transition appear show={props.isOpen} as={Fragment}>
            <Dialog
                as="div"
                className="relative z-[200]"
                onClose={() => props.onClose(false)}
            >
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-40"
                    leave="duration-200"
                    leaveFrom="opacity-40"
                    leaveTo="opacity-0"
                >
                    <Dialog.Overlay className="fixed inset-0 bg-black transition-opacity"/>
                </Transition.Child>

                <div className="fixed z-[200] inset-0 overflow-y-auto">
                    <div className="flex items-center justify-center min-h-full p-4 text-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <Dialog.Panel
                                className="inline-block w-full max-w-md p-4 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                                <div className="flex items-center place-content-between">
                                    <Dialog.Title>{props.entryData
                                        ?
                                        "Edit Blood Sugar Entry"
                                        :
                                        "New Blood Sugar Entry"}</Dialog.Title>
                                    <Button icon={XIcon} layout="outline" aria-label="Close"
                                            onClick={() => props.onClose(false)}
                                            className="justify-self-end"/>
                                </div>

                                <PatientBloodSugarEntry onSuccess={dataSubmissionSuccess} onError={dataSubmissionError} entryData={props.entryData}/>

                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>
    );
};

export default PatientBloodSugarPopup;