import React, {useEffect, useState} from 'react';
import LoadingDots from "../../components/LoadingDots";
import {axiosInstance} from "../../common/constants";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import classnames from "classnames";
import Button from "../../components/Button";
import {PlusIcon} from "@heroicons/react/solid";
import SmallLoadingSpinner from "../../components/SmallLoadingSpinner";
import {useForm} from "react-hook-form";
import SectionTitle from "../../components/SectionTitle";
import {TrashIcon} from "@heroicons/react/outline";
import {toast} from "react-toastify";

const DoctorManageNotifications = () => {

    const {register, handleSubmit, formState: {errors}, reset} = useForm();
    const [loading, setLoading] = useState(true)
    const [submitting, setSubmitting] = useState(false)
    const [emailList, setEmailList] = useState([]);

    async function getEmailsNotified() {
        setLoading(true)

        const res = await axiosInstance.get(`/doctor/getNotificationEmails`)

        setEmailList(res.data)
        setLoading(false)


    }

    async function onSubmitNewEntry(data) {
        setSubmitting(true)
        const res = await axiosInstance.post(`/doctor/addNotificationEmail`, {email: data.newEmail})
        setSubmitting(false)
        reset()
        toast.success("Entry added successfully")
        await getEmailsNotified()

    }

    async function onDeleteEntry(emailToDelete) {
        setLoading(true)
        const res = await axiosInstance.post(`/doctor/deleteNotificationEmail`, {emailToDelete: emailToDelete})
        await getEmailsNotified()
        toast.success("Entry deleted successfully")
    }

    const actionBodyTemplate = (data) => {
        return <Button layout="danger" icon={TrashIcon} onClick={()=>onDeleteEntry(data.email)}></Button>;
    }

    useEffect(() => {
            getEmailsNotified()

    }, [])

    return (
        <div>
            <SectionTitle>Notification Emails to Your Clinic Admin</SectionTitle>
            <p>Email addresses added to this list will be notified whenever you SEND or RECEIVE a memo referral</p>



            {loading ? <LoadingDots/>

            :

                <DataTable value={emailList} size="small" emptyMessage="No emails notified" className="max-w-xl my-3" responsiveLayout="scroll">
                    <Column field="email" header="E-mail address"></Column>
                    <Column header="Delete" headerStyle={{ width: '4rem', textAlign: 'center' }} bodyStyle={{ textAlign: 'center', overflow: 'visible' }} body={actionBodyTemplate} />
                </DataTable>



            }

            <form onSubmit={handleSubmit(onSubmitNewEntry)} className="flex flex-col sm:flex-row">
                <input
                    type="text"
                    name="email-notification"
                    id="email-notification"
                    placeholder="Email Address"
                    disabled={submitting}
                    // className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    className={classnames('focus:ring-indigo-500 focus:border-indigo-500 shadow-sm sm:text-sm border-gray-300 rounded-md',
                        {
                            'border-red-500': errors.newEmail
                        }
                    )}
                    {...register("newEmail", {
                        required: "Enter an email address to be notified",
                        pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                            message: "Enter a valid e-mail address"
                        }
                    })}
                />
                <Button type="submit" layout="submit" iconRight={PlusIcon} className="mt-3 sm:mt-0 sm:ml-3" disabled={submitting}>
                    {submitting && <SmallLoadingSpinner className="h-5 w-5 mr-2 -ml-1"/>}Add to list</Button>
            </form>
            <span className="block text-sm font-medium text-red-700 mt-2">{errors.newEmail?.message}</span>
        </div>
    );
};

export default DoctorManageNotifications;