import React, { useRef, useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import LoadingDots from "../../../components/LoadingDots";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useFormik } from "formik";
import * as Yup from "yup";

// Sevices
import {
  getDoctorDetails,
  updateDoctor,
} from "../../../services/admin/serviceDoctor";
import { FormProvider, useForm } from "react-hook-form";
import { servicePostFileUpload } from "../../../services/clinic/clinicApiService";
import {
  serviceGetClinicList,
  serviceGetMasterSpecialityList,
} from "../../../services/apiService";
import FileDropzone from "../../../components/FileDropzone";
import { ReactSearchAutocomplete } from "react-search-autocomplete";

const Edit = () => {
  const { uid } = useParams();
  const history = useHistory();
  const methods = useForm({ mode: "onBlur" });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [userDetails, setDetails] = useState(null);
  const [userDoctorClinics, setDoctorClinics] = useState(null);
  const [isValueLoded, valueLoded] = useState(false);
  const [selectedDate, setSelectedDate] = useState("");
  const [item, setItem] = useState({});
  const [itemSpeciality, setItemSpeciality] = useState({});
  const [pageTable, setPageTable] = useState(0);
  const [clinic, setClinic] = useState("");
  const [specialty, setSpecialty] = useState("");
  const [doctorFile, setDoctorFile] = useState({});
  const [documentChanged, setDocumentChanged] = useState(false);
  const filezoneRef = useRef();
  //------------------------- FUCNCTIONS ------------------
  const goBack = () => {
    history.goBack();
  };
  // ---------- Get user details --------------
  useEffect(() => {
    if (userDetails == null) {
      fetchData();
      valueLoded(false);
    } else {
      valueLoded(true);
    }
  }, [userDetails]);

  const fetchData = async () => {
    try {
      //const uid = "IXvBbV9CFuhErgeFAlSO3c1VEAZ2";
      setLoading(true);
      const response = await getDoctorDetails(uid);
      setLoading(false);
      if (response.status == 0) {
        toast.error(response.message);
        setLoading(false);
      }
      if (response.status == 1) {
        let _data = response.data;
        if (_data.clinics) {
          _data.clinics = response.data.clinics.map((doctor) => {
            return doctor;
          });
        }

        setDetails(response.data);
        if (response.data.user["dob"]) {
          setSelectedDate(new Date(response.data.user["dob"]));
        }
      }
    } catch (error) {
      setLoading(false);
      toast.error(error);
    }
  };

  const validationSchema = Yup.object({
    salutation: Yup.string().required("Salutation is required"),
    firstName: Yup.string()
      .required("First name is required")
      .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field "),
    middleName: Yup.string().matches(
      /^[aA-zZ\s]+$/,
      "Only alphabets are allowed for this field "
    ),
    lastName: Yup.string()
      .required("Last name is required")
      .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field "),
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    contactNumber: Yup.string().required("Contact number is required"),
    // age: Yup.string().required("Age is required").min(1, 'Age must be 1 charactor long').max(3, 'Age should not exceed 3 charactor').test('is-number', 'Age must be a valid number', (value) => !isNaN(parseInt(Number(value))) && !/[eE+-]/.test(value.toString())),
    gender: Yup.string().required("Gender is required"),
    dob: Yup.string().required("Dob is required"),
    ["doctor.about"]: Yup.string().max(300, "Max limit exceeded").optional(),
    ["doctor.education"]: Yup.string().max(50, "Max limit exceeded").optional(),
    ["doctor.experinceInYears"]: Yup.number()
      .nullable()
      .min(0, "Min Experience of doctor is 0.")
      .max(80, "Max Experience of doctor is 80."),
    clinics: Yup.array().required("Clinic is required"),
    specialities: Yup.array().required("Speciality is required"),
    files: Yup.array(),
  });

  const formik = useFormik({
    initialValues: {
      salutation: userDetails?.user.salutation
        ? userDetails?.user.salutation
        : "",
      firstName: userDetails?.user.firstName ? userDetails?.user.firstName : "",
      middleName: userDetails?.user.middleName
        ? userDetails?.user.middleName
        : "",
      lastName: userDetails?.user.lastName ? userDetails?.user.lastName : "",
      email: userDetails?.user.email ? userDetails?.user.email : "",
      contactNumber: userDetails?.user.contactNumber
        ? userDetails?.user.contactNumber
        : "",
      age: userDetails?.user.age ? userDetails?.user.age : "",
      gender: userDetails?.user.gender
        ? userDetails?.user.gender?.toLowerCase()
        : "",
      dob: userDetails?.user.dob ? userDetails?.user.dob : "",
      doctor: {
        about: userDetails?.about ? userDetails.about : "",
        education: userDetails?.education ? userDetails.education : "",
        experinceInYears: userDetails?.experinceInYears
          ? userDetails.experinceInYears
          : "",
      },
      clinics: [],
      specialities: [],
      files: [],
    },
    validationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      webHandleSubmit(values);
    },
  });

  // --------------------------------------- submit the form
  async function webHandleSubmit(_values) {
    try {
      setError("");
      setLoading(true);
      let fileResponse = undefined;
      //let uid = "IXvBbV9CFuhErgeFAlSO3c1VEAZ2";

      //upload file
      if (doctorFile?.path) {
        let fileData = {
          file: doctorFile,
          destination: "/doctor",
        };
        fileResponse = await servicePostFileUpload(fileData);
        if (fileResponse.status == 0) {
          return toast.error(fileResponse.message);
        }
      }

      let data = {
        salutation: _values.salutation,
        firstName: _values.firstName,
        middleName: _values.middleName || "",
        lastName: _values.lastName,
        email: _values.email,
        contactNumber: _values.contactNumber,
        password: _values.password,
        gender: _values.salutation == "Mr" ? "Male" : "Female",
        age: _values?.age,
        dob: _values?.dob,
        // clinics: clinic,
        // specialities: specialty,
        doctor: _values.doctor,
        documents: [
          {
            name: documentChanged
              ? fileResponse.data.filePath.substring(
                  fileResponse.data.filePath.lastIndexOf("/") + 1
                )
              : userDetails?.user?.documents?.[0]?.name,
            documentUrl: documentChanged
              ? fileResponse.data.filePath
              : userDetails?.user?.documents?.[0]?.documentUrl,
          },
        ],
      };

      const registerData = await updateDoctor(data, uid);
      setLoading(false);
      if (registerData.status == 0) {
        toast.error(registerData.message);
      }
      if (registerData.status == 1) {
        toast.success(registerData.message);
        setDocumentChanged(false);
        filezoneRef?.current?.clearChild();
        history.push("/admin/doctor/list");
      }
    } catch (e) {
      toast.error(e.message);
      setLoading(false);
    }
  }

  const handleDateChange = async (value) => {
    let date = moment(value);
    let formattedDate = date.format("MM-DD-YYYY");
    setSelectedDate(value);
    formik.setFieldValue("dob", formattedDate);
  };
  // ----------------------------------------- RENDER HTML ----------------------------

  async function fetchClinics(keywords = "") {
    try {
      const res = await serviceGetClinicList(pageTable, keywords);

      setLoading(false);
      if (res.status == 0) {
        toast.error(res.message);
      }

      if (res.status == 1) {
        let _items = [];
        res.data?.forEach((element, index) => {
          if (element) {
            _items.push({ id: element.uid, name: element?.name });
          }
        });
        setItem(_items);
      }
    } catch (error) {
      toast.error(error.message);
      setLoading(false);
    }
  }

  async function fetchMasterSpeciality(keywords = "") {
    try {
      const res = await serviceGetMasterSpecialityList(keywords);

      setLoading(false);
      if (res.status == 0) {
        toast.error(res.message);
      }

      if (res.status == 1) {
        let _items = [];
        res.data?.forEach((element, index) => {
          if (element) {
            _items.push({ id: element.id, name: element?.name });
          }
        });
        setItemSpeciality(_items);
      }
    } catch (error) {
      toast.error(error.message);
      setLoading(false);
    }
  }

  const handleOnSelect = (item) => {
    setPageTable(0);
    let id = item.id.trim();
    let clincArr = [
      {
        clinicID: id,
      },
    ];
    setClinic([
      {
        clinicID: id,
      },
    ]);
    formik.setFieldValue("clinics", clincArr);
  };

  const handleOnSpecialitySelect = (item) => {
    setPageTable(0);
    let id = item.id.trim();
    let specialityArr = [
      {
        specialityId: id,
      },
    ];
    setSpecialty([
      {
        specialityId: id,
      },
    ]);
    formik.setFieldValue("specialities", specialityArr);
  };

  const onClear = () => {
    setClinic("");
    fetchClinics();
  };

  const onSpecialityClear = () => {
    setSpecialty("");
    fetchMasterSpeciality();
  };

  const formatResult = (item) => {
    return (
      <>
        {/* <span style={{ display: 'block', textAlign: 'left' }}>id: {item.id}</span> */}
        <span style={{ display: "block", textAlign: "left" }}>{item.name}</span>
      </>
    );
  };

  const handleOnSearch = (_value) => {
    fetchClinics(_value);
  };

  const handleOnSpecialitySearch = (_value) => {
    fetchMasterSpeciality(_value);
  };

  // useEffect(() => {
  //     fetchClinics();
  //     fetchMasterSpeciality();
  // }, [pageTable]);

  const handleFileSelect = async (_selectedFile) => {
    try {
      if (_selectedFile) {
        if (_selectedFile.size <= 5 * 1024 * 1024) {
          formik.setFieldValue("files", [_selectedFile]);
          setDoctorFile(_selectedFile);
          setDocumentChanged(true);
        } else {
          setDoctorFile(null);
          return toast.error("File size exceeds 5 MB");
        }
      }
    } catch (e) {
      toast.error(e.message);
    }
  };

  return isValueLoded ? (
    <div className="container-fluid">
      <div className="form__box__container">
        <FormProvider {...methods}>
          <form onSubmit={formik.handleSubmit} action="#" method="POST">
            <div className="row">
              <h3 className="head__weekly__report">Edit Doctor</h3>
              <div className="col-md-2">
                <div className="mb-2">
                  <label for="" className="form-label">
                    Salutation
                  </label>
                  <select
                    className="form-control cust__form"
                    {...formik.getFieldProps("salutation")}
                  >
                    <option
                      value="Mr"
                      selected={userDetails?.user.salutation == "Mr"}
                    >
                      Mr
                    </option>
                    <option
                      value="Mrs"
                      selected={userDetails?.user.salutation == "Mrs"}
                    >
                      Mrs
                    </option>
                    <option
                      value="Miss"
                      selected={userDetails?.user.salutation == "Miss"}
                    >
                      Miss
                    </option>
                  </select>

                  {formik.touched.salutation && formik.errors.salutation && (
                    <div className="form_errors">
                      {formik.errors.salutation}
                    </div>
                  )}
                </div>
              </div>
              <div className="col-md-3">
                <div className="mb-2">
                  <label for="" className="form-label">
                    First Name
                  </label>
                  <input
                    type="text"
                    className="form-control cust__form"
                    placeholder="Enter first name"
                    {...formik.getFieldProps("firstName")}
                  />
                  {formik.touched.firstName && formik.errors.firstName && (
                    <div className="form_errors">{formik.errors.firstName}</div>
                  )}
                </div>
              </div>
              <div className="col-md-3">
                <div className="mb-2">
                  <label for="" className="form-label">
                    Middle Name
                  </label>
                  <input
                    type="text"
                    placeholder="Enter middle name"
                    className="form-control cust__form"
                    {...formik.getFieldProps("middleName")}
                  />
                  {formik.touched.middleName && formik.errors.middleName && (
                    <div className="form_errors">
                      {formik.errors.middleName}
                    </div>
                  )}
                </div>
              </div>
              <div className="col-md-4">
                <div className="mb-2">
                  <label for="" className="form-label">
                    Last Name
                  </label>
                  <input
                    type="text"
                    placeholder="Enter last name"
                    className="form-control cust__form"
                    {...formik.getFieldProps("lastName")}
                  />
                  {formik.touched.lastName && formik.errors.lastName && (
                    <div className="form_errors">{formik.errors.lastName}</div>
                  )}
                </div>
              </div>
              <div className="col-md-4">
                <div className="mb-2">
                  <label for="" className="form-label">
                    Email ID
                  </label>
                  <input
                    type="email"
                    className="form-control cust__form"
                    {...formik.getFieldProps("email")}
                    readOnly
                  />
                  {formik.touched.email && formik.errors.email && (
                    <div className="form_errors">{formik.errors.email}</div>
                  )}
                </div>
              </div>
              {/* <div className="col-md-4">
                                    <div className="mb-2">
                                        <label for="" className="form-label">
                                            Age
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control cust__form"
                                            {...formik.getFieldProps("age")}
                                        />
                                        {formik.touched.age && formik.errors.age && (
                                            <div className='form_errors'>{formik.errors.age}</div>
                                        )}
                                    </div>
                                </div> */}
              <div className="col-md-4">
                <div className="mb-2">
                  <label for="" className="form-label">
                    Contact No.
                  </label>
                  <input
                    readOnly
                    type="text"
                    className="form-control cust__form"
                    {...formik.getFieldProps("contactNumber")}
                  />
                  {formik.touched.contactNumber &&
                    formik.errors.contactNumber && (
                      <div className="form_errors">
                        {formik.errors.contactNumber}
                      </div>
                    )}
                </div>
              </div>
              <div className="col-md-4">
                <div className="mb-2">
                  <label for="" className="form-label">
                    Gender
                  </label>
                  <div className="col-auto">
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        {...formik.getFieldProps("gender")}
                        id="maleCheck"
                        value={"male"}
                        onChange={formik.handleChange}
                        //checked={(userDetails?.gender == 'male') ? true : false}
                        checked={formik.values.gender == "male"}
                      />

                      <label className="form-check-label" for="maleCheck">
                        Male
                      </label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        {...formik.getFieldProps("gender")}
                        id="femalecheck"
                        value={"female"}
                        onChange={formik.handleChange}
                        //checked={(userDetails?.gender == 'female') ? true : false}
                        checked={formik.values.gender == "female"}
                      />
                      <label className="form-check-label" for="femalecheck">
                        Female
                      </label>
                    </div>
                    {formik.touched.gender && formik.errors.gender && (
                      <div className="form_errors">{formik.errors.gender}</div>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div class="mb-2">
                  <label for="" class="form-label">
                    Dob
                  </label>
                  <br></br>
                  <DatePicker
                    className="form-control cust__form"
                    selected={selectedDate}
                    onChange={handleDateChange}
                    placeholderText="Select a date"
                    dateFormat="MM-dd-yyyy"
                    showMonthDropdown="true"
                    showYearDropdown="true"
                    maxDate={new Date()}
                  />
                  <input
                    type="hidden"
                    {...formik.getFieldProps("dob")}
                    name="dob"
                  ></input>
                  {formik.touched.dob && formik.errors.dob && (
                    <div className="form_errors">{formik.errors.dob}</div>
                  )}
                </div>
              </div>

              {/* <div className="col-md-4">
                                    <div className="mb-2">
                                        <label for="" className="form-label">
                                            Specialization
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control cust__form"
                                            {...formik.getFieldProps("specilization")}
                                        />
                                        {formik.touched.specilization && formik.errors.specilization && (
                                            <div className='form_errors'>{formik.errors.specilization}</div>
                                        )}
                                    </div>
                                </div> */}
              <div className="col-md-4">
                <div className="mb-2">
                  <label for="" className="form-label">
                    Education
                  </label>
                  <input
                    type="text"
                    placeholder="Enter doctor education"
                    className="form-control cust__form"
                    {...formik.getFieldProps("doctor.education")}
                  />
                  {formik.touched?.["doctor.education"] &&
                    formik.errors?.["doctor.education"] && (
                      <div className="form_errors">
                        {formik.errors?.["doctor.education"]}
                      </div>
                    )}
                </div>
              </div>
              {/* <div className="col-md-4">
                                    <div className="mb-2">
                                        <label for="" className="form-label">
                                            Clinic Name
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control cust__form"
                                            {...formik.getFieldProps("clinicName")}
                                        />
                                        {formik.touched.clinicName && formik.errors.clinicName && (
                                            <div className='form_errors'>{formik.errors.clinicName}</div>
                                        )}
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="mb-2">
                                        <label for="" className="form-label">
                                            Address - 1
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control cust__form"
                                            {...formik.getFieldProps("address1")}
                                        />
                                        {formik.touched.address1 && formik.errors.address1 && (
                                            <div className='form_errors'>{formik.errors.address1}</div>
                                        )}
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="mb-2">
                                        <label for="" className="form-label">
                                            Landmark
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control cust__form"
                                            {...formik.getFieldProps("landmark")}
                                        />
                                        {formik.touched.landmark && formik.errors.landmark && (
                                            <div className='form_errors'>{formik.errors.landmark}</div>
                                        )}
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="mb-2">
                                        <label for="" className="form-label">
                                            City
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control cust__form"
                                            {...formik.getFieldProps("city")}
                                        />
                                        {formik.touched.city && formik.errors.city && (
                                            <div className='form_errors'>{formik.errors.city}</div>
                                        )}
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="mb-2">
                                        <label for="" className="form-label">
                                            State
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control cust__form"
                                            {...formik.getFieldProps("state")}
                                        />
                                        {formik.touched.state && formik.errors.state && (
                                            <div className='form_errors'>{formik.errors.state}</div>
                                        )}
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="mb-2">
                                        <label for="" className="form-label">
                                            Pincode
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control cust__form"
                                            {...formik.getFieldProps("pincode")}
                                        />
                                        {formik.touched.pincode && formik.errors.pincode && (
                                            <div className='form_errors'>{formik.errors.pincode}</div>
                                        )}
                                    </div>
                                </div> */}
              <div className="col-md-4">
                <div className="mb-2">
                  <label for="" className="form-label">
                    Total Experience (In Years)
                  </label>
                  <input
                    type="text"
                    placeholder="Enter doctor exprience"
                    className="form-control cust__form"
                    {...formik.getFieldProps("doctor.experinceInYears")}
                  />
                  {formik.touched?.["doctor.experinceInYears"] &&
                    formik.errors?.["doctor.experinceInYears"] && (
                      <div className="form_errors">
                        {formik.errors?.["doctor.experinceInYears"]}
                      </div>
                    )}
                </div>
              </div>
              <div className="col-md-4">
                <div className="mb-2">
                  <label for="" className="form-label">
                    About
                  </label>
                  <textarea
                    placeholder="Enter about us doctor"
                    className="form-control cust__form"
                    {...formik.getFieldProps("doctor.about")}
                  ></textarea>
                  {formik.touched?.["doctor.about"] &&
                    formik.errors?.["doctor.about"] && (
                      <div className="form_errors">
                        {formik.errors?.["doctor.about"]}
                      </div>
                    )}
                </div>
              </div>

              <div className="col-md-4">
                <label className="form-label">Clinic</label>
                {userDetails?.clinics?.map((item) => (
                  <p>{item?.clinic?.name}</p>
                ))}
                {/* <ReactSearchAutocomplete
                                        items={item}
                                        onSearch={handleOnSearch}
                                        //onHover={handleOnHover}
                                        onSelect={handleOnSelect}
                                        // onFocus={handleOnFocus}
                                        onClear={onClear}
                                        autoFocus
                                        formatResult={formatResult}
                                        placeholder="Select Clinic"
                                        {...formik.getFieldProps("clinic")}
                                    />
                                    {formik.touched.clinic && formik.errors.clinic && (
                                        <div className="form_errors">{formik.errors.clinic}</div>
                                    )} */}
              </div>
              <div className="col-md-4">
                <label className="form-label">Speciality</label>
                <p>
                  {userDetails?.specialities?.map((item) => (
                    <p>{item.masterSpeciality?.name}</p>
                  ))}
                </p>
                {/* <ReactSearchAutocomplete
                                        items={itemSpeciality}
                                        onSearch={handleOnSpecialitySearch}
                                        //onHover={handleOnHover}
                                        onSelect={handleOnSpecialitySelect}
                                        // onFocus={handleOnFocus}
                                        onClear={onSpecialityClear}
                                        autoFocus
                                        formatResult={formatResult}
                                        placeholder="Select Speciality"
                                        {...formik.getFieldProps("specialities")}
                                    />
                                    {formik.touched.specialities && formik.errors.specialities && (
                                        <div className="form_errors">{formik.errors.specialities}</div>
                                    )} */}
              </div>
              {/* <div className="col-span-12"> */}
              <div className="col-md-12 mb-3">
                <label className="form-label">
                  Doctor Document<span>&nbsp;</span>
                  {!documentChanged && (
                    <p>
                      <a href={userDetails?.user?.documents?.[0]?.documentUrl}>
                        {userDetails?.user?.documents?.[0]?.name
                          ? userDetails?.user?.documents?.[0]?.name
                          : userDetails?.user?.documents?.[0]?.documentUrl}
                      </a>
                    </p>
                  )}
                  <input
                    type="hidden"
                    className="form-control"
                    name="files"
                  ></input>
                  {formik.touched.files && formik.errors.files && (
                    <div className="form_errors">{formik.errors.files}</div>
                  )}
                </label>
                <FileDropzone
                  ref={filezoneRef}
                  accept=".pdf, image/jpeg, image/png, image/jpg"
                  name={!documentChanged ? "idImage" : null}
                  handleFileSelect={handleFileSelect}
                  onClear={setDocumentChanged}
                  clear={documentChanged}
                />
                <div className="form_errors">
                  Note: Please upload image or PDF files only, and the file size
                  should be below 5 MB{" "}
                </div>
              </div>

              {!loading ? (
                <div className="col-md-12 d-flex justify-content-between mt-3">
                  <button type="submit" className="btn save-btn">
                    Update
                  </button>
                  <button className="btn cancel-btn" onClick={goBack}>
                    Back
                  </button>
                </div>
              ) : (
                <LoadingDots />
              )}
            </div>
          </form>
        </FormProvider>
      </div>
    </div>
  ) : (
    <LoadingDots />
  );
};

export default Edit;
