import React, { useState, useEffect } from "react";
import SectionTitle from "../../components/SectionTitle";
import PatientSearchDoctors from "../../components/patient/PatientSearchDoctors";
import { useAuth } from "../../useAuth";
import { Link } from "react-router-dom";
import SubTitle from "../../components/SubTitle";
import PatientVerificationForm from "./PatientVerificationForm";
import PatientBookAppointment from "./PatientBookAppointment";
import { serviceGetHomeAppointments } from "../../services/apiService";
import { formatTime } from "../../services/dateService";
import { formatDate } from "../../components/FormatDate";
import RecordPlaceholder from "../../components/RecordPlaceholder";

const PatientHome = (props) => {
  const { userData } = useAuth();
  const [appointments, setAppointment] = useState([]);

  async function getAppointments() {
    try {
      let response = await serviceGetHomeAppointments();
      if (response.status == 1) {
        setAppointment(response.data);
      }
    } catch (error) {}
  }

  useEffect(() => {
    getAppointments();
  }, []);

  return (
    <div>
      <SectionTitle>
        Welcome to Healthlink {userData.firstName} {userData.lastName}
      </SectionTitle>

      {userData.verified && userData.doctors.length === 0 && (
        <>
          <div>
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-9 mb-3">
                  <div className="upcoming__head">
                    <h2></h2>
                  </div>
                  <div className="appointment__booking__box">
                    <PatientBookAppointment homePage="true" />
                  </div>
                </div>
                <div className="col-md-3 mb-3">
                  <div className="">
                    <h5>Upcoming Appointment Details</h5>
                  </div>
                  <div className="upcoming__appointment__box">
                    <ul
                      className="upcoming__app__hist"
                      style={{ listStyleType: "circle" }}
                    >
                      <li>
                        <span style={{ color: "black" }}>
                          <b>Doctor</b>
                        </span>
                        <span style={{ width: "50%" }}>
                          {" "}
                          <b>Date & Slot</b>
                        </span>
                      </li>

                      {appointments && appointments.length > 0 ? (
                        appointments.map((appointment) => (
                          <li>
                            <span
                              style={{ color: "black", fontWeight: "normal" }}
                            >
                              Dr.{" "}
                              {appointment.doctor.user["firstName"] +
                                " " +
                                appointment.doctor.user["lastName"]}
                            </span>
                            <span style={{ width: "50%" }}>
                              {formatDate(appointment.date)} <br />[
                              {formatTime(appointment.startTime)} -{" "}
                              {formatTime(appointment.endTime)}]{" "}
                            </span>
                          </li>
                        ))
                      ) : (
                        <RecordPlaceholder table="false" />
                      )}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            {userData.verified ? (
              <p className="patient__txt__p">
                {/* Your account is fully verified, please navigate using links in
                the side bar */}
              </p>
            ) : (
              <>
                <PatientVerificationForm
                  onSubmitVerification={props.onSubmitVerification}
                  userData={userData}
                />
              </>
            )}

            {/* <div class="upcoming__head">
            <h2>Invite Doctors</h2>
          </div> */}
            <p className="patient__txt__p">
              {/* You currently do not have any doctors managing your data. Add one
              by searching for their{" "}
              <span className="font-bold">name, email or medical practice</span>{" "}
              below */}
            </p>
            {/* <PatientSearchDoctors /> */}
          </div>
        </>
      )}
    </div>
  );
};

export default PatientHome;
