import React, { useEffect, useState } from "react";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import {
  serviceGetDoctorAppointments,
  serviceGetDoctorWeeklyAppointments,
} from "../services/apiService";
import RecordPlaceholder from "./RecordPlaceholder";
// import PlaceHolderClinicImage from "../../public/placeholderimage.png"
const records = {
  id: 1,
  schedule: [
    {
      start: "10:12",
      end: "11:14",
      appointment: [
        {
          name: "abc",
          contact: 78945,
        },
      ],
    },
    {
      start: "09:30",
      end: "10:45",
      appointment: [
        {
          name: "def",
          contact: 12345,
        },
      ],
    },
    {
      start: "14:00",
      end: "15:30",
      appointment: [
        {
          name: "ghi",
          contact: 67890,
        },
      ],
    },
    {
      start: "08:00",
      end: "09:15",
      appointment: [
        {
          name: "jkl",
          contact: 23456,
        },
      ],
    },
    {
      start: "13:45",
      end: "15:00",
      appointment: [
        {
          name: "mno",
          contact: 78901,
        },
      ],
    },
    {
      start: "12:30",
      end: "13:45",
      appointment: [
        {
          name: "pqr",
          contact: 34567,
        },
      ],
    },
    {
      start: "09:00",
      end: "10:30",
      appointment: [
        {
          name: "stu",
          contact: 89012,
        },
      ],
    },
    {
      start: "16:00",
      end: "17:30",
      appointment: [
        {
          name: "vwx",
          contact: 45678,
        },
      ],
    },
    {
      start: "11:00",
      end: "12:15",
      appointment: [
        {
          name: "yz",
          contact: 90123,
        },
      ],
    },
    {
      start: "14:30",
      end: "15:45",
      appointment: [
        {
          name: "123",
          contact: 56789,
        },
      ],
    },
  ],
};

const WeeklyScheduler = () => {
  const [currentDate, setCurrentDate] = useState(new Date());
  const [selectedDate, setSelectedData] = useState(null);
  const [appointmentSlot, setAppointmentSlot] = useState(null);
  const [weeklyDates, setWeeklyDates] = useState([]);

  useEffect(() => {
    const day = new Date();
    setSelectedData(day.getDate());
    let _selectedData =
      day.getMonth() + 1 + "/" + day.getDate() + "/" + day.getFullYear();
    getDoctorWeeklyAppointment(_selectedData);

    getDoctorAppointment(_selectedData);
  }, []);

  async function getDoctorAppointment(_date) {
    let response = await serviceGetDoctorAppointments(_date);

    if (response.status == 1) {
      setAppointmentSlot(response.data);
    }
  }

  async function getDoctorWeeklyAppointment(_date) {
    let response = await serviceGetDoctorWeeklyAppointments(_date);

    if (response.status == 1) {
      setWeeklyDates(response.data);
    }
  }

  const handlePrevWeek = () => {
    const prevWeek = new Date(currentDate);
    const tempDate = new Date(currentDate);
    prevWeek.setDate(prevWeek.getDate() - 7);
    setCurrentDate(prevWeek);
    getDoctorWeeklyAppointment(
      new Date(prevWeek).setDate(prevWeek.getDate() - 7)
    );
  };

  const handleNextWeek = () => {
    const nextWeek = new Date(currentDate);
    const tempDate = new Date(currentDate);
    nextWeek.setDate(nextWeek.getDate() + 7);
    setCurrentDate(nextWeek);
    getDoctorWeeklyAppointment(
      new Date(nextWeek).setDate(nextWeek.getDate() + 7)
    );
  };

  const formatMonthYear = (date) => {
    const options = { year: "numeric", month: "long" };
    return date.toLocaleDateString("en-US", options);
  };

  const renderWeekDays = () => {
    const weekDays = [];
    const startDate = new Date(currentDate);

    for (let i = 0; i < 7; i++) {
      const day = new Date(startDate);
      day.setDate(day.getDate() + i);
      let isSlotpresent = false;
      let dayDate = "";
      let weekDate = "";
      if (weeklyDates.length > 0) {
        dayDate =
          day.getMonth() + 1 + "/" + day.getDate() + "/" + day.getFullYear();

        weekDate =
          new Date(weeklyDates[i].date).getMonth() +
          1 +
          "/" +
          new Date(weeklyDates[i].date).getDate() +
          "/" +
          new Date(weeklyDates[i].date).getFullYear();
        if (dayDate == weekDate) {
          isSlotpresent = weeklyDates[i]?.schedule.length > 0 ? true : false;
        }
      }

      weekDays.push(
        <div
          style={{
            border: "3px solid",
            borderColor: isSlotpresent ? "green" : "",
            boxShadow: isSlotpresent ? "0 0 0 4px rgb(146 236 103)" : "",
          }}
          onClick={() => {
            setSelectedData(day.getDate());
            let _selectedData =
              day.getMonth() +
              1 +
              "/" +
              day.getDate() +
              "/" +
              day.getFullYear();

            getDoctorAppointment(_selectedData);
          }}
          className={
            "date__txt__day " +
            (day.getDate() == selectedDate ? "selected__time__slot" : "")
          }
          key={i}
        >
          <p className="day__txt__val">{getDayName(day)}</p>
          <p className="date__txt__val"> {day.getDate()}</p>
        </div>
      );
    }

    return weekDays;
  };
  const getDayName = (date) => {
    const options = { weekday: "short" };
    return date.toLocaleDateString("en-US", options);
  };

  function getAppointmentStatus(_status) {
    let status = <p>N/A</p>;
    if (_status == "0") {
      status = <p style={{ color: "orange", padding: 0 }}>Pending</p>;
    }
    if (_status == "1") {
      status = <p style={{ color: "blue", padding: 0 }}>Accepted</p>;
    }
    if (_status == "2") {
      status = <p style={{ color: "red", padding: 0 }}>Rejected</p>;
    }
    if (_status == "3") {
      status = <p style={{ color: "green", padding: 0 }}>Completed</p>;
    }
    if (_status == "4") {
      status = <p style={{ color: "red", padding: 0 }}>Cancelled</p>;
    }
    if (_status == "5") {
      status = <p style={{ color: "Blue", padding: 0 }}>ON Hold(by clinic)</p>;
    }
    if (_status == "6") {
      status = <p style={{ color: "Blue", padding: 0 }}>ON Hold(by doctor)</p>;
    }
    return status;
  }

  const SlotAccordian = (props) => {
    return (
      <AccordionItem className="accordian-item">
        <AccordionItemHeading>
          <AccordionItemButton>
            <h2 className="accordion-header" id="headingOne">
              <button
                className="accordion-button accordion__btn__head"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseOne"
                aria-expanded="true"
                aria-controls="collapseOne"
              >
                <div className="accordian__head__time">
                  <div className="time__accrodion__box">
                    <div className="form-check">
                      <input
                        className="form-check-input cust__check__week float-right"
                        type="radio"
                        value=""
                        id=""
                      />
                    </div>
                    <p className="accordion__head__time__val">
                      {props.slot?.startTime.substr(0, 5)} &nbsp;- &nbsp;{" "}
                      {props.slot?.endTime.substr(0, 5)}
                    </p>
                  </div>
                  {/* <div className="accordion__profile__img__box">
                  <div className="flex -space-x-4">
                    <img
                      className="w-10 h-10 border-2 border-white rounded-full dark:border-gray-800"
                      src="https://firebasestorage.googleapis.com/v0/b/healthlink-4d49e.appspot.com/o/images%2Favatars%2Fyms5XoB2aeZBDkwWcBZuBcWGCzP2?alt=media&token=133e8e95-7ff0-47da-9945-67285c53a4f1"
                      alt=""
                    />
                    <img
                      className="w-10 h-10 border-2 border-white rounded-full dark:border-gray-800"
                      src="https://firebasestorage.googleapis.com/v0/b/healthlink-4d49e.appspot.com/o/images%2Favatars%2Fyms5XoB2aeZBDkwWcBZuBcWGCzP2?alt=media&token=133e8e95-7ff0-47da-9945-67285c53a4f1"
                      alt=""
                    />
                    <img
                      className="w-10 h-10 border-2 border-white rounded-full dark:border-gray-800"
                      src="https://firebasestorage.googleapis.com/v0/b/healthlink-4d49e.appspot.com/o/images%2Favatars%2Fyms5XoB2aeZBDkwWcBZuBcWGCzP2?alt=media&token=133e8e95-7ff0-47da-9945-67285c53a4f1"
                      alt=""
                    />
                    <img
                      className="w-10 h-10 border-2 border-white rounded-full dark:border-gray-800"
                      src="https://firebasestorage.googleapis.com/v0/b/healthlink-4d49e.appspot.com/o/images%2Favatars%2Fyms5XoB2aeZBDkwWcBZuBcWGCzP2?alt=media&token=133e8e95-7ff0-47da-9945-67285c53a4f1"
                      alt=""
                    />
                  </div>
                </div> */}
                </div>
              </button>
            </h2>
          </AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>
          <ul className="doctor__appointment__list">
            <table className="weeklY__shedule__table">
              <thead>
                <tr>
                  <th>First Name</th>
                  <th>Last Name</th>
                  {/* <th>Age</th> */}
                  <th>Contact</th>
                  <th>Email</th>
                  <th>Consultation Type</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {props.slot.appointment?.map((_data) => {
                  return (
                    <tr>
                      <td>{_data.firstName}</td>
                      <td>{_data.lastName}</td>
                      {/* <td>{_data.age}</td> */}
                      <td>{_data.phoneNumber}</td>
                      <td>{_data.email}</td>
                      <td>
                        {_data.consultationType == "1" ? "Online" : "Physical"}
                        &nbsp;
                        {_data?.videoLink ? (
                          <a href={_data.videoLink} target="_blank">
                            Link
                          </a>
                        ) : (
                          ""
                        )}
                      </td>
                      <td>{getAppointmentStatus(_data.status) || "N/A"}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </ul>
        </AccordionItemPanel>
      </AccordionItem>
    );
  };
  return (
    <>
      <div className="row">
        <div className="col-md-12">
          <div className="calendar__slider__box fix__calender__box">
            <div className="date__slider__cal">
              <a
                href="#"
                className="arrow__slider__doc"
                onClick={handlePrevWeek}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M18.75 19.5l-7.5-7.5 7.5-7.5m-6 15L5.25 12l7.5-7.5"
                  />
                </svg>
              </a>
              {/* <button onClick={handlePrevWeek}>&lt;</button> */}
              <p className="date__slider__txt">
                {formatMonthYear(currentDate)}
              </p>
              {/* <button onClick={handleNextWeek}>&gt;</button> */}
              <a
                href="#"
                onClick={handleNextWeek}
                className="arrow__slider__doc"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M11.25 4.5l7.5 7.5-7.5 7.5m-6-15l7.5 7.5-7.5 7.5"
                  />
                </svg>
              </a>
            </div>
            <div className="slider__main__date__box">{renderWeekDays()}</div>
            <div className="slider__accordion__box mt-5 docter__slider__width mb-3">
              {/* bootsrap 5 accordion */}

              {/* react accordion */}
              <Accordion allowZeroExpanded>
                {
                  // appointmentSlot?.schedule.map((_slot) => {
                  //   return <SlotAccordian slot={_slot} />
                  // })

                  appointmentSlot?.schedule &&
                  appointmentSlot?.schedule.length > 0 ? (
                    appointmentSlot?.schedule.map((_slot) => {
                      return <SlotAccordian slot={_slot} />;
                    })
                  ) : (
                    <RecordPlaceholder />
                  )
                }
              </Accordion>
            </div>
          </div>
        </div>
      </div>
      {/* icoming request page start */}
      {/* <div className="row">
        <div className="col-md-12">
            <div className="main__clinic__home">
                <div class="clinic__home__box">
                  <div className="clinic__profile__box">
                    <div className="clinic__profile__img"></div>
                  </div>
                  <div className="clinic__home__type">
                    <div className="clinic__home__head">
                      <h2>Crest health Physiotherapy Yanchep</h2>
                      <h5>Open until 6:00 pm today</h5>
                    </div>
                  </div>
                  <div className="clinic__home__desc">
                    <div className="clinic__home__desc__box">
                      <div className="clinic__profile__img__box">
                       <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                          <path stroke-linecap="round" stroke-linejoin="round" d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z" />
                        </svg>
                      </div>
                      <div className="clinic__profile__desc__box">
                          <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, </p>
                      </div>
                    </div>
                    <div className="show__content__btn"> 
                        <button className="btn btn_show__more">Show More</button>
                    </div>
                  </div> 
                  <div className="clinic__home__date__time">
                    <div className="direction__parking__box">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z" />
                        <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z" />
                      </svg>
                      <h6>Direction And Parking</h6>
                    </div>
                    <div className="direction__parking__content">
                      <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, </p>
                    </div>
                    <div className="direction__parking__map">
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3781.5018435391944!2d73.71612027503974!3d18.596484382511843!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2bb8ff7a7dd3f%3A0x92e43d2a7e3b3a0a!2sBlocksOne%20Private%20Limited!5e0!3m2!1sen!2sin!4v1688797883042!5m2!1sen!2sin" width="100%" height="150px" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                    </div>
                  </div>
                  <div className="clinic__home__location">
                    <div className="clinic__home__time__box mb-2">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z" />
                      </svg>
                      <a href="tel:+86 8764876334">+86 8764-876334</a>
                    </div>
                    <div className="clinic__home__time__box">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75" />
                      </svg>
                      <a href="mailto:cresthealth@outlook.com">cresthealth@outlook.com</a>
                    </div>
                  </div>
                </div>
            </div>
            <div className="incoming__request__container">
                <div className="incoming__request__head">
                    <h3>Incoming Request</h3>
                </div>
                <div className="row">
                    <div className="col-md-3 mb-3">
                        <div className="incoming__request__wrap">
                            <div className="incoming__request__box">
                                <div className="incoming__request__profile">
                                    <img src="https://firebasestorage.googleapis.com/v0/b/healthlink-4d49e.appspot.com/o/images%2Favatars%2FWTPv8Vl2tLSyrqkKL4AAeAbpGIC3?alt=media&amp;token=6832f469-36d1-494b-9d58-274d82e96ceb" class="w-100" alt="Avatar"></img>
                                </div>
                                <div className="incoming__request__details">
                                  <div class="star-rating">
                                    <input type="radio" id="5-stars" name="rating" value="5" />
                                    <label for="5-stars" class="star">&#9733;</label>
                                    <input type="radio" id="4-stars" name="rating" value="4" />
                                    <label for="4-stars" class="star">&#9733;</label>
                                    <input type="radio" id="3-stars" name="rating" value="3" />
                                    <label for="3-stars" class="star">&#9733;</label>
                                    <input type="radio" id="2-stars" name="rating" value="2" />
                                    <label for="2-stars" class="star">&#9733;</label>
                                    <input type="radio" id="1-star" name="rating" value="1" />
                                    <label for="1-star" class="star">&#9733;</label>
                                  </div>
                                  <div className="incoming__request__doc__name">
                                    <p>Dr. Paul Walker</p>
                                  </div>
                                  <div className="doc__exp__year__box"> 
                                    <div className="doc__desig__box">
                                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                        <path stroke-linecap="round" d="M16.5 12a4.5 4.5 0 11-9 0 4.5 4.5 0 019 0zm0 0c0 1.657 1.007 3 2.25 3S21 13.657 21 12a9 9 0 10-2.636 6.364M16.5 12V8.25" />
                                      </svg>
                                      <p>Dentist</p>
                                    </div>
                                    <div className="doct__exp__year__box">
                                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M4.26 10.147a60.436 60.436 0 00-.491 6.347A48.627 48.627 0 0112 20.904a48.627 48.627 0 018.232-4.41 60.46 60.46 0 00-.491-6.347m-15.482 0a50.57 50.57 0 00-2.658-.813A59.905 59.905 0 0112 3.493a59.902 59.902 0 0110.399 5.84c-.896.248-1.783.52-2.658.814m-15.482 0A50.697 50.697 0 0112 13.489a50.702 50.702 0 017.74-3.342M6.75 15a.75.75 0 100-1.5.75.75 0 000 1.5zm0 0v-3.675A55.378 55.378 0 0112 8.443m-7.007 11.55A5.981 5.981 0 006.75 15.75v-1.5" />
                                      </svg>
                                      <p>7 Year </p>
                                    </div>
                                </div>
                              </div>
                            </div>
                            <div className="incoming__request__btn__box">
                              <buttn className="btn btn__approved__request">Accept</buttn>
                              <buttn className="btn btn__cancel__request">Cancel</buttn>
                            </div>
                            <a href="#" className="crosss__btn__request">
                              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                 <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                              </svg>

                            </a>
                        </div>
                    </div>
                    <div className="col-md-3 mb-3">
                        <div className="incoming__request__wrap">
                            <div className="incoming__request__box">
                                <div className="incoming__request__profile">
                                    <img src="https://firebasestorage.googleapis.com/v0/b/healthlink-4d49e.appspot.com/o/images%2Favatars%2FWTPv8Vl2tLSyrqkKL4AAeAbpGIC3?alt=media&amp;token=6832f469-36d1-494b-9d58-274d82e96ceb" class="w-100" alt="Avatar"></img>
                                </div>
                                <div className="incoming__request__details">
                                  <div class="star-rating">
                                    <input type="radio" id="5-stars" name="rating" value="5" />
                                    <label for="5-stars" class="star">&#9733;</label>
                                    <input type="radio" id="4-stars" name="rating" value="4" />
                                    <label for="4-stars" class="star">&#9733;</label>
                                    <input type="radio" id="3-stars" name="rating" value="3" />
                                    <label for="3-stars" class="star">&#9733;</label>
                                    <input type="radio" id="2-stars" name="rating" value="2" />
                                    <label for="2-stars" class="star">&#9733;</label>
                                    <input type="radio" id="1-star" name="rating" value="1" />
                                    <label for="1-star" class="star">&#9733;</label>
                                  </div>
                                  <div className="incoming__request__doc__name">
                                    <p>Dr. Paul Walker</p>
                                  </div>
                                  <div className="doc__exp__year__box"> 
                                    <div className="doc__desig__box">
                                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                        <path stroke-linecap="round" d="M16.5 12a4.5 4.5 0 11-9 0 4.5 4.5 0 019 0zm0 0c0 1.657 1.007 3 2.25 3S21 13.657 21 12a9 9 0 10-2.636 6.364M16.5 12V8.25" />
                                      </svg>
                                      <p>Dentist</p>
                                    </div>
                                    <div className="doct__exp__year__box">
                                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M4.26 10.147a60.436 60.436 0 00-.491 6.347A48.627 48.627 0 0112 20.904a48.627 48.627 0 018.232-4.41 60.46 60.46 0 00-.491-6.347m-15.482 0a50.57 50.57 0 00-2.658-.813A59.905 59.905 0 0112 3.493a59.902 59.902 0 0110.399 5.84c-.896.248-1.783.52-2.658.814m-15.482 0A50.697 50.697 0 0112 13.489a50.702 50.702 0 017.74-3.342M6.75 15a.75.75 0 100-1.5.75.75 0 000 1.5zm0 0v-3.675A55.378 55.378 0 0112 8.443m-7.007 11.55A5.981 5.981 0 006.75 15.75v-1.5" />
                                      </svg>
                                      <p>7 Year </p>
                                    </div>
                                </div>
                              </div>
                            </div>
                            <div className="incoming__request__btn__box">
                              <buttn className="btn btn__approved__request">Accept</buttn>
                              <buttn className="btn btn__cancel__request">Cancel</buttn>
                            </div>
                            <a href="#" className="crosss__btn__request">
                              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                 <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                              </svg>

                            </a>
                        </div>
                    </div>
                    <div className="col-md-3 mb-3">
                        <div className="incoming__request__wrap">
                            <div className="incoming__request__box">
                                <div className="incoming__request__profile">
                                    <img src="https://firebasestorage.googleapis.com/v0/b/healthlink-4d49e.appspot.com/o/images%2Favatars%2FWTPv8Vl2tLSyrqkKL4AAeAbpGIC3?alt=media&amp;token=6832f469-36d1-494b-9d58-274d82e96ceb" class="w-100" alt="Avatar"></img>
                                </div>
                                <div className="incoming__request__details">
                                  <div class="star-rating">
                                    <input type="radio" id="5-stars" name="rating" value="5" />
                                    <label for="5-stars" class="star">&#9733;</label>
                                    <input type="radio" id="4-stars" name="rating" value="4" />
                                    <label for="4-stars" class="star">&#9733;</label>
                                    <input type="radio" id="3-stars" name="rating" value="3" />
                                    <label for="3-stars" class="star">&#9733;</label>
                                    <input type="radio" id="2-stars" name="rating" value="2" />
                                    <label for="2-stars" class="star">&#9733;</label>
                                    <input type="radio" id="1-star" name="rating" value="1" />
                                    <label for="1-star" class="star">&#9733;</label>
                                  </div>
                                  <div className="incoming__request__doc__name">
                                    <p>Dr. Paul Walker</p>
                                  </div>
                                  <div className="doc__exp__year__box"> 
                                    <div className="doc__desig__box">
                                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                        <path stroke-linecap="round" d="M16.5 12a4.5 4.5 0 11-9 0 4.5 4.5 0 019 0zm0 0c0 1.657 1.007 3 2.25 3S21 13.657 21 12a9 9 0 10-2.636 6.364M16.5 12V8.25" />
                                      </svg>
                                      <p>Dentist</p>
                                    </div>
                                    <div className="doct__exp__year__box">
                                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M4.26 10.147a60.436 60.436 0 00-.491 6.347A48.627 48.627 0 0112 20.904a48.627 48.627 0 018.232-4.41 60.46 60.46 0 00-.491-6.347m-15.482 0a50.57 50.57 0 00-2.658-.813A59.905 59.905 0 0112 3.493a59.902 59.902 0 0110.399 5.84c-.896.248-1.783.52-2.658.814m-15.482 0A50.697 50.697 0 0112 13.489a50.702 50.702 0 017.74-3.342M6.75 15a.75.75 0 100-1.5.75.75 0 000 1.5zm0 0v-3.675A55.378 55.378 0 0112 8.443m-7.007 11.55A5.981 5.981 0 006.75 15.75v-1.5" />
                                      </svg>
                                      <p>7 Year </p>
                                    </div>
                                </div>
                              </div>
                            </div>
                            <div className="incoming__request__btn__box">
                              <buttn className="btn btn__approved__request">Accept</buttn>
                              <buttn className="btn btn__cancel__request">Cancel</buttn>
                            </div>
                            <a href="#" className="crosss__btn__request">
                              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                 <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                              </svg>

                            </a>
                        </div>
                    </div>
                    <div className="col-md-3 mb-3">
                        <div className="incoming__request__wrap">
                            <div className="incoming__request__box">
                                <div className="incoming__request__profile">
                                    <img src="https://firebasestorage.googleapis.com/v0/b/healthlink-4d49e.appspot.com/o/images%2Favatars%2FWTPv8Vl2tLSyrqkKL4AAeAbpGIC3?alt=media&amp;token=6832f469-36d1-494b-9d58-274d82e96ceb" class="w-100" alt="Avatar"></img>
                                </div>
                                <div className="incoming__request__details">
                                  <div class="star-rating">
                                    <input type="radio" id="5-stars" name="rating" value="5" />
                                    <label for="5-stars" class="star">&#9733;</label>
                                    <input type="radio" id="4-stars" name="rating" value="4" />
                                    <label for="4-stars" class="star">&#9733;</label>
                                    <input type="radio" id="3-stars" name="rating" value="3" />
                                    <label for="3-stars" class="star">&#9733;</label>
                                    <input type="radio" id="2-stars" name="rating" value="2" />
                                    <label for="2-stars" class="star">&#9733;</label>
                                    <input type="radio" id="1-star" name="rating" value="1" />
                                    <label for="1-star" class="star">&#9733;</label>
                                  </div>
                                  <div className="incoming__request__doc__name">
                                    <p>Dr. Paul Walker</p>
                                  </div>
                                  <div className="doc__exp__year__box"> 
                                    <div className="doc__desig__box">
                                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                        <path stroke-linecap="round" d="M16.5 12a4.5 4.5 0 11-9 0 4.5 4.5 0 019 0zm0 0c0 1.657 1.007 3 2.25 3S21 13.657 21 12a9 9 0 10-2.636 6.364M16.5 12V8.25" />
                                      </svg>
                                      <p>Dentist</p>
                                    </div>
                                    <div className="doct__exp__year__box">
                                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M4.26 10.147a60.436 60.436 0 00-.491 6.347A48.627 48.627 0 0112 20.904a48.627 48.627 0 018.232-4.41 60.46 60.46 0 00-.491-6.347m-15.482 0a50.57 50.57 0 00-2.658-.813A59.905 59.905 0 0112 3.493a59.902 59.902 0 0110.399 5.84c-.896.248-1.783.52-2.658.814m-15.482 0A50.697 50.697 0 0112 13.489a50.702 50.702 0 017.74-3.342M6.75 15a.75.75 0 100-1.5.75.75 0 000 1.5zm0 0v-3.675A55.378 55.378 0 0112 8.443m-7.007 11.55A5.981 5.981 0 006.75 15.75v-1.5" />
                                      </svg>
                                      <p>7 Year </p>
                                    </div>
                                </div>
                              </div>
                            </div>
                            <div className="incoming__request__btn__box">
                              <buttn className="btn btn__approved__request">Accept</buttn>
                              <buttn className="btn btn__cancel__request">Cancel</buttn>
                            </div>
                            <a href="#" className="crosss__btn__request">
                              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                 <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                              </svg>

                            </a>
                        </div>
                    </div>
                    <div className="col-md-3 mb-3">
                        <div className="incoming__request__wrap">
                            <div className="incoming__request__box">
                                <div className="incoming__request__profile">
                                    <img src="https://firebasestorage.googleapis.com/v0/b/healthlink-4d49e.appspot.com/o/images%2Favatars%2FWTPv8Vl2tLSyrqkKL4AAeAbpGIC3?alt=media&amp;token=6832f469-36d1-494b-9d58-274d82e96ceb" class="w-100" alt="Avatar"></img>
                                </div>
                                <div className="incoming__request__details">
                                  <div class="star-rating">
                                    <input type="radio" id="5-stars" name="rating" value="5" />
                                    <label for="5-stars" class="star">&#9733;</label>
                                    <input type="radio" id="4-stars" name="rating" value="4" />
                                    <label for="4-stars" class="star">&#9733;</label>
                                    <input type="radio" id="3-stars" name="rating" value="3" />
                                    <label for="3-stars" class="star">&#9733;</label>
                                    <input type="radio" id="2-stars" name="rating" value="2" />
                                    <label for="2-stars" class="star">&#9733;</label>
                                    <input type="radio" id="1-star" name="rating" value="1" />
                                    <label for="1-star" class="star">&#9733;</label>
                                  </div>
                                  <div className="incoming__request__doc__name">
                                    <p>Dr. Paul Walker</p>
                                  </div>
                                  <div className="doc__exp__year__box"> 
                                    <div className="doc__desig__box">
                                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                        <path stroke-linecap="round" d="M16.5 12a4.5 4.5 0 11-9 0 4.5 4.5 0 019 0zm0 0c0 1.657 1.007 3 2.25 3S21 13.657 21 12a9 9 0 10-2.636 6.364M16.5 12V8.25" />
                                      </svg>
                                      <p>Dentist</p>
                                    </div>
                                    <div className="doct__exp__year__box">
                                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M4.26 10.147a60.436 60.436 0 00-.491 6.347A48.627 48.627 0 0112 20.904a48.627 48.627 0 018.232-4.41 60.46 60.46 0 00-.491-6.347m-15.482 0a50.57 50.57 0 00-2.658-.813A59.905 59.905 0 0112 3.493a59.902 59.902 0 0110.399 5.84c-.896.248-1.783.52-2.658.814m-15.482 0A50.697 50.697 0 0112 13.489a50.702 50.702 0 017.74-3.342M6.75 15a.75.75 0 100-1.5.75.75 0 000 1.5zm0 0v-3.675A55.378 55.378 0 0112 8.443m-7.007 11.55A5.981 5.981 0 006.75 15.75v-1.5" />
                                      </svg>
                                      <p>7 Year </p>
                                    </div>
                                </div>
                              </div>
                            </div>
                            <div className="incoming__request__btn__box">
                              <buttn className="btn btn__approved__request">Accept</buttn>
                              <buttn className="btn btn__cancel__request">Cancel</buttn>
                            </div>
                            <a href="#" className="crosss__btn__request">
                              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                 <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                              </svg>

                            </a>
                        </div>
                    </div>
                    <div className="col-md-3 mb-3">
                        <div className="incoming__request__wrap">
                            <div className="incoming__request__box">
                                <div className="incoming__request__profile">
                                    <img src="https://firebasestorage.googleapis.com/v0/b/healthlink-4d49e.appspot.com/o/images%2Favatars%2FWTPv8Vl2tLSyrqkKL4AAeAbpGIC3?alt=media&amp;token=6832f469-36d1-494b-9d58-274d82e96ceb" class="w-100" alt="Avatar"></img>
                                </div>
                                <div className="incoming__request__details">
                                  <div class="star-rating">
                                    <input type="radio" id="5-stars" name="rating" value="5" />
                                    <label for="5-stars" class="star">&#9733;</label>
                                    <input type="radio" id="4-stars" name="rating" value="4" />
                                    <label for="4-stars" class="star">&#9733;</label>
                                    <input type="radio" id="3-stars" name="rating" value="3" />
                                    <label for="3-stars" class="star">&#9733;</label>
                                    <input type="radio" id="2-stars" name="rating" value="2" />
                                    <label for="2-stars" class="star">&#9733;</label>
                                    <input type="radio" id="1-star" name="rating" value="1" />
                                    <label for="1-star" class="star">&#9733;</label>
                                  </div>
                                  <div className="incoming__request__doc__name">
                                    <p>Dr. Paul Walker</p>
                                  </div>
                                  <div className="doc__exp__year__box"> 
                                    <div className="doc__desig__box">
                                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                        <path stroke-linecap="round" d="M16.5 12a4.5 4.5 0 11-9 0 4.5 4.5 0 019 0zm0 0c0 1.657 1.007 3 2.25 3S21 13.657 21 12a9 9 0 10-2.636 6.364M16.5 12V8.25" />
                                      </svg>
                                      <p>Dentist</p>
                                    </div>
                                    <div className="doct__exp__year__box">
                                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M4.26 10.147a60.436 60.436 0 00-.491 6.347A48.627 48.627 0 0112 20.904a48.627 48.627 0 018.232-4.41 60.46 60.46 0 00-.491-6.347m-15.482 0a50.57 50.57 0 00-2.658-.813A59.905 59.905 0 0112 3.493a59.902 59.902 0 0110.399 5.84c-.896.248-1.783.52-2.658.814m-15.482 0A50.697 50.697 0 0112 13.489a50.702 50.702 0 017.74-3.342M6.75 15a.75.75 0 100-1.5.75.75 0 000 1.5zm0 0v-3.675A55.378 55.378 0 0112 8.443m-7.007 11.55A5.981 5.981 0 006.75 15.75v-1.5" />
                                      </svg>
                                      <p>7 Year </p>
                                    </div>
                                </div>
                              </div>
                            </div>
                            <div className="incoming__request__btn__box">
                              <buttn className="btn btn__approved__request">Accept</buttn>
                              <buttn className="btn btn__cancel__request">Cancel</buttn>
                            </div>
                            <a href="#" className="crosss__btn__request">
                              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                 <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                              </svg>

                            </a>
                        </div>
                    </div>
                    <div className="col-md-3 mb-3">
                        <div className="incoming__request__wrap">
                            <div className="incoming__request__box">
                                <div className="incoming__request__profile">
                                    <img src="https://firebasestorage.googleapis.com/v0/b/healthlink-4d49e.appspot.com/o/images%2Favatars%2FWTPv8Vl2tLSyrqkKL4AAeAbpGIC3?alt=media&amp;token=6832f469-36d1-494b-9d58-274d82e96ceb" class="w-100" alt="Avatar"></img>
                                </div>
                                <div className="incoming__request__details">
                                  <div class="star-rating">
                                    <input type="radio" id="5-stars" name="rating" value="5" />
                                    <label for="5-stars" class="star">&#9733;</label>
                                    <input type="radio" id="4-stars" name="rating" value="4" />
                                    <label for="4-stars" class="star">&#9733;</label>
                                    <input type="radio" id="3-stars" name="rating" value="3" />
                                    <label for="3-stars" class="star">&#9733;</label>
                                    <input type="radio" id="2-stars" name="rating" value="2" />
                                    <label for="2-stars" class="star">&#9733;</label>
                                    <input type="radio" id="1-star" name="rating" value="1" />
                                    <label for="1-star" class="star">&#9733;</label>
                                  </div>
                                  <div className="incoming__request__doc__name">
                                    <p>Dr. Paul Walker</p>
                                  </div>
                                  <div className="doc__exp__year__box"> 
                                    <div className="doc__desig__box">
                                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                        <path stroke-linecap="round" d="M16.5 12a4.5 4.5 0 11-9 0 4.5 4.5 0 019 0zm0 0c0 1.657 1.007 3 2.25 3S21 13.657 21 12a9 9 0 10-2.636 6.364M16.5 12V8.25" />
                                      </svg>
                                      <p>Dentist</p>
                                    </div>
                                    <div className="doct__exp__year__box">
                                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M4.26 10.147a60.436 60.436 0 00-.491 6.347A48.627 48.627 0 0112 20.904a48.627 48.627 0 018.232-4.41 60.46 60.46 0 00-.491-6.347m-15.482 0a50.57 50.57 0 00-2.658-.813A59.905 59.905 0 0112 3.493a59.902 59.902 0 0110.399 5.84c-.896.248-1.783.52-2.658.814m-15.482 0A50.697 50.697 0 0112 13.489a50.702 50.702 0 017.74-3.342M6.75 15a.75.75 0 100-1.5.75.75 0 000 1.5zm0 0v-3.675A55.378 55.378 0 0112 8.443m-7.007 11.55A5.981 5.981 0 006.75 15.75v-1.5" />
                                      </svg>
                                      <p>7 Year </p>
                                    </div>
                                </div>
                              </div>
                            </div>
                            <div className="incoming__request__btn__box">
                              <buttn className="btn btn__approved__request">Accept</buttn>
                              <buttn className="btn btn__cancel__request">Cancel</buttn>
                            </div>
                            <a href="#" className="crosss__btn__request">
                              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                 <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                              </svg>

                            </a>
                        </div>
                    </div>
                    <div className="col-md-3 mb-3">
                        <div className="incoming__request__wrap">
                            <div className="incoming__request__box">
                                <div className="incoming__request__profile">
                                    <img src="https://firebasestorage.googleapis.com/v0/b/healthlink-4d49e.appspot.com/o/images%2Favatars%2FWTPv8Vl2tLSyrqkKL4AAeAbpGIC3?alt=media&amp;token=6832f469-36d1-494b-9d58-274d82e96ceb" class="w-100" alt="Avatar"></img>
                                </div>
                                <div className="incoming__request__details">
                                  <div class="star-rating">
                                    <input type="radio" id="5-stars" name="rating" value="5" />
                                    <label for="5-stars" class="star">&#9733;</label>
                                    <input type="radio" id="4-stars" name="rating" value="4" />
                                    <label for="4-stars" class="star">&#9733;</label>
                                    <input type="radio" id="3-stars" name="rating" value="3" />
                                    <label for="3-stars" class="star">&#9733;</label>
                                    <input type="radio" id="2-stars" name="rating" value="2" />
                                    <label for="2-stars" class="star">&#9733;</label>
                                    <input type="radio" id="1-star" name="rating" value="1" />
                                    <label for="1-star" class="star">&#9733;</label>
                                  </div>
                                  <div className="incoming__request__doc__name">
                                    <p>Dr. Paul Walker</p>
                                  </div>
                                  <div className="doc__exp__year__box"> 
                                    <div className="doc__desig__box">
                                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                        <path stroke-linecap="round" d="M16.5 12a4.5 4.5 0 11-9 0 4.5 4.5 0 019 0zm0 0c0 1.657 1.007 3 2.25 3S21 13.657 21 12a9 9 0 10-2.636 6.364M16.5 12V8.25" />
                                      </svg>
                                      <p>Dentist</p>
                                    </div>
                                    <div className="doct__exp__year__box">
                                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M4.26 10.147a60.436 60.436 0 00-.491 6.347A48.627 48.627 0 0112 20.904a48.627 48.627 0 018.232-4.41 60.46 60.46 0 00-.491-6.347m-15.482 0a50.57 50.57 0 00-2.658-.813A59.905 59.905 0 0112 3.493a59.902 59.902 0 0110.399 5.84c-.896.248-1.783.52-2.658.814m-15.482 0A50.697 50.697 0 0112 13.489a50.702 50.702 0 017.74-3.342M6.75 15a.75.75 0 100-1.5.75.75 0 000 1.5zm0 0v-3.675A55.378 55.378 0 0112 8.443m-7.007 11.55A5.981 5.981 0 006.75 15.75v-1.5" />
                                      </svg>
                                      <p>7 Year </p>
                                    </div>
                                </div>
                              </div>
                            </div>
                            <div className="incoming__request__btn__box">
                              <buttn className="btn btn__approved__request">Accept</buttn>
                              <buttn className="btn btn__cancel__request">Cancel</buttn>
                            </div>
                            <a href="#" className="crosss__btn__request">
                              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                 <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                              </svg>

                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div> */}
      {/* icoming request page end */}

      {/* Appointment card page start */}
      {/* <div className="row">
          <div className="col-md-4">
              <div className="filter__appoinment__box">
                <label className="form-label"> Filter By</label>
                <input type="search" class="form-control" placeholder="Filter By"></input>
              </div>
              <div className="filter__list">
                <ul className="fliter__list__content">
                  <li>Anaesthesiology</li>
                  <li>Cardiology</li>
                  <li>Dermatology</li>
                  <li>Emergency Medicine</li>
                  <li>Endocrinology</li>
                  <li>Family Medicine</li>
                  <li>Gastroenterology</li>
                  <li>General Surgery</li>
                  <li>Hematology</li>
                  <li>Internal Medicine</li>
                  <li>Neurology</li>
                  <li>Obstetrics and Gynecology</li>
                  <li>Ophthalmology</li>
                  <li>Orthopedic Surgery</li>
                  <li>Otolaryngology (Ear, Nose, and Throat)</li>
                  <li>Pediatrics</li>
                  <li>Psychiatry</li>
                  <li>Radiology</li>
                  <li>Urology</li>
                  </ul>
              </div>
          </div>
          <div className="col-md-8">
            <div className="appointment__card__head">
            <label className="form-label">Appointment Card</label>
            </div>
              <div className="row">
                <div className="col-md-4">
                  <div className="appointment__calendar">
                    
                    <input type="date" class="form-control" placeholder="Filter By"></input>
                  </div>
                </div>
                <div className="col-md-8">
                  <div className="row">
                    <div className="col-md-4 mb-3">
                        <div className="appointment__time__box">
                          <div className="appointment__time__txt">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                              <path stroke-linecap="round" stroke-linejoin="round" d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                            </svg>
                            <p>10:00 - 10:30</p>
                          </div>
                          <div className="appointment__time__txt">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                              <path stroke-linecap="round" stroke-linejoin="round" d="M15 19.128a9.38 9.38 0 002.625.372 9.337 9.337 0 004.121-.952 4.125 4.125 0 00-7.533-2.493M15 19.128v-.003c0-1.113-.285-2.16-.786-3.07M15 19.128v.106A12.318 12.318 0 018.624 21c-2.331 0-4.512-.645-6.374-1.766l-.001-.109a6.375 6.375 0 0111.964-3.07M12 6.375a3.375 3.375 0 11-6.75 0 3.375 3.375 0 016.75 0zm8.25 2.25a2.625 2.625 0 11-5.25 0 2.625 2.625 0 015.25 0z" />
                            </svg>
                            <p>Paul Walker</p>
                          </div>
                          <div className="appointment__green__tick">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6">
                              <path fill-rule="evenodd" d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm13.36-1.814a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z" clip-rule="evenodd" />
                            </svg>
                          </div>
                        </div>
                        
                    </div>
                    <div className="col-md-4 mb-3">
                        <div className="appointment__time__box">
                          <div className="appointment__time__txt">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                              <path stroke-linecap="round" stroke-linejoin="round" d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                            </svg>
                            <p>10:00 - 10:30</p>
                          </div>
                          <div className="appointment__time__txt">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                              <path stroke-linecap="round" stroke-linejoin="round" d="M15 19.128a9.38 9.38 0 002.625.372 9.337 9.337 0 004.121-.952 4.125 4.125 0 00-7.533-2.493M15 19.128v-.003c0-1.113-.285-2.16-.786-3.07M15 19.128v.106A12.318 12.318 0 018.624 21c-2.331 0-4.512-.645-6.374-1.766l-.001-.109a6.375 6.375 0 0111.964-3.07M12 6.375a3.375 3.375 0 11-6.75 0 3.375 3.375 0 016.75 0zm8.25 2.25a2.625 2.625 0 11-5.25 0 2.625 2.625 0 015.25 0z" />
                            </svg>
                            <p>Paul Walker</p>
                          </div>
                          <div className="appointment__red__tick">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6">
                              <path fill-rule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zM12.75 9a.75.75 0 00-1.5 0v2.25H9a.75.75 0 000 1.5h2.25V15a.75.75 0 001.5 0v-2.25H15a.75.75 0 000-1.5h-2.25V9z" clip-rule="evenodd" />
                            </svg>

                          </div>
                        </div>
                        
                    </div>
                    <div className="col-md-4 mb-3">
                        <div className="appointment__time__box">
                          <div className="appointment__time__txt">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                              <path stroke-linecap="round" stroke-linejoin="round" d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                            </svg>
                            <p>10:00 - 10:30</p>
                          </div>
                          <div className="appointment__time__txt">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                              <path stroke-linecap="round" stroke-linejoin="round" d="M15 19.128a9.38 9.38 0 002.625.372 9.337 9.337 0 004.121-.952 4.125 4.125 0 00-7.533-2.493M15 19.128v-.003c0-1.113-.285-2.16-.786-3.07M15 19.128v.106A12.318 12.318 0 018.624 21c-2.331 0-4.512-.645-6.374-1.766l-.001-.109a6.375 6.375 0 0111.964-3.07M12 6.375a3.375 3.375 0 11-6.75 0 3.375 3.375 0 016.75 0zm8.25 2.25a2.625 2.625 0 11-5.25 0 2.625 2.625 0 015.25 0z" />
                            </svg>
                            <p>Paul Walker</p>
                          </div>
                          <div className="appointment__red__tick">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6">
                              <path fill-rule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zM12.75 9a.75.75 0 00-1.5 0v2.25H9a.75.75 0 000 1.5h2.25V15a.75.75 0 001.5 0v-2.25H15a.75.75 0 000-1.5h-2.25V9z" clip-rule="evenodd" />
                            </svg>

                          </div>
                        </div>
                        
                    </div>
                    <div className="col-md-4 mb-3">
                        <div className="appointment__time__box">
                          <div className="appointment__time__txt">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                              <path stroke-linecap="round" stroke-linejoin="round" d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                            </svg>
                            <p>10:00 - 10:30</p>
                          </div>
                          <div className="appointment__time__txt">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                              <path stroke-linecap="round" stroke-linejoin="round" d="M15 19.128a9.38 9.38 0 002.625.372 9.337 9.337 0 004.121-.952 4.125 4.125 0 00-7.533-2.493M15 19.128v-.003c0-1.113-.285-2.16-.786-3.07M15 19.128v.106A12.318 12.318 0 018.624 21c-2.331 0-4.512-.645-6.374-1.766l-.001-.109a6.375 6.375 0 0111.964-3.07M12 6.375a3.375 3.375 0 11-6.75 0 3.375 3.375 0 016.75 0zm8.25 2.25a2.625 2.625 0 11-5.25 0 2.625 2.625 0 015.25 0z" />
                            </svg>
                            <p>Paul Walker</p>
                          </div>
                          <div className="appointment__green__tick">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6">
                              <path fill-rule="evenodd" d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm13.36-1.814a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z" clip-rule="evenodd" />
                            </svg>
                          </div>
                        </div>
                        
                    </div>
                    <div className="col-md-4 mb-3">
                        <div className="appointment__time__box">
                          <div className="appointment__time__txt">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                              <path stroke-linecap="round" stroke-linejoin="round" d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                            </svg>
                            <p>10:00 - 10:30</p>
                          </div>
                          <div className="appointment__time__txt">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                              <path stroke-linecap="round" stroke-linejoin="round" d="M15 19.128a9.38 9.38 0 002.625.372 9.337 9.337 0 004.121-.952 4.125 4.125 0 00-7.533-2.493M15 19.128v-.003c0-1.113-.285-2.16-.786-3.07M15 19.128v.106A12.318 12.318 0 018.624 21c-2.331 0-4.512-.645-6.374-1.766l-.001-.109a6.375 6.375 0 0111.964-3.07M12 6.375a3.375 3.375 0 11-6.75 0 3.375 3.375 0 016.75 0zm8.25 2.25a2.625 2.625 0 11-5.25 0 2.625 2.625 0 015.25 0z" />
                            </svg>
                            <p>Paul Walker</p>
                          </div>
                          <div className="appointment__green__tick">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6">
                              <path fill-rule="evenodd" d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm13.36-1.814a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z" clip-rule="evenodd" />
                            </svg>
                          </div>
                        </div>
                        
                    </div>
                    <div className="col-md-4 mb-3">
                        <div className="appointment__time__box">
                          <div className="appointment__time__txt">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                              <path stroke-linecap="round" stroke-linejoin="round" d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                            </svg>
                            <p>10:00 - 10:30</p>
                          </div>
                          <div className="appointment__time__txt">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                              <path stroke-linecap="round" stroke-linejoin="round" d="M15 19.128a9.38 9.38 0 002.625.372 9.337 9.337 0 004.121-.952 4.125 4.125 0 00-7.533-2.493M15 19.128v-.003c0-1.113-.285-2.16-.786-3.07M15 19.128v.106A12.318 12.318 0 018.624 21c-2.331 0-4.512-.645-6.374-1.766l-.001-.109a6.375 6.375 0 0111.964-3.07M12 6.375a3.375 3.375 0 11-6.75 0 3.375 3.375 0 016.75 0zm8.25 2.25a2.625 2.625 0 11-5.25 0 2.625 2.625 0 015.25 0z" />
                            </svg>
                            <p>Paul Walker</p>
                          </div>
                          <div className="appointment__red__tick">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6">
                              <path fill-rule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zM12.75 9a.75.75 0 00-1.5 0v2.25H9a.75.75 0 000 1.5h2.25V15a.75.75 0 001.5 0v-2.25H15a.75.75 0 000-1.5h-2.25V9z" clip-rule="evenodd" />
                            </svg>

                          </div>
                        </div>
                        
                    </div>
                    <div className="col-md-4 mb-3">
                        <div className="appointment__time__box">
                          <div className="appointment__time__txt">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                              <path stroke-linecap="round" stroke-linejoin="round" d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                            </svg>
                            <p>10:00 - 10:30</p>
                          </div>
                          <div className="appointment__time__txt">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                              <path stroke-linecap="round" stroke-linejoin="round" d="M15 19.128a9.38 9.38 0 002.625.372 9.337 9.337 0 004.121-.952 4.125 4.125 0 00-7.533-2.493M15 19.128v-.003c0-1.113-.285-2.16-.786-3.07M15 19.128v.106A12.318 12.318 0 018.624 21c-2.331 0-4.512-.645-6.374-1.766l-.001-.109a6.375 6.375 0 0111.964-3.07M12 6.375a3.375 3.375 0 11-6.75 0 3.375 3.375 0 016.75 0zm8.25 2.25a2.625 2.625 0 11-5.25 0 2.625 2.625 0 015.25 0z" />
                            </svg>
                            <p>Paul Walker</p>
                          </div>
                          <div className="appointment__red__tick">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6">
                              <path fill-rule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zM12.75 9a.75.75 0 00-1.5 0v2.25H9a.75.75 0 000 1.5h2.25V15a.75.75 0 001.5 0v-2.25H15a.75.75 0 000-1.5h-2.25V9z" clip-rule="evenodd" />
                            </svg>

                          </div>
                        </div>
                        
                    </div>
                  </div>
                </div>
              </div>
          </div>
       </div> */}
      {/* Appointment card page end */}
    </>
  );
};

export default WeeklyScheduler;
