import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";

const PatientHealthRecords = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [formData, setFormData] = useState({
    // Initialize form data fields here
    name: "",
    email: "",
    // Add other form fields as needed
  });

  const steps = ["Step 1", "Step 2", "Step 3"]; // Customize the step labels

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  // Render the content for each step
  const renderStepContent = (stepIndex) => {
    switch (stepIndex) {
      case 0:
        return <div className="container-fluid">Basic records</div>;
      case 1:
        return <div className="container-fluid">pathalogy records</div>;
      case 2:
        return <div className="container-fluid">Lab records</div>;
      default:
        return <div>Invalid Step</div>;
    }
  };

  return (
    <div>
      {/* <Stepper activeStep={activeStep}>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper> */}

      <div>
        {/* <div>{renderStepContent(activeStep)}</div> */}

        <div className="row">
          <div className="col-md-12">
            <div className="tab__btn__box__clinic">
              <button
                onClick={() => {
                  setActiveStep(0);
                }}
                className={
                  activeStep == "0"
                    ? "btn btn__tab__select_clinic active"
                    : "btn btn__tab__select_clinic"
                }
              >
                Medical basic history
              </button>
              &nbsp;
              <button
                onClick={() => {
                  setActiveStep(1);
                }}
                className={
                  activeStep == "1"
                    ? "btn btn__tab__select_clinic active"
                    : "btn btn__tab__select_clinic"
                }
              >
                Medicinal Information
              </button>
              &nbsp;
              <button
                onClick={() => {
                  setActiveStep(2);
                }}
                className={
                  activeStep == "2"
                    ? "btn btn__tab__select_clinic active"
                    : "btn btn__tab__select_clinic"
                }
              >
                Pathalogy reports
              </button>
            </div>
          </div>
          <div>{renderStepContent(activeStep)}</div>

          <div className="col-md-12 d-flex justify-content-between">
            <button
              className="btn cancel-btn"
              disabled={activeStep === 0}
              onClick={handleBack}
            >
              Back
            </button>
            <button
              className="btn save-btn"
              variant="contained"
              color="primary"
              onClick={handleNext}
            >
              {activeStep === steps.length - 1 ? "Finish" : "Next"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PatientHealthRecords;
