import React, {useState} from 'react';
import DoctorMemoTable from "../../components/doctor/DoctorMemoTable";
import Button from "../../components/Button";
import DoctorMemoForm from "./DoctorMemoForm";
import {Route, Switch, useHistory, useRouteMatch} from "react-router-dom";
import SubTitle from "../../components/SubTitle";
import DoctorMemoView from "./DoctorMemoView";
import DoctorBloodSugarView from "./DoctorBloodSugarView";

const DoctorMemoMainView = (props) => {

    const {url} = useRouteMatch()
    const history = useHistory()


    return (
        <div>

            <Switch>
                {[`${url}`, `${url}/memos`].map(path => (
                <Route key={path} exact path={path}>
                    <>
                        <SubTitle>Viewing All Memos</SubTitle>

                        <DoctorMemoTable patientUid={props.patientUid}/>
                    </>
                </Route>
                    ))}
                {/*<Route exact path={`${url}/createMemo`}>*/}
                {/*    <DoctorMemoForm patientUid={props.patientUid} onBack={() => history.push(url)}/>*/}
                {/*</Route>*/}
                <Route exact path={`${url}/memos/:memoId`}>
                    <DoctorMemoView patientUid={props.patientUid} onCopyMemo={props.onCopyMemo}/>
                </Route>
            </Switch>

        </div>
    );
};

export default DoctorMemoMainView;