import React from 'react';
import SectionTitle from "../components/SectionTitle";
import { useAuth } from "../useAuth";

const AdminHome = () => {
  const { userData } = useAuth()

  return (
    // <SectionTitle>Welcome Admin {userData.email} </SectionTitle>

    <div className='container-fluid'>
      <div className="form__box__container mt-4">
        Coming Soon
      </div>

      {/* view details start  */}
      {/* <div className='container-fluid mt-5'>
       <div className='form__box__container'>
        <div className='row mt-3'>
            <div className='col-md-6'>
              <div class="mb-2">
                  <label for="" class="form-label cust__label__txt">First Name</label>
                  <label for="" class="form-label">Nitish</label>
                </div>
            </div>
            <div className='col-md-6'>
              <div class="mb-2">
                  <label for="" class="form-label cust__label__txt">Last Name</label>
                  <label for="" class="form-label">Beejawat</label>
                </div>
            </div>
            <div className='col-md-6'>
              <div class="mb-2">
                  <label for="" class="form-label cust__label__txt">Email</label>
                  <label for="" class="form-label">nitish@gmail.com</label>
                </div>
            </div>
            <div className='col-md-6'>
              <div class="mb-2">
                  <label for="" class="form-label cust__label__txt">Contact Number</label>
                  <label for="" class="form-label">+ 12221831654</label>
                </div>
            </div>
            <div className='col-md-6'>
              <div class="mb-2">
                  <label for="" class="form-label cust__label__txt">Gender</label>
                  <label for="" class="form-label">Male</label>
                </div>
            </div>
            <div className='col-md-6'>
              <div class="mb-2">
                  <label for="" class="form-label cust__label__txt">Age</label>
                  <label for="" class="form-label">30</label>
                </div>
            </div>
          </div>
       </div>
      </div> */}
      {/* Help Desk start  */}
      {/* <div className="form__box__container mt-4"> 
                <div className="row">
                    <h3 className="head__weekly__report">Help Desk</h3>
                    <div className="col-md-4">
                      <div className="mb-2">
                        <label for="" className="form-label">
                          ID
                        </label>
                        <input type="text" className="form-control cust__form" id="" />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="mb-2">
                        <label for="" className="form-label">
                          Name
                        </label>
                        <input type="text" className="form-control cust__form" id="" />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="mb-2">
                        <label for="" className="form-label">
                          Email ID
                        </label>
                        <input type="email" className="form-control cust__form" id="" />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="mb-2">
                        <label for="" className="form-label">
                          Status
                        </label>
                        <input type="text" className="form-control cust__form" id="" />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="mb-2">
                        <label for="" className="form-label">
                          Last Response Given Date
                        </label>
                        <input type="date" className="form-control cust__form" id="" />
                      </div>
                    </div>
                    <div className="col-md-12 d-flex justify-content-between mt-3">
                      <button
                        className="btn cancel-btn"
                      >
                        Cancel
                      </button>
                      <button className="btn save-btn">Search</button>
                    </div>
                  </div>
            </div>
            {/* chatbot start  */}
      {/* <div className=' mt-4'>
        <div className='row'>
          <div className='col-md-4'>
            <div className='form__box__container chat__profile__left'>
              <div className="location__book__app mb-3">
                <input
                  className="form-control cust__form"
                  type="search"
                  placeholder="Search"
                />
              </div>
              <div className="clinic__doc__details__box chat__bot__main__box active">
                <div className="row">
                  <div className="col-md-12">
                    <div className="profile__img__doc">
                      <div className="profile__img__details">

                      </div>
                      <div className="profile__details__doc__weekly">
                        <p>Dean Henry</p>
                        <p className='chat__left__profile__time'>Online</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="clinic__doc__details__box chat__bot__main__box">
                <div className="row">
                  <div className="col-md-12">
                    <div className="profile__img__doc">
                      <div className="profile__img__details">

                      </div>
                      <div className="profile__details__doc__weekly">
                        <p>Mike Thomas</p>
                        <p className='chat__left__profile__time'>left 30 mins ago</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="clinic__doc__details__box chat__bot__main__box">
                <div className="row">
                  <div className="col-md-12">
                    <div className="profile__img__doc">
                      <div className="profile__img__details">

                      </div>
                      <div className="profile__details__doc__weekly">
                        <p>Erica Hughes</p>
                        <p className='chat__left__profile__time'>left 45 mins ago</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='col-md-8'>
            <div className='form__box__container'>
              <div className="chat">
                <div className="chat-header clearfix">
                  <div className="profile__img__details">

                  </div>
                  <div className="chat-about">
                    <div className="chat-with">Chat with <span>Dean Henry</span></div>
                    <div className="chat-num-messages">Online</div>
                  </div>
                  <i className="fa fa-star"></i>
                </div>
                <div className="chat-history">
                  <ul>
                    <li className="clearfix">
                      <div className="message-data align-right">
                        <span className="message-data-time" >10:10 AM, Today</span> &nbsp; &nbsp;
                        <span className="message-data-name" >Mike Thomas</span> <i className="fa fa-circle me"></i>

                      </div>
                      <div className="message other-message float-right">
                        Hi Henry, how are you? How is the project coming along?
                      </div>
                    </li>
                    <li>
                      <div className="message-data">
                        <span className="message-data-name"><i className="fa fa-circle online"></i> Dean Henry</span>
                        <span className="message-data-time">10:12 AM, Today</span>
                      </div>
                      <div className="message my-message">
                        Are we meeting today? Project has been already finished and I have results to show you.
                      </div>
                    </li>
                    <li className="clearfix">
                      <div className="message-data align-right">
                        <span className="message-data-time" >10:14 AM, Today</span> &nbsp; &nbsp;
                        <span className="message-data-name" >Mike Thomas</span> <i className="fa fa-circle me"></i>

                      </div>
                      <div className="message other-message float-right">
                        Well I am not sure. The rest of the team is not here yet. Maybe in an hour or so? Have you faced any problems at the last phase of the project?
                      </div>
                    </li>
                    <li>
                      <div className="message-data">
                        <span className="message-data-name"><i className="fa fa-circle online"></i> Dean Henry</span>
                        <span className="message-data-time">10:20 AM, Today</span>
                      </div>
                      <div className="message my-message">
                        Actually everything was fine. I'm very excited to show this to our team.
                      </div>
                    </li>
                  </ul>
                </div>

                <div className="chat-message clearfix row">
                  <div className='col-md-10'>
                    <textarea name="message-to-send" id="message-to-send" placeholder="Type your message" rows="1"></textarea>
                  </div>
                  <div className='col-md-2'>
                    <button className=''>Send</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}

    </div>

  );
};

export default AdminHome;
