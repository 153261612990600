import React, {createContext, useContext, useEffect, useState} from 'react';
import {axiosInstance, publicAxiosInstance, userType} from "../common/constants";
import {toast} from "react-toastify";

const GlobalContext = createContext();

export const useGlobal = () => {
    return useContext(GlobalContext);
};

export default function GlobalProvider({children}) {

    const [refreshInvites, setRefreshInvites] = useState(true);

    const value = {
        refreshInvites,
        setRefreshInvites
    };

    useEffect(() => {
        axiosInstance.interceptors.response.use(function (response) {
            // Any status code that lie within the range of 2xx cause this function to trigger
            // Do something with response data
            return response;
        }, function (error) {
            // Any status codes that falls outside the range of 2xx cause this function to trigger
            // Do something with response error
            if (error.response.status !== 410) {
                toast.error("Something went wrong! Please try again.", {theme: "colored"});
            }
            return Promise.reject(error);
        });
    }, []);
    return (<GlobalContext.Provider value={value}>
        {children}
    </GlobalContext.Provider>)
}
