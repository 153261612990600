import React, {useRef, useState} from 'react';
import PatientBloodSugarTable from "../../components/patient/PatientBloodSugarTable";
import BloodSugarLineGraph from "../../components/BloodSugarLineGraph";
import {Tab} from '@headlessui/react'
import classNames from "classnames";
import Button from "../../components/Button";
import PatientBloodSugarPopup from "./PatientBloodSugarPopup";
import {PlusIcon} from "@heroicons/react/solid";


const PatientDiabetes = () => {

    const [modalIsOpen, setModalIsOpen] = useState(false)
    const [selectedIndex, setSelectedIndex] = useState(0)
    const [toRefreshData, setToRefreshData] = useState(0)

    function onCloseEntryPopup(toRefresh = false) {
        setModalIsOpen(false)
        if (toRefresh) {
            setToRefreshData(value => value + 1)
        }
    }

    return (
        <div>


            {/*<PatientBloodSugarEntry/>*/}
            <div className="flex flex-col">

                <Tab.Group selectedIndex={selectedIndex} onChange={setSelectedIndex}>
                    <div className="w-full sm:w-4/5 sm:flex sm:flex-row-reverse items-center sm:justify-between">
                        <Button layout="submit" className="mb-3 sm:mb-0 w-full sm:w-auto" iconLeft={PlusIcon} onClick={() => setModalIsOpen(true)}>New Entry</Button>

                        <Tab.List
                            className="bg-gray-200 hover:bg-gray-300 rounded-lg flex  grow w-full sm:max-w-sm">
                            <Tab className={({selected}) =>
                                classNames(
                                    'w-full py-2.5 text-sm leading-5 font-medium rounded-lg',
                                    'focus-visible::outline-none focus-visible:ring-2 ring-offset-2 ring-offset-blue-400 ring-white ring-opacity-60',
                                    selected
                                        ? 'bg-white shadow'
                                        : ''
                                )
                            }>Graph</Tab>
                            <Tab className={({selected}) =>
                                classNames(
                                    'w-full py-2.5 text-sm leading-5 font-medium rounded-lg',
                                    'focus-visible:outline-none focus-visible:ring-2 ring-offset-2 ring-offset-blue-400 ring-white ring-opacity-60',
                                    selected
                                        ? 'bg-white shadow'
                                        : ''
                                )
                            }>Table</Tab>
                        </Tab.List>

                    </div>

                    <Tab.Panels>
                        <Tab.Panel><BloodSugarLineGraph pendingRefresh={toRefreshData}/></Tab.Panel>
                        <Tab.Panel><PatientBloodSugarTable pendingRefresh={toRefreshData}/></Tab.Panel>
                    </Tab.Panels>
                </Tab.Group>

            </div>

            <PatientBloodSugarPopup isOpen={modalIsOpen} onClose={(toRefresh) => onCloseEntryPopup(toRefresh)}/>


        </div>
    );
};

export default PatientDiabetes;