import React, { useEffect, useState } from 'react'
import { Link, Route, Switch, useRouteMatch, useHistory } from "react-router-dom";
import AdminHome from "./AdminHome";
import { useAuth } from "../useAuth";
import { axiosInstance, userType } from "../common/constants";
import LoadingDots from "../components/LoadingDots";
import AdminApprovals from "./AdminApprovals";
import AdminApprovalDetails from "./AdminApprovalDetails";
import { AppShell, Header, Navbar } from "@mantine/core";
import SidenavItems from "../dashboard/sidenavigation/SidenavItems";
import TopNavigation from "../dashboard/topnavigation/TopNavigation";
import AdminAwaitingSubmission from "./AdminAwaitingSubmission";

// ----
import AdminAccountDetails from "./AdminAccountSettings";
import RegisterDoctor from "./admin/doctor/create";
import EditDoctor from "./admin/doctor/edit";
import DoctorsList from "./admin/doctor/list";
import DoctorView from "./admin/doctor/view";
import ViewDoctorAppoiments from "./admin/doctor/view-appoiments";
import RegisterPatient from "./admin/patient/create";
import EditPatient from "./admin/patient/edit";
import PatientList from "./admin/patient/list";
import PatientView from "./admin/patient/view";
import RatingAndReviews from "./admin/ratingAndReviews";
import ClinicList from "./admin/clinics/list";
import ClinicView from "./admin/clinics/view";

// Speicialist 
import CreateSpeicialist from "./admin/specialist/create";
import EditSpeicialist from "./admin/specialist/edit";
import Speicialist from "./admin/specialist/list";


const Admin = () => {
    let { path } = useRouteMatch();
    const { userData, setUserData } = useAuth()
    const [loading, setLoading] = useState(true)
    const [isValidRole, setIsValidRole] = useState(false)
    const [opened, setOpened] = useState(false);
    const { signout, user } = useAuth();
    const history = useHistory();

    async function getAdminDetails() {
        try {
            const res = await axiosInstance.get("/getUserDetails")

            const validRole = res.data.userRoles.filter(function (item) {
                return item.role === userType.ADMIN
            })

            if (validRole.length > 0) {
                setUserData({
                    role: userType.ADMIN,
                    email: res.data.email,
                    firstName: res.data.firstName,
                    lastName: res.data.lastName,
                    avatarImageUrl: res.data.avatarImageUrl
                })
                setIsValidRole(true)
            } else {
                setUserData(null)
                setIsValidRole(false)
            }
            setLoading(false)

        } catch (e) {
            console.log(e.message)
            setUserData(null)
            setIsValidRole(false)
            setLoading(false)
        }
    }

    async function handleLogout(e) {
        e.preventDefault();

        try {
            localStorage.clear();
            await signout();
            history.push("/");

        } catch {
            console.log("Failed to log out");
        }
    }

    useEffect(() => {
        if (!userData) {
            getAdminDetails()
        } else {
            setIsValidRole(userData.role === userType.ADMIN)
            setLoading(false)
        }
    }, []);

    return (
        <AppShell
            padding="lg"
            fixed
            navbarOffsetBreakpoint="sm"
            navbar={
                isValidRole ?
                    <Navbar hiddenBreakpoint="sm" hidden={!opened} width={{ sm: 200, lg: 300 }} classNames={{ root: 'font-sans' }}>
                        <SidenavItems isValidRole={isValidRole} onNavItemClick={() => setOpened(false)} />
                    </Navbar> : ""
            }
            header={
                isValidRole ?
                    <Header height={60} p="xs" classNames={{ root: 'font-sans' }}>
                        <TopNavigation opened={opened} setOpened={setOpened} />
                    </Header> : ""
            }
            styles={(theme) => ({
                main: { backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.colors.gray[0] },
            })}
        >
            {loading
                ?
                <LoadingDots />
                :
                isValidRole
                    ?

                    < Switch >
                        <Route path={`${path}`} exact={true}>
                            <AdminHome />
                        </Route>
                        <Route exact path={`${path}/doctor/create`}>
                            <RegisterDoctor />
                        </Route>
                        <Route exact path={`${path}/doctor/appoiments/:uid`}>
                            <ViewDoctorAppoiments />
                        </Route>
                        <Route exact path={`${path}/doctor/view/:uid`}>
                            <DoctorView />
                        </Route>
                        <Route exact path={`${path}/doctor/edit/:uid`}>
                            <EditDoctor />
                        </Route>
                        <Route exact path={`${path}/doctor/list`}>
                            <DoctorsList />
                        </Route>
                        <Route exact path={`${path}/patient/create`}>
                            <RegisterPatient />
                        </Route>
                        <Route exact path={`${path}/patient/edit/:uid`}>
                            <EditPatient />
                        </Route>
                        <Route exact path={`${path}/patient/list`}>
                            <PatientList />
                        </Route>
                        <Route exact path={`${path}/patient/view/:uid`}>
                            <PatientView />
                        </Route>
                        <Route exact path={`${path}/pending`}>
                            <AdminApprovals />
                        </Route>
                        <Route exact path={`${path}/ratings`}>
                            <RatingAndReviews />
                        </Route>
                        <Route exact path={`${path}/accountSettings`}>
                            <AdminAccountDetails />
                        </Route>
                        <Route exact path={`${path}/pending/details`}>
                            <AdminApprovalDetails />
                        </Route>
                        <Route exact path={`${path}/awaiting`}>
                            <AdminAwaitingSubmission />
                        </Route>
                        {/* Specialist */}
                        <Route exact path={`${path}/speicialist/create`}>
                            <CreateSpeicialist />
                        </Route>
                        <Route exact path={`${path}/speicialist/edit/:uid`}>
                            <EditSpeicialist />
                        </Route>
                        <Route exact path={`${path}/speicialist/list`}>
                            <Speicialist />
                        </Route>
                        <Route exact path={`${path}/clinic/list`}>
                            <ClinicList />
                        </Route>
                        <Route exact path={`${path}/clinic/view/:uid`}>
                            <ClinicView />
                        </Route>
                    </Switch>
                    :
                    <>
                        <div className="login-container">
                            <div className="login-centered-div">
                                <span style={{ marginRight: '10px' }}>Your account does not have Administrator privileges. Are you perhaps trying
                                    to login as a</span>
                                <Link onClick={handleLogout} className="text-indigo-600">
                                    Patient or Doctor
                                </Link>
                                ?
                            </div>
                        </div>
                    </>
            }

        </AppShell >

    )
}

export default Admin
