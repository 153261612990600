import React, {useState} from 'react';
import {Controller, useForm} from "react-hook-form";
import {DatePicker, DateTimePicker, TimeInput} from "@mantine/dates";
import classnames from "classnames";
import {RadioGroup} from "@headlessui/react";
import {CheckCircleIcon} from "@heroicons/react/solid";
import Button from "../Button";
import SmallLoadingSpinner from "../SmallLoadingSpinner";
import {axiosInstance} from "../../common/constants";
import classNames from "classnames";

const choices = [
    {value: "", label: "N/A"},
    {value: "BEFORE_MEAL", label: "Before Meal"},
    {value: "AFTER_MEAL", label: "After Meal"},
]

const PatientBloodSugarEntry = (props) => {
    const {register, handleSubmit, formState: {errors}, control} = useForm();
    const [submitting, setSubmitting] = useState(false);

    async function onSubmit(data) {

        const finalDate = data.dateTime


        setSubmitting(true)

        if (props.entryData == null) {

            const res = await axiosInstance.post("/patient/submitBloodSugar", {
                entryDateTime: finalDate,
                mealType: data.mealType,
                value: data.bloodSugar
            }).then(function () {
                props.onSuccess()
            }).catch(function (error) {
                props.onError()
                throw error;
            })

        } else {
            const res = await axiosInstance.post("/patient/editBloodSugar", {
                id: props.entryData.id,
                entryDateTime: finalDate,
                mealType: data.mealType,
                value: data.bloodSugar
            }).then(function () {
                props.onSuccess()
            }).catch(function (error) {
                props.onError()
                throw error;
            })

        }
        setSubmitting(false)
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)} className={classNames("grid", props.className)}>

            <Controller
                name="dateTime"
                control={control}
                rules={{required: "Please enter a date and time."}}
                defaultValue={props.entryData ? new Date(props.entryData.entryTime) : new Date()}
                render={({field}) =>
                    <DateTimePicker
                        placeholder="Enter date and Time"
                        label="Time of Entry"
                        required
                        onChange={field.onChange}
                        value={field.value}
                        error={errors.dateTime?.message}
                        clearable={false}
                        zIndex={1000}
                        popoverProps={{ withinPortal: true }}
                        ref={field.ref}/>}
            />

            <label className="block text-sm font-medium text-gray-700 mt-3">
                Blood Sugar Level
            </label>
            <div className="relative">
                <input type="number" inputMode="decimal" step=".01"
                       defaultValue={props.entryData ? props.entryData.bloodSugarValue : ""}
                       className={classnames('[appearance:textfield] mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md',
                           {
                               'border-red-500': errors.lastName
                           }
                       )}
                       {...register("bloodSugar", {
                           required: "Please enter blood sugar value",
                           valueAsNumber: true
                       })}/>
                <div className="absolute inset-y-0 right-0 pr-6 flex items-center pointer-events-none">
                    <span className="text-gray-500 sm:text-sm">
                        mmol/L
                    </span>
                </div>
            </div>

            {errors.bloodSugar &&
                <p className="block text-sm font-medium text-red-700 mt-2">{errors.bloodSugar.message}</p>}

            <label className="block text-sm font-medium text-gray-700 mt-3">
                Choose one:
            </label>
            <Controller
                name="mealType"
                control={control}
                defaultValue={props.entryData?.mealType ? props.entryData.mealType : choices[0].value}
                render={({field}) =>
                    <RadioGroup {...field} ref={null} className="my-3">
                        <RadioGroup.Label className="sr-only">Measurement Time</RadioGroup.Label>
                        <div className="space-x-2 flex">
                            {choices.map((choice) => (
                                <RadioGroup.Option
                                    key={choice.value}
                                    value={choice.value}
                                    className={({active, checked}) =>
                                        `${
                                            active
                                                ? 'ring-2 ring-offset-2 ring-offset-sky-300 ring-white ring-opacity-60' : ''}
                                    ${checked ? 'bg-indigo-600 text-white' : 'bg-white'}
                                    relative rounded-lg shadow-md px-3 py-2 cursor-pointer flex focus:outline-none`}>
                                    {({active, checked}) => (
                                        <>
                                            <div className="flex items-center justify-between w-full">
                                                <div className="flex items-center">
                                                    <div className="text-sm">
                                                        <RadioGroup.Label
                                                            as="p"
                                                            className={`font-medium  ${
                                                                checked ? 'text-white' : 'text-gray-900'
                                                            }`}
                                                        >
                                                            {choice.label}
                                                        </RadioGroup.Label>
                                                    </div>
                                                </div>
                                                {checked && (
                                                    <div className="pl-2 flex-shrink-0 text-white">
                                                        <CheckCircleIcon className="w-6 h-6"/>
                                                    </div>
                                                )}
                                            </div>
                                        </>
                                    )}
                                </RadioGroup.Option>
                            ))}
                        </div>
                    </RadioGroup>}

            />


            <Button
                type="submit" layout="submit"
                disabled={submitting}>{submitting &&
                <SmallLoadingSpinner className="h-5 w-5 mr-2 -ml-1"/>}Save</Button>
        </form>
    );
};

export default PatientBloodSugarEntry;