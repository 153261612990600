import React, { useEffect, useState } from "react";
import {
  serviceGetDoctorConfiguration,
  servicePostDoctorConfiguration,
} from "../../services/apiService";
import { toast } from "react-toastify";
import "react-datepicker/dist/react-datepicker.css";
import ToastConfirmation from "../../components/toastrConfirmation";
import LoadingDots from "../../components/LoadingDots";
import {
  serviceGetDoctorSettingAffectedRecords,
  serviceListDoctorClinics,
} from "../../services/doctor/services";
import Modal from "react-modal";
import { useAuth } from "../../useAuth";
import DatePicker from "react-datepicker";
import moment, { duration } from "moment";
import TickIcon from "../../dashboard/sidenavigation/icons/tick";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    //marginRight: '-50%',
    transform: "translate(-50%, -50%)",
  },
};

const DoctorSettings = () => {
  const [loading, setLoading] = useState(true);
  const [clinicList, setClinicList] = useState(null);
  const [selectedClinic, setSelectedClinic] = useState(null);
  const [selectedClinicID, setSelectedClinicID] = useState(null);
  const [selectedDate, setSelectedDate] = useState("");
  const [dateArr, setDateArr] = useState([]);
  const [dayArr, setDayArr] = useState([
    "SUN",
    "MON",
    "TUE",
    "WED",
    "THU",
    "FRI",
    "SAT",
  ]);
  const defaultWeek = {
    SUN: [{ startTime: "", endTime: "", maxPatient: 10, day: 0 }],
    MON: [{ startTime: "", endTime: "", maxPatient: 10, day: 1 }],
    TUE: [{ startTime: "", endTime: "", maxPatient: 10, day: 2 }],
    WED: [{ startTime: "", endTime: "", maxPatient: 10, day: 3 }],
    THU: [{ startTime: "", endTime: "", maxPatient: 10, day: 4 }],
    FRI: [{ startTime: "", endTime: "", maxPatient: 10, day: 5 }],
    SAT: [{ startTime: "", endTime: "", maxPatient: 10, day: 6 }],
  };
  let [initialValues, setInitialValues] = useState(defaultWeek);
  const [sundayArray, setSundayArray] = useState(initialValues);
  const [autoBooking, setAutoBooking] = useState(false);
  const [consultationType, setConsultationType] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showClinicSettingModal, setShowClinicSettingModal] = useState(null);
  const inititalAffectedRecords = {
    appointmentCount: 0,
    doctorsSlots: 0,
  };
  const [affectedRecords, setAffectedRecords] = useState(
    inititalAffectedRecords
  );
  const { userData } = useAuth();
  const [slotDuration, setSlotDuration] = useState(30);
  const [durationStart, setDurationStart] = useState("");
  const [durationEnd, setDurationEnd] = useState("");

  useEffect(() => {
    setSelectedClinicID(null);
    setSelectedClinic(null);
    getDoctorClinicList();
  }, []);

  useEffect(() => {
    resetConfiguration();
    getDoctorConfiguration();
  }, [selectedClinicID]);

  function getDayIndex(_day) {
    switch (_day) {
      case "SUN":
        return 0;

      case "MON":
        return 1;

      case "TUE":
        return 2;

      case "WED":
        return 3;

      case "THU":
        return 4;

      case "FRI":
        return 5;

      case "SAT":
        return 6;
    }
  }

  async function resetConfiguration() {
    setConsultationType(null);
    setShowModal(false);
    setShowClinicSettingModal(false);
    setAutoBooking(false);
    setDateArr([]);
    setSundayArray(defaultWeek);
    return true;
  }

  async function getDoctorClinicList() {
    setLoading(true);
    let clinicListResponse = await serviceListDoctorClinics(
      localStorage.getItem("uid"),
      true
    ).catch((e) => toast.error("Failed to fetch clinics!"));
    if (clinicListResponse?.status != 1) {
      toast.error("Failed to fetch clinics!");
    }
    setClinicList(clinicListResponse.data);
    setLoading(false);
  }

  async function getDoctorConfiguration() {
    setLoading(true);
    if (selectedClinicID && selectedClinicID.toString().length > 0) {
      let response = await serviceGetDoctorConfiguration(selectedClinicID);
      let _initialValues = {
        SUN: [{ startTime: "", endTime: "", maxPatient: 10, day: 0 }],
        MON: [{ startTime: "", endTime: "", maxPatient: 10, day: 1 }],
        TUE: [{ startTime: "", endTime: "", maxPatient: 10, day: 2 }],
        WED: [{ startTime: "", endTime: "", maxPatient: 10, day: 3 }],
        THU: [{ startTime: "", endTime: "", maxPatient: 10, day: 4 }],
        FRI: [{ startTime: "", endTime: "", maxPatient: 10, day: 5 }],
        SAT: [{ startTime: "", endTime: "", maxPatient: 10, day: 6 }],
      };
      if (response.status == 1 && response.data) {
        // setDateArr(response.data.excludedDates?response.data.excludedDates:[])

        setConsultationType(response.data.consultationType);
        setAutoBooking(response.data.autoAcceptBooking);

        let tempExcludedDate = [];
        for (const _excludedDate of response.data.excludedDates) {
          const date = new Date(_excludedDate.excludeDate);

          const yyyy = date.getFullYear();
          let mm = date.getMonth() + 1; // Months start at 0!
          let dd = date.getDate();

          mm = mm <= 9 ? "0" + mm : mm;
          dd = dd <= 9 ? "0" + dd : dd;

          const formattedDate = yyyy + "-" + mm + "-" + dd;

          tempExcludedDate.push(formattedDate);
        }

        setDateArr(tempExcludedDate);
        for (const _slot of response.data.week) {
          if (_initialValues[getDayShortName(_slot.day)][0].startTime != "") {
            _initialValues[getDayShortName(_slot.day)].push({
              startTime: _slot.startTime.substr(0, 5),
              endTime: _slot.endTime.substr(0, 5),
              maxPatient: 10,
              day: _slot.day,
            });
          } else {
            _initialValues[getDayShortName(_slot.day)] = [
              {
                startTime: _slot.startTime.substr(0, 5),
                endTime: _slot.endTime.substr(0, 5),
                maxPatient: 10,
                day: _slot.day,
              },
            ];
          }
        }

        setSundayArray({ ..._initialValues });
      } else {
        toast.error("Failed to fetch doctor configuration!");
      }
    }
    setLoading(false);
  }

  async function setDoctorConfiguration(_weekSlot) {
    let data = {
      doctorID: userData.uid,
      clinicID: selectedClinicID,
      forthcomingDays: 1,
      priorDaysToUpdateBooking: 1,
      autoAcceptBooking: autoBooking,
      slotDuration: 60,
      week: _weekSlot,
      excludedDates: dateArr,
      consultationType: consultationType,
    };

    let response = await servicePostDoctorConfiguration(data);

    if (response.status == 1) {
      toast.success(response.message);
    } else {
      toast.error(response.message);
    }
  }

  const ClinicSetting = () => {
    useEffect(() => { });
    return (
      <Modal
        isOpen={showClinicSettingModal}
        onRequestClose={closeClinicSettingModal}
        style={customStyles}
        contentLabel="Clinic Settings"
        ariaHideApp={false}
      >
        <div id="doctor-modal">
          <div className="modal-header">
            <h2 className="modal-title" color="primary">
              {selectedClinic?.clinic?.name}
            </h2>
            <button
              type="button"
              class="btn btn-light d-flex align-self-end"
              onClick={() => closeClinicSettingModal()}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-6 h-6"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>

          <div className="modal-body view-clinic-setting">
            <div className="row">
              <div className="col">
                <label>
                  <h4 color="primary">Weekly Schedule</h4>
                </label>
                <div className="row mt-3">
                  <div className="mb-2">
                    <label className="col-md-4">
                      <h5>Day:</h5>
                    </label>
                    <label className="col-md-8">
                      <h5>Open At - Close At</h5>
                    </label>
                  </div>
                  {selectedClinic?.clinic?.weekAvailability.map((el) => {
                    return (
                      <div className="mb-2">
                        <label className="col-md-4">
                          <h6>{dayArr[el?.day]}:</h6>
                        </label>
                        <label className="col-md-8">
                          <h6>
                            {el?.startTime} - {el?.endTime}
                          </h6>
                        </label>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="col">
                <label>
                  <h4 color="primary">Holidays</h4>
                </label>
                <div className="row mt-3">
                  {selectedClinic?.clinic?.excludeAvailability
                    ? selectedClinic?.clinic?.excludeAvailability.map((el) => {
                      return (
                        <div>
                          <label>
                            <h6>
                              {new Date(el?.excludeDate).toLocaleDateString()}
                            </h6>
                          </label>
                        </div>
                      );
                    })
                    : ""}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  };


  const handleDate = async (value) => {

    const date = `${moment(value).format("YYYY-MM-DD")}T00:00:00.000Z`;
    const _validateDate = `${moment(value).format("YYYY-MM-DD")}`;
    const index = dateArr.indexOf(_validateDate);
    const _index = dateArr.indexOf(date);

    if (index == -1 && _index == -1) {
      setSelectedDate(date);
      setDateArr((prevItems) => [...prevItems, date]);
    }
  };

  function addFormFields(_day) {
    if (
      sundayArray[_day].length == 1 &&
      sundayArray[_day][0]?.startTime == "" &&
      sundayArray[_day][0]?.endTime == ""
    ) {
      return;
    }
    let test = sundayArray;
    let formValues = { ...sundayArray };
    formValues[_day] = [
      ...formValues[_day],
      { startTime: "", endTime: "", maxPatient: 10, day: getDayIndex(_day) },
    ];
    setSundayArray(formValues);
  }

  function removeFormFields(_day, index) {
    let formValues = { ...sundayArray };
    if (formValues[_day].length > 1) {
      formValues[_day].splice(index, 1);
      setSundayArray(formValues);
    } else {
      formValues[_day] = [
        {
          startTime: "",
          endTime: "",
          maxPatient: 10,
          day: getDayIndex(_day),
        },
      ];
      setSundayArray(formValues);
    }
  }

  const assignSlots = (slots, day, index) => {
    let week = sundayArray;
    if (!day && isNaN(index)) {
      Object.keys(sundayArray).map((day, index) => {
        week[day] = [...slots].map((el) => {
          return {
            ...el,
            day: getDayIndex(day),
            maxPatient: 10,
          };
        });
        if (index >= 6) {
          setSundayArray({
            ...week,
          });
        }
      });
    } else if (day && !isNaN(index)) {
      week[day] = week[day].slice(0, index + 1);
      week[day] = [...week[day], ...slots].map((el) => {
        return {
          ...el,
          day: getDayIndex(day),
          maxPatient: 10,
        };
      });
      setSundayArray({
        ...week,
      });
    } else if (day) {
      week[day] = [...slots].map((el) => {
        return {
          ...el,
          day: getDayIndex(day),
          maxPatient: 10,
        };
      });
      setSundayArray({
        ...week,
      });
    }
  };

  const copyToAllDays = (day) => {
    const slots = sundayArray[day];
    let week = Array.from(sundayArray);
    Object.keys(sundayArray).map((day, index) => {
      week[day] = Array.from(slots).map((el) => ({
        ...el,
        day: index,
      }));
      if (index >= 6) {
        setSundayArray({
          ...week,
        });
      }
    });
  };

  const generateSlots = (startTime, slots, day, index) => {
    if (startTime && startTime >= moment(durationEnd, moment.HTML5_FMT.TIME)) {
      return assignSlots(slots, day, index);
    }
    let endTime;
    if (!startTime) {
      slots = [];
      startTime = durationStart;
      endTime = moment(durationStart, moment.HTML5_FMT.TIME).add(
        slotDuration,
        "m"
      );
      let lastSlot = endTime >= moment(durationEnd, moment.HTML5_FMT.TIME);
      slots.push({
        startTime: startTime,
        endTime: lastSlot
          ? moment(durationEnd, moment.HTML5_FMT.TIME).format("HH:mm")
          : endTime?.format("HH:mm"),
      });
      return generateSlots(endTime, slots);
    }
    endTime = moment(startTime, moment.HTML5_FMT.TIME).add(slotDuration, "m");
    let lastSlot = endTime >= moment(durationEnd, moment.HTML5_FMT.TIME);
    slots.push({
      startTime: startTime?.format("HH:mm"),
      endTime: lastSlot
        ? moment(durationEnd, moment.HTML5_FMT.TIME).format("HH:mm")
        : endTime?.format("HH:mm"),
    });
    return generateSlots(endTime, slots, day, index);
  };

  const TimeSlotInputField = (props) => {
    const handleStartTimeChange = async (_index, _day, event) => {
      const time = event.target.value;
      if (time.length <= 0) {
        sundayArray[_day][_index].endTime = "";
        setSundayArray({
          ...sundayArray,
        });
      }
      const validSlot = await validateSlot(_index, _day, time);
      if (validSlot) {
        sundayArray[_day][_index].startTime = time;
        setSundayArray({
          ...sundayArray,
        });
      } else {
        event.target.setCustomValidity(
          "Invalid time. Time present in another slot."
        );
        event.target.reportValidity();
        setTimeout(() => {
          event.target.setCustomValidity("");
          event.target.reportValidity();
        }, 1000);
      }
      // if (durationStart?.length > 0 && durationEnd?.length > 0) {
      //   let startTime = moment(
      //     document.getElementById(`startTime_${_index - 1}`).value,
      //     moment.HTML5_FMT.TIME
      //   );
      //   generateSlots(startTime, [], _day, _index - 1);
      // }
    };

    async function handleEndTimeChange(_index, _day, event) {
      const time = event.target.value;
      if (time == sundayArray[_day][_index].startTime) {
        event.target.setCustomValidity(
          "Can not select start time as end time!"
        );
        event.target.reportValidity();
        return;
      }
      sundayArray[_day][_index].endTime = time;
      setSundayArray({
        ...sundayArray,
      });
      if (durationStart?.length > 0 && durationEnd?.length > 0) {
        let startTime = moment(time, moment.HTML5_FMT.TIME);
        generateSlots(startTime, [], _day, _index);
      }
    }

    const validateSlot = async (index, day, time) => {
      if (
        sundayArray[day].length <= 1 ||
        (durationStart?.length > 0 && durationEnd?.length > 0)
      ) {
        return true;
      }
      time = new Date(`01-01-2000 ${time}`);
      const validSlot = sundayArray[day].findIndex((el, i) => {
        const startTime = new Date(`01-01-2000 ${el.startTime}`);
        const endTime = new Date(`01-01-2000 ${el.endTime}`);
        return index != i && startTime < time && time < endTime;
      });
      return validSlot < 0;
    };

    return (
      <div className="row">
        <div className="col-1">
          {props.index == 0 && <p className="days__txt">{props.day}</p>}
        </div>
        <div className="col-3">
          <input
            id={"startTime_" + props.index}
            name="startTime"
            value={props.startTime}
            onChange={(e) => handleStartTimeChange(props.index, props.day, e)}
            class="form-control  "
            type="time"
          />
        </div>
        <div className="col-3">
          <input
            id={"endTime_" + props.index}
            name="endTime"
            value={props.endTime}
            min={props.startTime}
            disabled={props.startTime?.toString().length <= 0}
            onChange={(e) => {
              if (e.target?.checkValidity() && e.target.value?.length > 0) {
                handleEndTimeChange(props.index, props.day, e);
              } else {
                e.target.setCustomValidity(
                  "End time must be greater than start time!"
                );
                e.target.reportValidity();
                setTimeout(() => {
                  e.target.setCustomValidity("");
                }, 2000);
              }
            }}
            class="form-control"
            type="time"
            required
          />
        </div>

        <div className="col-5">
          <button
            type="button"
            onClick={() => removeFormFields(props.day, props?.index)}
            className="btn btn-outline-danger m-2"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-6 h-6"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M20.25 7.5l-.625 10.632a2.25 2.25 0 01-2.247 2.118H6.622a2.25 2.25 0 01-2.247-2.118L3.75 7.5M10 11.25h4M3.375 7.5h17.25c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125z"
              />
            </svg>
          </button>
          {props?.index == sundayArray[props?.day]?.length - 1 && (
            <button
              type="button"
              onClick={() => addFormFields(props.day)}
              className="btn btn-outline-info m-2"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-6 h-6"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
            </button>
          )}
        </div>
      </div>
    );
  };

  function getDayShortName(_day) {
    switch (_day) {
      case 0:
        return "SUN";

      case 1:
        return "MON";

      case 2:
        return "TUE";

      case 3:
        return "WED";

      case 4:
        return "THU";

      case 5:
        return "FRI";

      case 6:
        return "SAT";
    }
  }

  const handleSubmit = async (e) => {
    if (e) {
      e.preventDefault();
    }
    closeModal();

    let finalDaySlots = [];
    for (const key in sundayArray) {
      if (Object.hasOwnProperty.call(sundayArray, key)) {
        finalDaySlots.push(...sundayArray[key]);
      }
    }

    // await setDoctorConfiguration(finalDaySlots);
    handleConfirmation(e, finalDaySlots);
  };

  const handleConfirmation = (event, finalDaySlots) => {
    const prop1 = JSON.stringify(finalDaySlots);
    toast(
      <ToastConfirmation
        prop1={prop1}
        prop2={handleConfirm}
        prop3={handleCancel}
      />,
      { position: toast.POSITION.TOP_CENTER, closeButton: false }
    );
  };

  const handleConfirm = async (event) => {
    toast.dismiss();
    const data = JSON.parse(event.target.dataset.userid);
    // Perform the action
    setLoading(true);
    await setDoctorConfiguration(data);
    setLoading(false);
  };

  const getAffectedRecords = async () => {
    try {
      setLoading(true);
      let finalDaySlots = [];
      for (const key in sundayArray) {
        if (Object.hasOwnProperty.call(sundayArray, key)) {
          finalDaySlots.push(...sundayArray[key]);
        }
      }

      let data = {
        doctorID: userData.uid,
        clinicID: selectedClinicID,
        forthcomingDays: 1,
        priorDaysToUpdateBooking: 1,
        autoAcceptBooking: autoBooking,
        slotDuration: 60,
        week: finalDaySlots,
        excludedDates: dateArr,
        consultationType: consultationType,
      };
      const res = await serviceGetDoctorSettingAffectedRecords(data);
      if (res.status == 1) {
        affectedRecords.appointmentCount = res.data.appointmentCount;
        affectedRecords.doctorsSlots = res.data.doctorsSlots;
        setAffectedRecords({
          ...affectedRecords,
        });
        return res.data;
      } else {
        throw "Failed to get affected records. Please try again.";
      }
    } catch (error) {
      throw "Failed to get affected records. Please try again.";
    } finally {
      setLoading(false);
    }
  };

  const handleCancel = () => {
    toast.dismiss();
  };

  // Function to handle opening the modal and setting the selected doctor
  const openModal = () => {
    setShowModal(true);
  };

  // Function to handle opening the modal and setting the selected doctor
  const openClinicSettingModal = () => {
    if (selectedClinicID) {
      setShowClinicSettingModal(true);
    }
  };

  const showWarning = async (e) => {
    try {
      if (e) {
        e.preventDefault();
      }
      const records = await getAffectedRecords();
      if (+affectedRecords.appointmentCount > 0) {
        openModal();
      } else {
        handleSubmit();
      }
    } catch (error) {
      toast.error(error);
    }
  };

  // Function to handle closing the modal
  const closeModal = () => {
    setShowModal(false);
  };

  // Function to handle closing the modal
  const closeClinicSettingModal = () => {
    setShowClinicSettingModal(false);
  };

  const ConfirmationModal = () => {
    return (
      <Modal
        isOpen={showModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Doctor Details"
        ariaHideApp={false}
      >
        <div id="doctor-modal">
          <div className="modal-header">
            <h5 className="modal-title text-danger">
              Are you sure you want to update settings?
            </h5>
          </div>

          <div className="modal-body">
            <div>
              <p>
                Attention {userData.firstName + " " + userData.lastName} Team,
              </p>
              <p className="text-danger">
                Important Alert: Potential Impact on Appointments and Doctor
                Slots Due to Clinic Settings Update
              </p>
              <p>
                We need to bring to your attention that updating the clinic
                settings may have an effect on appointments and doctor slots.
              </p>
              <p>Currently:</p>
              <p>
                Appointments are scheduled:{" "}
                <span className="text-danger">
                  {affectedRecords.appointmentCount}
                </span>
              </p>
              <p>Before proceeding, please consider the following:</p>
              <p>
                Appointment Scheduling: The update to clinic settings could lead
                to adjustments in the appointment scheduling system. Some
                scheduled appointments might be affected during the process.
              </p>
              <p>
                Doctor's Slots: The update might also influence the availability
                of doctor slots, potentially affecting the number of available
                appointments.
              </p>
            </div>

            <div class="form-group mt-3 text-end">
              <button
                type="button"
                class="btn btn-primary"
                onClick={(e) => handleSubmit(e)}
              >
                Yes
              </button>
              <button
                type="button"
                class="btn btn-light"
                onClick={() => closeModal()}
              >
                No
              </button>
            </div>
          </div>
        </div>
      </Modal>
    );
  };

  const clearSlots = () => {
    setSlotDuration(30);
    setDurationStart("");
    setDurationEnd("")
  }

  return !loading ? (
    <>
      <div className="docter__setting__header">
        <h2>Setting</h2>
        {/* <button className="setting__doctor__save">Save</button> */}
      </div>
      <div className="container-fluid">
        <div className="main__duration__box">
          <div className="row">
            <div className="duration__head" style={{ marginTop: "10px" }}>
              <div class="form-group row">
                <label for="clinic" class="col-sm-2 col-form-label">
                  <h3>Select Clinic</h3>
                </label>
                <div class="col-sm-7">
                  <select
                    id="clinic"
                    class="form-control form-control"
                    onChange={async (event) => {
                      setSelectedClinicID(event.target.value);
                      const newClinic = clinicList.find(
                        (el) => el.clinicID == event.target.value
                      );
                      setSelectedClinic({
                        ...newClinic,
                      });
                      clearSlots()
                    }}
                    value={selectedClinicID}
                  >
                    <option value="">Select Clinic</option>
                    {clinicList && clinicList.length > 0
                      ? clinicList.map((el) => (
                        <option value={el.clinicID}>
                          {el?.clinic?.name}
                        </option>
                      ))
                      : ""}
                  </select>
                </div>
                {selectedClinicID ? (
                  <div
                    className="col-sm-3 align-items-center"
                    color="primary"
                    style={{
                      cursor: "pointer",
                      display:
                        selectedClinicID && selectedClinicID.length > 0
                          ? "flex !important"
                          : "none !important",
                    }}
                  >
                    <button
                      type="button"
                      id="clinic_setting"
                      className="d-flex"
                      onClick={(e) => openClinicSettingModal()}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        class="w-6 h-6"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M9.594 3.94c.09-.542.56-.94 1.11-.94h2.593c.55 0 1.02.398 1.11.94l.213 1.281c.063.374.313.686.645.87.074.04.147.083.22.127.324.196.72.257 1.075.124l1.217-.456a1.125 1.125 0 011.37.49l1.296 2.247a1.125 1.125 0 01-.26 1.431l-1.003.827c-.293.24-.438.613-.431.992a6.759 6.759 0 010 .255c-.007.378.138.75.43.99l1.005.828c.424.35.534.954.26 1.43l-1.298 2.247a1.125 1.125 0 01-1.369.491l-1.217-.456c-.355-.133-.75-.072-1.076.124a6.57 6.57 0 01-.22.128c-.331.183-.581.495-.644.869l-.213 1.28c-.09.543-.56.941-1.11.941h-2.594c-.55 0-1.02-.398-1.11-.94l-.213-1.281c-.062-.374-.312-.686-.644-.87a6.52 6.52 0 01-.22-.127c-.325-.196-.72-.257-1.076-.124l-1.217.456a1.125 1.125 0 01-1.369-.49l-1.297-2.247a1.125 1.125 0 01.26-1.431l1.004-.827c.292-.24.437-.613.43-.992a6.932 6.932 0 010-.255c.007-.378-.138-.75-.43-.99l-1.004-.828a1.125 1.125 0 01-.26-1.43l1.297-2.247a1.125 1.125 0 011.37-.491l1.216.456c.356.133.751.072 1.076-.124.072-.044.146-.087.22-.128.332-.183.582-.495.644-.869l.214-1.281z"
                        />
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                        />
                      </svg>
                      View Clinic Settings
                    </button>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {selectedClinicID && selectedClinicID.toString().length > 0 ? (
        <div className="container-fluid">
          {/* Auto accept booking */}
          <div className="main__duration__box">
            <div className="row">
              <div className="duration__head" style={{ marginTop: "10px" }}>
                <div>
                  {/* <input
                    value={autoBooking}
                    checked={autoBooking}
                    onChange={() => setAutoBooking(!autoBooking)}
                    class="form-check-input"
                    type="checkbox"
                    id=""
                  />
                  <label class="form-check-label" for="">
                    <h3>Auto Accept Booking</h3>
                  </label> */}
                  <label className="switch__toggle">
                    <input
                      value={autoBooking}
                      checked={autoBooking}
                      onChange={() => setAutoBooking(!autoBooking)}
                      class="check-toggle__input"
                      type="checkbox"
                      id=""
                    />
                    <span class="slider-checkbox round-50"></span>
                  </label>
                  <label class="form-check-label ps-2" for="">
                    <h3>Auto Accept Booking</h3>
                  </label>
                </div>
              </div>
              {/* </div> */}
            </div>
          </div>
          {/* End of auto accept */}

          {/* Consultation Type */}
          <div className="main__duration__box">
            <h3 className="head__weekly__report">Select Consultation Type</h3>
            <div className="row ps-2">
              <div>
                <input
                  class="form-check-input"
                  type="checkbox"
                  name="consultationType"
                  id="consultationType1"
                  value="1"
                  onChange={(event) =>
                    event.target.checked
                      ? setConsultationType(1)
                      : setConsultationType(2)
                  }
                  checked={consultationType == 1}
                />
                <label class="form-check-label ps-2" for="consultationType1">
                  Are you consulting online?
                </label>
              </div>
            </div>
          </div>
          {/* End of consultation type */}

          {/* Opening Hour */}
          <div className="main__duration__box">
            <h3 className="head__weekly__report">Opening Hours</h3>
            <div className="row ps-2">
              <div className="d-flex flex-column">
                <label class="form-check-label" for="slot_duration">
                  Slot Duration (Minutes)
                </label>
                <input
                  style={{ width: "fit-content" }}
                  type="number"
                  name="Duration"
                  id="slot_duration"
                  step="1"
                  // onInput="validity.valid||(value=Math.floor(value));"
                  onInput={(event) =>
                    (event.target.value = Math.floor(event.target.value))
                  }
                  value={slotDuration}
                  className="form-control"
                  onChange={(e) => setSlotDuration(e.target.value)}
                />
              </div>

              <div className="col-8 d-flex mt-2 align-items-center">
                <div className="p-2 ps-0 col-3">
                  <div className="d-flex flex-column">
                    <label class="form-check-label" for="duration_start">
                      Start Time
                    </label>
                    <input
                      type="time"
                      name="Duration"
                      id="duration_start"
                      className="form-control"
                      value={durationStart}
                      onChange={(e) => {
                        setDurationStart(e.target.value);
                        e.target.value?.length <= 0 && setDurationEnd("");
                      }}
                    />
                  </div>
                </div>
                <div className="p-2 col-3">
                  <div className="d-flex flex-column">
                    <label class="form-check-label" for="duration_end">
                      End Time
                    </label>
                    <input
                      type="time"
                      name="Duration"
                      id="duration_end"
                      className="form-control"
                      value={durationEnd}
                      min={durationStart}
                      onChange={(e) => {
                        if (e.target.checkValidity()) {
                          setDurationEnd(e.target.value);
                        } else {
                          e.target.setCustomValidity(
                            "End time must be greater than start time!"
                          );
                          e.target.reportValidity();
                          setTimeout(() => {
                            e.target.setCustomValidity("");
                          }, 2000);
                        }
                      }}
                    />
                  </div>
                </div>
                <div className="p-2 align-self-end col-3">
                  <div>
                    <button
                      type="button"
                      className="btn d-flex btn-outline-primary"
                      onClick={() => {
                        if (slotDuration < 15 || slotDuration > 3600) {
                          const duration =
                            document.getElementById("slot_duration");
                          console.log(duration);
                          duration.setCustomValidity(
                            "Value should be greater than or equal to 15 and less than 3600."
                          );
                          duration.reportValidity();
                          setTimeout(() => {
                            duration.setCustomValidity("");
                          }, 2000);
                          return;
                        }
                        if (
                          durationStart?.length > 0 &&
                          durationEnd?.length > 0
                        ) {
                          generateSlots();
                        }
                      }}
                    >
                      <TickIcon />
                      &nbsp;Apply
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Opening Hour */}

          <div className="main__duration__box">
            <div className="row">
              <div className="col-md-8">
                <h3 className="head__weekly__report">Weekly Working Hour</h3>
                <div className="weekly__w__hour">
                  <form onSubmit={showWarning}>
                    <div className="mb-3">
                      <button
                        type="button"
                        className="btn d-flex btn-outline-primary btn-sm"
                        onClick={(event) => copyToAllDays("SUN")}
                      >
                        <TickIcon />
                        &nbsp;Apply To All
                      </button>
                    </div>
                    {dayArr.map((element, index) => (
                      <div className="pb-2">
                        {/* <DayHoursField day={element} index={index} /> */}
                        {sundayArray[element].map((slotValue, index) => (
                          <TimeSlotInputField
                            startTime={slotValue.startTime}
                            endTime={slotValue.endTime}
                            index={index}
                            day={element}
                            subFields={index ? true : false}
                          />
                        ))}
                      </div>
                    ))}

                    <div className="docter__setting__header">
                      <h2></h2>
                      <button type="submit" className="setting__doctor__save">
                        Save
                      </button>
                    </div>
                  </form>
                </div>
              </div>
              <div className="col-md-4">
                <h3 className="head__weekly__report">Exclude Dates</h3>
                <div className="form-">
                  {/* <label class="form-label">Enter Date</label> */}
                  <DatePicker
                    className="form-control"
                    placeholderText="Select a date"
                    dateFormat="MM-dd-yyyy"
                    showMonthDropdown="true"
                    showYearDropdown="true"
                    onChange={handleDate}
                    minDate={new Date()}
                  />
                </div>
                <div style={{ marginTop: "20px" }}>
                  {dateArr && dateArr?.length > 0 && <b>Excluded Dates:</b>}
                  <div>
                    {dateArr.map((e, i) => (
                      <div
                        className="mt-2 d-flex align-item-center border-bottom"
                        style={{ width: "fit-content" }}
                      >
                        <h5>{new Date(e).toLocaleDateString()}</h5>
                        <button
                          type="button"
                          className="btn btn-outline-danger ms-2"
                          onClick={(e) => {
                            const dates = dateArr;
                            dates.splice(i, 1);
                            setDateArr([...dates]);
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            class="w-6 h-6"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                            />
                          </svg>
                        </button>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              {/* <div className="col-md-1"></div> */}
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      <ConfirmationModal closeModal={closeModal} modalIsOpen={showModal} />
      <ClinicSetting
        closeModal={closeClinicSettingModal}
        modalIsOpen={showClinicSettingModal}
      />
    </>
  ) : (
    <LoadingDots />
  );
};

export default DoctorSettings;
