import React, { createContext, useContext, useEffect, useState } from "react";
import { serviceGetAccessTokenDetails, serviceGetSignOut, servicePostChangeUserPassword, servicePostResetPassword, servicePostSignIn } from "./services/apiService";

const AuthContext = createContext();

export const useAuth = () => {
    return useContext(AuthContext);
};


// Provider component that wraps your app and makes auth object ...
// ... available to any child component that calls useAuth().
export function AuthProvider({ children }) {
    const [user, setUser] = useState(null);
    const [userData, setUserData] = useState(null);
    const [loading, setLoading] = useState(true);

    // ... to save the user to state.
    const signin = async(email, password) => {
        try {
            let data = {
                "email": email,
                "password": password
            };

            const response = await servicePostSignIn(data);
            if (response.status == 0) {
                throw response;
            }
            if (response.status == 1) {
                setUser(response.data)
                return {
                    user: response.data
                }
            }
        } catch (e) {
            throw e;
        }
    };

    const signout = async() => {
        try {
            const response = await serviceGetSignOut();
            if (response.status == 0) {
                throw response;
            }
            if (response.status == 1) {
                setUser(null)
                setUserData(null)
                return response
            }
        } catch (e) {
            throw e;
        }
    };

    async function updateUserPassword(currentPassword, newPassword, confirmPassword) {
        try {
            let data = {
                "oldPassword": currentPassword,
                "newPassword": newPassword,
                "confirmPassword": confirmPassword
            };

            const response = await servicePostChangeUserPassword(data);
            if (response.status == 0) {
                throw response;
            }
            if (response.status == 1) {
                setUser(response.data)
                return response
            }
        } catch (e) {
            throw e;
        }
    }

    async function resetUserPassword(credentialID, newPassword, confirmPassword) {
        try {
            let data = {
                "credentialID": credentialID,
                "password": newPassword,
                "confirmPassword": confirmPassword
            };

            const response = await servicePostResetPassword(data);
            if (response.status == 0) {
                throw response;
            }
            if (response.status == 1) {
                setUser(response.data)
                return response
            }
        } catch (e) {
            throw e;
        }
    }

    // Subscribe to user on mount
    // Because this sets state in the callback it will cause any ...
    // ... component that utilizes this hook to re-render with the ...
    // ... latest auth object.
    useEffect(() => {
        // const authToken = localStorage.getItem('authToken');
        // console.log()
        // if (authToken && !user) {
        //     getTokenDetails()
        // } else if (!user) {
        //     setUserData(null)
        // }
        setLoading(false)
    }, []);

    // Return the user object and auth methods
    const value = {
        user,
        setUser,
        userData,
        setUserData,
        signin,
        signout,
        updateUserPassword,
        resetUserPassword
    };
    return (<AuthContext.Provider value={value}>
        {!loading && children}
    </AuthContext.Provider>)
}