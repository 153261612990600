import React, { useState, useEffect } from "react";
import { serviceGetPatientClinicDetails } from "../../services/apiService";
import { useParams } from "react-router-dom";
import DefaultAvatar from "../../components/DefaultAvatar";
import PatientClinicBookAppointment from "./patientClinicBookAppointment";
import LoadingSpinner from "../../components/LoadingSpinner";

const PatientClinicDetails = () => {
  const [loading, setLoading] = useState(true);
  const [clinicDetails, setClinicDetails] = useState([]);
  const { id } = useParams();

  async function getPatientClinicDetails() {
    try {
      setLoading(true);
      let response = await serviceGetPatientClinicDetails(id);
      if (response.status == 1) {
        setLoading(false);
        setClinicDetails(response.data);
        console.log("response123", response.data);
      }
    } catch (e) {
      setLoading(false);
    }
  }

  useEffect(() => {
    getPatientClinicDetails();
  }, []);
  return (
    <>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <>
          <div className="container-fluid">
            <div className="col-md-12 d-flex justify-content-between">
              <h2 className="text-2xl font-semibold text-gray-700 dark:text-gray-200">
                Clinic Details
              </h2>
            </div>

            <div className="row">
              <div className="profile__image__box">
                {clinicDetails?.user?.avatarImageUrl ? (
                  <img
                    src={clinicDetails?.user?.avatarImageUrl}
                    className="margin-auto rounded-full col-span-3 profile__img__setting"
                    alt="Avatar"
                  />
                ) : (
                  <DefaultAvatar className="m-auto profile__img__setting" />
                )}
              </div>
              <div className="col-sm-6">
                <label className="patient__details__label">Name: </label>
                {clinicDetails?.name || "N/A"}
              </div>
              <div className="col-sm-6">
                <label className="patient__details__label">Email :</label>
                {clinicDetails?.user?.email || "N/A"}
              </div>
              <div className="col-sm-6">
                <label className="patient__details__label">Mobile:</label>
                {clinicDetails?.user?.contactNumber || "N/A"}
              </div>
              <div className="col-sm-6">
                <label className="patient__details__label">Country:</label>
                {clinicDetails?.country || "N/A"}
              </div>
              <div className="col-sm-6">
                <label className="patient__details__label">Timings:</label>
                {clinicDetails?.weekAvailability?.filter(
                  (item) =>
                    item?.day == new Date(Date.now()).getDay() &&
                    item.startTime != ""
                ) == ""
                  ? "Holiday"
                  : clinicDetails?.weekAvailability?.filter(
                    (item) =>
                      item?.day == new Date(Date.now()).getDay() &&
                      item.startTime != ""
                  )?.[0].startTime +
                  " - " +
                  clinicDetails?.weekAvailability?.filter(
                    (item) =>
                      item?.day == new Date(Date.now()).getDay() &&
                      item.startTime != ""
                  )?.[0].endTime}
              </div>
              <div className="col-sm-6">
                <label className="patient__details__label">Address: </label>
                {clinicDetails?.addressLine1 ||
                  "" + "" + clinicDetails?.addressLine2 ||
                  "N/A"}
              </div>
            </div>
          </div>

          <hr></hr>
          <div className="container-fluid">
            <PatientClinicBookAppointment clinicID={id} />
          </div>
        </>
      )}
    </>
  );
};

export default PatientClinicDetails;
