import React, { useEffect, useState } from "react";
import Table from "../../components/table/Table";
import TableHeader from "../../components/table/TableHeader";
import TableCell from "../../components/table/TableCell";
import TableBody from "../../components/table/TableBody";
import TableRow from "../../components/table/TableRow";
import TableFooter from "../../components/table/TableFooter";
import TableContainer from "../../components/table/TableContainer";
import LoadingDots from "../../components/LoadingDots";
import Pagination from "../../components/Pagination";
import SectionTitle from "../../components/SectionTitle";
import { serviceGetDoctorPatientsList } from "../../services/doctor/services";
import SearchIcon from "../../dashboard/sidenavigation/icons/search";
import RecordPlaceholder from "../../components/RecordPlaceholder";
import { useAuth } from "../../useAuth";
import { capitalizeFirstChar } from "../../helper/utils";
import EyeIcon from "../../dashboard/sidenavigation/icons/eye";
import { Link, useRouteMatch } from "react-router-dom";

const DoctorPatientsList = () => {
  const { userData } = useAuth();
  const [loading, setLoading] = useState(true);
  const [dataTable, setDataTable] = useState(true);
  // const [selectedStatus, setSelectedStatus] = useState(false);
  const [pageTable, setPageTable] = useState(0);
  const [totalResults, setTotalResults] = useState(0);
  const { url } = useRouteMatch();

  const resultsPerPage = 10;

  function onPageChangeTable(p) {
    setPageTable(p - 1);
  }

  useEffect(() => {
    fetchDoctorPatientsList();
  }, [pageTable]);

  async function fetchDoctorPatientsList(search = "") {
    const res = await serviceGetDoctorPatientsList(
      userData.uid,
      pageTable,
      search
    );
    setDataTable(res.data);
    setTotalResults(res.totalCount);
    setLoading(false);
  }

  return (
    <>
      <SectionTitle>Your Patients </SectionTitle>
      <div className="container-fluid">
        <div className="row">
          <div class="col-4 ps-0">
            <input
              type="search"
              class="form-control cust__form"
              id="search"
              placeholder="Search"
              // onKeyUp={(event) => searchReview(event)}
            />
          </div>
          <div class="col-2">
            <button
              type="button"
              class="btn d-flex"
              style={{ background: "#4F46E5", color: "white" }}
              // onClick={() => searchAppointment()}
            >
              <SearchIcon />
              <p className="m-0">Search</p>
            </button>
          </div>
        </div>
      </div>
      {!loading ? (
        <TableContainer className="form__box__container mb-8 mt-3">
          <Table>
            <TableHeader>
              <TableRow>
                <TableCell>Sr.</TableCell>
                <TableCell>Patient Name</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Contact</TableCell>
                <TableCell>DOB</TableCell>
                <TableCell>Gender</TableCell>
                <TableCell>Age</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHeader>
            <TableBody>
              {dataTable && dataTable.length > 0 ? (
                dataTable.map((el, i) => (
                  <TableRow key={i}>
                    <TableCell>
                      <p className="font-semibold text-sm">
                        {pageTable * resultsPerPage + (i + 1)}
                      </p>
                    </TableCell>
                    <TableCell>
                      <p className="font-semibold text-sm">
                        {capitalizeFirstChar(el?.firstName)} {el?.lastName}
                      </p>
                    </TableCell>
                    <TableCell>
                      <p className="font-semibold text-sm">{el?.email}</p>
                    </TableCell>
                    <TableCell>
                      <p className="font-semibold text-sm">
                        {el?.contactNumber}
                      </p>
                    </TableCell>
                    <TableCell>
                      <p className="font-semibold text-sm">
                        {el?.dob ? new Date(el?.dob).toLocaleDateString() : ""}
                      </p>
                    </TableCell>
                    <TableCell>
                      <p className="font-semibold text-sm">{el?.gender}</p>
                    </TableCell>
                    <TableCell>
                      <p className="font-semibold text-sm">{el?.age}</p>
                    </TableCell>
                    <TableCell>
                      <Link
                        to={{
                          pathname: `patientDetails/${el?.uid}`,
                        }}
                      >
                        <EyeIcon></EyeIcon>
                      </Link>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <RecordPlaceholder table="true" />
              )}
            </TableBody>
          </Table>
          {totalResults > 0 && (
            <TableFooter>
              <Pagination
                totalResults={totalResults}
                resultsPerPage={resultsPerPage}
                onChange={onPageChangeTable}
                label="Table navigation"
              />
            </TableFooter>
          )}
        </TableContainer>
      ) : (
        <LoadingDots />
      )}
    </>
  );
};

export default DoctorPatientsList;
