import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { capitalizeFirstChar } from "../../../helper/utils";
// Sevices
import { getDoctorClinics } from "../../../services/admin/serviceDoctor";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "30%",
  },
};

const ViewClinics = (props) => {
  let subtitle;
  var _openModel = props.openmode;

  const [loading, setLoading] = useState(true);
  const [clinics, setClinics] = useState(null);

  useEffect(() => {
    //if (clinics == null) {
    fetchData();
    //}
  }, []);

  async function fetchData() {
    try {
      const res = await getDoctorClinics(props.uid);
      setLoading(false);
      if (res.status == 1) {
        setClinics(res.data.list);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  }

  // function openModal() {
  //     setIsOpen(_openModel);
  // }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    subtitle.style.color = "#f00";
  }

  function closeModal() {
    //setIsOpen(false);
    props.setOpenModel(false);
  }

  return (
    <>
      <Modal
        isOpen={_openModel}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        ariaHideApp={false}
      >
        <button onClick={closeModal}>close</button>
        <div>
          <h4 ref={(_subtitle) => (subtitle = _subtitle)}>Clinics</h4>
          {clinics?.map((_row) => (
            <div>
              <hr></hr>
              <p>{capitalizeFirstChar(_row.clinic.name)}</p>
              <span>{_row.clinic.addressLine1 || ""}</span>
            </div>
          ))}
        </div>
      </Modal>
    </>
  );
};

export default ViewClinics;
