import React, {useEffect, useState} from 'react';
import {axiosInstance, notificationBadgeType} from "../../common/constants";
import {useGlobal} from "../../store/global-context";

const SidenavBadge = (props) => {

    const [count, setCount] = useState(0)

    const {badgeType} = props
    const {refreshInvites, setRefreshInvites} = useGlobal()

    useEffect(() => {

        if (refreshInvites) {
            getBadgeCount()
        }

    }, [refreshInvites])

    async function getBadgeCount() {
        let count = 0
        switch (badgeType) {
            case notificationBadgeType.PATIENT_REQS:
                const patientRes = await axiosInstance.get(`/patient/getDoctorRequestsCount`)
                count = patientRes.data
                break
            case notificationBadgeType.DOCTOR_REQS:
                const doctorRes = await axiosInstance.get(`/doctor/getPatientRequestsCount`)
                count = doctorRes.data
                break
        }

        setCount(count)
        setRefreshInvites(false)
    }

    return (
        <>
            {count > 0 &&
                <span
                    className="inline-flex items-center justify-center px-2 py-1 mr-2 text-xs font-bold leading-none text-red-100 bg-red-600 rounded-full">
                                            {count}
                </span>
            }
        </>
    );
};

export default SidenavBadge;