import React, { useState } from "react";
import Table from "../table/Table";
import TableHeader from "../table/TableHeader";
import TableCell from "../table/TableCell";
import TableBody from "../table/TableBody";
import TableRow from "../table/TableRow";
import DefaultAvatar from "../DefaultAvatar";
import Button from "../Button";
import TableFooter from "../table/TableFooter";
import TableContainer from "../table/TableContainer";
import { userType } from "../../common/constants";
import { SearchIcon } from "@heroicons/react/solid";
import PatientViewDoctorPopup from "../../pages/patient/PatientViewDoctorPopup";
import RelationshipStatusBadge from "../RelationshipStatusBadge";
import { useGlobal } from "../../store/global-context";
import SmallLoadingSpinner from "../SmallLoadingSpinner";
import { acceptDoctor, rejectDoctor } from "../../common/CommonApis";

const PatientDoctorTable = (props) => {
  const { dataTable, onRefresh, acceptReject } = props;
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [doctor, setDoctor] = useState();
  const { setRefreshInvites } = useGlobal();
  const [accepting, setAccepting] = useState(false);
  const [rejecting, setRejecting] = useState(false);

  function openModal(data) {
    setDoctor(data);
    setModalIsOpen(true);
  }

  function closeModalAndRefresh() {
    setModalIsOpen(false);
    setRefreshInvites(true);
    onRefresh();
  }

  async function onAccept(uid) {
    setAccepting(true);
    await acceptDoctor(uid);
    setAccepting(false);
    setRefreshInvites(true);
    onRefresh();
  }

  async function onReject(uid) {
    setRejecting(true);
    await rejectDoctor(uid);
    setRejecting(false);
    setRefreshInvites(true);
    onRefresh();
  }

  return (
    <>
      <TableContainer className="mb-8 max-w-xl">
        <Table>
          <TableHeader>
            <tr>
              <TableCell>Select</TableCell>
              <TableCell>Image</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Status</TableCell>
              {acceptReject && <TableCell>Action</TableCell>}
            </tr>
          </TableHeader>
          <TableBody>
            {dataTable.map((value, i) => (
              <TableRow key={i}>
                <TableCell>
                  <Button onClick={() => openModal(value)} icon={SearchIcon} />
                </TableCell>
                <TableCell>
                  {value.user.avatarImageUrl ? (
                    <img
                      src={value.user.avatarImageUrl}
                      className="rounded-full h-12 w-12"
                      alt="Avatar"
                    />
                  ) : (
                    <DefaultAvatar />
                  )}
                </TableCell>
                <TableCell>
                  <div>
                    <p className="font-semibold">{`Dr. ${value.user.firstName} ${value.user.lastName}`}</p>
                    <p className="text-sm text-gray-600 dark:text-gray-400">
                      {value.medicalPractice}
                    </p>
                  </div>
                </TableCell>
                <TableCell>
                  {value.patients[0] && (
                    <RelationshipStatusBadge
                      relationship={value.patients[0]}
                      requesterIsSelf={
                        value.patients[0].requester === userType.PATIENT
                      }
                    />
                  )}
                </TableCell>
                {acceptReject && (
                  <TableCell>
                    <Button
                      className="mr-2"
                      layout="danger"
                      onClick={() => onReject(value.user.uid)}
                      disabled={accepting || rejecting}
                    >
                      {rejecting && (
                        <SmallLoadingSpinner className="h-5 w-5 mr-2 -ml-1" />
                      )}
                      Reject
                    </Button>
                    <Button
                      layout="submit"
                      onClick={() => onAccept(value.user.uid)}
                      disabled={accepting || rejecting}
                    >
                      {accepting && (
                        <SmallLoadingSpinner className="h-5 w-5 mr-2 -ml-1" />
                      )}
                      Accept
                    </Button>
                  </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TableFooter>
          {/*<Pagination*/}
          {/*    totalResults={totalResults}*/}
          {/*    resultsPerPage={resultsPerPage}*/}
          {/*    onChange={onPageChangeTable}*/}
          {/*    label="Table navigation"*/}
          {/*/>*/}
        </TableFooter>
      </TableContainer>
      {doctor && (
        <PatientViewDoctorPopup
          isOpen={modalIsOpen}
          onClose={() => setModalIsOpen(false)}
          onCloseAndRefresh={closeModalAndRefresh}
          data={doctor}
        />
      )}
    </>
  );
};

export default PatientDoctorTable;
