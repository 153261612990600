import React, { useState, useEffect } from "react";
import { useHistory, useParams, Link } from "react-router-dom";
import Table from "../../components/table/Table";
import TableHeader from "../../components/table/TableHeader";
import TableCell from "../../components/table/TableCell";
import TableBody from "../../components/table/TableBody";
import TableRow from "../../components/table/TableRow";
import TableFooter from "../../components/table/TableFooter";
import TableContainer from "../../components/table/TableContainer";
import Pagination from "../../components/Pagination";
import RecordPlaceholder from "../../components/RecordPlaceholder";
import LoadingSpinner from "../../components/LoadingSpinner";
import { SearchIcon } from "@heroicons/react/solid";
import {
  serviceDoctorGetMemoAppointmentList,
  servicePostSendMemo,
} from "../../services/apiService";
import { useAuth } from "../../useAuth";
import { formatDate } from "../../components/FormatDate";
import DoctorGenerateMemoModal from "../../components/doctor/DoctorGenerateMemoModal";
import { toast } from "react-toastify";
import ToastConfirmation from "../../components/toastrConfirmation";

const DoctorMemoList = () => {
  const [loading, setLoading] = useState(true);
  const [appointmentList, setAppointmentList] = useState([]);
  const [page, setPage] = useState(0);
  const [totalResults, setTotalResults] = useState(0);
  const [search, setSearch] = useState("");
  const { userData } = useAuth();
  const [showModal, setShowModal] = useState(false);
  const [patientId, setPatientId] = useState();
  const [appointmentId, setAppointmentId] = useState();
  const [patientName, setPatientName] = useState("");

  const resultsPerPage = 10;

  async function getAppointmentList() {
    try {
      setLoading(true);
      let response = await serviceDoctorGetMemoAppointmentList(
        userData.uid,
        search,
        page
      );
      console.log("response123", response.data);
      if (response.status == 1) {
        setLoading(false);
        setTotalResults(response.totalCount);
        setAppointmentList(response.data);
      }
    } catch (e) {
      setLoading(false);
    }
  }

  // Function to handle opening the modal and setting the selected doctor
  const openModal = (_aid, _pid, _pname) => {
    setPatientId(_pid);
    setAppointmentId(_aid);
    setPatientName(_pname);
    setShowModal(!showModal);
  };

  // Function to handle closing the modal
  const closeModal = () => {
    setShowModal(false);
  };

  function onPageChangeTable(p) {
    setPage(p - 1);
  }

  useEffect(() => {
    getAppointmentList();
  }, [page, search]);

  async function searchClinc(_search) {
    setSearch(_search);
    await getAppointmentList();
  }

  // async function sendMemo(_appointmentID) {
  //   try {
  //     setLoading(true);
  //     let requestData = {
  //       appointmentID: _appointmentID,
  //     };
  //     let response = await servicePostSendMemo(requestData);
  //     if (response.status) {
  //       setLoading(false);
  //       toast.success(response.message);
  //     } else {
  //       setLoading(false);
  //       toast.error("Error while sending memo,please try again");
  //     }
  //   } catch (e) {
  //     setLoading(false);
  //     return toast.error("Error while sending memo,please try again");
  //   }
  // }

  const handleConfirmation = (appointmentID) => {
    toast(
      <ToastConfirmation
        prop1={appointmentID}
        prop2={handleConfirm}
        prop3={handleCancel}
      />,
      {
        position: toast.POSITION.TOP_CENTER,
        closeButton: false,
      }
    );
  };

  const handleConfirm = async (event) => {
    toast.dismiss();
    const appointmentID = event.target.dataset.userid;
    // Perform the action
    setLoading(true);
    await handleSendMemo(appointmentID);
    setLoading(false);
  };

  const handleCancel = () => {
    toast.dismiss();
  };

  async function handleSendMemo(_appointmentID) {
    try {
      setLoading(true);
      let requestData = {
        appointmentID: _appointmentID,
      };
      let response = await servicePostSendMemo(requestData);
      if (response.status) {
        setLoading(false);
        toast.success(response.message);
      } else {
        setLoading(false);
        toast.error("Error while sending memo,please try again");
      }
    } catch (e) {
      setLoading(false);
      return toast.error("Error while sending memo,please try again");
    }
  }

  return (
    <>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <div className="container-fluied">
          <div className="row">
            <div class="col-6 ps-0">
              <input
                type="text"
                class="form-control cust__form"
                id="search"
                placeholder="Search"
                value={search}
                onChange={(event) => setSearch(event.target.value)}
              />
            </div>
            <div class="col-6">
              <button
                type="button"
                class="btn d-flex"
                style={{ background: "#4F46E5", color: "white" }}
                onClick={() => searchClinc(search)}
              >
                <SearchIcon />
                <p className="m-0">Search</p>
              </button>
            </div>
          </div>
          <TableContainer className="mb-8 max-w-7xl">
            <Table>
              <TableHeader>
                <tr className="head__th__appointment">
                  <TableCell>Appointment ID</TableCell>
                  <TableCell>Patient Name</TableCell>
                  <TableCell>Clinic Name</TableCell>
                  <TableCell>Vist Date</TableCell>
                  <TableCell>Actions</TableCell>
                </tr>
              </TableHeader>
              <TableBody className="table__body__app">
                {appointmentList && appointmentList.length > 0 ? (
                  appointmentList.map((appointment) => (
                    <TableRow key={appointment.id}>
                      <TableCell>{appointment?.id || "N/A"}</TableCell>
                      <TableCell>
                        {appointment?.firstName + " " + appointment?.lastName ||
                          "N/A"}
                      </TableCell>
                      <TableCell>{appointment?.clinic.name || "N/A"}</TableCell>

                      <TableCell>
                        <b>{formatDate(appointment?.date)}</b>
                      </TableCell>
                      <TableCell>
                        <button
                          onClick={() =>
                            openModal(
                              appointment.id,
                              appointment.patientID,
                              appointment?.firstName +
                                "" +
                                appointment?.lastName
                            )
                          }
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            class="w-6 h-6"
                          >
                            <title>Generate Memo</title>
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M12 7.5h1.5m-1.5 3h1.5m-7.5 3h7.5m-7.5 3h7.5m3-9h3.375c.621 0 1.125.504 1.125 1.125V18a2.25 2.25 0 01-2.25 2.25M16.5 7.5V18a2.25 2.25 0 002.25 2.25M16.5 7.5V4.875c0-.621-.504-1.125-1.125-1.125H4.125C3.504 3.75 3 4.254 3 4.875V18a2.25 2.25 0 002.25 2.25h13.5M6 7.5h3v3H6v-3z"
                            />
                          </svg>
                        </button>
                        &nbsp;&nbsp;
                        {appointment?.canSendMemo == "1" ? (
                          <button
                            onClick={() => handleConfirmation(appointment.id)}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke-width="1.5"
                              stroke="currentColor"
                              class="w-6 h-6"
                            >
                              <title>Send Memo</title>
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="M6 12L3.269 3.126A59.768 59.768 0 0121.485 12 59.77 59.77 0 013.27 20.876L5.999 12zm0 0h7.5"
                              />
                            </svg>
                          </button>
                        ) : (
                          ""
                        )}
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <RecordPlaceholder table="false" />
                )}
              </TableBody>
            </Table>
            {totalResults > 0 && (
              <TableFooter>
                <Pagination
                  totalResults={totalResults}
                  resultsPerPage={resultsPerPage}
                  onChange={onPageChangeTable}
                  label="Table navigation app__table__details"
                />
              </TableFooter>
            )}
          </TableContainer>
        </div>
      )}
      {showModal ? (
        <>
          <DoctorGenerateMemoModal
            patientId={patientId}
            appointmentId={appointmentId}
            patientName={patientName}
            closeModal={closeModal}
            modalIsOpen={showModal}
          />
        </>
      ) : (
        ""
      )}
    </>
  );
};

export default DoctorMemoList;
