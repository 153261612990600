import React, {useRef, useState} from 'react';
import classnames from "classnames";
import Button from "../Button";
import {useForm} from "react-hook-form";
import {axiosInstance} from "../../common/constants";
import DoctorViewPatientPopup from "../../pages/doctor/DoctorViewPatientPopup";
import SmallLoadingSpinner from "../SmallLoadingSpinner";
import ConfirmDialog from "../ConfirmDialog";
import {PlusIcon} from "@heroicons/react/solid";
import {useHistory} from "react-router-dom";

const DoctorSearchPatients = (props) => {

    const {register, handleSubmit, formState: {errors}} = useForm();
    const [error, setError] = useState(false)
    const [loading, setLoading] = useState(false)
    const [searchResult, setSearchResult] = useState()
    const [modalIsOpen, setModalIsOpen] = useState(false)
    const [newPatientEmail, setNewPatientEmail] = useState("")
    const [createDialogIsOpen, setCreateDialogIsOpen] = useState(false)
    const formRef = useRef(null)
    const {onRefresh} = props
    const history = useHistory()


    async function onSubmit(data) {
        setLoading(true)
        setError(false)

        const res = await axiosInstance.post("/doctor/searchPatients", {
            query: data.queryStr
        }).catch(function (error) {

            if (error.response.status === 210            ) {
                setNewPatientEmail(data.queryStr)
                setCreateDialogIsOpen(true)
            }
            else {
                setError(error.response.data)
            }
        })

        if (res) {

            if (res.status === 210) {
                setNewPatientEmail(data.queryStr)
                setCreateDialogIsOpen(true)
            }
            else {
                setSearchResult(res.data)
                setModalIsOpen(true)
            }
        }
        setLoading(false)
    }

    function closeModalAndRefresh() {
        setModalIsOpen(false)
        onRefresh()
    }

    return (
        <div className="flex-col">
            <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col sm:flex-row" ref={formRef}>
                <input
                    type="text"
                    name="query-string"
                    id="query-string"
                    placeholder="Patient's Email"
                    // className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    className={classnames('focus:ring-indigo-500 focus:border-indigo-500 shadow-sm sm:text-sm border-gray-300 rounded-md',
                        {
                            'border-red-500': error || errors.queryStr
                        }
                    )}
                    {...register("queryStr", {
                        required: "Enter a search term",
                        pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                            message: "Enter a valid e-mail address"
                        }
                    })}
                />
                <Button type="submit" layout="submit" iconRight={PlusIcon} className="mt-3 sm:mt-0 sm:ml-3" disabled={loading}>
                    {loading && <SmallLoadingSpinner className="h-5 w-5 mr-2 -ml-1"/>}Add Patient</Button>
            </form>
            <span className="block text-sm font-medium text-red-700 mt-2">{error}</span>
            <span className="block text-sm font-medium text-red-700 mt-2">{errors.queryStr?.message}</span>
            {/*<Button onClick={() => setModalIsOpen(true)}>Test</Button>*/}
            {searchResult &&
                <DoctorViewPatientPopup isOpen={modalIsOpen} onClose={() => setModalIsOpen(false)} data={searchResult} onCloseAndRefresh={closeModalAndRefresh}/>}

            <ConfirmDialog isOpen={createDialogIsOpen} onClose={() => setCreateDialogIsOpen(false)} message={`A patient with that email was not found.\n\nDo you want to create a new patient with this email: ${newPatientEmail}`}
                           title="Patient not found" onAccept={()=> history.push(`/doctor/createPatient?email=${newPatientEmail}`)} acceptLayout="submit" acceptButton="Create"/>

        </div>
    );
};

export default DoctorSearchPatients;