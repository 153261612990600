import React, { useRef, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import LoadingDots from "../../../components/LoadingDots";

import { useFormik } from "formik";
import * as Yup from "yup";
import PhoneInput from "react-phone-number-input";
// Sevices
import { regiter } from "../../../services/admin/servicePatient";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const Create = () => {
  const { uid } = useParams();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [selectedDate, setSelectedDate] = useState("");
  const [contactNumber, setContactNumber] = useState("");
  //------------------------- FUCNCTIONS ------------------
  const goBack = () => {
    history.goBack();
  };

  const validationSchema = Yup.object({
    salutation: Yup.string().required("Salutation is required"),
    firstName: Yup.string()
      .required("First name is required")
      .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field "),
    middleName: Yup.string().matches(
      /^[aA-zZ\s]+$/,
      "Only alphabets are allowed for this field "
    ),
    lastName: Yup.string()
      .required("Last name is required")
      .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field "),
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    contactNumber: Yup.string()
      .required("Contact number is required")
      .min(7, "Contact number must be 7 charactor long")
      .max(14, "Contact number should not exceed 14 charactor")
      .test("is-number", "Contact number must be a valid number", (value) => {
        return !isNaN(Number(value));
      }),
    age: Yup.string()
      .required("Age is required")
      .min(1, "Age must be 1 charactor long")
      .max(3, "Age should not exceed 3 charactor")
      .test(
        "is-number",
        "Age must be a valid number",
        (value) =>
          !isNaN(parseInt(Number(value))) && !/[eE+-]/.test(value.toString())
      ),
    gender: Yup.string().required("Gender is required"),
    dob: Yup.string().required("Dob is required"),
  });

  const formik = useFormik({
    initialValues: {
      salutation: "Mr",
      firstName: "",
      middleName: "",
      lastName: "",
      email: "",
      contactNumber: "",
      age: "",
      gender: "",
      dob: "",
    },
    validationSchema,
    onSubmit: (values) => {
      webHandleSubmit(values);
    },
  });

  async function webHandleSubmit(_values) {
    try {
      setError("");
      setLoading(true);
      let data = {
        salutation: _values.salutation,
        firstName: _values.firstName,
        middleName: _values.middleName,
        lastName: _values.lastName,
        email: _values.email,
        contactNumber: _values.contactNumber,
        age: parseInt(_values.age),
        gender: _values.gender,
        dob: String(_values.dob),
      };

      const registerData = await regiter(data, "patient");
      setLoading(false);
      if (registerData.status == 0) {
        toast.error(registerData.message);
      }
      if (registerData.status == 1) {
        toast.success(registerData.message);
        history.push("/admin/doctor/list");
      }
    } catch (e) {
      toast.error(e.message);
      setLoading(false);
    }
  }

  const handleDateChange = async (value) => {
    let date = moment(value);
    let formattedDate = date.format("MM-DD-YYYY");

    setSelectedDate(value);
    formik.setFieldValue("dob", formattedDate);
  };

  // ----------------------------------------- RENDER HTML ----------------------------
  return (
    <div className="container-fluid">
      <div className="form__box__container">
        <form onSubmit={formik.handleSubmit} action="#" method="POST">
          <div className="row">
            <h3 className="head__weekly__report">Register Patient</h3>
            <div className="col-md-2">
              <div className="mb-2">
                <label for="" className="form-label">
                  Salutation
                </label>
                <select
                  className="form-control cust__form"
                  {...formik.getFieldProps("salutation")}
                >
                  <option value="Mr">Mr</option>
                  <option value="Mrs">Mrs</option>
                  <option value="Miss">Miss</option>
                </select>

                {formik.touched.salutation && formik.errors.salutation && (
                  <div className="form_errors">{formik.errors.salutation}</div>
                )}
              </div>
            </div>
            <div className="col-md-3">
              <div className="mb-2">
                <label for="" className="form-label">
                  First Name
                </label>
                <input
                  type="text"
                  placeholder="Enter first name"
                  className="form-control cust__form"
                  {...formik.getFieldProps("firstName")}
                />
                {formik.touched.firstName && formik.errors.firstName && (
                  <div className="form_errors">{formik.errors.firstName}</div>
                )}
              </div>
            </div>
            <div className="col-md-3">
              <div className="mb-2">
                <label for="" className="form-label">
                  Middle Name
                </label>
                <input
                  type="text"
                  placeholder="Enter middle name"
                  className="form-control cust__form"
                  {...formik.getFieldProps("middleName")}
                />
                {formik.touched.middleName && formik.errors.middleName && (
                  <div className="form_errors">{formik.errors.middleName}</div>
                )}
              </div>
            </div>
            <div className="col-md-4">
              <div className="mb-2">
                <label for="" className="form-label">
                  Last Name
                </label>
                <input
                  type="text"
                  placeholder="Enter last name"
                  className="form-control cust__form"
                  {...formik.getFieldProps("lastName")}
                />
                {formik.touched.lastName && formik.errors.lastName && (
                  <div className="form_errors">{formik.errors.lastName}</div>
                )}
              </div>
            </div>
            <div className="col-md-4">
              <div className="mb-2">
                <label for="" className="form-label">
                  Email ID
                </label>
                <input
                  type="email"
                  placeholder="Enter email"
                  className="form-control cust__form"
                  {...formik.getFieldProps("email")}
                />
                {formik.touched.email && formik.errors.email && (
                  <div className="form_errors">{formik.errors.email}</div>
                )}
              </div>
            </div>
            <div className="col-md-4">
              <div className="mb-2">
                <label for="" className="form-label">
                  Age
                </label>
                <input
                  type="text"
                  placeholder="Enter age"
                  className="form-control cust__form"
                  {...formik.getFieldProps("age")}
                />
                {formik.touched.age && formik.errors.age && (
                  <div className="form_errors">{formik.errors.age}</div>
                )}
              </div>
            </div>
            <div className="col-md-4">
              <div className="mb-2">
                <label for="" className="form-label">
                  Contact No.
                </label>
                <PhoneInput
                  className={"input-phone-number admin-contact-number"}
                  placeholder="Enter phone number"
                  name="contactNumber"
                  value={contactNumber}
                  onChange={(e) => formik.setFieldValue("contactNumber", e)}
                />
                {formik.touched.contactNumber &&
                  formik.errors.contactNumber && (
                    <div className="form_errors">
                      {formik.errors.contactNumber}
                    </div>
                  )}
              </div>
            </div>
            <div className="col-md-4">
              <div class="mb-2">
                <label for="" class="form-label">
                  Dob
                </label>
                <br></br>
                <DatePicker
                  className="form-control cust__form"
                  selected={selectedDate}
                  onChange={handleDateChange}
                  placeholderText="Select a date"
                  dateFormat="MM-dd-yyyy"
                  showMonthDropdown="true"
                  showYearDropdown="true"
                  maxDate={new Date()}
                />
                <input
                  type="hidden"
                  {...formik.getFieldProps("dob")}
                  name="dob"
                ></input>
                {formik.touched.dob && formik.errors.dob && (
                  <div className="form_errors">{formik.errors.dob}</div>
                )}
              </div>
            </div>
            <div className="col-md-4">
              <div className="mb-2">
                <label for="" className="form-label">
                  Gender
                </label>
                <div className="col-auto">
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      {...formik.getFieldProps("gender")}
                      id="maleCheck"
                      value={"male"}
                      onChange={formik.handleChange}
                      checked={formik.values.gender === "male"}
                    />

                    <label className="form-check-label" for="maleCheck">
                      Male
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      {...formik.getFieldProps("gender")}
                      id="femalecheck"
                      value={"female"}
                      onChange={formik.handleChange}
                      checked={formik.values.gender === "female"}
                    />
                    <label className="form-check-label" for="femalecheck">
                      Female
                    </label>
                  </div>
                  {formik.touched.gender && formik.errors.gender && (
                    <div className="form_errors">{formik.errors.gender}</div>
                  )}
                </div>
              </div>
            </div>
            {!loading ? (
              <div className="col-md-12 d-flex justify-content-between mt-3">
                <button type="submit" className="btn save-btn">
                  Register
                </button>
                <button className="btn cancel-btn" onClick={goBack}>
                  Back
                </button>
              </div>
            ) : (
              <LoadingDots />
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

export default Create;
