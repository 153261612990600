import React, { useEffect, useState, useRef } from "react";
import { useAuth } from "../../useAuth";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  servicePostMemoReferral,
  serviceGetDoctorList,
  serviceGetMemoReferrel,
} from "../../services/apiService";
import LoadingSpinner from "../../components/LoadingSpinner";
import { ReactSearchAutocomplete } from "react-search-autocomplete";

const MemoReferrel = ({ patientId, appointmentId }) => {
  const [submitting, setSubmitting] = useState(false);
  const [loading, setLoading] = useState(false);
  const [medicalHistory, setMedicalHistory] = useState({});
  const [error, setError] = useState(false);
  const [doctor, setDoctor] = useState("");
  const [item, setItem] = useState({});
  const [doctorName, setDoctorName] = useState("");
  const [pageTable, setPageTable] = useState(0);
  const [isDropdownOpen, setDropdownOpen] = useState(false);

  const { userData } = useAuth();

  const validationSchema = Yup.object({
    refferedDoctorID: Yup.string().required("Doctor name is required"),
  });

  const formik = useFormik({
    initialValues: {
      patientID: patientId,
      doctorID: userData.uid,
      appointmentID: appointmentId,
      refferedDoctorID: "",
    },
    validationSchema,
    onSubmit: (values) => {
      webHandleSubmit(values);
    },
  });

  const handleOnSelect = (item) => {
    setPageTable(0);
    console.log("item selected", item);
    formik.setFieldValue("refferedDoctorID", item.id);
    setDoctorName(item?.name);
  };

  async function fetchDoctors(keywords = "") {
    try {
      const res = await serviceGetDoctorList(pageTable, keywords);

      setLoading(false);
      if (res.status == 0) {
        toast.error(res.message);
      }

      if (res.status == 1) {
        let _items = [];
        res.data?.forEach((element, index) => {
          if (element) {
            _items.push({
              id: element.uid,
              name:
                "Dr." +
                element?.user["firstName"] +
                "" +
                element?.user["lastName"],
            });
          }
        });
        setItem(_items);
      }
    } catch (error) {
      toast.error(error.message);
      setLoading(false);
    }
  }

  const onClear = () => {
    setDoctor("");
    fetchDoctors();
  };

  const formatResult = (item) => {
    return (
      <>
        {/* <span style={{ display: 'block', textAlign: 'left' }}>id: {item.id}</span> */}
        <span style={{ display: "block", textAlign: "left" }}>{item.name}</span>
      </>
    );
  };

  const handleOnSearch = (_value) => {
    fetchDoctors(_value);
  };

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await serviceGetMemoReferrel(appointmentId);

      if (response.status == 0) {
        setLoading(false);
        toast.error(response.message);
      }
      if (response.status == 1) {
        setLoading(false);
        //toast.success(response.message);
        const referrelData = response.data;
        const newValues = {
          patientID: patientId,
          doctorID: userData.uid,
          appointmentID: appointmentId,
          refferedDoctorID: referrelData.refferedDoctorID,
        };
        setDoctorName(
          "Dr." +
            referrelData?.refferedDoctor?.user["firstName"] +
            " " +
            referrelData?.refferedDoctor?.user["lastName"]
        );
        formik.setValues(newValues);
      }
    } catch (error) {
      setLoading(false);
      toast.error(error);
    }
  };

  async function webHandleSubmit(_values) {
    try {
      console.log("_values", _values);
      setError("");
      setLoading(true);
      let data = _values;
      const response = await servicePostMemoReferral(data);
      if (response.status == 0) {
        toast.error(response.message);
      }
      if (response.status == 1) {
        toast.success(response.message);
        // fetchData()
        setDropdownOpen(false);
      }
      setLoading(false);
    } catch (e) {
      setError(e.message);
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchDoctors();
    fetchData();
  }, []);

  return (
    <>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <div>
          <div className="gap-3 setting__form">
            <div className="py-3 border-gray-400 col-span-3 grid grid-cols-2 gap-y-3 items-center">
              <form
                onSubmit={formik.handleSubmit}
                action="#"
                method="POST"
                className="col-span-2"
              >
                <div className="form__box__container">
                  <div className="grid grid-cols-4 gap-3">
                    <div className="col-span-2 sm:col-span-2">
                      <label className="form-label">
                        <b>Doctor</b>
                      </label>
                      <ReactSearchAutocomplete
                        items={item}
                        onSearch={handleOnSearch}
                        //onHover={handleOnHover}
                        onSelect={handleOnSelect}
                        // onFocus={handleOnFocus}
                        onClear={onClear}
                        formatResult={formatResult}
                        placeholder="Search Doctor"
                        name="refferedDoctorID"
                        inputSearchString={doctorName}
                        isOpen={false}
                        showNoResults={false}
                        {...formik.getFieldProps("refferedDoctorID")}
                      />
                      {formik.touched.refferedDoctorID &&
                        formik.errors.refferedDoctorID && (
                          <div className="form_errors">
                            {formik.errors.refferedDoctorID}
                          </div>
                        )}
                    </div>
                    <div className="col-span-1 sm:col-span-1">
                      <div style={{ marginLeft: "20%", marginTop: "10%" }}>
                        <button type="submit" className="setting__doctor__save">
                          Save
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default MemoReferrel;
