import React, { useEffect, useState } from "react";
import { axiosInstance, formatDateTime } from "../../common/constants";
import TableContainer from "../table/TableContainer";
import Table from "../table/Table";
import TableHeader from "../table/TableHeader";
import TableCell from "../table/TableCell";
import TableBody from "../table/TableBody";
import TableRow from "../table/TableRow";
import Button from "../Button";
import TableFooter from "../table/TableFooter";
import Pagination from "../Pagination";
import LoadingDots from "../LoadingDots";
import PatientBloodSugarPopup from "../../pages/patient/PatientBloodSugarPopup";
import ConfirmDialog from "../ConfirmDialog";
import { toast } from "react-toastify";
import SubTitle from "../SubTitle";
import { PencilAltIcon, TrashIcon } from "@heroicons/react/solid";

const PatientBloodSugarTable = (props) => {
  const [loading, setLoading] = useState(true);
  const [pageLoading, setPageLoading] = useState(true);
  const [dataTable, setDataTable] = useState(true);
  const [pageTable, setPageTable] = useState(1);
  const [totalResults, setTotalResults] = useState(0);
  const [editModalIsOpen, setEditModalIsOpen] = useState(false);
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
  const [toDeleteIndex, setToDeleteIndex] = useState(null);
  const [currentlyEditing, setCurrentlyEditing] = useState(null);

  const resultsPerPage = 10;

  function onPageChangeTable(p) {
    setPageTable(p);
  }

  useEffect(() => {
    fetchData();
  }, [pageTable, props.pendingRefresh]);

  async function fetchData() {
    setPageLoading(true);
    // const res = await axiosInstance.get(`/patient/getSelfBloodSugar?pageNum=${pageTable}&perPage=${resultsPerPage}`)

    let res;
    if (props.patientUid == null) {
      res = await axiosInstance.get(
        `/patient/getSelfBloodSugar?pageNum=${pageTable}&perPage=${resultsPerPage}`
      );
    } else {
      res = await axiosInstance.get(
        `/doctor/getPatientTableBloodSugar?patientUid=${props.patientUid}&pageNum=${pageTable}&perPage=${resultsPerPage}`
      );
    }

    setDataTable(res.data.results);
    setTotalResults(res.data.totalCount);
    setLoading(false);
    setPageLoading(false);
  }

  function openEditModal(data) {
    setCurrentlyEditing(data);
    setEditModalIsOpen(true);
  }

  async function onEditModalClose(toRefresh) {
    setEditModalIsOpen(false);
    if (toRefresh) {
      await fetchData();
    }
  }

  async function deleteBloodSugarEntry() {
    setDeleteModalIsOpen(false);
    const res = await axiosInstance
      .post(`/patient/deleteBloodSugar`, {
        id: toDeleteIndex,
      })
      .catch(function (error) {});

    toast.success("Blood sugar entry deleted.");
    await fetchData();
  }

  return (
    <>
      <SubTitle>List of Blood Sugar Entries</SubTitle>
      {!loading ? (
        <>
          <TableContainer className="mb-8">
            <Table>
              <TableHeader>
                <tr>
                  <TableCell>ID</TableCell>
                  <TableCell>Date</TableCell>
                  <TableCell>Blood Sugar</TableCell>
                  <TableCell>Meal Type</TableCell>
                  {!props.patientUid && <TableCell>Actions</TableCell>}
                </tr>
              </TableHeader>
              {!pageLoading ? (
                <TableBody>
                  {dataTable.map((value, i) => (
                    <TableRow key={i}>
                      <TableCell>
                        <p>{value.id}</p>
                      </TableCell>
                      <TableCell>
                        <p>{formatDateTime(value.entryTime)}</p>
                      </TableCell>
                      <TableCell>
                        <p>{value.bloodSugarValue}</p>
                      </TableCell>
                      <TableCell>
                        <p>{value.mealType}</p>
                      </TableCell>
                      {!props.patientUid && (
                        <TableCell>
                          <Button
                            size="extra_small"
                            icon={PencilAltIcon}
                            onClick={() => openEditModal(value)}
                          />
                          <Button
                            size="extra_small"
                            icon={TrashIcon}
                            className="ml-2"
                            layout="danger"
                            onClick={() => {
                              setDeleteModalIsOpen(true);
                              setToDeleteIndex(value.id);
                            }}
                          />
                        </TableCell>
                      )}
                    </TableRow>
                  ))}
                </TableBody>
              ) : (
                <tbody>
                  <tr>
                    <td colSpan="4">
                      <LoadingDots />
                    </td>
                  </tr>
                </tbody>
              )}
            </Table>
            {totalResults > 0 && (
              <TableFooter>
                <Pagination
                  totalResults={totalResults}
                  resultsPerPage={resultsPerPage}
                  onChange={onPageChangeTable}
                  label="Table navigation"
                />
              </TableFooter>
            )}
          </TableContainer>
          {currentlyEditing && (
            <PatientBloodSugarPopup
              isOpen={editModalIsOpen}
              onClose={(toRefresh) => onEditModalClose(toRefresh)}
              entryData={currentlyEditing}
            />
          )}
          <ConfirmDialog
            isOpen={deleteModalIsOpen}
            onClose={() => setDeleteModalIsOpen(false)}
            message="Do you want to delete this record?"
            title="Delete Confirmation"
            onAccept={deleteBloodSugarEntry}
            acceptLayout="danger"
          />
        </>
      ) : (
        <LoadingDots />
      )}
    </>
  );
};

export default PatientBloodSugarTable;
