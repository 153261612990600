import React, { useRef, useState, useEffect } from "react";
import { LockClosedIcon } from "@heroicons/react/solid";
import { useAuth } from "../../useAuth.js";
import { Link, useHistory, useParams, useLocation } from "react-router-dom";
import logo from "../../healthlink_logo.svg";
import { userType } from "../../common/constants";
import SmallLoadingSpinner from "../../components/SmallLoadingSpinner";
import {
  servicePostDoctorRegistration,
  serviceGetClinicList,
  serviceGetMasterSpecialityList,
} from "../../services/apiService";
import { ToastContainer, toast } from "react-toastify";
import { useFormik } from "formik";
import * as Yup from "yup";
import LoadingSpinner from "../../components/LoadingSpinner";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { ReactSearchAutocomplete } from "react-search-autocomplete";
import { servicePostFileUpload } from "../../services/clinic/clinicApiService.js";
import FileDropzone from "../../components/FileDropzone.js";
import { FormProvider, useForm } from "react-hook-form";

const DoctorRegister = () => {
  const emailRef = useRef();
  const passwordRef = useRef();
  const passwordConfirmRef = useRef();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const methods = useForm({ mode: "onBlur" });

  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [contactNumber, setContactNumber] = useState("");
  const [salutation, setSalutation] = useState("Mr");
  const [item, setItem] = useState({});
  const [itemSpeciality, setItemSpeciality] = useState({});
  const [pageTable, setPageTable] = useState(0);
  const [clinic, setClinic] = useState("");
  const [specialty, setSpecialty] = useState("");

  const email = queryParams.get("email");
  const clinicID = queryParams.get("clinicID");
  const clinicName = queryParams.get("clinicName");
  const id = queryParams.get("id");
  const [doctorFile, setDoctorFile] = useState({});

  function onAlertCloseClick() {
    setError("");
  }

  const validationSchema = Yup.object({
    firstName: Yup.string()
      .matches(
        /^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*)$/gi,
        "Name can only contain Latin letters."
      )
      .required("First name is required"),
    middleName: Yup.string().matches(
      /^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*)$/gi,
      "Middle name can only contain Latin letters."
    ),
    lastName: Yup.string()
      .matches(
        /^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*)$/gi,
        "Last name can only contain Latin letters."
      )
      .required("Last name is required"),
    email: Yup.string()
      .lowercase()
      .email("Invalid email address")
      .required("Email is required"),
    contactNumber: Yup.string()
      .min(5, "Mobile number must be at least 5 digits")
      .max(20, "Mobile number must not exceed 20 digits")
      .required("Mobile number is required"),
    password: Yup.string()
      .required("Password is required.")
      .matches(
        /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*\W)(?!.* ).{8,16}$/,
        "Password must be 8-16 characters, with at least one uppercase letter, one lowercase letter, one digit, one special character, and no spaces."
      ),
    passwordConfirm: Yup.string()
      .required("Confirm password is required")
      .oneOf(
        [Yup.ref("password"), null],
        "Password and confirm password should match"
      ),
    terms: Yup.boolean().isTrue("Please Accept Terms and Conditions"),
    clinics: Yup.array()
      .min(1, "Clinic is required")
      .required("Clinic is required"),
    specialities: Yup.array()
      .min(1, "Speciality is required")
      .required("Speciality is required"),
    experinceInYears: Yup.number()
      .min(0, "Invalid experience")
      .max(100, "Invalid experience"),
    files: Yup.array()
      .min(1, "Medical licence is required")
      .required("Medical licence is required"),
    licenceExpiryDate: Yup.date().required(
      "Medical licence expiry date is required"
    ),
    licenceNumber: Yup.string().required("Medical licence number is required"),
  });

  const formik = useFormik({
    initialValues: {
      firstName: "",
      middleName: "",
      lastName: "",
      email: email != undefined ? email : "",
      contactNumber: "",
      password: "",
      passwordConfirm: "",
      terms: false,
      clinics:
        clinicID != undefined
          ? [
              {
                clinicID: clinicID,
              },
            ]
          : "",
      specialities: [],
      experinceInYears: "",
      files: [],
      licenceExpiryDate: "",
      licenceNumber: "",
    },
    validationSchema,
    onSubmit: (values) => {
      // Handle form submission
      webHandleSubmit(values);
    },
  });

  async function webHandleSubmit(_values) {
    try {
      if (!_values.terms) {
        return toast.error("Please Accept Terms & Conditions.");
      }
      setError("");
      setLoading(true);
      let data = "";
      //upload file
      let fileData = {
        file: doctorFile,
        destination: "/doctor",
      };
      const fileResponse = await servicePostFileUpload(fileData);
      if (fileResponse.status != 1) {
        return toast.error(fileResponse.message);
      }

      if (email == undefined && clinicName == undefined) {
        data = {
          salutation: salutation,
          firstName: _values.firstName,
          middleName: _values.middleName || "",
          lastName: _values.lastName,
          email: _values.email,
          contactNumber: _values.contactNumber,
          password: _values.password,
          gender: salutation == "Mr" ? "Male" : "Female",
          clinics: clinic,
          specialities: specialty,
          experinceInYears: _values.experinceInYears,
          documents: [
            {
              name: fileResponse.data.filePath.substring(
                fileResponse.data.filePath.lastIndexOf("/") + 1
              ),
              documentUrl: fileResponse.data.filePath,
            },
          ],
          licenceExpiryDate: _values.licenceExpiryDate,
          licenceNumber: _values.licenceNumber,
        };
      } else {
        data = {
          salutation: salutation,
          firstName: _values.firstName,
          middleName: _values.middleName || "",
          lastName: _values.lastName,
          email: email,
          contactNumber: _values.contactNumber,
          password: _values.password,
          gender: salutation == "Mr" ? "Male" : "Female",
          clinics: [
            {
              clinicID: clinicID,
            },
          ],
          specialities: specialty,
          experinceInYears: _values.experinceInYears,
          documents: [
            {
              name: fileResponse.data.filePath.substring(
                fileResponse.data.filePath.lastIndexOf("/") + 1
              ),
              documentUrl: fileResponse.data.filePath,
            },
          ],
          licenceExpiryDate: _values.licenceExpiryDate,
          licenceNumber: _values.licenceNumber,
        };
      }

      if (_values.password != _values.passwordConfirm) {
        return toast.error("Password and Confirm password do not match");
      }

      const registerData = await servicePostDoctorRegistration(data);
      if (registerData.status == 0) {
        toast.error(registerData.message);
        setLoading(false);
      }
      if (registerData.status == 1) {
        history.replace("/");
        history.push("/verify-email/doctor/" + registerData.data.credentialID);
        toast.success(registerData.message);
        setLoading(false);
      }
    } catch (e) {
      setError(e.message);

      setLoading(false);
    }
  }

  async function fetchClinics(keywords = "") {
    try {
      const res = await serviceGetClinicList(pageTable, keywords, true);

      setLoading(false);
      if (res.status == 0) {
        toast.error(res.message);
      }

      if (res.status == 1) {
        let _items = [];
        res.data?.forEach((element, index) => {
          if (element) {
            _items.push({ id: element.uid, name: element?.name });
          }
        });
        setItem(_items);
      }
    } catch (error) {
      toast.error(error.message);
      setLoading(false);
    }
  }

  async function fetchMasterSpeciality(keywords = "") {
    try {
      const res = await serviceGetMasterSpecialityList(keywords);

      setLoading(false);
      if (res.status == 0) {
        toast.error(res.message);
      }

      if (res.status == 1) {
        let _items = [];
        res.data?.forEach((element, index) => {
          if (element) {
            _items.push({ id: element.id, name: element?.name });
          }
        });
        setItemSpeciality(_items);
      }
    } catch (error) {
      toast.error(error.message);
      setLoading(false);
    }
  }

  const handleSalutationChange = (event) => {
    setSalutation(event.target.value);
  };

  const handleOnSelect = (item) => {
    setPageTable(0);
    let id = item.id.trim();
    let clincArr = [
      {
        clinicID: id,
      },
    ];
    setClinic([
      {
        clinicID: id,
      },
    ]);
    formik.setFieldValue("clinics", clincArr);
  };

  const handleOnSpecialitySelect = (item) => {
    setPageTable(0);
    let id = item.id.trim();
    let specialityArr = [
      {
        specialityId: id,
      },
    ];
    setSpecialty([
      {
        specialityId: id,
      },
    ]);
    formik.setFieldValue("specialities", specialityArr);
  };

  const onClear = () => {
    setClinic("");
    fetchClinics();
  };

  const onSpecialityClear = () => {
    setSpecialty("");
    fetchMasterSpeciality();
  };

  const formatResult = (item) => {
    return (
      <>
        {/* <span style={{ display: 'block', textAlign: 'left' }}>id: {item.id}</span> */}
        <span style={{ display: "block", textAlign: "left" }}>{item.name}</span>
      </>
    );
  };

  const handleOnSearch = (_value) => {
    fetchClinics(_value);
  };

  const handleOnSpecialitySearch = (_value) => {
    fetchMasterSpeciality(_value);
  };

  useEffect(() => {
    fetchClinics();
    fetchMasterSpeciality();
  }, [pageTable]);

  const handleFileSelect = async (_selectedFile) => {
    try {
      if (_selectedFile) {
        if (_selectedFile.size <= 5 * 1024 * 1024) {
          formik.setFieldValue("files", [_selectedFile]);
          setDoctorFile(_selectedFile);
        } else {
          setDoctorFile(null);
          return toast.error("File size exceeds 5 MB");
        }
      }
    } catch (e) {
      toast.error(e.message);
    }
  };

  return (
    <>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <div className="min-h-full">
          <div className="container">
            <div className="row mt-4">
              <div className="col-md-12">
                <Link to="/" className="flex items-center justify-center">
                  <img src={logo} className="h-10 w-10 mr-2" alt="logo" />
                  <span className="font-extrabold text-indigo-600 text-3xl">
                    HealthLink <sub className="text-base font-bold">Doctor</sub>
                  </span>
                </Link>
                <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
                  Doctor Registration
                </h2>
                <p className="mt-2 text-center text-sm text-gray-600">
                  Or{" "}
                  <Link
                    to="/doctorLogin"
                    className="font-medium text-indigo-600 hover:text-indigo-500"
                  >
                    login with an existing account
                  </Link>
                </p>
              </div>
            </div>
            <FormProvider {...methods}>
              <form onSubmit={formik.handleSubmit} action="#" method="POST">
                <div className="row justify-content-center mb-3">
                  <div className="col-md-4">
                    <div className="row">
                      <div className="col-3">
                        <label className="form-label" htmlFor="firstName">
                          Salutation
                        </label>
                        <select
                          class="form-control cust__form col-4"
                          name="salutation"
                          value={salutation}
                          onChange={handleSalutationChange}
                        >
                          <option value="Mr">Mr</option>
                          <option value="Mrs">Mrs</option>
                          <option value="Ms">Ms</option>
                        </select>
                      </div>
                      <div className="col-9">
                        <label className="form-label" htmlFor="firstName">
                          First Name
                        </label>
                        <input
                          id="firstName"
                          style={{ textTransform: "capitalize" }}
                          type="text"
                          {...formik.getFieldProps("firstName")}
                          className="form-control cust__form col-8"
                        />
                        {formik.touched.firstName &&
                          formik.errors.firstName && (
                            <div className="form_errors">
                              {formik.errors.firstName}
                            </div>
                          )}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <label className="form-label">Middle Name</label>
                    <input
                      type="text"
                      name="middleName"
                      style={{ textTransform: "capitalize" }}
                      className="form-control cust__form"
                      {...formik.getFieldProps("middleName")}
                    />
                    {formik.touched.middleName && formik.errors.middleName && (
                      <div className="form_errors">
                        {formik.errors.middleName}
                      </div>
                    )}
                  </div>
                </div>
                <div className="row justify-content-center mb-3">
                  <div className="col-md-4">
                    <label className="form-label">Last Name</label>
                    <input
                      type="text"
                      style={{ textTransform: "capitalize" }}
                      {...formik.getFieldProps("lastName")}
                      className="form-control cust__form"
                    />
                    {formik.touched.lastName && formik.errors.lastName && (
                      <div className="form_errors">
                        {formik.errors.lastName}
                      </div>
                    )}
                  </div>
                  {email == undefined ? (
                    <div className="col-md-4">
                      <label className="form-label">Email ID</label>

                      <input
                        type="email"
                        className="form-control cust__form"
                        ref={emailRef}
                        id="emailRef"
                        name="emailRef"
                        pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                        title="Please enter only lowercase letters"
                        {...formik.getFieldProps("email")}
                      />
                      {formik.touched.email && formik.errors.email && (
                        <div className="form_errors">{formik.errors.email}</div>
                      )}
                    </div>
                  ) : (
                    <div className="col-md-4">
                      <label className="form-label">Email ID</label>

                      <input
                        type="email"
                        className="form-control cust__form"
                        name="emailRef"
                        disabled={true}
                        {...formik.getFieldProps("email")}
                      />
                      {formik.touched.email && formik.errors.email && (
                        <div className="form_errors">{formik.errors.email}</div>
                      )}
                    </div>
                  )}
                </div>
                <div className="row justify-content-center mb-3">
                  <div className="col-md-4">
                    <label className="form-label">Mobile</label>
                    <div className="doc-register-mob">
                      <PhoneInput
                        className={"input-phone-number"}
                        placeholder="Enter phone number"
                        value={contactNumber}
                        onChange={(e) =>
                          formik.setFieldValue("contactNumber", e)
                        }
                      />
                    </div>
                    {formik.touched.contactNumber &&
                      formik.errors.contactNumber && (
                        <div className="form_errors">
                          {formik.errors.contactNumber}
                        </div>
                      )}
                  </div>

                  {clinicName == undefined ? (
                    <div
                      className="col-md-4 doc-register-mob"
                      style={{ zIndex: 1 }}
                    >
                      <label className="form-label">Clinic</label>
                      <ReactSearchAutocomplete
                        items={item}
                        onSearch={handleOnSearch}
                        //onHover={handleOnHover}
                        onSelect={handleOnSelect}
                        // onFocus={handleOnFocus}
                        onClear={onClear}
                        autoFocus
                        formatResult={formatResult}
                        placeholder="Select Clinic"
                        {...formik.getFieldProps("clinic")}
                      />
                      {formik.touched.clinics && formik.errors.clinics && (
                        <div className="form_errors">
                          {formik.errors.clinics}
                        </div>
                      )}
                    </div>
                  ) : (
                    <div className="col-md-4">
                      <label className="form-label">Clinic </label>
                      <input
                        className="form-control cust__form"
                        type="text"
                        disabled={true}
                        value={clinicName}
                      ></input>
                    </div>
                  )}
                </div>
                <div className="row justify-content-center mb-3">
                  <div className="col-md-4">
                    <label className="form-label">
                      Total Experience ( in Years )
                    </label>
                    <input
                      type="number"
                      min={0}
                      max={100}
                      step={1}
                      {...formik.getFieldProps("experinceInYears")}
                      className="form-control cust__form"
                    />
                    {formik.touched.experinceInYears &&
                      formik.errors.experinceInYears && (
                        <div className="form_errors">
                          {formik.errors.experinceInYears}
                        </div>
                      )}
                  </div>
                  <div
                    className="col-md-4 doc-register-mob"
                    style={{ zIndex: 0 }}
                  >
                    <label className="form-label">Speciality</label>
                    <ReactSearchAutocomplete
                      items={itemSpeciality}
                      onSearch={handleOnSpecialitySearch}
                      //onHover={handleOnHover}
                      onSelect={handleOnSpecialitySelect}
                      // onFocus={handleOnFocus}
                      onClear={onSpecialityClear}
                      autoFocus
                      formatResult={formatResult}
                      placeholder="Select Speciality"
                      {...formik.getFieldProps("specialities")}
                    />
                    {formik.touched.specialities &&
                      formik.errors.specialities && (
                        <div className="form_errors">
                          {formik.errors.specialities}
                        </div>
                      )}
                  </div>
                </div>
                <div className="row justify-content-center mb-3">
                  <div className="col-md-4"></div>
                </div>
                <div className="row justify-content-center mb-3">
                  <div className="col-md-4">
                    <label className="form-label">Password</label>
                    <input
                      type="password"
                      className="form-control cust__form"
                      ref={passwordRef}
                      id="passwordRef"
                      name="passwordRef"
                      {...formik.getFieldProps("password")}
                    />
                    {formik.touched.password && formik.errors.password && (
                      <div className="form_errors">
                        {formik.errors.password}
                      </div>
                    )}
                  </div>

                  <div className="col-md-4">
                    <label className="form-label">Confirm Password</label>
                    <input
                      type="password"
                      className="form-control cust__form"
                      ref={passwordConfirmRef}
                      id="passwordConfirm"
                      name="passwordConfirm"
                      {...formik.getFieldProps("passwordConfirm")}
                    />
                    {formik.touched.passwordConfirm &&
                      formik.errors.passwordConfirm && (
                        <div className="form_errors">
                          {formik.errors.passwordConfirm}
                        </div>
                      )}
                  </div>
                </div>
                {/* License Info */}
                <div className="row justify-content-center mb-3">
                  <div className="col-md-4">
                    <label className="form-label">Licence Expiry Date</label>
                    <input
                      min={new Date().toISOString().split("T")[0]}
                      type="date"
                      className="form-control cust__form"
                      id="licenceExpiryDateRef"
                      name="licenceExpiryDateRef"
                      {...formik.getFieldProps("licenceExpiryDate")}
                      onBlur={(event) => {
                        const invalidDate = new Date(
                          new Date().getTime() + 30 * 24 * 60 * 60 * 1000
                        );
                        if (new Date(event.target.value) <= invalidDate) {
                          toast.warning(
                            "Your Medical licence is expiring soon. Please renew your licence to avoid suspension."
                          );
                        }
                      }}
                    />
                    {formik.touched.licenceExpiryDate &&
                      formik.errors.licenceExpiryDate && (
                        <div className="form_errors">
                          {formik.errors.licenceExpiryDate}
                        </div>
                      )}
                  </div>

                  <div className="col-md-4">
                    <label className="form-label">Licence Number</label>
                    <input
                      type="text"
                      className="form-control cust__form"
                      id="licenceNumber"
                      name="licenceNumber"
                      {...formik.getFieldProps("licenceNumber")}
                    />
                    {formik.touched.licenceNumber &&
                      formik.errors.licenceNumber && (
                        <div className="form_errors">
                          {formik.errors.licenceNumber}
                        </div>
                      )}
                  </div>
                </div>
                {/* End Of License Info */}
                <div className="row justify-content-center mb-3">
                  <div className="col-md-8">
                    <label className="form-label">
                      Medical licence<span>&nbsp;</span>
                      <input
                        type="hidden"
                        className="form-control"
                        name="files"
                      ></input>
                      {formik.touched.files && formik.errors.files && (
                        <div className="form_errors">{formik.errors.files}</div>
                      )}
                    </label>
                    <FileDropzone
                      accept=".pdf, image/jpeg, image/png, image/jpg"
                      name="idImage"
                      handleFileSelect={handleFileSelect}
                    />
                    <div className="form_errors">
                      Note: Please upload image or PDF files only, and the file
                      size should be below 5 MB{" "}
                    </div>
                  </div>

                  {/* <div className="col-md-4"></div> */}
                </div>
                <div className="row justify-content-center mb-3">
                  <div className="col-md-4">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="exampleCheck1"
                      name="terms"
                      value={false}
                      {...formik.getFieldProps("terms")}
                    />
                    <label
                      className="form-check-label ml-3"
                      for="exampleCheck1"
                    >
                      I Agree To Terms and Condition
                    </label>
                    {formik.touched.terms && formik.errors.terms && (
                      <div className="form_errors">{formik.errors.terms}</div>
                    )}
                  </div>
                  <div className="col-md-4"></div>
                </div>
                <div className="row justify-content-end mb-3">
                  <div className="col-md-4"></div>
                  <div className="col-md-4">
                    <button className="btn save-btn"> Save</button>
                    <button
                      className="btn cancel-btn ml-3"
                      onClick={() => history.goBack()}
                    >
                      Back
                    </button>
                  </div>
                </div>
              </form>
            </FormProvider>
          </div>
          <div className="max-w-md w-full space-y-8 center-div">
            <div></div>
            {error && (
              <div className="text-white px-6 py-4 border-0 rounded relative mb-4 bg-red-600">
                <span className="inline-block align-middle mr-8">
                  <b className="capitalize">Error:</b> {error}
                </span>
                <button
                  onClick={onAlertCloseClick}
                  className="absolute bg-transparent text-2xl font-semibold leading-none right-0 top-0 mt-4 mr-6 outline-none focus:outline-none"
                >
                  <span>×</span>
                </button>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default DoctorRegister;
