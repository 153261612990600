import { useEffect, useState } from "react";
import LoadingDots from "../../components/LoadingDots";
import PatientEditReview from "../../components/patient/PatientEditReview";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { serviceGetDoctorReviews } from "../../services/apiService";
import { useAuth } from "../../useAuth";
import { formatDate } from "../../components/FormatDate";
import { Rating } from "react-simple-star-rating";

const PatientDoctorReviews = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [doctorReviews, setDoctorReviews] = useState([]);
  const { userData } = useAuth();
  const [showModal, setShowModal] = useState(false);
  const [review, setReview] = useState("");
  const [reviewId, setReviewId] = useState("");

  useEffect(() => {
    fetchDoctorReiews();
  }, []);

  const fetchDoctorReiews = async () => {
    try {
      setLoading(true);
      const doctorReviews = await serviceGetDoctorReviews(id);
      if (doctorReviews.status != 1) {
        toast.error(doctorReviews.message);
      }

      setDoctorReviews(doctorReviews.data);
    } catch (error) {
      toast.error("Failed to load doctor reviews.");
    } finally {
      setLoading(false);
    }
  };

  const openModal = (_id, _review) => {
    setReviewId(_id);
    setReview(_review);
    setShowModal(!showModal);
  };

  function calculateAverageRating(_ratingArr) {
    let totalCount = 0;
    let totalRating = 0;
    let averageRating = "";
    if (_ratingArr.length > 0) {
      totalCount = _ratingArr.length;
      _ratingArr.map((rating) => (totalRating += rating.rating));

      averageRating = totalRating / totalCount;
      return averageRating;
    }
    return averageRating;
  }

  function displayStarRating(ratingValue) {
    const maxRating = 5;
    const fullStar = "★";
    const emptyStar = "☆";

    const fullStars = Math.round(ratingValue);
    const emptyStars = maxRating - fullStars;

    const stars = fullStar.repeat(fullStars) + emptyStar.repeat(emptyStars);

    return `${stars}`;
  }

  // Function to handle closing the modal
  const closeModal = () => {
    fetchDoctorReiews();
    setShowModal(false);
  };

  return (
    <>
      <h2>Review's</h2>
      {!loading ? (
        doctorReviews && doctorReviews.length > 0 ? (
          doctorReviews.map((el) => (
            <div id="container-fluid" style={{ marginBottom: "2%" }}>
              <div className="form__box__container">
                <div class="form-group">
                  <div className="row">
                    <div className="col-6">
                      <label
                        for="exampleFormControlTextarea1 col-12"
                        style={{ fontWeight: "bolder" }}
                      >
                        {el.patient.firstName || ""} {el.patient.lastName}
                        <label
                          className="col-12"
                          style={{
                            fontSize: "0.8rem",
                            color: "gray",
                            fontStyle: "italic",
                          }}
                        >
                          On Date: {formatDate(el.createdAt)}, at:{" "}
                          {new Date(el.createdAt).toLocaleTimeString()}
                        </label>
                      </label>
                      <div className="rating d-flex align-items-center">
                        <b>Rating : </b>
                        <div
                          className="ps-3"
                          style={{
                            direction: "ltr",
                            fontFamily: "sans-serif",
                            touchAction: "none",
                          }}
                        >
                          <Rating
                            size={15}
                            allowFraction
                            initialValue={calculateAverageRating(el.ratings)}
                            readonly={true}
                            SVGstyle={{ display: "inline" }}
                            showTooltip={calculateAverageRating(el.ratings) > 0}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-6 text-end">
                      {el.patientID == userData.uid ? (
                        <button
                          style={{ color: "hsl(243, 59%, 50%)" }}
                          onClick={() => openModal(el.id, el.review)}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            class="w-6 h-6"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
                            />
                          </svg>
                        </button>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <div>
                    {" "}
                    <div className="col-md-12 ">
                      <b>Review:</b> {el.review}
                    </div>
                  </div>
                </div>
              </div>

              <PatientEditReview
                closeModal={closeModal}
                modalIsOpen={showModal}
                reviewId={reviewId}
                reviewMessage={review}
              />
            </div>
          ))
        ) : (
          <div className="row text-center m-3">No review found.</div>
        )
      ) : (
        <LoadingDots />
      )}
    </>
  );
};

export default PatientDoctorReviews;
