import React, { useEffect, useState } from "react";
import {
  Link,
  Route,
  Switch,
  useRouteMatch,
  useHistory,
} from "react-router-dom";
import LoadingDots from "../../components/LoadingDots";
import { useAuth } from "../../useAuth";
import PatientHome from "./PatientHome";
import { axiosInstance, userType } from "../../common/constants";
import PatientDoctorList from "./PatientDoctorList";
import PatientAccountSettings from "./PatientAccountSettings";
import PatientMemoList from "./PatientMemoList";
import PatientMemoView from "./PatientMemoView";
import PatientUnverifiedError from "./PatientUnverifiedError";
import PatientDiabetes from "./PatientDiabetes";
import { AppShell, Header, Navbar } from "@mantine/core";
import SidenavItems from "../../dashboard/sidenavigation/SidenavItems";
import TopNavigation from "../../dashboard/topnavigation/TopNavigation";
import { VerifiedRoute } from "../../common/router-functions";
import PatientNotification from "./PatientNotification";
import PatientAppointments from "./PatientAppointments";
import PatientBookAppointment from "./PatientBookAppointment";
import PatientEditAppointment from "./patientEditAppointment";
import PatientPostReview from "./PatientPostReview";
import DoctorDetail from "../../components/doctor/DoctorDetail";
import PatientDoctorReviews from "./PatientDoctorReviews";
import PatientViewAppointment from "./PatientViewAppointment";
import PatientSpeciality from "./patientSpeciality";
import PatientClinicList from "./patientClinicList";
import PatientClinicDetails from "./patientClinicDetails";
import PatientHealthRecords from "./patientHealthRecords";
import PatientMedicalHistory from "./patientMedicalHistory";
import PatientHelpdesk from "./PatientHelpdesk";
const Patient = () => {
  let { path } = useRouteMatch();
  const { userData, setUserData } = useAuth();
  const [loading, setLoading] = useState(true);
  const [isValidRole, setIsValidRole] = useState(false);
  const [opened, setOpened] = useState(false);
  const { signout, user } = useAuth();
  const history = useHistory();

  async function getPatientData() {
    try {
      setLoading(true);
      const res = await axiosInstance.get("/getUserDetails");

      if (res.data.patient != null) {
        setUserData({
          role: userType.PATIENT,
          email: res.data.email,
          firstName: res.data.firstName,
          lastName: res.data.lastName,
          avatarImageUrl: res.data.avatarImageUrl,
          contactNumber: res.data.contactNumber,
          ...res.data.patient,
        });
        setIsValidRole(true);
      } else {
        setUserData(null);
        setIsValidRole(false);
      }
      setLoading(false);
    } catch (e) {
      console.log(e.message);
      setUserData(null);
      setIsValidRole(false);
      setLoading(false);
    }
  }

  async function handleLogout(e) {
    e.preventDefault();

    try {
      localStorage.clear();
      await signout();
      history.push("/");
    } catch {
      console.log("Failed to log out");
    }
  }

  useEffect(() => {
    if (!userData) {
      getPatientData();
    } else {
      setIsValidRole(userData.role === userType.PATIENT);
      setLoading(false);
    }
  }, []);

  return (
    <AppShell
      padding="lg"
      fixed
      navbarOffsetBreakpoint="sm"
      navbar={
        isValidRole ? (
          <Navbar
            hiddenBreakpoint="sm"
            hidden={!opened}
            width={{ sm: 200, lg: 300 }}
            classNames={{ root: "font-sans" }}
          >
            <SidenavItems
              isValidRole={isValidRole}
              onNavItemClick={() => setOpened(false)}
            />
          </Navbar>
        ) : (
          ""
        )
      }
      header={
        isValidRole ? (
          <Header height={60} p="xs" classNames={{ root: "font-sans" }}>
            <TopNavigation opened={opened} setOpened={setOpened} />
          </Header>
        ) : (
          ""
        )
      }
      styles={(theme) => ({
        main: {
          backgroundColor:
            theme.colorScheme === "dark"
              ? theme.colors.dark[8]
              : theme.colors.gray[0],
        },
      })}
    >
      {" "}
      {/* Routing structure  */}
      {/* Routing structure  */}
      {loading ? (
        <LoadingDots />
      ) : isValidRole ? (
        <Switch>
          <Route path={`${path}`} exact={true}>
            <PatientHome onSubmitVerification={getPatientData} />
          </Route>
          <VerifiedRoute
            path={`${path}/doctorList`}
            redirectTo={`${path}/unverified`}
          >
            <PatientDoctorList />
          </VerifiedRoute>
          <VerifiedRoute
            path={`${path}/accountSettings`}
            redirectTo={`${path}/unverified`}
          >
            <PatientAccountSettings />
          </VerifiedRoute>
          <VerifiedRoute
            path={`${path}/specialities`}
            redirectTo={`${path}/unverified`}
          >
            <PatientSpeciality />
          </VerifiedRoute>
          <VerifiedRoute
            path={`${path}/memos`}
            redirectTo={`${path}/unverified`}
          >
            <PatientMemoList />
          </VerifiedRoute>
          <VerifiedRoute
            exact
            path={`${path}/memos`}
            redirectTo={`${path}/unverified`}
          >
            <PatientMemoList />
          </VerifiedRoute>
          <VerifiedRoute
            exact
            path={`${path}/memos/:memoId`}
            redirectTo={`${path}/unverified`}
          >
            <PatientMemoView />
          </VerifiedRoute>
          <VerifiedRoute
            exact
            path={`${path}/diabetes`}
            redirectTo={`${path}/unverified`}
          >
            <PatientDiabetes />
          </VerifiedRoute>
          <VerifiedRoute
            exact
            path={`${path}/notifications`}
            redirectTo={`${path}/unverified`}
          >
            <PatientNotification />
          </VerifiedRoute>
          <VerifiedRoute
            exact
            path={`${path}/appointments`}
            redirectTo={`${path}/unverified`}
          >
            <PatientAppointments />
          </VerifiedRoute>
          <VerifiedRoute
            exact
            path={`${path}/bookAppointment`}
            redirectTo={`${path}/unverified`}
          >
            <PatientBookAppointment />
          </VerifiedRoute>
          <VerifiedRoute
            exact
            path={`${path}/editAppointment/:id`}
            redirectTo={`${path}/unverified`}
          >
            <PatientEditAppointment />
          </VerifiedRoute>

          <VerifiedRoute
            exact
            path={`${path}/viewAppointment/:id`}
            redirectTo={`${path}/unverified`}
          >
            <PatientViewAppointment />
          </VerifiedRoute>

          <VerifiedRoute
            exact
            path={`${path}/postReview/:bookingId`}
            redirectTo={`${path}/unverified`}
          >
            <PatientPostReview />
          </VerifiedRoute>
          <VerifiedRoute
            exact
            path={`${path}/doctor/:id`}
            redirectTo={`${path}/unverified`}
          >
            <DoctorDetail />
          </VerifiedRoute>
          <VerifiedRoute
            exact
            path={`${path}/doctor/:id/reviews`}
            redirectTo={`${path}/unverified`}
          >
            <PatientDoctorReviews />
          </VerifiedRoute>

          <VerifiedRoute
            exact
            path={`${path}/clinics/:id`}
            redirectTo={`${path}/unverified`}
          >
            <PatientClinicList />
          </VerifiedRoute>
          <VerifiedRoute
            exact
            path={`${path}/clinicDetails/:id`}
            redirectTo={`${path}/unverified`}
          >
            <PatientClinicDetails />
          </VerifiedRoute>

          <VerifiedRoute
            exact
            path={`${path}/healthRecords`}
            redirectTo={`${path}/unverified`}
          >
            <PatientHealthRecords />
          </VerifiedRoute>

          <VerifiedRoute
            exact
            path={`${path}/medicalHistory`}
            redirectTo={`${path}/unverified`}
          >
            <PatientMedicalHistory />
          </VerifiedRoute>

          <VerifiedRoute
            exact
            path={`${path}/memos`}
            redirectTo={`${path}/unverified`}
          >
            <PatientMemoList />
          </VerifiedRoute>

          <VerifiedRoute
            exact
            path={`${path}/helpdesk`}
            redirectTo={`${path}/unverified`}
          >
            <PatientHelpdesk />
          </VerifiedRoute>

          <Route path={`${path}/unverified`}>
            <PatientUnverifiedError />
          </Route>
        </Switch>
      ) : (
        <>
          <div className="login-container">
            <div className="login-centered-div">
              <span style={{ marginRight: "10px" }}>
                Your account does not have Patient privileges. Are you perhaps
                trying to login as a
              </span>
              <Link onClick={handleLogout} className="text-indigo-600">
                Doctor or Clinic
              </Link>
              ?
            </div>
          </div>
        </>
      )}
    </AppShell>
  );
};

export default Patient;
