import React from 'react';

const ToastConfirmation = (props) => {
    // ------------- Render Html------------
    return (
        <div>
            <h3>Confirm Action</h3>
            <p>Are you sure you want to perform this action?</p>
            <button data-userid={props.prop1} className='toast-confrim-button' onClick={props.prop2}>Yes</button>
            <button className='toast-confrim-button' onClick={props.prop3}>No</button>
        </div>
    )
};

export default ToastConfirmation;