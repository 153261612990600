import React, { useRef, useState } from "react";
import { useAuth } from "../useAuth";
import DefaultAvatar from "./DefaultAvatar";
import { useForm } from "react-hook-form";
import SectionTitle from "./SectionTitle";
import Button from "./Button";
import AvatarEditor from "react-avatar-editor";
import { ZoomInIcon, ZoomOutIcon } from "@heroicons/react/solid";
import Slider from "rc-slider";
import { axiosInstance } from "../common/constants";
import SmallLoadingSpinner from "./SmallLoadingSpinner";
import classnames from "classnames";
import ChangePasswordForm from "./ChangePasswordForm";
import { servicePostFileUpload } from "../services/clinic/clinicApiService";
import { updateDoctorProfileImage } from "../services/doctor/services";
import { toast } from "react-toastify";

const AdminAccountSettings = () => {
  const {
    register: registerName,
    handleSubmit: handleNameSubmit,
    formState: { errors: nameErrors },
  } = useForm();
  const {
    register: registerContact,
    handleSubmit: handleContactSubmit,
    formState: { errors: contactErrors },
  } = useForm();
  const [submitting, setSubmitting] = useState(false);
  const { userData } = useAuth();
  const [avatarImage, setAvatarImage] = useState();
  const [avatarImageScale, setAvatarImageScale] = useState(1.2);
  const avatarImageInput = useRef(null);

  const avatarImageEditor = useRef(null);

  function onScaleChange(value) {
    setAvatarImageScale(value);
  }

  async function uploadAvatarImage() {
    setSubmitting(true);
    let avatarImageUrl = "";
    if (avatarImageEditor.current) {
      // const avatarDataUrl = avatarImageEditor.current
      //   .getImageScaledToCanvas()
      //   .toDataURL();
      //upload file
      let fileData = {
        file: avatarImage,
        destination: "/avatar",
      };
      avatarImageUrl = await servicePostFileUpload(fileData);

      let data = {
        avatarImageUrl: avatarImageUrl?.data?.filePath,
      };
      const registerData = await updateDoctorProfileImage(data);

      if (registerData.status == 0) {
        setSubmitting(false);
        toast.error(registerData.message);
      }
      if (registerData.status == 1) {
        setSubmitting(false);
        setAvatarImage(null);
        toast.success(registerData.message);
        window.location.reload();
      }
    } else {
      return null;
    }
  }

  async function onNameSubmit(data) {
    setSubmitting(true);
    await axiosInstance.post("/updateName", {
      firstName: data.firstName,
      lastName: data.lastName,
    });
    setSubmitting(false);
    window.location.reload();
  }

  async function onNumberSubmit(data) {
    setSubmitting(true);
    await axiosInstance.post("/updateContactNumber", {
      contactNumber: data.contactNumber,
    });
    setSubmitting(false);
    window.location.reload();
  }

  return (
    <div>
      {/* <SectionTitle>Account Settings</SectionTitle> */}
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <div className="profile__image__box">
              {!avatarImage ? (
                userData.avatarImageUrl ? (
                  <img
                    src={userData.avatarImageUrl}
                    className="margin-auto rounded-full col-span-3 profile__img__setting"
                    alt="Avatar"
                  />
                ) : (
                  <DefaultAvatar className="m-auto profile__img__setting" />
                )
              ) : (
                <>
                  <AvatarEditor
                    ref={avatarImageEditor}
                    className="justify-self-center w-full"
                    color={[0, 0, 0, 0.6]} // RGBA
                    scale={avatarImageScale}
                    rotate={0}
                    image={avatarImage}
                  />
                  <div className="flex items-center mt-2 w-[250px]">
                    <ZoomOutIcon className="h-5 w-5 text-gray-600 " />
                    <Slider
                      className="mx-3"
                      min={1}
                      max={2}
                      step={0.01}
                      defaultValue={1.2}
                      onChange={onScaleChange}
                    />
                    <ZoomInIcon className="h-5 w-5 text-gray-600" />
                  </div>
                </>
              )}
              {avatarImage ? (
                <>
                  <Button onClick={() => setAvatarImage(null)} layout="neutral">
                    Cancel
                  </Button>
                  <Button
                    onClick={uploadAvatarImage}
                    layout="submit"
                    disabled={submitting}
                  >
                    {submitting && (
                      <SmallLoadingSpinner className="h-5 w-5 mr-2 -ml-1" />
                    )}
                    Save
                  </Button>
                </>
              ) : (
                <>
                  <Button
                    className="btn__profile__change"
                    onClick={() => avatarImageInput.current.click()}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="w-6 h-6"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
                      />
                    </svg>
                  </Button>
                  <input
                    type="file"
                    accept="image/*"
                    className="hidden"
                    ref={avatarImageInput}
                    onChange={(e) => setAvatarImage(e.target.files[0])}
                  />
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="gap-3 setting__form">
        <div className="py-3 border-gray-400 col-span-3 grid grid-cols-2 gap-y-3 items-center">
          {/* <div className="col-span-2">
                        <label className="block font-medium text-sm text-gray-700">
                            Email
                        </label>
                        <span className="col-span-1">{userData.email}</span>
                    </div> */}
          <form
            onSubmit={handleNameSubmit(onNameSubmit)}
            className="col-span-2"
          >
            <div className="form__box__container">
              <div className="grid grid-cols-4 gap-3">
                <div className="col-span-2 sm:col-span-1">
                  <label className="form-label">First Name</label>
                  {/*<span className="col-span-1">{userData.firstName}</span>*/}
                  <input
                    type="text"
                    name="first-name"
                    id="first-name"
                    placeholder="First Name"
                    className={classnames("form-control cust__form", {
                      "border-red-500": nameErrors.firstName,
                    })}
                    defaultValue={userData.firstName}
                    {...registerName("firstName", { required: true })}
                  />
                  {nameErrors.firstName && (
                    <p className="block text-sm font-medium text-red-700 mt-2">
                      First name is required
                    </p>
                  )}
                </div>
                <div className="col-span-2 sm:col-span-1">
                  <label className="form-label">Last Name</label>
                  {/*<span className="col-span-1">{userData.lastName}</span>*/}
                  <input
                    type="text"
                    name="last-name"
                    id="last-name"
                    placeholder="Last Name"
                    className={classnames("form-control cust__form", {
                      "border-red-500": nameErrors.lastName,
                    })}
                    defaultValue={userData.lastName}
                    {...registerName("lastName", { required: true })}
                  />
                  {nameErrors.lastName && (
                    <p className="block text-sm font-medium text-red-700 mt-2">
                      Last name is required
                    </p>
                  )}
                </div>

                <div className="col-span-2 sm:col-span-1">
                  <label className="form-label">Email</label>
                  <input
                    type="text"
                    name="Email"
                    placeholder="Email"
                    className={classnames("form-control cust__form")}
                  />
                </div>
                <div className="col-span-2 sm:col-span-1">
                  <label className="form-label">Mobile</label>
                  <input
                    type="text"
                    name="Email"
                    placeholder="Mobile"
                    className={classnames("form-control cust__form")}
                  />
                </div>
                <div className="col-span-2 sm:col-span-1">
                  <label className="form-label">Specialization</label>
                  <input
                    type="text"
                    name="Email"
                    placeholder="Specialization"
                    className={classnames("form-control cust__form")}
                  />
                </div>
                <div className="col-span-2 sm:col-span-1">
                  <label className="form-label">Education</label>
                  <input
                    type="text"
                    name="Specialization"
                    placeholder="Education"
                    className={classnames("form-control cust__form")}
                  />
                </div>
                <div className="col-span-2 sm:col-span-1">
                  <label className="form-label">Clinic Name</label>
                  <input
                    type="text"
                    name="Specialization"
                    placeholder="Clinic Name"
                    className={classnames("form-control cust__form")}
                  />
                </div>
                <div className="col-span-2 sm:col-span-1">
                  <label className="form-label">Address - 1</label>
                  <input
                    type="text"
                    name="Specialization"
                    placeholder="Address - 1"
                    className={classnames("form-control cust__form")}
                  />
                </div>
                <div className="col-span-2 sm:col-span-1">
                  <label className="form-label">Landmark</label>
                  <input
                    type="text"
                    name="Specialization"
                    placeholder="Landmark"
                    className={classnames("form-control cust__form")}
                  />
                </div>
                <div className="col-span-2 sm:col-span-1">
                  <label className="form-label">City</label>
                  <input
                    type="text"
                    name="Specialization"
                    placeholder="City"
                    className={classnames("form-control cust__form")}
                  />
                </div>
                <div className="col-span-2 sm:col-span-1">
                  <label className="form-label">State</label>
                  <input
                    type="text"
                    name="Specialization"
                    placeholder="State"
                    className={classnames("form-control cust__form")}
                  />
                </div>
                <div className="col-span-2 sm:col-span-1">
                  <label className="form-label">Pincode</label>
                  <input
                    type="text"
                    name="Specialization"
                    placeholder="Pincode"
                    className={classnames("form-control cust__form")}
                  />
                </div>
                <div className="col-span-2 sm:col-span-1">
                  <label className="form-label">
                    Total Experience (In Years)
                  </label>
                  <input
                    type="text"
                    name="Specialization"
                    placeholder="Total Experience (In Years)"
                    className={classnames("form-control cust__form")}
                  />
                </div>
                <div className="col-span-2 sm:col-span-1">
                  <label className="form-label">About</label>
                  <input
                    type="text"
                    name="Specialization"
                    placeholder="About"
                    className={classnames("form-control cust__form")}
                  />
                </div>
                <div className="col-span-2 sm:col-span-1 profile__update__btn__container"></div>
                <div className="col-span-2 sm:col-span-1 profile__update__btn__container">
                  <button className="btn btn__view mt-0">Update</button>
                </div>

                {/* <div className="col-start-1">
                                    <Button
                                        type="submit"
                                        layout="submit"
                                        disabled={submitting}>{submitting &&
                                        <SmallLoadingSpinner className="h-5 w-5 mr-2 -ml-1"/>}Update Name</Button>
                                </div> */}
              </div>
            </div>
          </form>
        </div>
        <div className="form__box__container mb-3">
          <div className="row">
            <div className="col-md-4">
              <label className="form-label">Add Documents</label>
              <input
                type="file"
                name="Specialization"
                placeholder="Add Documents"
                className={classnames("form-control cust__form")}
              />
            </div>
          </div>
        </div>
        <div class="upcoming__head">
          <h2>Contact Number</h2>
        </div>
        <form
          onSubmit={handleContactSubmit(onNumberSubmit)}
          className="form__box__container"
        >
          <div className="row">
            <div className="col-md-4">
              <input
                type="tel"
                name="contact-number"
                id="contact-number"
                placeholder="Enter contact number"
                className={classnames("form-control cust__form", {
                  "border-red-500": contactErrors.contactNumber,
                })}
                defaultValue={userData.contactNumber}
                {...registerContact("contactNumber")}
              />
            </div>
            <div className="col-md-4">
              <div className="">
                <Button type="submit" layout="submit" disabled={submitting}>
                  {submitting && (
                    <SmallLoadingSpinner className="h-5 w-5 mr-2 -ml-1" />
                  )}
                  Update{" "}
                </Button>
              </div>
            </div>
          </div>
        </form>

        <div className="py-3 border-b-2 border-gray-400 items-center">
          <div class="upcoming__head">
            <h2>Change password</h2>
          </div>
          <div className="form__box__container">
            <ChangePasswordForm />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminAccountSettings;
