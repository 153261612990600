import React, { useState, useEffect } from "react";
import { serviceGetPatientClinicList } from "../../services/apiService";
import { useHistory, useParams, Link } from "react-router-dom";
import Table from "../../components/table/Table";
import TableHeader from "../../components/table/TableHeader";
import TableCell from "../../components/table/TableCell";
import TableBody from "../../components/table/TableBody";
import TableRow from "../../components/table/TableRow";
import TableFooter from "../../components/table/TableFooter";
import TableContainer from "../../components/table/TableContainer";
import LoadingDots from "../../components/LoadingDots";
import Pagination from "../../components/Pagination";
import RecordPlaceholder from "../../components/RecordPlaceholder";
import LoadingSpinner from "../../components/LoadingSpinner";
import { SearchIcon } from "@heroicons/react/solid";

const PatientClinicList = () => {
  const [loading, setLoading] = useState(true);
  const [clinicList, setClinicList] = useState([]);
  const [page, setPage] = useState(0);
  const [totalResults, setTotalResults] = useState(0);
  const [search, setSearch] = useState("");
  const { id } = useParams();
  const resultsPerPage = 10;

  async function getPatientClinicList() {
    // try {
    //   setLoading(true);
    let response = await serviceGetPatientClinicList(id, page, search);
    console.log("response123", response.data);
    if (response.status == 1) {
      setLoading(false);
      setTotalResults(response.count);
      setClinicList(response.data);
    }
    // } catch (e) {
    //   setLoading(false);
    // }
  }

  function onPageChangeTable(p) {
    setPage(p - 1);
  }

  useEffect(() => {
    getPatientClinicList();
  }, [page, search]);

  async function searchClinc(_search) {
    setSearch(_search);
    await getPatientClinicList();
  }

  return (
    <>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <div className="container-fluied">
          <div className="row">
            <div class="col-6 ps-0">
              <input
                type="text"
                class="form-control cust__form"
                id="search"
                placeholder="Search"
                value={search}
                onChange={(event) => setSearch(event.target.value)}
              />
            </div>
            <div class="col-6">
              <button
                type="button"
                class="btn d-flex"
                style={{ background: "#4F46E5", color: "white" }}
                onClick={() => searchClinc(search)}
              >
                <SearchIcon />
                <p className="m-0">Search</p>
              </button>
            </div>
          </div>
          <TableContainer>
            <Table>
              <TableHeader>
                <tr className="head__th__appointment">
                  <TableCell>Clinic Name</TableCell>
                  <TableCell>Address</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Timings</TableCell>
                  <TableCell>Actions</TableCell>
                </tr>
              </TableHeader>
              <TableBody className="table__body__app">
                {clinicList && clinicList.length > 0 ? (
                  clinicList.map((clinic) => (
                    <TableRow key={clinic.uid}>
                      <TableCell>
                        <Link to={`/patient/clinicDetails/${clinic.uid}`}>
                          {clinic.name || "N/A"}
                        </Link>{" "}
                      </TableCell>
                      <TableCell>
                        {clinic?.addressLine1 ||
                          "" + clinic?.addressLine2 ||
                          "" + "," + clinic?.city ||
                          "" + "," + clinic?.state ||
                          "" + "," + clinic?.country ||
                          ""}
                      </TableCell>
                      <TableCell>{clinic?.user?.email}</TableCell>
                      <TableCell>
                        {clinic?.weekAvailability?.filter(
                          (item) =>
                            item?.day == new Date(Date.now()).getDay() &&
                            item.startTime != ""
                        ) == ""
                          ? "Holiday"
                          : clinic?.weekAvailability?.filter(
                              (item) =>
                                item?.day == new Date(Date.now()).getDay() &&
                                item.startTime != ""
                            )?.[0].startTime +
                            " - " +
                            clinic?.weekAvailability?.filter(
                              (item) =>
                                item?.day == new Date(Date.now()).getDay() &&
                                item.startTime != ""
                            )?.[0].endTime}
                      </TableCell>

                      <TableCell>
                        <button>
                          <Link to={`/patient/clinicDetails/${clinic.uid}`}>
                            View
                          </Link>
                        </button>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <RecordPlaceholder table="false" />
                )}
              </TableBody>
            </Table>
            {totalResults > 0 && (
              <TableFooter>
                <Pagination
                  totalResults={totalResults}
                  resultsPerPage={resultsPerPage}
                  onChange={onPageChangeTable}
                  label="Table navigation app__table__details"
                />
              </TableFooter>
            )}
          </TableContainer>
        </div>
      )}
    </>
  );
};

export default PatientClinicList;
