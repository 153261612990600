import React, { useEffect, useState } from "react";
import DoctorHome from "./DoctorHome";
import {
  Link,
  Redirect,
  Route,
  Switch,
  useRouteMatch,
  useHistory,
} from "react-router-dom";
import DoctorPatientList from "./DoctorPatientList";
import { useAuth } from "../../useAuth";
import { axiosInstance, userType } from "../../common/constants";
import LoadingDots from "../../components/LoadingDots";
import DoctorPatientManagement from "./DoctorPatientManagement";
import DoctorAccountSettings from "./DoctorAccountSettings";
import DoctorPatientView from "./DoctorPatientView";
import DoctorUnverifiedError from "./DoctorUnverifiedError";
import { AppShell, Header, Navbar, MediaQuery, Burger } from "@mantine/core";
import SidenavItems from "../../dashboard/sidenavigation/SidenavItems";
import TopNavigation from "../../dashboard/topnavigation/TopNavigation";
import DoctorCreatePatient from "./DoctorCreatePatient";
import { VerifiedRoute } from "../../common/router-functions";
import DoctorManageNotifications from "./DoctorManageNotifications";
import SidenavBadge from "../../dashboard/sidenavigation/SidenavBadge";
import { QuestionMarkCircleIcon } from "@heroicons/react/solid";
import DoctorNotification from "./DoctorNotification";
import DoctorSettings from "./DoctorSettings";
import VerifyEmail from "../verifyEmail";
import DoctorAppointmentList from "./appointment/DoctorAppointmentList";
import AppointmentDetail from "./appointment/AppointmentDetail";
import DoctorMemo from "./DoctorMemo";
import DoctorReviewAndRatingList from "./reviewAndRating/reviewAndRatingList";
import DoctorInvitationsList from "./DoctorInvitationsList";
import EditAppointment from "./appointment/EditAppointment";
import DoctorMemoList from "./doctorMemolist";
import DoctorPatientsList from "./DoctorPatient-List";
import DoctorPatientDetails from "./DoctorPatientDetails";

const style = {
  title: `font-semibold mx-4 text-sm`,
  active: `bg-gradient-to-r border-r-4 border-blue-500 border-r-4 border-blue-500 from-white to-blue-100 text-blue-500`,
  link: `duration-200 flex font-thin items-center justify-start my-2 p-4 transition-colors text-gray-500 w-full lg:hover:text-blue-500`,
};

const Doctor = () => {
  let { url } = useRouteMatch();
  const { userData, setUserData } = useAuth();
  const [loading, setLoading] = useState(true);
  const [isValidRole, setIsValidRole] = useState(false);
  const [opened, setOpened] = useState(false);
  const { signout, user } = useAuth();
  const history = useHistory();

  async function getDoctorDetails() {
    try {
      setLoading(true);
      const res = await axiosInstance.get("/getUserDetails");

      if (res.data.doctor != null) {
        setUserData({
          role: userType.DOCTOR,
          email: res.data.email,
          firstName: res.data.firstName,
          lastName: res.data.lastName,
          avatarImageUrl: res.data.avatarImageUrl,
          contactNumber: res.data.contactNumber,
          ...res.data.doctor,
        });
        setIsValidRole(true);
      } else {
        setUserData(null);
        setIsValidRole(false);
      }
      setLoading(false);
    } catch (e) {
      console.log(e);
      setUserData(null);
      setIsValidRole(false);
      setLoading(false);
    }
  }

  async function handleLogout(e) {
    e.preventDefault();

    try {
      localStorage.clear();
      await signout();
      history.push("/");
    } catch {
      console.log("Failed to log out");
    }
  }

  useEffect(() => {
    if (!userData) {
      getDoctorDetails();
    } else {
      setIsValidRole(userData.role === userType.DOCTOR);
      setLoading(false);
    }
  }, []);

  return (
    <AppShell
      padding="lg"
      navbarOffsetBreakpoint="sm"
      fixed
      navbar={
        isValidRole ? (
          <Navbar
            hiddenBreakpoint="sm"
            hidden={!opened}
            width={{ sm: 200, lg: 300 }}
            classNames={{ root: "font-sans" }}
          >
            <Navbar.Section grow>
              <SidenavItems
                isValidRole={isValidRole}
                onNavItemClick={() => setOpened(false)}
              />
            </Navbar.Section>
            <Navbar.Section>
              <a
                href="https://s3.us-west-2.amazonaws.com/public.healthlinkprotocol/HLP_FAQ.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                <span className={`${style.link}`}>
                  <span className="h-6 w-6">
                    <QuestionMarkCircleIcon />
                  </span>
                  <span className={style.title}>FAQs</span>
                </span>
              </a>

              {/*<a className="text-gray-400 hover:text-gray-800 dark:text-gray-300 dark:hover:text-white transition-colors duration-200"*/}
              {/*   href="https://s3.us-west-2.amazonaws.com/public.healthlinkprotocol/HLP_FAQ.pdf" target="_blank" rel="noopener noreferrer">*/}
              {/*    FAQs*/}
              {/*</a>*/}
            </Navbar.Section>
          </Navbar>
        ) : (
          ""
        )
      }
      header={
        isValidRole ? (
          <Header height={60} p="xs" classNames={{ root: "font-sans" }}>
            <TopNavigation opened={opened} setOpened={setOpened} />
          </Header>
        ) : (
          ""
        )
      }
      styles={(theme) => ({
        main: {
          backgroundColor:
            theme.colorScheme === "dark"
              ? theme.colors.dark[8]
              : theme.colors.gray[0],
        },
      })}
    >
      {loading ? (
        <LoadingDots />
      ) : isValidRole ? (
        <Switch>
          <Route path={`${url}`} exact={true}>
            <DoctorHome onSubmitRegistration={getDoctorDetails} />
          </Route>
          <VerifiedRoute
            exact
            path={`${url}/patients`}
            redirectTo={`${url}/unverified`}
          >
            <DoctorPatientList />
          </VerifiedRoute>
          <VerifiedRoute
            path={`${url}/patients/:patientUid`}
            redirectTo={`${url}/unverified`}
          >
            <DoctorPatientView />
          </VerifiedRoute>
          <VerifiedRoute
            path={`${url}/managePatients`}
            redirectTo={`${url}/unverified`}
          >
            <DoctorPatientManagement />
          </VerifiedRoute>
          <VerifiedRoute
            path={`${url}/createPatient`}
            redirectTo={`${url}/unverified`}
          >
            <DoctorCreatePatient />
          </VerifiedRoute>
          <Route path={`${url}/notifications`} redirectTo={`${url}/unverified`}>
            <DoctorNotification />
          </Route>
          <VerifiedRoute
            path={`${url}/manageNotifications`}
            redirectTo={`${url}/unverified`}
          >
            <DoctorManageNotifications />
          </VerifiedRoute>
          <Route path={`${url}/accountSettings`}>
            <DoctorAccountSettings />
          </Route>

          <Route path={`${url}/settings`} redirectTo={`${url}/unverified`}>
            <DoctorSettings />
          </Route>
          <VerifiedRoute
            exact
            path={`${url}/appointment`}
            redirectTo={`${url}/unverified`}
          >
            <DoctorAppointmentList />
          </VerifiedRoute>
          <VerifiedRoute
            exact
            path={`${url}/appointment/edit/:id`}
            redirectTo={`${url}/unverified`}
          >
            <EditAppointment />
          </VerifiedRoute>
          <VerifiedRoute
            exact
            path={`${url}/appointment/detail/:id`}
            redirectTo={`${url}/unverified`}
          >
            <AppointmentDetail />
          </VerifiedRoute>

          <VerifiedRoute
            exact
            path={`${url}/reviewAndRating/list`}
            redirectTo={`${url}/unverified`}
          >
            <DoctorReviewAndRatingList />
          </VerifiedRoute>

          <VerifiedRoute
            exact
            path={`${url}/invitations`}
            redirectTo={`${url}/unverified`}
          >
            <DoctorInvitationsList />
          </VerifiedRoute>

          <VerifiedRoute path={`${url}/memo`} redirectTo={`${url}/unverified`}>
            {/* < /> */}
            <DoctorMemo />
          </VerifiedRoute>

          <VerifiedRoute
            exact
            path={`${url}/memos`}
            redirectTo={`${url}/unverified`}
          >
            <DoctorMemoList />
          </VerifiedRoute>
          <VerifiedRoute
            exact
            path={`${url}/patients-list`}
            redirectTo={`${url}/unverified`}
          >
            <DoctorPatientsList />
          </VerifiedRoute>

          <VerifiedRoute
            exact
            path={`${url}/patientDetails/:patientID`}
            redirectTo={`${url}/unverified`}
          >
            <DoctorPatientDetails />
          </VerifiedRoute>

          <Route path={`${url}/unverified`}>
            <DoctorUnverifiedError />
          </Route>
        </Switch>
      ) : (
        <>
          <div className="login-container">
            <div className="login-centered-div">
              <span style={{ marginRight: "10px" }}>
                {" "}
                Your account does not have Doctor privileges. Are you perhaps
                trying to login as a{" "}
              </span>
              <Link onClick={handleLogout} className="text-indigo-600">
                Patient or Clinic
              </Link>
              ?
            </div>
          </div>
        </>
      )}
    </AppShell>
  );
};

export default Doctor;
