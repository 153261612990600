import React, { useEffect, useRef, useState } from "react";
import DefaultAvatar from "../../components/DefaultAvatar";
import {
  useHistory,
  useLocation,
  useParams,
  useRouteMatch,
} from "react-router-dom";
import { axiosInstance } from "../../common/constants";
import LoadingDots from "../../components/LoadingDots";
import Button from "../../components/Button";
import Badge from "../../components/Badge";
import { DateTime } from "luxon";
import DoctorMemoForm from "./DoctorMemoForm";
import DoctorBloodSugarView from "./DoctorBloodSugarView";
import DoctorMemoMainView from "./DoctorMemoMainView";
import { Tab, TabList, Tabs, TabPanel } from "react-tabs";
import { PencilIcon, PlusIcon } from "@heroicons/react/solid";
import { XIcon } from "@heroicons/react/outline";
import classnames from "classnames";
import { useForm } from "react-hook-form";
import SmallLoadingSpinner from "../../components/SmallLoadingSpinner";
import DoctorAsthmaFreedQuestionnaire from "./DoctorAsthmaFreedQuestionnaire";
import { formatDate } from "../../components/FormatDate";

const DoctorPatientView = () => {
  //TODO:change to memoTab = 0, bloodSugarTab = 2, createMemoTab = 3 when enabling blood sugar
  const memoTab = 0;
  const bloodSugarTab = 2;
  const asthmaFreedTab = 1;
  const createMemoTab = 3;

  const [patient, setPatient] = useState(null);
  const [loading, setLoading] = useState(true);
  const [submittingContact, setSubmittingContact] = useState(false);
  const [contactEdit, setContactEdit] = useState(false);
  const [hasAccess, setHasAccess] = useState(false);
  const [showCreateMemoTab, setShowCreateMemoTab] = useState(false);
  const [copyMemoData, setCopyMemoData] = useState();
  const { patientUid } = useParams();
  const { url } = useRouteMatch();
  const history = useHistory();
  const location = useLocation();
  const [activeIndex, setActiveIndex] = useState(getNumber(location?.pathname));

  let theUrl = useRef(`${url}/memos`);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  function getNumber(location) {
    const pathname = location.split("/")[4];

    if (!pathname) return 0;
    else {
      switch (pathname) {
        case "memos":
          return memoTab;
        case "bloodSugar":
          return bloodSugarTab;
        case "asthmaFreed":
          return asthmaFreedTab;
        case "createMemo":
          return createMemoTab;
        default:
          return 0;
      }
    }
  }

  async function getPatientDetails() {
    const res = await axiosInstance.get(
      `/doctor/viewPatientDetails?patientUid=${patientUid}`
    );
    setPatient(res.data);
    setHasAccess(res.data.hasAccess);
    setLoading(false);
  }

  async function onContactNumberSubmit(data) {
    setSubmittingContact(true);
    const res = await axiosInstance.post("/doctor/updatePatientContactNumber", {
      newContact: data.contactNumber,
      patientUid: patient.uid,
    });

    patient.user = res.data;

    setSubmittingContact(false);

    setContactEdit(false);
  }

  function calculateAge() {
    const date = DateTime.fromISO(patient.birthDate);
    const diffInYears = DateTime.now().diff(date, "year");
    return <>{Math.floor(diffInYears.as("years"))}</>;
  }

  function toggleCreateMemo(toShow) {
    setCopyMemoData(null);
    if (toShow) {
      setShowCreateMemoTab(true);
      history.push(`${url}/createMemo`);
      onTabChange(createMemoTab);
    } else {
      setShowCreateMemoTab(false);
      if (activeIndex === createMemoTab) {
        history.push(`${url}/memos`);
        onTabChange(memoTab);
      }
    }
  }

  function copyCreateMemo(data) {
    setShowCreateMemoTab(true);
    history.push(`${url}/createMemo`);
    setCopyMemoData(data);
    onTabChange(createMemoTab);
  }

  function onTabChange(index) {
    if (activeIndex === 0) {
      // setMemoUrl(location.pathname)
      theUrl.current = location.pathname;
    }

    switch (index) {
      case memoTab:
        history.push(theUrl.current);
        break;
      case bloodSugarTab:
        history.push(`${url}/bloodSugar`);
        break;
      case asthmaFreedTab:
        history.push(`${url}/asthmaFreed`);
        break;
      case createMemoTab:
        history.push(`${url}/createMemo`);
        break;
    }

    setActiveIndex(index);
  }

  function onMemoCreated(memoId) {
    // setMemoUrl(`${url}/memos/${memoId}`)
    theUrl.current = `${url}/memos/${memoId}`;
    onTabChange(memoTab);
    setShowCreateMemoTab(false);
  }

  useEffect(() => {
    if (!patient) {
      getPatientDetails();
    }
  }, []);

  return (
    <div>
      {loading ? (
        <LoadingDots />
      ) : (
        <>
          <div className="grid grid-cols-3 max-w-6xl">
            <div className="flex items-center col-span-3 sm:col-span-2">
              <div className="flex-none">
                {patient.user.avatarImageUrl ? (
                  <img
                    src={patient.user.avatarImageUrl}
                    className="rounded-full w-28 h-28 md:w-32 md:h-32"
                    alt="Avatar"
                  />
                ) : (
                  <DefaultAvatar className="w-28 h-28 md:w-32 md:h-32" />
                )}
              </div>
              <div className="sm:grid sm:grid-cols-4 gap-y-1 gap-x-3 justify-items-start items-center max-w-full ml-4 text-sm">
                <dt className="text-gray-500 text-sm">First Name</dt>
                {patient.user.firstName === null ||
                patient.user.firstName === "" ? (
                  <Badge className="sm:col-span-3" type="neutral">
                    not given
                  </Badge>
                ) : (
                  <span className="sm:col-span-3">
                    {patient.user.firstName}
                  </span>
                )}
                <dt className="text-gray-500 text-sm">Last Name</dt>
                {patient.user.lastName === null ||
                patient.user.lastName === "" ? (
                  <Badge className="sm:col-span-3" type="neutral">
                    not given
                  </Badge>
                ) : (
                  <span className="sm:col-span-3">{patient.user.lastName}</span>
                )}
                <dt className="text-gray-500 text-sm">Age</dt>
                <span className="sm:col-span-3">{calculateAge()}</span>
                <dt className="text-gray-500 text-sm">Date of Birth</dt>
                <span className="sm:col-span-3">
                  {formatDate(patient.birthDate)}
                </span>
                <dt className="text-gray-500 text-sm">E-mail</dt>
                <p className="sm:col-span-3 break-all">{patient.user.email}</p>
                <dt className="text-gray-500 text-sm">Contact Number</dt>
                <div className="col-span-3">
                  {contactEdit ? (
                    <div>
                      {submittingContact ? (
                        <SmallLoadingSpinner className="h-5 w-5 text-indigo-600" />
                      ) : (
                        <form
                          onSubmit={handleSubmit(onContactNumberSubmit)}
                          className="grid grid-cols-4 gap-1"
                        >
                          <input
                            type="text"
                            name="contactNumber"
                            id="contactNumber"
                            className={classnames(
                              "col-span-3 py-1 px-2 focus:ring-indigo-500 focus:border-indigo-500 w-full shadow-sm sm:text-sm border-gray-300 rounded-md",
                              {
                                "border-red-500": errors.contactNumber,
                              }
                            )}
                            defaultValue={patient.user.contactNumber}
                            {...register("contactNumber", { required: true })}
                          />
                          <div className="col-span-1">
                            <Button
                              size="small"
                              layout="outline"
                              icon={XIcon}
                              onClick={() => setContactEdit(!contactEdit)}
                            />
                          </div>
                          <Button
                            className="col-span-4 w-64"
                            type="submit"
                            size="small"
                          >
                            Update Contact
                          </Button>
                        </form>
                      )}
                    </div>
                  ) : (
                    <div className="flex items-center space-x-2">
                      {patient.user.contactNumber === null ||
                      patient.user.contactNumber === "" ? (
                        <Badge className="col-span-3 h-auto" type="neutral">
                          not given
                        </Badge>
                      ) : (
                        <span className="sm:col-span-3">
                          {patient.user.contactNumber}
                        </span>
                      )}

                      <Button
                        size="small"
                        layout="outline"
                        icon={PencilIcon}
                        onClick={() => setContactEdit(!contactEdit)}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
            {hasAccess && (
              <div className="col-span-3 sm:col-span-1 flex items-center justify-center py-3">
                {!showCreateMemoTab && (
                  <Button
                    className="w-full sm:w-auto"
                    layout="submit"
                    iconLeft={PlusIcon}
                    onClick={() => toggleCreateMemo(true)}
                  >
                    Create New Memo
                  </Button>
                )}
              </div>
            )}
          </div>

          {hasAccess ? (
            <div>
              <Tabs selectedIndex={activeIndex} onSelect={onTabChange}>
                <TabList className="flex flex-wrap text-center text-gray-500 border-b border-gray-200 dark:text-gray-400 dark:border-gray-700 mb-3">
                  <Tab
                    className="inline-block p-4 rounded-t-lg border-b-2 cursor-pointer"
                    selectedClassName="text-indigo-600 border-indigo-600 font-bold"
                  >
                    Memos
                  </Tab>
                  <Tab
                    className="inline-block p-4 rounded-t-lg border-b-2 cursor-pointer"
                    selectedClassName="text-indigo-600 border-indigo-600 font-bold"
                  >
                    Asthma FREED
                  </Tab>
                  {/*TODO: UNCOMMENT THIS TO ENABLE BLOOD SUGAR VIEW*/}
                  <Tab
                    className="inline-block p-4 rounded-t-lg border-b-2 cursor-pointer"
                    selectedClassName="text-indigo-600 border-indigo-600 font-bold"
                  >
                    Blood Sugar Entries
                  </Tab>
                  {showCreateMemoTab && (
                    <Tab
                      className="bg-green-200 inline-block p-4 rounded-t-lg border-b-2 cursor-pointer flex"
                      selectedClassName="text-indigo-600 border-indigo-600 font-bold bg-green-300"
                    >
                      New Memo
                      <XIcon
                        className="ml-3 h-5 w-5"
                        onClick={(e) => {
                          toggleCreateMemo(false);
                          e.stopPropagation();
                        }}
                      />
                    </Tab>
                  )}
                </TabList>
                <TabPanel>
                  <DoctorMemoMainView
                    patientUid={patient.uid}
                    onCopyMemo={copyCreateMemo}
                  />
                </TabPanel>
                <TabPanel>
                  <DoctorAsthmaFreedQuestionnaire
                    onSubmitMemo={copyCreateMemo}
                  />
                </TabPanel>
                {/*TODO: UNCOMMENT THIS TO ENABLE BLOOD SUGAR VIEW*/}
                <TabPanel forceRender={true}>
                  <DoctorBloodSugarView patientUid={patient.uid} />
                </TabPanel>

                {showCreateMemoTab && (
                  <TabPanel forceRender={true}>
                    <DoctorMemoForm
                      patientUid={patient.uid}
                      onSubmitted={onMemoCreated}
                      copyData={copyMemoData}
                    />
                  </TabPanel>
                )}
              </Tabs>
            </div>
          ) : (
            <div
              className="bg-red-200 border-red-600 text-red-600 border-l-4 p-4 mt-3"
              role="alert"
            >
              <p className="font-bold">No Access</p>
              <p>
                You do not have permissions to access this patient's data.
                Please request access first.
              </p>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default DoctorPatientView;
