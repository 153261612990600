import React, { Fragment } from 'react';
import { Popover, Transition } from '@headlessui/react'
import { MenuIcon, XIcon } from '@heroicons/react/outline'
import '../index.css';
import { Link } from 'react-router-dom';
import logo from '../healthlink_logo.svg';
import logoWhite from '../healthlink_logo_white-svg.png';
import Header from "../components/Header";
import Footer from "../components/Footer";
import FeaturesSection from "../components/FeaturesSection";
import { Helmet } from "react-helmet-async";


const navigation = [
    { name: 'Admin', href: '/admin' },
]

const HomePage = () => {
    return (
        <div>
            <Helmet>
                <meta name="description" content="The healthcare communication platform for both doctors and patients" />
            </Helmet>

            <div className="relative bg-white home-main-container">
                <div className='nav__home__box mb-3'>
                    <img src={logoWhite} className="nav_home__logo" alt="logo" />
                    
                </div>
                <div className="max-w-7xl mx-auto">
                    <div
                        className="relative z-10 bg-white lg:max-w-2xl lg:w-full">
                        {/* <svg
                            className="hidden lg:block absolute right-0 inset-y-0 h-full w-48 text-white transform translate-x-1/2"
                            fill="currentColor"
                            viewBox="0 0 100 100"
                            preserveAspectRatio="none"
                            aria-hidden="true"
                        >
                            <polygon points="50,0 100,0 50,100 0,100"/>
                        </svg> */}

                        {/* <Header/> */}

                        <main
                            className="mt-10 mx-auto max-w-7xl px-4 sm:mt-12 sm:px-6 md:mt-11 lg:mt-15 lg:px-8 xl:mt-20">
                            <div className="sm:text-center">
                                <h1 className='home__page__welcom'>Welcome to Health Link</h1>
                                <div className='home__page__logo__box__wrap'>
                                    <img src={logo} className="health__link__logo__home" alt="logo" />
                                    <span className="health__link__txt__home"> HealthLink</span>
                                </div>
                                <p className='home__page__login__option'>Kindly select a login option</p>
                                <div className='button__box__home mt-3'>
                                    <Link to="/doctor">
                                        <button className='btn btn__home__login'>Doctor</button>
                                    </Link>

                                </div>
                                <div className='button__box__home'>
                                    <Link to="/clinic">
                                        <button className='btn btn__home__login'>Clinic</button>
                                    </Link>
                                </div>
                                <div className='button__box__home'>
                                    <Link to="/patient">
                                        <button className='btn btn__home__login'>Patient</button>
                                    </Link>
                                </div>
                                {/* <h1 className="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl">
                                    <span className="block xl:inline">Welcome to</span>{' '}
                                    <span className="block text-indigo-600 flex items-center"><img src={logo}
                                                                                                   className="h-8 w-auto sm:h-12 inline mr-1"
                                                                                                   alt="logo"/>HealthLink</span>

                                </h1> */}
                                {/* <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
                                    Login as a Doctor or a Patient below
                                </p> */}
                                {/* <div className="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-start">
                                    <div className="rounded-md shadow">
                                        <Link to="/doctor">
                                            <button
                                                className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 md:py-4 md:text-lg md:px-10"
                                            >
                                                Doctor
                                            </button>
                                        </Link>
                                    </div>
                                    <div className="rounded-md shadow sm:mt-0 sm:ml-3">
                                        <Link to="/patient">
                                            <button
                                                className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 md:py-4 md:text-lg md:px-10"
                                            >
                                                Patient
                                            </button>
                                        </Link>
                                    </div>
                                </div> */}
                            </div>
                        </main>
                    </div>
                </div>
                <div className="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2 home__login__img__box">
                    <img
                        className="h-56 w-full object-cover sm:h-72 md:h-96 lg:w-full lg:h-full home__bg__clipp"
                        // src="https://images.unsplash.com/photo-1551434678-e076c223a692?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2850&q=80"
                        src="https://images.unsplash.com/photo-1576091160550-2173dba999ef?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80"
                        alt=""
                    />
                </div>

            </div>
            <div className="footer__wrap__main">
                <div className='footer__main__box__left'>
                    <a href='javascript:void(0)'> &copy;HealthLink</a>
                </div>
                <div className='footer__main__box__right'>
                    <Link to='/admin'> Admin</Link>
                    <Link to='/pdpa'> PDPA Policy</Link>
                    <a target="_blank" href='https://s3.us-west-2.amazonaws.com/public.healthlinkprotocol/HLP_terms.pdf'> Terms & Conditions</a>
                </div>
            </div>
            {/* <Footer/> */}
        </div>
    );
};

export default HomePage;