import React, { useEffect, useState, useRef } from "react";
import { Link, useHistory } from "react-router-dom";
import MemoExamination from "../../pages/doctor/MemoExamination";
import MemoDignosis from "../../pages/doctor/MemoDignosis";
import MemoTreatMentPlan from "../../pages/doctor/MemoTreatMentPlan";
import MemoFollowups from "../../pages/doctor/MemoFollowups";
import MemoReferrel from "../../pages/doctor/MemoReferrel";
import ToastConfirmation from "../../components/toastrConfirmation";
import { toast } from "react-toastify";
import { servicePostSendMemo } from "../../services/apiService";
import LoadingSpinner from "../../components/LoadingSpinner";

const DoctorGenerateMemo = ({ patientId, appointmentId, closeModal }) => {
  const history = useHistory();
  const memoExaminationRef = useRef(null);
  const childRef = useRef(null);
  const [callChildMethod, setCallChildMethod] = useState(false);
  const [loading, setLoading] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [formData, setFormData] = useState({
    // Initialize form data fields here
    name: "",
    email: "",
    // Add other form fields as needed
  });

  const steps = ["Step 1", "Step 2", "Step 3", "step 4", "step 5"]; // Customize the step labels

  const handleNext = (_step) => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);

    //handle next step
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  // Render the content for each step
  const renderStepContent = (stepIndex) => {
    switch (stepIndex) {
      case 0:
        return (
          <div className="container-fluid">
            <MemoExamination
              patientId={patientId}
              appointmentId={appointmentId}
            />
          </div>
        );
      case 1:
        return (
          <div className="container-fluid">
            <MemoDignosis patientId={patientId} appointmentId={appointmentId} />
          </div>
        );

      case 2:
        return (
          <div className="container-fluid">
            {" "}
            <MemoTreatMentPlan
              patientId={patientId}
              appointmentId={appointmentId}
            />
          </div>
        );
      case 3:
        return (
          <div className="container-fluid">
            <MemoFollowups
              patientId={patientId}
              appointmentId={appointmentId}
            />
          </div>
        );
      case 4:
        return (
          <div className="container-fluid">
            <MemoReferrel patientId={patientId} appointmentId={appointmentId} />
          </div>
        );
      default:
        return <div>Invalid Step</div>;
    }
  };

  function renderNextButton(_activeStep) {
    return (
      <>
        {_activeStep == "4" ? (
          <button
            className="btn save-btn"
            variant="contained"
            color="primary"
            onClick={(event) => handleConfirmation(event, appointmentId)}
          >
            Send Memo
          </button>
        ) : (
          <button
            className="btn save-btn"
            variant="contained"
            color="primary"
            onClick={() => handleNextCall()}
          >
            Next
          </button>
        )}
      </>
    );
  }

  function handleNextCall() {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  }

  function handleActiveTab() {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  }

  const handleConfirmation = (event, _appointmentID) => {
    toast(<ToastConfirmation prop2={handleConfirm} prop3={handleCancel} />, {
      position: toast.POSITION.TOP_CENTER,
      closeButton: false,
    });
  };

  const handleConfirm = async (event) => {
    toast.dismiss();
    // Perform the action
    setLoading(true);
    await handleSendMemo();
    setLoading(false);
  };

  const handleCancel = () => {
    toast.dismiss();
  };

  async function handleSendMemo() {
    try {
      setLoading(true);
      let requestData = {
        appointmentID: appointmentId,
      };
      let response = await servicePostSendMemo(requestData);
      if (response.status) {
        setLoading(false);
        toast.success(response.message);
        closeModal();
      } else {
        setLoading(false);
        toast.error("Error while sending memo,please try again");
      }
    } catch (e) {
      setLoading(false);
      return toast.error("Error while sending memo,please try again");
    }
  }

  return (
    <>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <div className="col-12">
          <div>
            <div className="col-md-12">
              <div className="">
                <div className="modal_tab__btn__box__clinic">
                  <button
                    onClick={() => {
                      setActiveStep(0);
                    }}
                    className={
                      activeStep == "0"
                        ? "modal_btn btn__tab__select_clinic active"
                        : "modal_btn btn__tab__select_clinic"
                    }
                  >
                    Examination
                  </button>
                  &nbsp;
                  <button
                    onClick={() => {
                      setActiveStep(1);
                    }}
                    className={
                      activeStep == "1"
                        ? "modal_btn btn__tab__select_clinic active"
                        : "modal_btn btn__tab__select_clinic"
                    }
                  >
                    Diagnosis
                  </button>
                  &nbsp; &nbsp;
                  <button
                    onClick={() => {
                      setActiveStep(2);
                    }}
                    className={
                      activeStep == "2"
                        ? "modal_btn btn__tab__select_clinic active"
                        : "modal_btn btn__tab__select_clinic"
                    }
                  >
                    Treatment Plan
                  </button>
                  &nbsp;
                  <button
                    onClick={() => {
                      setActiveStep(3);
                    }}
                    className={
                      activeStep == "3"
                        ? "modal_btn btn__tab__select_clinic active"
                        : "modal_btn btn__tab__select_clinic"
                    }
                  >
                    Followup's
                  </button>
                  &nbsp;
                  <button
                    onClick={() => {
                      setActiveStep(4);
                    }}
                    className={
                      activeStep == "4"
                        ? "modal_btn btn__tab__select_clinic active"
                        : "modal_btn btn__tab__select_clinic"
                    }
                  >
                    Referral
                  </button>
                </div>
              </div>
              <div>{renderStepContent(activeStep)}</div>

              <div className="col-md-12 d-flex justify-content-between">
                {activeStep !== 0 && ( // Hide "Back" button for "Examination"
                  <button className="btn cancel-btn" onClick={handleBack}>
                    Back
                  </button>
                )}

                <div style={{ position: "absolute", right: 30 }}>
                  {renderNextButton(activeStep)}{" "}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default DoctorGenerateMemo;
