import { useAuth } from "../useAuth";
import { Redirect, Route } from "react-router-dom";
import React from "react";

export function VerifiedRoute({ redirectTo, children, ...rest }) {
    let { userData } = useAuth();

    return (
        <Route
            {...rest}
            render={({ location }) =>
                userData.verified ? (
                    children
                ) : (
                    <Redirect
                        to={{
                            pathname: redirectTo,
                            state: { from: location }
                        }}
                    />
                )

            }
        />
    );
}