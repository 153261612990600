import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useParams, useHistory } from "react-router-dom";

// Sevices
import { getDoctorDetails } from "../../../services/admin/serviceDoctor";
import { formatDate } from "../../../components/FormatDate";

const View = () => {
  const { uid } = useParams();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [userDetails, setDetails] = useState(null);
  const [isValueLoded, valueLoded] = useState(false);

  //------------------------- FUCNCTIONS ------------------
  const goBack = () => {
    history.goBack();
  };

  useEffect(() => {
    if (userDetails == null) {
      fetchData();
      //valueLoded(false)
    } else {
      valueLoded(true);
    }
  }, [userDetails]);

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await getDoctorDetails(uid);
      setLoading(false);
      if (response.status == 0) {
        toast.error(response.message);
        setLoading(false);
      }
      if (response.status == 1) {
        // let _data = response.data;
        // _data.clinics = response.data.clinics.map((doctor) => {
        //     return doctor;
        // });

        setDetails(response.data);
      }
    } catch (error) {
      setLoading(false);
      toast.error(error);
    }
  };

  // ---------------------------------- Render Html
  return userDetails ? (
    <div className="container-fluid mt-5">
      <div className="form__box__container">
        <h3 className="head__weekly__report">View Doctor</h3>
        <div className="row mt-3">
          <div className="col-md-6">
            <div class="mb-2">
              <label for="" class="form-label cust__label__txt">
                First Name
              </label>
              <label for="" class="form-label">
                {userDetails.user.firstName || "N/A"}
              </label>
            </div>
          </div>
          <div className="col-md-6">
            <div class="mb-2">
              <label for="" class="form-label cust__label__txt">
                Last Name
              </label>
              <label for="" class="form-label">
                {userDetails.user.lastName || "N/A"}
              </label>
            </div>
          </div>
          <div className="col-md-6">
            <div class="mb-2">
              <label for="" class="form-label cust__label__txt">
                Email
              </label>
              <label for="" class="form-label">
                {userDetails.user.email || "N/A"}
              </label>
            </div>
          </div>
          <div className="col-md-6">
            <div class="mb-2">
              <label for="" class="form-label cust__label__txt">
                Contact Number
              </label>
              <label for="" class="form-label">
                {userDetails.user.contactNumber || "N/A"}
              </label>
            </div>
          </div>
          <div className="col-md-6">
            <div class="mb-2">
              <label for="" class="form-label cust__label__txt">
                Gender
              </label>
              <label for="" class="form-label">
                {userDetails.user.gender || "N/A"}
              </label>
            </div>
          </div>
          <div className="col-md-6">
            <div class="mb-2">
              <label for="" class="form-label cust__label__txt">
                Date Of Birth
              </label>
              <label for="" class="form-label">
                {formatDate(userDetails.user?.dob) || "N/A"}
              </label>
            </div>
          </div>
          <div className="col-md-6">
            <div class="mb-2">
              <label for="" class="form-label cust__label__txt">
                Age
              </label>
              <label for="" class="form-label">
                {userDetails.user.age || "N/A"}
              </label>
            </div>
          </div>

          <div className="col-md-6">
            <div class="mb-2">
              <label for="" class="form-label cust__label__txt">
                Education
              </label>
              <label for="" class="form-label">
                {userDetails.education || "N/A"}
              </label>
            </div>
          </div>
          <div className="col-md-6">
            <div class="mb-2">
              <label for="" class="form-label cust__label__txt">
                Experience (In Years)
              </label>
              <label for="" class="form-label">
                {userDetails.experinceInYears || "N/A"}
              </label>
            </div>
          </div>
          <div className="col-md-6">
            <div class="mb-2">
              <label for="" class="form-label cust__label__txt">
                Specialization
              </label>

              {userDetails?.specialities.length > 0
                ? userDetails?.specialities?.map((item, index) => (
                    <label for="" class="form-label">
                      {(index != 0 ? ", " : "") + item.masterSpeciality.name}
                    </label>
                  ))
                : "N/A"}
            </div>
          </div>

          <div className="col-md-6">
            <div class="mb-2">
              <label for="" class="form-label cust__label__txt">
                Clinic Name
              </label>
              {userDetails.clinics.length > 0
                ? userDetails?.clinics?.map((item, index) => (
                    <label for="" class="form-label">
                      {(index != 0 ? ", " : "") + item.clinic.name}
                    </label>
                  ))
                : "N/A"}
            </div>
          </div>

          <div className="col-md-6">
            <div class="mb-2">
              <label for="" class="form-label cust__label__txt">
                Documents
              </label>
              {userDetails?.user?.documents.length > 0
                ? userDetails?.user?.documents?.map((item, index) => (
                    <label for="" class="form-label">
                      <a href={item?.documentUrl} target="_blank">
                        {(index != 0 ? ", " : "") + item?.name}
                      </a>
                    </label>
                  ))
                : "N/A"}
            </div>
          </div>
          {/* <div className='col-md-6'>
                            <div class="mb-2">
                                <label for="" class="form-label cust__label__txt">Address line 1</label>
                                <label for="" class="form-label">{userDetails?.clinics[0]?.addressLine1}</label>
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div class="mb-2">
                                <label for="" class="form-label cust__label__txt">Landmark</label>
                                <label for="" class="form-label">{userDetails?.clinics[0]?.landmark}</label>
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div class="mb-2">
                                <label for="" class="form-label cust__label__txt">City</label>
                                <label for="" class="form-label">{userDetails?.clinics[0]?.city}</label>
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div class="mb-2">
                                <label for="" class="form-label cust__label__txt">State</label>
                                <label for="" class="form-label">{userDetails?.clinics[0]?.state}</label>
                            </div>
                        </div> */}

          <div className="col-md-6">
            <div class="mb-2">
              <label for="" class="form-label cust__label__txt">
                About
              </label>
              <label for="" class="form-label">
                {userDetails.about || "N/A"}
              </label>
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-12 d-flex justify-content-between mt-3">
        <button className="btn cancel-btn" onClick={goBack}>
          Back
        </button>
      </div>
    </div>
  ) : (
    ""
  );
};
export default View;
