import React, { useState } from 'react';
import classnames from "classnames";
import Button from "./Button";
import SmallLoadingSpinner from "./SmallLoadingSpinner";
import { useForm } from "react-hook-form";
import { useAuth } from "../useAuth";
import { toast } from "react-toastify";
import { getMessageFromErrorCode } from "../common/constants";
import { useHistory, useParams } from 'react-router-dom';

const ResetPasswordForm = () => {
    const history = useHistory();
    const { register, handleSubmit, formState: { errors }, getValues, reset } = useForm();
    const [submitting, setSubmitting] = useState(false)
    const [error, setError] = useState("")
    const { resetUserPassword } = useAuth()
    const { credentialID } = useParams();

    async function onPasswordResetSubmit(data) {
        setError("")
        setSubmitting(true)

        try {
            await resetUserPassword(credentialID, data.newPassword, data.confirmNewPassword)
            toast.success("Password successfully updated!")
            history.replace("/");
            history.push("/");
        }
        catch (e) {
            setError(getMessageFromErrorCode(e.code, e.message))
            toast.error(e.message, { theme: "colored" });
        }

        reset()
        setSubmitting(false)

    }

    return (
        <form onSubmit={handleSubmit(onPasswordResetSubmit)} className="col-span-2">
                <div className="rounded-md shadow-sm -space-y-px">
                    <label className="form-label">
                        New Password
                    </label>
                    <input
                        name="new-password"
                        id="new-password"
                        type="password"
                        className={classnames("form-control cust__form",
                            {
                                'border-red-500': errors.newPassword
                            })}
                        {...register("newPassword", {
                            required: "A new password is required",
                            minLength: { value: 6, message: "Minimum password length is 6 characters." }
                        })}
                    />
                    {errors.newPassword &&
                        <p className="block text-sm font-medium text-red-700 mt-1 mb-1">{errors.newPassword.message}</p>}
                </div>
                <div className="rounded-md shadow-sm -space-y-px">
                    <label className="form-label">
                        Confirm Password
                    </label>
                    <input
                        name="confirm-new-password"
                        id="confirm-new-password"
                        type="password"
                        className={classnames("form-control cust__form",
                            {
                                'border-red-500': errors.confirmNewPassword
                            })}
                        {...register("confirmNewPassword", {
                            required: "Confirmation of new password is required",
                            validate: value => value === getValues().newPassword || "The passwords do not match"
                        })}
                    />
                    {errors.confirmNewPassword &&
                        <p className="block text-sm font-medium text-red-700 mt-1 mb-1">{errors.confirmNewPassword.message}</p>}
                </div>
                <div className="col-span-12">

                    {error && <p className="block text-sm font-medium text-red-700 mt-1 mb-1">{error}</p>}
                </div>

                <div className="col-md-12 mt-3 text-center">
                    <Button
                        type="submit"
                        disabled={submitting}>{submitting &&
                            <SmallLoadingSpinner className="h-5 w-5 mr-2 -ml-1" />}Update</Button>
                </div>
        </form>
    );
};

export default ResetPasswordForm;