import React, { useState } from "react";
import classnames from "classnames";
import Button from "./Button";
import SmallLoadingSpinner from "./SmallLoadingSpinner";
import { useForm } from "react-hook-form";
import { useAuth } from "../useAuth";
import { toast } from "react-toastify";
import { getMessageFromErrorCode } from "../common/constants";

const ChangePasswordForm = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    reset,
  } = useForm();
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState("");
  const { updateUserPassword } = useAuth();

  async function onPasswordChangeSubmit(data) {
    setError("");
    setSubmitting(true);

    try {
      if (data.currentPassword == data.newPassword) {
        toast.error("Old password and new password should not be same.", {
          theme: "colored",
        });
        reset();
        setSubmitting(false);
        return;
      }
      await updateUserPassword(
        data.currentPassword,
        data.newPassword,
        data.confirmNewPassword
      );
      toast.success("Password successfully updated!");
    } catch (e) {
      setError(getMessageFromErrorCode(e.code, e.message));
      toast.error(e.message, { theme: "colored" });
    }

    reset();
    setSubmitting(false);
  }

  return (
    <form
      onSubmit={handleSubmit(onPasswordChangeSubmit)}
      className="col-span-2"
    >
      <div className="row">
        <div className="col-md-4">
          <label className="form-label">Current Password</label>
          <input
            name="current-password"
            id="current-password"
            type="password"
            autoComplete="current-password"
            className={classnames("form-control cust__form", {
              "border-red-500": errors.currentPassword,
            })}
            {...register("currentPassword", {
              required: "Current password is required",
            })}
          />
          {errors.currentPassword && (
            <p className="block text-sm font-medium text-red-700 mt-1 mb-1">
              {errors.currentPassword.message}
            </p>
          )}
        </div>
        <div className="col-md-4">
          <label className="form-label">New Password</label>
          <input
            name="new-password"
            id="new-password"
            type="password"
            className={classnames("form-control cust__form", {
              "border-red-500": errors.newPassword,
            })}
            {...register("newPassword", {
              required: "A new password is required",
              pattern: {
                value:
                  /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*\W)(?!.* ).{8,16}$/,
                message:
                  "Password must be 8-16 characters, with at least one uppercase letter, one lowercase letter, one digit, one special character, and no spaces.",
              },
            })}
          />
          {errors.newPassword && (
            <p className="block text-sm font-medium text-red-700 mt-1 mb-1">
              {errors.newPassword.message}
            </p>
          )}
        </div>
        <div className="col-md-4">
          <label className="form-label">Confirm New Password</label>
          <input
            name="confirm-new-password"
            id="confirm-new-password"
            type="password"
            className={classnames("form-control cust__form", {
              "border-red-500": errors.confirmNewPassword,
            })}
            {...register("confirmNewPassword", {
              required: "Confirmation of new password is required",
              validate: (value) =>
                value === getValues().newPassword ||
                "The passwords do not match",
            })}
          />
          {errors.confirmNewPassword && (
            <p className="block text-sm font-medium text-red-700 mt-1 mb-1">
              {errors.confirmNewPassword.message}
            </p>
          )}
        </div>
        <div className="col-span-12">
          {error && (
            <p className="block text-sm font-medium text-red-700 mt-1 mb-1">
              {error}
            </p>
          )}
        </div>

        <div className="col-md-12 mt-3">
          <Button type="submit" disabled={submitting}>
            {submitting && (
              <SmallLoadingSpinner className="h-5 w-5 mr-2 -ml-1" />
            )}
            Update
          </Button>
        </div>
      </div>
    </form>
  );
};

export default ChangePasswordForm;
