export function formatDate(inputDate) {
    let formattedDate = inputDate?.replace(/\//g, "-");
    const date = new Date(formattedDate);
    // Formatting options
    let options = { year: 'numeric', month: 'long', day: 'numeric' };
    formattedDate = date.toLocaleDateString('en-US', {
        month: '2-digit',
        day: '2-digit',
        year: 'numeric'
    });

    return formattedDate;
}