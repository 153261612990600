import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useParams, useHistory } from "react-router-dom";
// import { serviceGetDoctorAppointmentsById } from "../../../services/apiService";

const DoctorMemo = () => {
  return <div>
    <h5 className="doctor__memo__heading">Coming Soon...</h5>
  </div>;
}
export default DoctorMemo;
