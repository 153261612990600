const apiCall = require("../../Api");

async function getAppointmentList(page, search = "") {
  let _response = await apiCall.getData(
    `/api/V1/doctor/appointments?page=${page}&search=${search}`
  );
  return _response;
}

export { getAppointmentList };
