import React, { useEffect, useState } from "react";
import Table from "../../components/table/Table";
import TableHeader from "../../components/table/TableHeader";
import TableCell from "../../components/table/TableCell";
import TableBody from "../../components/table/TableBody";
import TableRow from "../../components/table/TableRow";
import TableFooter from "../../components/table/TableFooter";
import TableContainer from "../../components/table/TableContainer";
import LoadingDots from "../../components/LoadingDots";
import ToastConfirmation from "../../components/toastrConfirmation";
import moment from "moment";
import Button from "../../components/Button";
import { Link, useRouteMatch } from "react-router-dom";
import DefaultAvatar from "../../components/DefaultAvatar";
import Pagination from "../../components/Pagination";
import { axiosInstance } from "../../common/constants";
import SectionTitle from "../../components/SectionTitle";
import { ToastContainer, toast } from "react-toastify";
import { ReactSearchAutocomplete } from "react-search-autocomplete";

// Sevices
import {
  getReviewList,
  hideReview,
  getDoctorsList,
  toggleReview,
} from "../../services/admin/serviceAdmin";
import { formatDate } from "../../components/FormatDate";

const List = () => {
  const [loading, setLoading] = useState(true);
  const [dataTable, setDataTable] = useState(true);
  const [_doctors, setDoctors] = useState({});
  const [pageTable, setPageTable] = useState(0);
  const [totalResults, setTotalResults] = useState(0);
  const [selectedValues, setSelectedValues] = useState([]);
  const [item, setItem] = useState({});
  const [doctorId, setDoctorId] = useState("");
  const [keyword, setKeyword] = useState("");

  let { url } = useRouteMatch();

  const resultsPerPage = 10;

  function onPageChangeTable(p) {
    const skip = (p - 1) * 10;
    setPageTable(skip);
  }

  useEffect(() => {
    fetchData();
    fetchDoctors();
  }, [pageTable]);

  async function fetchDoctors(keywords = "") {
    try {
      const res = await getDoctorsList(keywords);

      setLoading(false);
      if (res.status == 0) {
        toast.error(res.message);
      }

      if (res.status == 1) {
        let _items = [];
        res.data?.doctors.forEach((element, index) => {
          if (element) {
            _items.push({
              id: element.uid,
              name: element?.user.firstName + " " + element?.user.lastName,
            });
          }
        });
        setItem(_items);
      }
    } catch (error) {
      toast.error(error.message);
      setLoading(false);
    }
  }

  async function fetchData(doctorId, keyword = "") {
    try {
      const res = await getReviewList(
        pageTable,
        resultsPerPage,
        doctorId,
        keyword
      );
      setLoading(false);
      if (res.status == 0) {
        toast.error(res.message);
      }

      if (res.status == 1) {
        if (res.data?.reviews) {
          let _data = res.data?.reviews;
          res.data?.reviews.forEach((element, index) => {
            let rating = 0;

            if (element?.ratings.length != 0) {
              var _elementLenght = element?.ratings.length;
              for (let i = 0; i < element?.ratings.length; i++) {
                rating += element?.ratings[i].rating;
              }
              let _average = rating / _elementLenght;
              _data[index].ratings = _average;
            } else {
              _data[index].ratings = 0;
            }
          });
        }

        setDataTable(res.data.reviews);
        setTotalResults(res.data.count);
        setLoading(false);
      }
    } catch (error) {
      toast.error(error.message);
      setLoading(false);
    }
  }

  // ----------------------------------- Delte user
  const handleConfirmation = (id, status) => {
    const data = {
      id,
      status,
    };

    toast(
      <ToastConfirmation
        prop1={JSON.stringify(data)}
        prop2={handleConfirm}
        prop3={handleCancel}
      />,
      { position: toast.POSITION.TOP_CENTER, closeButton: false }
    );
  };

  const handleConfirm = async (event) => {
    toast.dismiss();
    const dataset = JSON.parse(event.target.dataset.userid);
    const _userId = dataset.id;
    const _status = dataset.status;

    // Perform the action
    setLoading(true);
    let _data = {
      reviewID: _userId,
      status: parseInt(_status) == 1 ? 0 : 1,
    };

    const response = await toggleReview(_data);

    if (response.status == 0) {
      toast.error(response.message);
    }
    if (response.status == 1) {
      toast.success(response.message);
      fetchData();
    }
    setLoading(false);
  };

  const handleCancel = () => {
    toast.dismiss();
  };

  function displayStarRating(ratingValue) {
    const maxRating = 5;
    const fullStar = "★";
    const emptyStar = "☆";

    const fullStars = Math.round(ratingValue);
    const emptyStars = maxRating - fullStars;

    const stars = fullStar.repeat(fullStars) + emptyStar.repeat(emptyStars);

    return `${stars}`;
  }

  const formatResult = (item) => {
    return (
      <>
        {/* <span style={{ display: 'block', textAlign: 'left' }}>id: {item.id}</span> */}
        <span style={{ display: "block", textAlign: "left" }}>{item.name}</span>
      </>
    );
  };
  const handleOnSelect = (item) => {
    setPageTable(0);
    let id = item.id.trim();
    setDoctorId(id);
    fetchData(id);
  };

  const onClear = () => {
    setDoctorId("");
    fetchData();
  };

  // ----------------------------------- Searh user
  const handleKeyUp = (event) => {
    setPageTable(0);
    let _keyword = event.target.value;
    fetchData(doctorId, _keyword);
  };
  //--------------------------------------------- RENDER HTML --------------------
  return (
    <>
      <SectionTitle>Patient Ratings </SectionTitle>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-4 p-2">
            <div class="mb-3">
              <input
                onKeyUpCapture={handleKeyUp}
                type="search"
                class="form-control cust__form"
                id=""
                placeholder="Search Review"
              />
            </div>
          </div>
          <div className="col-md-4 p-2">
            <div className="mb-3">
              <div class="mb-3">
                {/* Select List */}
                <ReactSearchAutocomplete
                  items={item}
                  //onSearch={handleOnSearch}
                  //onHover={handleOnHover}
                  onSelect={handleOnSelect}
                  // onFocus={handleOnFocus}
                  onClear={onClear}
                  autoFocus
                  formatResult={formatResult}
                  placeholder="Select Doctor"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {!loading ? (
        <TableContainer className="form__box__container mb-8">
          <Table>
            <TableHeader>
              <tr>
                <TableCell>Patient</TableCell>
                <TableCell>Rating</TableCell>
                <TableCell>Review</TableCell>
                <TableCell>Date</TableCell>
                <TableCell>Action</TableCell>
              </tr>
            </TableHeader>
            <TableBody>
              {dataTable.length > 0
                ? dataTable.map((value, i) => (
                    <TableRow key={i}>
                      <TableCell>
                        <div>
                          <p className="font-semibold">{`${value.patient?.user.firstName} ${value.patient?.user.lastName}`}</p>
                        </div>
                      </TableCell>
                      <TableCell>
                        <p className="font-semibold">
                          {displayStarRating(value.ratings)}
                        </p>
                      </TableCell>
                      <TableCell>
                        <div className="flex items-center text-sm">
                          <p className="font-semibold">{value.review}</p>
                        </div>
                      </TableCell>
                      <TableCell>
                        <div className="flex items-center text-sm">
                          <p className="font-semibold">
                            {formatDate(value.createdAt)}
                          </p>
                        </div>
                      </TableCell>
                      <TableCell>
                        <Button
                          id={value.id}
                          status={value.status}
                          onClick={() =>
                            handleConfirmation(value.id, value.status)
                          }
                        >
                          {" "}
                          {parseInt(value.status) == 0 ? "Unhide" : "Hide"}{" "}
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))
                : ""}
            </TableBody>
          </Table>
          {totalResults > 0 && (
            <TableFooter>
              <Pagination
                totalResults={totalResults}
                resultsPerPage={resultsPerPage}
                onChange={onPageChangeTable}
                label="Table navigation"
              />
            </TableFooter>
          )}
        </TableContainer>
      ) : (
        <LoadingDots />
      )}
    </>
  );
};

export default List;
