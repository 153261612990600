import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { toast } from "react-toastify";
import { getDoctorOverAllRating } from "../../services/doctor/services";
import { serviceUpdateReview } from "../../services/apiService";
import RatingStars from "../ratings/RatingStars";
import { useParams, useHistory } from "react-router-dom";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

// Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
// Modal.setAppElement('#yourAppElement');

const PatientEditReview = ({
  modalIsOpen,
  closeModal,
  reviewId,
  reviewMessage,
}) => {
  const [overallRating, setOverallRating] = useState({});
  const [rate, setRate] = useState(0);
  const [updatedReview, setUpdatedReview] = useState(reviewMessage);
  const history = useHistory();

  useEffect(() => {
    setUpdatedReview(reviewMessage);
  }, []);

  const updateReview = async () => {
    try {
      if (updatedReview == "") {
        return toast.warning("Review cannot be empty");
      }
      let data = {
        reviewID: reviewId,
        review: updatedReview,
      };
      const response = await serviceUpdateReview(data);
      if (response.status == 0) {
        toast.error(response.message);
        closeModal();
      }
      if (response.status == 1) {
        toast.success(response.message);
        closeModal();
      }
    } catch (error) {
      toast.error(error);
    }
  };

  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel="Edit Review"
    >
      <div id="doctor-modal">
        <div className="modal-header">
          <h5 className="modal-title">Edit Review</h5>
          <button className="close-button" onClick={closeModal}>
            X
          </button>
        </div>

        <div className="modal-body">
          <textarea
            className="form-control"
            defaultValue={reviewMessage}
            cols="50"
            rows="25px"
            maxLength={500}
            required={true}
            onChange={(e) => setUpdatedReview(e.target.value)}
          ></textarea>
          <br></br>
          <button onClick={() => updateReview()} className="btn btn__view mt-0">
            Update
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default PatientEditReview;
