const apiCall = require('../../Api')

const create = async (data) => {
    try {
        let _response = await apiCall.postFormData('/api/V1/admin/add-specialist', data)
        return _response
    } catch (error) {
        console.error('Error posting data:', error);
        throw error;
    }
};

const getDetails = async (uid) => {
    try {
        let _response = await apiCall.getData('/api/V1/admin/get-specialist-details/' + uid)
        return _response
    } catch (error) {
        console.error('Error posting data:', error);
        throw error;
    }
};

const edit = async (data) => {
    try {
        let _response = await apiCall.postFormData('/api/V1/admin/edit-speciatlist', data)
        return _response
    } catch (error) {
        console.error('Error posting data:', error);
        throw error;
    }
};

const getList = async (skip, take, search = "") => {
    try {
        let _response = await apiCall.getData('/api/V1/admin/get-specialist?skip=' + skip + '&take=' + take + '&search=' + search)
        return _response
    } catch (error) {
        console.error('Error posting data:', error);
        throw error;
    }
};

const chageStatus = async (data) => {
    try {
        let _response = await apiCall.postRawJsonData('/api/V1/admin/change-specialist-status', data)
        return _response
    } catch (error) {
        console.error('Error posting data:', error);
        throw error;
    }
};


export {
    create,
    getDetails,
    edit,
    getList,
    chageStatus
}