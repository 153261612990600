import React, { useEffect, useState } from "react";
import { Link, Route, Switch, useRouteMatch, useHistory } from "react-router-dom";
import LoadingDots from "../../components/LoadingDots";
import { useAuth } from "../../useAuth";
import { axiosInstance, userType } from "../../common/constants";
import { AppShell, Header, Navbar } from "@mantine/core";
import SidenavItems from "../../dashboard/sidenavigation/SidenavItems";
import TopNavigation from "../../dashboard/topnavigation/TopNavigation";
import { VerifiedRoute } from "../../common/router-functions";
import ClinicUnverifiedError from "./clinicUnverifiedError";
import ClinicHome from "./clinicHome";
import ClinicLogin from "./clinicLogin";
import ClinicDoctors from "./clinicDoctors";
import ClinicInvitationsList from "./ClinicInvitationsList";
import ClinicProfile from "./clinicProfile";
import ClinicNotification from "./ClinicNotification";
import ClinicSetting from "./clinicSetting";

const Clinic = () => {
  let { path } = useRouteMatch();
  const { userData, setUserData } = useAuth();
  const [loading, setLoading] = useState(true);
  const [isValidRole, setIsValidRole] = useState(false);
  const [opened, setOpened] = useState(false);
  const { signout, user } = useAuth();
  const history = useHistory();

  async function getClinicData() {
    try {
      setLoading(true);
      const res = await axiosInstance.get("/getUserDetails");
      if (res.data != null) {
        setUserData({
          role: res.data.userRoles[0]["role"],
          email: res.data.email,
          firstName: res.data.firstName,
          lastName: res.data.lastName,
          avatarImageUrl: res.data.avatarImageUrl,
          contactNumber: res.data.contactNumber,
          ...res.data.clinic,
        });
        setIsValidRole(res.data.userRoles[0]['role'] === userType.CLINIC_ADMIN);
      } else {
        setUserData(null);
        setIsValidRole(false);
      }
      setLoading(false);
    } catch (e) {
      console.log(e.message);
      setUserData(null);
      setIsValidRole(false);
      setLoading(false);
    }
  }

  async function handleLogout(e) {
    e.preventDefault();

    try {
      localStorage.clear();
      await signout();
      history.push("/");

    } catch {
      console.log("Failed to log out");
    }
  }

  useEffect(() => {
    if (!userData) {
      getClinicData();
    } else {
      setIsValidRole(userData.role === userType.CLINIC_ADMIN);
      setLoading(false);
    }
  }, []);

  return (
    <AppShell
      padding="lg"
      fixed
      navbarOffsetBreakpoint="sm"
      navbar={
        isValidRole ?
          <Navbar
            hiddenBreakpoint="sm"
            hidden={!opened}
            width={{ sm: 200, lg: 300 }}
            classNames={{ root: "font-sans" }}
          >
            <SidenavItems
              isValidRole={isValidRole}
              onNavItemClick={() => setOpened(false)}
            />
          </Navbar> : ""
      }
      header={
        isValidRole ?
          <Header height={60} p="xs" classNames={{ root: "font-sans" }}>
            <TopNavigation opened={opened} setOpened={setOpened} />
          </Header> : ""
      }
      styles={(theme) => ({
        main: {
          backgroundColor:
            theme.colorScheme === "dark"
              ? theme.colors.dark[8]
              : theme.colors.gray[0],
        },
      })}
    >
      {loading ? (
        <LoadingDots />
      ) : isValidRole ? (
        <Switch>
          <Route path={`${path}`} exact={true}>
            <ClinicHome onSubmitVerification={getClinicData} />
          </Route>

          <VerifiedRoute path={`${path}/clinicLogin`}>
            <ClinicLogin />
          </VerifiedRoute>

          <VerifiedRoute
            path={`${path}/clinicHome`}
            redirectTo={`${path}/unverified`}
          >
            <clinicHome />
          </VerifiedRoute>

          <VerifiedRoute
            exact
            path={`${path}/doctors`}
            redirectTo={`${path}/unverified`}
          >
            <ClinicDoctors />
          </VerifiedRoute>

          <VerifiedRoute
            exact
            path={`${path}/invitations`}
            redirectTo={`${path}/unverified`}
          >
            <ClinicInvitationsList />
          </VerifiedRoute>

          <VerifiedRoute
            exact
            path={`${path}/accountSettings`}
            redirectTo={`${path}/unverified`}
          >
            <ClinicProfile />
          </VerifiedRoute>

          <VerifiedRoute
            exact
            path={`${path}/settings`}
            redirectTo={`${path}/unverified`}
          >
            <ClinicSetting />
          </VerifiedRoute>

          <Route
            path={`${path}/notifications`}
            redirectTo={`${path}/unverified`}
          >
            <ClinicNotification />
          </Route>

          <Route path={`${path}/unverified`}>
            <ClinicUnverifiedError />
          </Route>
        </Switch>
      ) : (
        <>
          <div className="login-container">
            <div className="login-centered-div">
              <span style={{ marginRight: '10px' }}>Your account does not have Clinic privileges. Are you perhaps trying
                to login as a</span>
              <Link onClick={handleLogout} className="text-indigo-600">
                Patient or Doctor
              </Link>
              ?
            </div>
          </div>
        </>
      )}
    </AppShell>
  );
};

export default Clinic;
