import HelpdeskPlaceHolder from "../../img/Helpdesk_Placeholder.svg";
import "../../css/patient.helpdesk.css";
import FileDropPlaceholder from "../../img/File_Drop.svg";
import { useEffect, useState } from "react";
import { handleDrag } from "../../helper/fileUploadDrag.helper";
import { serviceListTicketCategory } from "../../services/apiService";
import { toast } from "react-toastify";

const PatientHelpdesk = () => {
  const [category, setCategory] = useState(null);
  const [files, setFiles] = useState([]);
  useEffect(() => {
    listTicketCategory();
    let dropzone = document.getElementById("drop_zone");
    let fileSelector = document.getElementById("upload");
    if (dropzone) {
      handleDrag(dropzone);
    }
    if (fileSelector) {
      handleFilesSelect(fileSelector);
    }
  }, []);

  function handleFilesSelect(el) {
    el.addEventListener("change", (e) => {
      let fileArr = [];
      if (e.target.files) {
        for (const file of e.target.files) {
          fileArr.push(file);
          setFiles(fileArr);
        }
      }
    });
  }

  async function listTicketCategory() {
    try {
      const response = await serviceListTicketCategory("PATIENT");
      if (response && response?.status == 1) {
        setCategory(response?.data);
      } else {
        throw {
          message: "Failed to list ticket category!",
        };
      }
    } catch (error) {
      toast.error(error?.message);
    }
  }

  return (
    <>
      {/* Header */}
      <div>
        <h1>Create Ticket</h1>
      </div>
      {/* End Of Header */}
      <div className="row">
        {/* Form */}
        <div className="col-6">
          <form>
            <div class="form-group mt-3">
              <label for="subject">Subject/Issue</label>
              <input
                type="text"
                class="form-control"
                id="subject"
                placeholder="Subject / Issue"
              />
            </div>
            <div class="form-group mt-3">
              <label for="description">Description</label>
              <textarea
                class="form-control"
                id="description"
                rows="3"
              ></textarea>
            </div>
            <div class="form-group mt-3">
              <label for="category">Category</label>
              <select
                class="form-select"
                aria-label="Default select example"
                id="category"
              >
                <option selected>Select Category</option>
                {category && category?.length > 0
                  ? category?.map((category) => (
                      <option value={category?.id}>{category?.name}</option>
                    ))
                  : ""}
              </select>
            </div>
            <div class="form-group mt-3">
              <label for="category" className="w-100">
                Priority
              </label>
              <div class="form-check form-check-inline">
                <input
                  class="form-check-input"
                  type="radio"
                  name="inlineRadioOptions"
                  id="inlineRadio1"
                  value="High"
                />
                <label class="form-check-label" for="inlineRadio1">
                  High
                </label>
              </div>
              <div class="form-check form-check-inline">
                <input
                  class="form-check-input"
                  type="radio"
                  name="inlineRadioOptions"
                  id="inlineRadio2"
                  value="Mid"
                />
                <label class="form-check-label" for="inlineRadio2">
                  Mid
                </label>
              </div>
              <div class="form-check form-check-inline">
                <input
                  class="form-check-input"
                  type="radio"
                  name="inlineRadioOptions"
                  id="inlineRadio3"
                  value="Low"
                />
                <label class="form-check-label" for="inlineRadio3">
                  Low
                </label>
              </div>
            </div>
            <div class="form-group mt-3">
              <label for="category">Attachment</label>
              <div className="attachment">
                <div
                  className="wrapper d-flex flex-column justify-content-center align-items-center"
                  id="drop_zone"
                >
                  <input type="file" id="upload" multiple hidden />
                  <img src={FileDropPlaceholder} />
                  <label>Drag and drop files here or</label>
                  <label className="upload-label" for="upload">
                    Browse File
                  </label>
                  <div className="fileNames d-flex flex-column w-100 ps-3">
                    {files && files.length > 0
                      ? files.map((file) => <label>{file?.name}</label>)
                      : ""}
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group mt-3">
              <button type="button" className="btn btn-primary btn-lg">
                Submit
              </button>
            </div>
          </form>
        </div>
        {/* End Of Form */}
        {/* Image Placeholder */}
        <div className="col-6">
          <div
            style={{
              height: "80vh",
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src={HelpdeskPlaceHolder}
              style={{ aspectRatio: 1, height: "100%" }}
            />
          </div>
        </div>
        {/* End Image Placeholder */}
      </div>
    </>
  );
};

export default PatientHelpdesk;
