import React, { useState } from "react";
import NoRecordImg from "./../img/healthlink_logo.svg"

const RemoteImage = ({ src, alt }) => {
  const [imageLoaded, setImageLoaded] = useState(false);
  const [imageError, setImageError] = useState(false);

  const handleImageLoad = () => {
    setTimeout(() => setImageLoaded(true), 500); // Simulate a 1-second delay
  };

  const handleImageError = () => {
    setImageError(true);
  };

  return (
    <div>
      {imageError ? (
        <img src={NoRecordImg} 
        style={{display:"block",height:'60%',width:"60%"}}/>
      ) : (
        <img
          src={src}
          alt={alt}
          onLoad={handleImageLoad}
          onError={handleImageError}
          style={{ height:'60%',width:"60%",display: imageLoaded ? "block" : "none" }}
        />
      )}
    </div>
  );
};

export default RemoteImage;
