import React, { Fragment, useEffect, useState } from "react";
import { useToggle } from "../provider/context";
import { Menu, Transition } from "@headlessui/react";
import classNames from "classnames";
import { MenuIcon } from "@heroicons/react/solid";
import { useAuth } from "../../useAuth";
import { useHistory, useRouteMatch } from "react-router-dom";
import DefaultAvatar from "../../components/DefaultAvatar";
import DefaultHeaderIcon from "../../components/DefaultHeaderIcon";
import { Link } from "react-router-dom";
import { Burger, MediaQuery } from "@mantine/core";
import SidenavHeader from "../sidenavigation/header";
import logo from "../../healthlink_logo.svg";
import { userType } from "../../common/constants";
import BellIcon from "./../sidenavigation/icons/bell";
import {
  serviceGetNotificationCount,
  serviceUpdateNotificationStatus,
} from "../../services/apiService";
import { toast } from "react-toastify";
import logoWhite from "../../healthlink_logo_white-svg.png";

const frontend_base_url = window.location.origin;

export default function TopNavigation(props) {
  const { toggle } = useToggle();
  const { opened, setOpened } = props;
  const [avatarImageUrl, setAvatarImageUrl] = useState("");
  const [avatarLoading, setAvatarLoading] = useState(true);
  const [loading, setLoading] = useState(true);
  const [notificationCount, setNotificationCount] = useState(0);

  const { signout, user } = useAuth();
  const history = useHistory();
  const { userData } = useAuth();
  let { path } = useRouteMatch();

  function LogoDecoration() {
    switch (userData?.role) {
      case userType.DOCTOR:
        return <>{<sub className="text-base font-bold">Doctor</sub>}</>;
      case userType.PATIENT:
        return <>{<sub className="text-base font-bold">Patient</sub>}</>;
      case userType.ADMIN:
        return <>{<sub className="text-base font-bold">Admin</sub>}</>;
    }
  }

  useEffect(() => {
    if (userData) {
      setAvatarImageUrl(userData.avatarImageUrl);
    }
  }, [userData]);

  useEffect(() => {
    const specificURL = frontend_base_url + path + "/notifications";

    if (window.location.href === specificURL) {
      // Run your code here when the URL matches the specific value

      updateNotificationStatus();
      setTimeout(() => {
        getUnreadNotificationCount();
      }, 1000);
    } else {
      getUnreadNotificationCount();
    }
  }, [window.location.href]);

  async function handleLogout(e) {
    e.preventDefault();

    try {
      localStorage.clear();
      await signout();
      history.push("/");
    } catch {
      console.log("Failed to log out");
    }
  }

  function getAccountSettingsPath() {
    return path + "/accountSettings";
  }

  async function getUnreadNotificationCount() {
    try {
      setLoading(true);
      let response = await serviceGetNotificationCount();
      if (response.status == 1) {
        setNotificationCount(response.data.count);
        setLoading(false);
      }
    } catch (error) {
      toast.error(error.message);
      setLoading(false);
    }
  }

  async function updateNotificationStatus() {
    try {
      setLoading(true);
      let data = {
        status: 1,
      };
      let response = await serviceUpdateNotificationStatus(data);
      if (response.status == 1) {
        setLoading(false);
        setNotificationCount(0);
      }
    } catch (error) {
      toast.error(error.message);
      setLoading(false);
    }
  }

  return (
    <div className="nav__home__box mb-3 shadow-bottom dark:bg-gray-800">
      <div className="flex h-full justify-center mx-auto px-1 sm:px-5 relative">
        <div className="flex items-center pl-1 relative w-full sm:pr-2 sm:ml-0 lg:max-w-68">
          <div className="flex h-full relative">
            <div className="flex items-center justify-center h-full relative">
              <MediaQuery largerThan="sm" styles={{ display: "none" }}>
                <Burger
                  opened={opened}
                  onClick={() => setOpened((o) => !o)}
                  size="sm"
                  mr="md"
                />
              </MediaQuery>
              <div>
                <img src={logoWhite} alt="logo" />
                {/* <span className="font-extrabold text-indigo-600 text-2xl">
                    HealthLink <LogoDecoration />
                  </span> */}
              </div>
            </div>
          </div>
        </div>
        <div className="inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
          <Menu as="div" className="ml-3 relative">
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              {userData?.role != "ADMIN" ? (
                <div style={{ margin: "10px" }}>
                  <Link to={path + "/notifications"}>
                    <BellIcon />
                    <div
                      style={{
                        position: "absolute",
                        backgroundColor: "red",
                        height: 20,
                        width: 20,
                        borderRadius: 20,
                        fontSize: 12,
                        color: "white",
                        textAlign: "center",
                        top: 3,
                        left: 20,
                      }}
                    >
                      {notificationCount}
                    </div>
                  </Link>
                </div>
              ) : (
                ""
              )}

              <Menu.Button className=" flex text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-1 focus:ring-offset-gray-700 focus:ring-white">
                <span className="sr-only">Open user menu</span>
                {avatarImageUrl ? (
                  <>
                    {avatarLoading && <DefaultAvatar />}
                    <img
                      src={avatarImageUrl}
                      onLoad={() => setAvatarLoading(false)}
                      className={classNames(
                        avatarLoading && "hidden",
                        "h-12 w-12 rounded-full"
                      )}
                      style={{
                        height: "3rem",
                        width: "3rem",
                        borderRadius: "50%",
                        maxWidth: "3rem",
                      }}
                      alt="Avatar"
                    />
                  </>
                ) : (
                  <DefaultHeaderIcon />
                )}
              </Menu.Button>
            </div>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg divide-y divide-gray-100 py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                <div className="px-4 py-3">
                  <p style={{ color: "black" }} className="text-sm leading-5">
                    Signed in as
                  </p>
                  <p className="text-sm font-medium leading-5 text-gray-900 truncate">
                    {user.email}
                  </p>
                </div>
                {userData?.role != "ADMIN" ? (
                  <div className="py-1">
                    <Menu.Item>
                      {({ active }) => (
                        <Link
                          to={getAccountSettingsPath()}
                          className={`${
                            active
                              ? "bg-gray-100 text-gray-900"
                              : "text-gray-700"
                          } flex justify-between w-full px-4 py-2 text-sm leading-5 text-left`}
                        >
                          Account settings
                        </Link>
                      )}
                    </Menu.Item>
                  </div>
                ) : (
                  ""
                )}

                <Menu.Item>
                  {({ active }) => (
                    <a
                      href="/"
                      onClick={handleLogout}
                      className={classNames(
                        active ? "bg-gray-100" : "",
                        "block px-4 py-2 text-sm text-gray-700"
                      )}
                    >
                      Sign out
                    </a>
                  )}
                </Menu.Item>
              </Menu.Items>
            </Transition>
          </Menu>
        </div>
      </div>
    </div>
  );
}
