import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

const DateInput = ({ selectableDates, onDateChange }) => {
  const [selectedDate, setSelectedDate] = useState(null);
  const [formattedSelectableDates, setFormatedSelectableDate] = useState([]);

  const filterDates = (date) => {
    return selectableDates.some(
      (selectableDate) => selectableDate.getTime() === date.getTime()
    );
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
    onDateChange(date);
  };

  function formatDate(date) {
    let formattedDate = date.replace(/\//g, "-");
    let dateObject = moment(String(formattedDate), "MM-DD-YYYY").toDate();
    return dateObject;
  }

  useEffect(() => {
    if (selectableDates.length != 0) {
      selectableDates.map((date) =>
        setFormatedSelectableDate((formattedSelectableDates) => [
          ...formattedSelectableDates,
          formatDate(date),
        ])
      );
    }
  }, []);

  const handleDateChangeRaw = (e) => {
    e.preventDefault();
  }

  return (
    <DatePicker
      minDate={new Date()}
      className="form-control cust__form"
      selected={selectedDate}
      onChange={handleDateChange}
      // includeDates={formattedSelectableDates}
      placeholderText="Select a date"
      onChangeRaw={handleDateChangeRaw}
    />
  );
};

export default DateInput;
