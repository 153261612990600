import {axiosInstance} from "./constants";


export async function acceptPatient(patientUid) {
    await axiosInstance.post("/doctor/acceptPatient", {
        patientUid: patientUid
    })
}

export async function rejectPatient(patientUid) {
    await axiosInstance.post("/doctor/rejectPatient", {
        patientUid: patientUid
    })
}

export async function acceptDoctor(doctorUid) {
    await axiosInstance.post("/patient/acceptDoctor", {
        doctorUid: doctorUid
    })
}

export async function rejectDoctor(doctorUid) {
    await axiosInstance.post("/patient/rejectDoctor", {
        doctorUid: doctorUid
    })
}