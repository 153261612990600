const apiCall = require('../../Api')

const getAdminList = async (skip, take, search = "", isVerified = "") => {
    try {
        let _response = await apiCall.getData('/api/V1/admin/clinic/list?skip=' + skip + '&take=' + take + '&search=' + search + '&isVerified=' + isVerified)
        return _response
    } catch (error) {
        console.error('Error posting data:', error);
        throw error;
    }
};

const getList = async (skip, take, search = "", isVerified = "") => {
    try {
        let _response = await apiCall.getData('/api/V1/clinic/list?skip=' + skip + '&take=' + take + '&search=' + search + '&isVerified=' + isVerified)
        return _response
    } catch (error) {
        console.error('Error posting data:', error);
        throw error;
    }
};

const getClinicDetails = async (uid) => {
    try {
        let _response = await apiCall.getData('/api/V1/clinic/details?uid=' + uid)
        return _response
    } catch (error) {
        console.error('Error posting data:', error);
        throw error;
    }
};

const verifyClinic = async (data, uid) => {
    try {
        let _response = await apiCall.postRawJsonData('/api/V1/clinic/verify-clinic', data)
        return _response
    } catch (error) {
        console.error('Error posting data:', error);
        throw error;
    }
};

const clinicStatus = async (data, uid) => {
    try {
        let _response = await apiCall.postRawJsonData('/api/V1/clinic/clinic-status', data)
        return _response
    } catch (error) {
        console.error('Error posting data:', error);
        throw error;
    }
};

export {
    getAdminList,
    getList,
    getClinicDetails,
    verifyClinic,
    clinicStatus
}