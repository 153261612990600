import React, { useState, useEffect, useRef } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import {
  servicePostBookAppointment,
  serviceGetDoctorAvailability,
  serviceGetDoctorTimeSlots,
  serviceListDoctorClinics,
  servicePatientGetClinicDoctors,
} from "../../services/apiService";
import { axiosInstance, userType } from "../../common/constants";
import { ToastContainer, toast } from "react-toastify";
import { useAuth } from "../../useAuth";
import { useFormik } from "formik";
import * as Yup from "yup";
import Pagination from "../../components/Pagination";
import DateInput from "../../components/DateInput";
import moment from "moment";
import LoadingSpinner from "../../components/LoadingSpinner";
import { capitalizeFirstChar } from "../../helper/utils";
import DoctorDetailsModel from "../../components/doctor/DoctorDetailsModel";
import UserPlaceholder from "../../img/User_placeholder.jpeg";
import DatePicker from "react-datepicker";

const PatientClinicBookAppointment = ({ clinicID }) => {
  const [formStep, setFormStep] = useState(1);
  const [page, setPage] = useState(0);
  const [take, setTake] = useState(10);
  const [search, setSearch] = useState("");
  const [doctorList, setDoctorList] = useState([]);
  const [selectedDoctor, setSelectedDoctor] = useState("");
  const [gender, setGeder] = useState("");
  const [selectableDates, setSelectableDates] = useState([]);
  const [timeSlotArray, setTimeSlotArray] = useState([]);
  const [selectedDate, setSelectedDate] = useState("");
  const [selectedTimeSlot, setSelectedTimeSlot] = useState("");
  const [totalCount, setTotalCount] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [selectedViewDoctor, setSelectedViewDoctor] = useState(null);
  const [isValueLoded, valueLoded] = useState(false);
  const [clinicList, setClinicList] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [userData, setUserData] = useState(null);
  const [selectedClinicID, setSelectedClinicID] = useState(null);
  const [consultationType, setConsulTationType] = useState("");
  const [isAvailableOnline, setIsAvailableOnline] = useState(false);
  const [datePicker, setDatePicker] = useState("Note: No available date");
  const [pageTable, setPageTable] = useState();
  const [searchDate, setSearchDate] = useState(new Date());
  const [toggleDoctorSearchLable, setToggleDoctorSearchLable] = useState(true);

  const searchValue = useRef();
  const history = useHistory();
  const params = useParams();
  let docID;

  const handleOptionChange = async (_doctorId) => {
    docID = _doctorId;
    handleTabChange("2", docID);
    setDatePicker("Note:Please select clinic ");
    setTimeSlotArray([]);
    setSelectedClinicID(params?.id || null);
    await getDoctorClinicList(docID);
    setSelectedDoctor(docID);
    setToggleDoctorSearchLable(false);
    // await getDoctorClinicList(_doctorId);
  };

  // Function to handle opening the modal and setting the selected doctor
  const openModal = (doctor) => {
    setSelectedViewDoctor(doctor);
    setShowModal(!showModal);
  };

  // Function to handle closing the modal
  const closeModal = () => {
    setShowModal(false);
  };

  useEffect(() => {
    if (userData == null) {
      getPatientData();
      valueLoded(false);
    } else {
      valueLoded(true);
    }
  }, [userData]);

  useEffect(() => {
    getDoctorList();
  }, [searchDate]);

  async function getPatientData() {
    try {
      setLoading(true);
      const res = await axiosInstance.get("/getUserDetails");
      if (res.data != null) {
        setUserData(res.data);
      } else {
        setUserData(null);
      }
      setLoading(false);
    } catch (e) {
      setUserData(null);
      setLoading(false);
    }
  }

  const handleGenderSelect = (event) => {
    setGeder(event.target.value);
  };

  const validationSchema = Yup.object({
    time: Yup.string().required("Time is required"),
    clinicID: Yup.string().required("Clinic is required"),
    // date: Yup.string().required("Date is required"),
  });
  const formik = useFormik({
    initialValues: {
      firstName: userData?.firstName,
      lastName: userData?.lastName,
      email: userData?.email,
      contactNumber: userData?.contactNumber,
      date: "",
      time: "",
      pastHistory: "",
      remark: "",
      gender: userData?.gender,
      age: userData?.age,
      clinicID: "",
      consultationType: "",
    },
    validationSchema,
    onSubmit: (values) => {
      webHandleSubmit(values);
    },
  });

  function calculate_age(dob) {
    if (dob) {
      var diff_ms = Date.now() - dob.getTime();
      var age_dt = new Date(diff_ms);
      return Math.abs(age_dt.getUTCFullYear() - 1970);
    } else {
      return 0;
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        getDoctorList();

        await getDoctorAvailability(selectedDoctor, clinicID);

        setSelectedClinicID(clinicID);
        formik.setFieldValue("clinicID", clinicID);

        formik.setFieldValue("firstName", userData?.firstName);
        formik.setFieldValue("lastName", userData?.lastName);
        formik.setFieldValue("age", userData?.age);
        formik.setFieldValue("gender", capitalizeFirstChar(userData?.gender));
        formik.setFieldValue("email", userData?.email);
        formik.setFieldValue("contactNumber", userData?.contactNumber);
        // formik.setFieldValue('age',(userData.age));
        formik.setFieldValue("date", selectedDate);
      } catch (error) {}
    };

    fetchData();
  }, [clinicID]);

  async function webHandleSubmit(_values) {
    try {
      const time = _values.time.split("-");
      setError("");
      setLoading(true);
      let data = {
        patientID: userData.uid,
        doctorID: selectedDoctor,
        firstName: _values.firstName,
        lastName: _values.lastName,
        email: _values.email,
        phoneNumber: _values.contactNumber,
        age: _values.age,
        gender: _values.gender,
        date: selectedDate,
        startTime: time[0],
        endTime: time[1],
        patientHistory: _values.pastHistory,
        clinicID: _values.clinicID,
        consultationType: consultationType ? Number(consultationType) : 2,
      };
      if (selectedDate == "") {
        setLoading(false);
        return toast.error("Please select a date");
      }
      const response = await servicePostBookAppointment(data);
      if (response.status == 0) {
        setLoading(false);
        return toast.error(response.message);
      }
      if (response.status == 1) {
        toast.success(response.message);
        history.replace("/patient/bookAppointment");
        history.push("/patient/appointments/");
      }
    } catch (e) {
      toast.error(e.message);
      setError(e.message);
      setLoading(false);
    }
  }

  async function handleTabChange(_step, _doctorId = false) {
    setToggleDoctorSearchLable(true);
    if (_step == "2") {
      if (_doctorId == "") {
        return toast.warning("Please select doctor first");
      }
      await getDoctorAvailability(_doctorId, selectedClinicID);
    }
    setFormStep(_step);
  }

  async function getDoctorList() {
    try {
      setLoading(true);
      let response = "";

      response = await servicePatientGetClinicDoctors(
        pageTable,
        search,
        clinicID,
        searchDate.toISOString().split("T")[0],
        searchDate.toISOString().split("T")[0]
      );

      if (response.status == 1) {
        setDoctorList(response.data);
        setTotalCount(response.totalCount);
        setLoading(false);
      }
    } catch (error) {
      toast.error(error.message);
      setLoading(false);
    }
  }

  async function getDoctorAvailability(_doctorId, _selectedClinicID) {
    console.log("_doctorId", _doctorId);
    try {
      let response = await serviceGetDoctorAvailability(
        _doctorId,
        _selectedClinicID
      );
      console.log("response", response);
      if (response.status == 1) {
        setConsulTationType(response.data.consultationType);
        setIsAvailableOnline(response.data.consultationType);
        await setDates(response);
      } else {
        console.log("in else");
      }
    } catch (error) {}
  }

  async function setDates(response) {
    console.log("response", response);
    let datePicker = "";
    let dateArr = [];
    if (response?.status == 1) {
      dateArr = [];
      for (let availableDay of response.data.availability) {
        if (availableDay.slots.length > 0) {
          dateArr.push(availableDay.date);
        }
      }
      datePicker = (
        <DateInput
          selectableDates={dateArr}
          onDateChange={handleDateChange}
          name="date"
          value={selectedDate}
          {...formik.getFieldProps("date")}
        />
      );

      setDatePicker(datePicker);
    }
  }

  async function handleSearch(_searchValue) {
    try {
      let response = await servicePatientGetClinicDoctors(
        page,
        _searchValue,
        clinicID,
        searchDate.toISOString().split("T")[0],
        searchDate.toISOString().split("T")[0]
      );
      if (response.status == 1) {
        setDoctorList(response.data);
      }
    } catch (error) {}
  }

  // async function onPageChange(p) {
  //   setPage(p);
  //   try {
  //     // let _page = page;
  //     // if (page == 1) {
  //     //   _page = 0;
  //     // }
  //     let response = await servicePatientGetClinicDoctors(
  //       page,
  //       searchValue.current.value,
  //       clinicID
  //     );
  //     if (response.status == 1) {
  //       setDoctorList(response.data);
  //     }
  //   } catch (error) { }
  // }

  async function onPageChangeTable(p) {
    try {
      let response = await servicePatientGetClinicDoctors(
        p - 1,
        searchValue.current.value,
        clinicID,
        searchDate.toISOString().split("T")[0],
        searchDate.toISOString().split("T")[0]
      );
      if (response.status == 1) {
        setDoctorList(response.data);
      }
    } catch (error) {}
  }

  const handleDateChange = async (value) => {
    formik.setFieldValue("time", "");
    let date = moment(value);
    let formattedDate = date.format("MM-DD-YYYY");
    setSelectedDate(formattedDate);
    await getTimeSlots(formattedDate);
  };

  async function getTimeSlots(_date) {
    try {
      setTimeSlotArray([]);
      let response = await serviceGetDoctorTimeSlots(docID, clinicID, _date);
      if (response.status == 1) {
        let _timeSlotData = response.data.slots;
        _timeSlotData.sort((a, b) => {
          if (a.startTime < b.startTime) return -1;
          if (a.startTime > b.startTime) return 1;
          return 0;
        });

        setTimeSlotArray(_timeSlotData);
        //setTimeSlotArray(response.data.slots);
      }
    } catch (error) {}
  }

  const DoctorTimeSlots = timeSlotArray.map((option) => (
    <option
      key={option.startTime + "-" + option.endTime}
      value={option.startTime + "-" + option.endTime}
    >
      {option.startTime + "-" + option.endTime}
    </option>
  ));

  const handleSelectedTimeSlot = (event) => {
    setSelectedTimeSlot(event.target.value);
  };

  async function getDoctorClinicList(_selectedDoctorId) {
    setLoading(true);
    let clinicListResponse = await serviceListDoctorClinics(_selectedDoctorId);
    setClinicList(clinicListResponse.data);
    setLoading(false);
  }

  function RadioButton(props) {
    return (
      <label className="p-2">
        <input
          type="radio"
          name={props.name}
          value={props.value}
          checked={props.checked}
          onChange={props.onChange}
        />
        <label className="ps-2">{props.label}</label>
      </label>
    );
  }

  const handleRadioChange = (event) => {
    setConsulTationType(event.target.value);
  };

  return (
    <>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <div>
          {toggleDoctorSearchLable ? (
            <h3 className="text-2xl font-semibold text-gray-700 dark:text-gray-200">
              Search Doctors
            </h3>
          ) : (
            ""
          )}

          {formStep == "1" ? (
            <>
              <div className="form__box__container">
                <div className="row">
                  <div className="d-flex col-8">
                    <div className="location__book__app form-group col-8">
                      <label for="searchValue">Search</label>
                      <input
                        className="form-control cust__form mt-1"
                        type="text"
                        placeholder="Search by name"
                        id="searchValue"
                        name="searchValue"
                        ref={searchValue}
                      />
                    </div>
                    <div className="col-4" style={{ margin: "auto" }}>
                      <button
                        type="button"
                        onClick={() => handleSearch(searchValue.current.value)}
                        className="btn btn__view float-left ms-5 mt-0"
                      >
                        Search
                      </button>
                    </div>
                  </div>
                  <div className="col-4">
                    <div class="form-group d-flex flex-column">
                      <label for="search_date">Search By Availability</label>
                      <DatePicker
                        minDate={new Date()}
                        className="form-control cust__form mt-1"
                        placeholderText="Select a date"
                        selected={searchDate}
                        onChange={(e) => setSearchDate(e)}
                        onChangeRaw={(e) => e.preventDefault()}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mt-5">
                <div className="col-md-12">
                  <h5 className="">Select Doctor</h5>
                  {doctorList.length > 0 ? (
                    doctorList.map((doctor) => (
                      <div className="clinic__doc__details__box">
                        <div className="row">
                          <div className="col-md-8">
                            <div className="profile__img__doc">
                              <div>
                                <div className="profile__img__details">
                                  <img
                                    src={
                                      doctor?.user?.avatarImageUrl ||
                                      UserPlaceholder
                                    }
                                    style={{
                                      borderRadius: "50%",
                                      aspectRatio: 1,
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="profile__details__doc__weekly d-flex justify-content-center flex-column">
                                {/* <p>
                                  <b>Clinic Name: </b>
                                  {doctor?.clinics[0]?.["clinicName"] || "-"}
                                </p> */}
                                <p className="pb-2">
                                  <b> Name: </b>
                                  Dr{" "}
                                  {doctor["user"]["firstName"] +
                                    " " +
                                    doctor["user"]["lastName"]}
                                  <b>
                                    {" "}
                                    {doctor["education"]
                                      ? "(" + doctor["education"] + ")"
                                      : ""}
                                  </b>
                                </p>
                                <p style={{ textTransform: "lowercase" }}>
                                  <b> Email: </b>
                                  {doctor["user"]["email"].toLowerCase() || "-"}
                                </p>

                                {/* <p>
                              <b> About:</b>{doctor["about"]  || ''}
                              </p> */}
                              </div>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="check__box__details__doc">
                              {/* <div className="form-check">
                            <input
                              className="form-check-input cust__check__week float-right"
                              type="radio"
                              value={doctor.uid}
                              checked={selectedDoctor == doctor.uid}
                              onChange={handleOptionChange}
                            />
                          </div> */}
                              {/* <p>Doctor Experience:18-19 Years</p> */}
                              <button
                                className="btn btn__view mt-0 w-50"
                                onClick={() => handleOptionChange(doctor.uid)}
                              >
                                Book Appointment
                              </button>
                              <br></br>
                            </div>

                            {/* <p>Doctor Experience:18-19 Years</p> */}
                            <br></br>
                            <Link
                              to={{
                                pathname: `/patient/doctor/${doctor?.uid}`,
                              }}
                            >
                              <button
                                className="btn btn__view mt-0 w-50"
                                // onClick={() => openModal(doctor)}
                              >
                                View
                              </button>
                            </Link>
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className="clinic__doc__details__box">
                      No records found
                    </div>
                  )}
                </div>

                <div className="col-md-12 d-flex justify-content-between">
                  <Pagination
                    totalResults={totalCount}
                    resultsPerPage={10}
                    onChange={onPageChangeTable}
                    label="Table navigation app__table__details"
                  />
                  {/* <button
                  onClick={() => {
                    handleTabChange("2");
                  }}
                  className="btn cancel-btn"
                >
                  Next
                </button> */}
                  {/* <button className="btn save-btn">Save</button> */}
                </div>
              </div>
            </>
          ) : (
            " "
          )}
          {formStep == "2" && userData ? (
            <>
              <div className="container-fluid">
                <div className="form__box__container">
                  <form onSubmit={formik.handleSubmit}>
                    <div className="row">
                      <h3 className="head__weekly__report">Book Appointment</h3>
                      <div className="col-md-4">
                        <div className="mb-2">
                          <label for="" className="form-label">
                            <b>First Name :</b> {userData.firstName}
                          </label>
                          {/* <input
                            type="text"
                            className="form-control cust__form"
                            //value={userData?.firstName}
                            {...formik.getFieldProps("firstName")}
                            className="form-control cust__form"
                            readOnly
                          />
                          {formik.touched.firstName &&
                            formik.errors.firstName && (
                              <div className="form_errors">
                                {formik.errors.firstName}
                              </div>
                            )} */}
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="mb-2">
                          <label for="" className="form-label">
                            <b>Last Name :</b> {userData.lastName}
                          </label>
                          {/* <input
                            type="text"
                            className="form-control cust__form"
                            id=""
                            {...formik.getFieldProps("lastName")}
                            readOnly
                          />
                          {formik.touched.lastName &&
                            formik.errors.lastName && (
                              <div className="form_errors">
                                {formik.errors.lastName}
                              </div>
                            )} */}
                        </div>
                      </div>
                      <div className="col-md-4 ">
                        <div className="mb-2">
                          <label for="" className="form-label">
                            <b>Age :</b> {userData.age}
                          </label>
                          {/* <input
                            readOnly
                            type="text"
                            name="age"
                            className="form-control cust__form"
                            id=""
                            {...formik.getFieldProps("age")}
                          />
                          {formik.touched.age && formik.errors.age && (
                            <div className="form_errors">
                              {formik.errors.age}
                            </div>
                          )} */}
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="mb-2">
                          <label for="" className="form-label">
                            <b>Gender :</b> {userData.gender}
                          </label>
                          {/* <input
                            readOnly
                            type="text"
                            name="gender"
                            className="form-control cust__form"
                            id=""
                            {...formik.getFieldProps("gender")}
                          />
                          {formik.touched.gender && formik.errors.gender && (
                            <div className="form_errors">
                              {formik.errors.gender}
                            </div>
                          )} */}
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="mb-2">
                          <label for="" className="form-label">
                            <b>Email :</b> {userData.email}
                          </label>
                          {/* <input
                            type="email"
                            className="form-control cust__form"
                            id=""
                            {...formik.getFieldProps("email")}
                            readOnly
                          /> */}
                        </div>
                        {/* {formik.touched.email && formik.errors.email && (
                          <div className="form_errors">
                            {formik.errors.email}
                          </div>
                        )} */}
                      </div>
                      <div className="col-md-4">
                        <div className="mb-2">
                          <label for="" className="form-label">
                            <b>Mobile :</b> {userData?.contactNumber}
                          </label>
                          {/* <input
                            type="text"
                            className="form-control cust__form"
                            id=""
                            {...formik.getFieldProps("contactNumber")}
                            readOnly
                          /> */}
                        </div>
                        {/* {formik.touched.contactNumber &&
                          formik.errors.contactNumber && (
                            <div className="form_errors">
                              {formik.errors.contactNumber}
                            </div>
                          )} */}
                      </div>
                      {/* <div className="col-md-4">
                        <label for="" className="form-label">
                          <b>Clinic</b>
                        </label>
                        <select
                          id="clinic"
                          className="form-select cust__form"
                          onChange={handleClinicChange}
                          value={selectedClinicID}
                        >
                          <option>Select Clinic</option>
                          {clinicList && clinicList.length > 0
                            ? clinicList.map((el) => (
                                <option value={el.clinicID}>
                                  {el?.clinic?.name}
                                </option>
                              ))
                            : ""}
                        </select>
                        {formik.touched.clinicID && formik.errors.clinicID && (
                          <div className="form_errors">
                            {formik.errors.clinicID}
                          </div>
                        )}
                      </div> */}

                      <div className="col-md-4">
                        <div className="mb-2">
                          <label for="" className="form-label">
                            <b> Date</b>
                          </label>
                          <br></br>

                          {datePicker}
                          {formik.touched.date && formik.errors.date && (
                            <div className="form_errors">
                              {formik.errors.date}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="mb-2">
                          <label for="" className="form-label">
                            <b> Time</b>
                          </label>
                          {DoctorTimeSlots.length > 0 ? (
                            <>
                              <select
                                name="time"
                                className="form-select cust__form"
                                aria-label="Default select example"
                                onChange={() => handleSelectedTimeSlot}
                                {...formik.getFieldProps("time")}
                              >
                                <option value="">Select Time Slot</option>
                                {DoctorTimeSlots}
                              </select>
                              {formik.touched.time && formik.errors.time && (
                                <div className="form_errors">
                                  {formik.errors.time}
                                </div>
                              )}
                            </>
                          ) : (
                            <p>Note: Please select Time</p>
                          )}
                        </div>
                      </div>
                      <br></br>
                      <div className="col-md-4 mt-2">
                        <div className="mb-2">
                          <label for="" className="form-label">
                            <b> Consultation Type :</b>
                          </label>{" "}
                          <br />
                          {isAvailableOnline == "1" ? (
                            <>
                              <RadioButton
                                name="type"
                                value={1}
                                checked={consultationType == 1}
                                onChange={handleRadioChange}
                                label="Online"
                              />
                              <RadioButton
                                name="type"
                                value={2}
                                checked={consultationType == 2}
                                onChange={handleRadioChange}
                                label="Physical"
                              />
                            </>
                          ) : (
                            <>
                              <RadioButton
                                name="type"
                                value={2}
                                checked={consultationType == 2}
                                onChange={handleRadioChange}
                                label="Physical"
                              />
                            </>
                          )}
                          &nbsp; &nbsp;
                        </div>
                      </div>
                      <br></br>

                      <div className="col-md-12">
                        <div className="mb-2">
                          <label for="" className="form-label">
                            Do you have any serious medical history? if yes
                            please specify
                          </label>
                          <textarea
                            className="form-control cust__form"
                            id="exampleFormControlTextarea1"
                            rows="3"
                            {...formik.getFieldProps("pastHistory")}
                          ></textarea>
                        </div>
                        {formik.touched.pastHistory &&
                          formik.errors.pastHistory && (
                            <div className="form_errors">
                              {formik.errors.pastHistory}
                            </div>
                          )}
                      </div>
                      {/* <div className="col-md-12">
                        <div className="mb-2">
                          <label for="" className="form-label">
                            Remark
                          </label>
                          
                          <textarea
                            className="form-control cust__form"
                            id="exampleFormControlTextarea1"
                            rows="3"
                            {...formik.getFieldProps("remark")}
                          ></textarea>
                        </div>
                        {formik.touched.remark && formik.errors.remark && (
                          <div className="form_errors">
                            {formik.errors.remark}
                          </div>
                        )}
                      </div> */}
                      <div className="col-md-12 d-flex justify-content-between">
                        <button
                          onClick={() => {
                            handleTabChange("1");
                          }}
                          className="btn cancel-btn"
                        >
                          Back
                        </button>
                        <button className="btn save-btn">Save</button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </>
          ) : (
            ""
          )}
        </div>
      )}
      <DoctorDetailsModel
        doctor={selectedViewDoctor}
        closeModal={closeModal}
        modalIsOpen={showModal}
      />
    </>
  );
};

export default PatientClinicBookAppointment;
