import React, { useState, useEffect } from "react";
import { serviceGetPatientSpecialities } from "../../services/apiService";
import LoadingSpinner from "../../components/LoadingSpinner";
import RemoteImage from "../../components/remoteImage";
import { Link } from "react-router-dom";
import { capitalizeFirstChar } from "../../helper/utils";

const PatientSpeciality = () => {
  const [loading, setLoading] = useState(true);
  const [specialityList, setSpecialityList] = useState([]);
  const [imageLoaded, setImageLoaded] = useState(false);
  const [imageError, setImageError] = useState(false);

  async function getSpecialities() {
    try {
      setLoading(true);
      let response = await serviceGetPatientSpecialities();
      if (response.status == 1) {
        setLoading(false);
        setSpecialityList(response.data);
      }
    } catch (e) {
      setLoading(false);
    }
  }

  useEffect(() => {
    getSpecialities();
  }, []);
  const handleImageLoad = () => {
    setTimeout(() => setImageLoaded(true), 3000); // Simulate a 1-second delay
  };

  const handleImageError = () => {
    setImageError(true);
  };
  return (
    <>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <div className="container-fluid">
          <h2 className="text-2xl font-semibold text-gray-700 dark:text-gray-200">
            Book By Specialties
          </h2>
          <div className="row">
            {specialityList.length > 0 ? (
              specialityList.map((speciality) => (
                <>
                  <div className="col-md-3 mb-3">
                    <Link
                      to={`/patient/clinics/${speciality.id}`}
                      style={{ textDecoration: "none" }}
                    >
                      <div className="incoming__request__wrap">
                        <div className="incoming__request__box">
                          <div className="incoming__request__profile">
                            {/* {imageError ?
                            <img
                              src={`${
                                process.env.REACT_APP_LOCAL_API_URL +
                                speciality.icon
                              }`}
                              className="profile__img__setting"
                              alt="Icon"
                              onLoad={handleImageLoad}
                              onError={handleImageError}
                              style={{ display: imageLoaded ? 'block' : 'none' }}

                              
                            />
                            :""} */}
                            <RemoteImage
                              key={speciality.id}
                              src={`${speciality.icon}`}
                              alt={"Icon"}
                              className="profile__img__setting"
                            />
                          </div>
                          <div className="incoming__request__details">
                            <div className="incoming__request__doc__name">
                              <b>
                                {capitalizeFirstChar(speciality.name) || "N/A"}
                              </b>
                            </div>
                          </div>
                        </div>
                        <div className="incoming__request__btn__box"></div>
                      </div>
                    </Link>
                  </div>
                </>
              ))
            ) : (
              <p>No records found...</p>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default PatientSpeciality;
