import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useParams, useHistory } from "react-router-dom";

// Sevices
import { getPatientDetails } from "../../../services/admin/servicePatient";
import { formatDate } from "../../../components/FormatDate";

const View = () => {
    const { uid } = useParams();
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [userDetails, setDetails] = useState(null);
    const [isValueLoded, valueLoded] = useState(false);

    //------------------------- FUCNCTIONS ------------------
    const goBack = () => {
        history.goBack();
    };

    useEffect(() => {
        if (userDetails == null) {
            fetchData();
            //valueLoded(false)
        } else {
            valueLoded(true)
        }
    }, [userDetails]);


    const fetchData = async () => {
        try {
            setLoading(true);
            const response = await getPatientDetails(uid);
            setLoading(false);
            if (response.status == 0) {
                toast.error(response.message);
                setLoading(false);
            }
            if (response.status == 1) {
                setDetails(response.data.user);

            }
        } catch (error) {
            setLoading(false);
            toast.error(error);;
        }
    };

    // ---------------------------------- Render Html
    return (

        userDetails ?
            <div className='container-fluid mt-5'>
                < div className='form__box__container' >
                    <h3 className="head__weekly__report">View Patient</h3>
                    <div className='row mt-3'>
                        <div className='col-md-6'>
                            <div class="mb-2">
                                <label for="" class="form-label cust__label__txt">First Name</label>
                                <label for="" class="form-label">{userDetails.firstName}</label>
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div class="mb-2">
                                <label for="" class="form-label cust__label__txt">Last Name</label>
                                <label for="" class="form-label">{userDetails.lastName}</label>
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div class="mb-2">
                                <label for="" class="form-label cust__label__txt">Email</label>
                                <label for="" class="form-label">{userDetails.email}</label>
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div class="mb-2">
                                <label for="" class="form-label cust__label__txt">Contact Number</label>
                                <label for="" class="form-label">{userDetails.contactNumber}</label>
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div class="mb-2">
                                <label for="" class="form-label cust__label__txt">Gender</label>
                                <label for="" class="form-label">{userDetails.gender}</label>
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div class="mb-2">
                                <label for="" class="form-label cust__label__txt">Age</label>
                                <label for="" class="form-label">{userDetails.age}</label>
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div class="mb-2">
                                <label for="" class="form-label cust__label__txt">Dob</label>
                                <label for="" class="form-label">{formatDate(userDetails?.dob) || 'N/A'}</label>
                            </div>
                        </div>
                    </div>
                </div >
                <div className="col-md-12 d-flex justify-content-between mt-3">
                    <button className="btn cancel-btn" onClick={goBack}>Back</button>
                </div>
            </div >

            : ""

    );
};
export default View;