import React, {useEffect, useState} from 'react';
import Table from "../table/Table";
import TableHeader from "../table/TableHeader";
import TableCell from "../table/TableCell";
import TableBody from "../table/TableBody";
import TableRow from "../table/TableRow";
import {useHistory} from "react-router-dom";
import Button from "../Button";
import {SearchIcon} from "@heroicons/react/solid";
import TableFooter from "../table/TableFooter";
import Pagination from "../Pagination";
import TableContainer from "../table/TableContainer";
import LoadingSpinner from "../LoadingSpinner";
import {axiosInstance, formatDateTime} from "../../common/constants";
import LoadingDots from "../LoadingDots";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {FilterMatchMode, FilterService} from "primereact/api";

const DoctorMemoTable = (props) => {

    const [loading, setLoading] = useState(true)
    const [dataTable, setDataTable] = useState()
    const [pageTable, setPageTable] = useState(1)
    const [totalResults, setTotalResults] = useState(0)
    const {patientUid} = props
    const history = useHistory();
    const [filters, setFilters] = useState({
        'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
        'doctor.user.fullName': {value: null, matchMode: FilterMatchMode.CONTAINS},
        'diagnoses': {value: null, matchMode: "diagnosesFilter"},
        'referrals': {value: null, matchMode: "referralsFilter"},
        'createdAtFormatted': {value: null, matchMode: FilterMatchMode.CONTAINS},
    });

    FilterService.register('diagnosesFilter', filterDiagnoses);
    FilterService.register('referralsFilter', filterReferrals);

    async function getPatientMemos() {
        const res = await axiosInstance.get(`/doctor/getAllMemos?patientUid=${patientUid}`)
        
        res.data.forEach(e => {
            e.doctor.user.fullName = e.doctor.user.firstName?.toString() + " " + e.doctor.user.lastName?.toString()
        })

        res.data.forEach(e => {
            e.createdAtFormatted = formatDateTime(e.createdAt)
        })
        setDataTable(res.data)
        setLoading(false)
    }

    function onSelect(rowData) {
        viewMemo(rowData.value.id)
    }

    function viewMemo(memoId) {
        history.push(`/doctor/patients/${patientUid}/memos/${memoId}`)
    }

    const doctorTemplate = (rowData) => {
        return `Dr. ${rowData.doctor.user.fullName}`

    }

    const diagnosisTemplate = (rowData) => {
        return rowData.diagnoses.map((v, j) => (
            <li key={j}>{`[${v.code}] ${v.title}`}</li>
        ))
    }

    const referredTemplate = (rowData) => {
        return rowData.referrals.map((v, j) => (
            <li key={j}>{`Dr. ${v.doctor.user.firstName?.toString()} ${v.doctor.user.lastName?.toString()}`}</li>
        ))
    }

    function filterDiagnoses (value, filter, filterLocale) {
        if (filter === undefined || filter === null || filter.trim() === '') {
            return true;
        }

        const lowerCaseFilter = filter.toLocaleLowerCase(filterLocale)

        for (let i = 0; i < value.length; i++) {
            const code = value[i].code.toLocaleLowerCase(filterLocale)
            const title = value[i].title.toLocaleLowerCase(filterLocale)
            if (code.indexOf(lowerCaseFilter) !== -1 || title.indexOf(lowerCaseFilter) !== -1) {
                return true
            }
        }
        return false
    }

    function filterReferrals (value, filter, filterLocale) {
        if (filter === undefined || filter === null || filter.trim() === '') {
            return true;
        }

        const lowerCaseFilter = filter.toLocaleLowerCase(filterLocale)

        for (let i = 0; i < value.length; i++) {
            const referredName = (value[i].doctor.user.firstName?.toString() + " " + value[i].doctor.user.lastName?.toString()).toLocaleLowerCase(filterLocale)
            if (referredName.indexOf(lowerCaseFilter) !== -1) {
                return true
            }
        }
        return false
    }


    useEffect(() => {
        getPatientMemos()
    }, [])

    return (
        <div>
            <div className="mb-8 max-w-7xl">
                {loading ?
                    <LoadingDots/>
                    :
                    <div className="">
                        <DataTable value={dataTable} selectionMode="single" onSelectionChange={onSelect} filters={filters} filterDisplay="row"
                                   paginator rows={10}  responsiveLayout="stack" breakpoint="640px" size="small" emptyMessage="No memos found.">
                            <Column body={doctorTemplate} showFilterMenu={false} filter filterField='doctor.user.fullName' filterPlaceholder="Search by name" header="Doctor" className="w-64"/>
                            <Column body={diagnosisTemplate} filter filterField='diagnoses' showFilterMenu={false} filterPlaceholder="Search by diagnosis code or title" filterFunction={filterDiagnoses} header="Diagnoses"/>
                            <Column body={referredTemplate} filter filterField='referrals' showFilterMenu={false} filterPlaceholder="Search by name" filterFunction={filterReferrals} className="w-72" header="Referred To"/>
                            <Column field="createdAtFormatted" header="Date Created"/>
                        </DataTable>
                    </div>
                }
            </div>
        </div>
    );
};

export default DoctorMemoTable;