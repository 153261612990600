import React, { useEffect, useState } from "react";
import './index.css';
import { BrowserRouter as Router, Redirect, Route, Switch, } from "react-router-dom";
import HomePage from "./pages/HomePage";
import Doctor from './pages/doctor/Doctor';
import DoctorLogin from './pages/doctor/DoctorLogin';
import Admin from "./pages/Admin";
import { AuthProvider, useAuth } from "./useAuth.js";
import DoctorRegister from './pages/doctor/DoctorRegister';
import AdminLogin from "./pages/AdminLogin";
import Patient from "./pages/patient/Patient";
import PasswordReset from "./pages/PasswordReset";
// import TestBackend from "./pages/TestBackend";
import PatientLogin from "./pages/patient/PatientLogin";
import PatientRegister from "./pages/patient/PatientRegister";
import 'react-toastify/dist/ReactToastify.css';
import './css/admin.css'
import './css/bootstrap.min.css'
import './css/custom.css'
import { ToastContainer } from "react-toastify";
import Pdpa from "./pages/Pdpa";
import { MantineProvider } from '@mantine/core';
import "primereact/resources/themes/tailwind-light/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import 'react-tabs/style/react-tabs.css';
import GlobalProvider from "./store/global-context";
import { HelmetProvider } from "react-helmet-async";
import VerifyEmail from "./pages/verifyEmail";
import Clinic from "./pages/clinic/clinic";
import ClinicSignUp from "./pages/clinic/clinicSignUp";
import ClinicLogin from "./pages/clinic/clinicLogin";
import ResetAccountPassword from "./pages/ResetAccountPassword";
import { serviceGetAccessTokenDetails } from "./services/apiService";
import LoadingSpinner from "./components/LoadingSpinner";


function App() {

    return (
        <AuthProvider>
            <HelmetProvider>
                <MantineProvider emotionOptions={{ key: 'mantine', prepend: false }}
                    classNames={{
                        // DatePicker: {input: 'font-sans text-sm', label: 'font-sans'},
                        NumberInput: { input: 'font-sans focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm text-base sm:text-sm border-gray-300 rounded-md' }
                    }}>

                    <Router>
                        <Switch>
                            <Route exact path="/">
                                <HomePage />
                            </Route>
                            <Route path="/pdpa">
                                <Pdpa />
                            </Route>

                            <GlobalProvider>

                                <PrivateRoute redirectTo="/adminLogin" path="/admin">
                                    <Admin />
                                </PrivateRoute>
                                <Route exact path="/adminLogin">
                                    <AdminLogin />
                                </Route>
                                <PrivateRoute redirectTo="/doctorLogin" path="/doctor">
                                    <Doctor />
                                </PrivateRoute>
                                <Route exact path="/doctorLogin">
                                    <DoctorLogin />
                                </Route>
                                <Route exact path="/doctorClinicRegister">
                                    <DoctorRegister />
                                </Route>
                                <Route exact path="/doctorRegister">
                                    <DoctorRegister />
                                </Route>
                              
                                <PrivateRoute redirectTo="/patientLogin" path="/patient">
                                    <Patient />
                                </PrivateRoute>
                                <Route exact path="/patientLogin">
                                    <PatientLogin />
                                </Route>
                                <Route exact path="/patientRegister">
                                    <PatientRegister />
                                </Route>
                                <Route exact path="/clinicRegister">
                                    <ClinicSignUp />
                                </Route>
                                <PrivateRoute redirectTo="/clinicLogin" path="/clinic">
                                    <Clinic />
                                </PrivateRoute>
                                <Route exact path="/passwordReset">
                                    <PasswordReset />
                                </Route>
                                <Route exact path="/resetAccountPassword/:credentialID">
                                    <ResetAccountPassword />
                                </Route>
                                <Route exact path="/verify-email/patient/:credentialID">
                                    <VerifyEmail role="patient"/>
                                </Route>
                                <Route exact path="/verify-email/doctor/:credentialID">
                                    <VerifyEmail role="doctor"/>
                                </Route>
                                <Route exact path="/verify-email/clinic/:credentialID">
                                    <VerifyEmail role="clinic"/>
                                </Route>
                                <Route exact path="/verify-account/:credentialID">
                                    <VerifyEmail role="general"/>
                                </Route>
                                <Route exact path="/clinicLogin">
                                    <ClinicLogin />
                                </Route>
                                {/*<Route exact path="/testBackend">*/}
                                {/*    <TestBackend/>*/}
                                {/*</Route>*/}
                                <ToastContainer />
                            </GlobalProvider>

                        </Switch>
                    </Router>
                </MantineProvider>
            </HelmetProvider>

        </AuthProvider>
    );
}


// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated.
function PrivateRoute({ redirectTo, children, ...rest }) {
    let { user, setUser } = useAuth();
    const [loading, setLoading] = useState(true);

    useEffect(()=>{
        if(!user) {
            getTokenDetails();
        }
    }, [])

    // ... to save the user to state.
    const getTokenDetails = async() => {
        try {
            const response = await serviceGetAccessTokenDetails();
            if (response.status == 0) {
                setLoading(false);
                // throw response;
            }
            if (response.status == 1) {
                setUser(response.data)
                setLoading(false);
                return {
                    user: response.data
                }
            }
        } catch (e) {
            console.log("sdashdgahj response ---------- ", e);
            setLoading(false);
            // throw e;
        }
    };

    return (
        <Route
            {...rest}
            render={({ location }) =>
                loading ? (
                    <LoadingSpinner />
                ) :
                    user ? (
                        children
                    ) : (
                        <Redirect
                            to={{
                                pathname: redirectTo,
                                state: { from: location }
                            }}
                        />
                    )
            }
        />
    );
}

export default App;
