import React, {Fragment} from 'react';
import {Dialog, Transition} from "@headlessui/react";
import Button from "./Button";

const ConfirmDialog = (props) => {

    const {isOpen, onClose, title, message, onAccept, acceptLayout, acceptButton} = props

    return (
        <Transition appear show={isOpen} as={Fragment}>
            <Dialog
                as="div"
                className="relative z-[200]"
                onClose={onClose}
            >
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-40"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-40"
                    leaveTo="opacity-0"
                >
                    <Dialog.Overlay className="fixed inset-0 bg-black transition-opacity"/>
                </Transition.Child>

                <div className="fixed z-[200] inset-0 overflow-y-auto">
                    <div className="flex items-center justify-center min-h-full p-4 text-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <Dialog.Panel
                                className="inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                                <Dialog.Title
                                    as="h3"
                                    className="text-lg font-medium leading-6 text-gray-900"
                                >
                                    {title}
                                </Dialog.Title>
                                <div className="mt-2">
                                    <p className="text-sm text-gray-600">
                                        {message}
                                    </p>
                                </div>

                                {/*<div className="w-full flex flex-col sm:flex-row-reverse gap-3 justify-center">*/}
                                <div className="align-bottom px-4 pt-6 sm:px-6 sm:flex sm:flex-row-reverse gap-3">
                                    <Button onClick={onAccept} layout={acceptLayout}
                                            className="w-full sm:w-auto">
                                        {acceptButton || "Confirm"}
                                    </Button>

                                    <Button onClick={onClose}
                                            className="bg-gray-600 hover:bg-gray-700 focus:ring-gray-500 w-full sm:w-auto mt-3 sm:mt-0">
                                        Cancel
                                    </Button>


                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>

    );
};

export default ConfirmDialog;