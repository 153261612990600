const apiCall = require("../../Api");

/**
 *
 * @param {*} data
 * @returns
 */
async function servicePostClinicRegistration(data) {
  let _response = await apiCall.postRawJsonData(
    "/api/V1/auth/clinic/register",
    data
  );
  return _response;
}

async function servicePostFileUpload(data) {
  let _response = await apiCall.postImage("/api/V1/file/upload", data);
  return _response;
}

async function serviceGetDoctorList(_page, _search) {
  let _response = await apiCall.getData(
    `/api/v1/doctor/listing?page=${_page}&search=${_search}`
  );
  return _response;
}

async function serviceSendClinicInvite(data) {
  let _response = await apiCall.postRawJsonData(
    "/api/V1/clinic/inviteDoctor",
    data
  );
  return _response;
}

// async function servicePostVerifyEmail(data) {
//   let _response = await apiCall.postRawJsonData(
//     "/api/V1/auth/verifyEmailOTP",
//     data
//   );
//   return _response;
// }

//   async function serviceGetReviewDetails(_id) {
//     let _response = await apiCall.getData(`/api/V1/reviews/getReviewDetails?reviewID=${_id}`);
//     return _response;
//   }

async function serviceGetClinicDetails(_id) {
  let _response = await apiCall.getData(`/api/V1/clinic/details?uid=${_id}`);
  return _response;
}

async function listClinicInvitations(
  page,
  search = "",
  isAccepted = "0",
  status = "1"
) {
  let _response = await apiCall.getData(
    `/api/V1/clinic/invitation?search=${search}&isAccepted=${isAccepted}&status=${status}`
  );
  return _response;
}

async function serviceUpdateClinicProfile(data) {
  let _response = await apiCall.postRawJsonData(
    `/api/V1/clinic/profile/update`,
    data
  );
  return _response;
}

async function clinicInvitationAction(data) {
  let _response = await apiCall.postRawJsonData(
    `/api/V1/clinic/invitation/action`,
    data
  );
  return _response;
}

async function updateClinicProfileImage(data) {
  let _response = await apiCall.postRawJsonData(
    "/api/V1/clinic/profile/updateImage/",
    data
  );
  return _response;
}

async function serviceListClinicInvitations(page, search = "") {
  let _response = await apiCall.getData(
    `/api/V1/clinic/invitation?search=${search}`
  );
  return _response;
}

async function serviceClinicInvitationAction(data) {
  let _response = await apiCall.postRawJsonData(
    `/api/V1/clinic/invitation/action`,
    data
  );
  return _response;
}

async function serviceClinicSettingUpdate(data) {
  let _response = await apiCall.postRawJsonData(
    `/api/V1/clinic/configuration/update`,
    data
  );
  return _response;
}

async function serviceGetClinicSetting() {
  let _response = await apiCall.getData(`/api/V1/clinic/configuration`);
  return _response;
}

async function serviceGetClinicSettingAffectedRecords(data) {
  let _response = await apiCall.postRawJsonData(
    `/api/V1/clinic/configuration/affectedRecords`,
    data
  );
  return _response;
}

async function serviceListClinics() {
  let _response = await apiCall.getData(`/api/V1/clinic/list`);
  return _response;
}

async function serviceListClinicDoctors(
  _page,
  _search,
  _clinicID,
  listAll = false
) {
  let url = `/api/v1/clinic/doctors?page=${_page}&search=${_search}&clinicID=${_clinicID}`;
  if (listAll) {
    url += `&listAll=true`;
  }
  let _response = await apiCall.getData(url);
  return _response;
}

async function serviceRemoveClinicDoctors(doctorID) {
  let _response = await apiCall.postRawJsonData(
    `/api/V1/clinic/doctor/remove`,
    {
      doctorID,
    }
  );
  return _response;
}

export {
  serviceListClinicInvitations,
  serviceClinicInvitationAction,
  servicePostClinicRegistration,
  servicePostFileUpload,
  serviceGetDoctorList,
  serviceSendClinicInvite,
  serviceGetClinicDetails,
  listClinicInvitations,
  clinicInvitationAction,
  serviceUpdateClinicProfile,
  updateClinicProfileImage,
  serviceClinicSettingUpdate,
  serviceGetClinicSetting,
  serviceGetClinicSettingAffectedRecords,
  serviceListClinics,
  serviceListClinicDoctors,
  serviceRemoveClinicDoctors,
};
