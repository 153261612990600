import React, {useEffect, useState} from 'react';
import LoadingDots from "../components/LoadingDots";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {axiosInstance, formatDate, titleCase} from "../common/constants";
import SectionTitle from "../components/SectionTitle";

const AdminAwaitingSubmission = () => {

    const [loading, setLoading] = useState(true)
    const [dataTable, setDataTable] = useState()

    async function getAwaitingList() {
        const res = await axiosInstance.get(`/admin/getAwaitingList`)

        setDataTable(res.data)
        setLoading(false)

    }

    useEffect(() => {
        setLoading(true)
        getAwaitingList()
    }, [])

    return (
        <div>
            <SectionTitle>Awaiting Doctor Verification Submission</SectionTitle>
            <p>These emails have registered for a doctor account, but have not submitted the verification form</p>
            {loading ?
                <LoadingDots/>
                :
                <div className="max-w-7xl">
                    <DataTable value={dataTable}
                               paginator rows={10}  responsiveLayout="stack" breakpoint="640px" size="small" emptyMessage="No records found.">
                        <Column body={(rowData) => rowData.user.email} header="Email"/>
                    </DataTable>
                </div>
            }
        </div>
    );
};

export default AdminAwaitingSubmission;