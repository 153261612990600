import React, { useEffect, useState } from "react";
import { ArrowLeftIcon, ArrowRightIcon } from "@heroicons/react/solid";
import { axiosInstance, formatDate } from "../../common/constants";
import { DateTime } from "luxon";
import LoadingSpinner from "../../components/LoadingSpinner";
import { Link } from "react-router-dom";
import classNames from "classnames";

import WeeklyScheduler from "../../components/WeeklyScheduler";

const DoctorRecentActivityCard = () => {
  const [activityData, setActivityData] = useState([]);
  const [loading, setLoading] = useState(true);

  function compareMemoDates(first, second) {
    const firstDate = DateTime.fromISO(first.createdAt);
    const secondDate = DateTime.fromISO(second.createdAt);

    if (firstDate < secondDate) {
      return 1;
    }
    if (firstDate > secondDate) {
      return -1;
    }
    return 0;
  }

  useEffect(() => {
    async function fetchLatestActivity() {
      const latestActivity = await axiosInstance.get(
        `/doctor/getRecentActivity`
      );
      latestActivity.data.sort(compareMemoDates);
      let timelineData = [];
      for (let i = 1; i < latestActivity.data.length; i++) {
        let patientFullName = "an unnamed patient";
        if (
          latestActivity.data[i].patient.user.firstName ||
          latestActivity.data[i].patient.user.lastName
        ) {
          patientFullName =
            latestActivity.data[i].patient.user.firstName?.toString() +
            " " +
            latestActivity.data[i].patient.user.lastName?.toString();
        }

        timelineData.push({
          id: i,
          content: latestActivity.data[i].isSender
            ? "You issued"
            : "You received",
          memoText: "a memo",
          memoLink:
            "doctor/patients/" +
            latestActivity.data[i].patient.user.uid +
            "/memos/" +
            latestActivity.data[i].id,
          patientName: patientFullName,
          patientLink:
            "doctor/patients/" + latestActivity.data[i].patient.user.uid,
          date: formatDate(latestActivity.data[i].createdAt),
          datetime: "XXXXXXX",
          icon: latestActivity.data[i].isSender
            ? ArrowRightIcon
            : ArrowLeftIcon,
          iconBackground: latestActivity.data[i].isSender
            ? "bg-green-600"
            : "bg-blue-600",
        });
      }
      setActivityData(timelineData);
      setLoading(false);
    }

    fetchLatestActivity();
  }, []);

  return (
    <div>
      {/* doctor home page start */}

      <div className="container-fluid mt-2">
        <WeeklyScheduler />
      </div>
    </div>
  );
};

export default DoctorRecentActivityCard;
