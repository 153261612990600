import React, { useRef, useState, useEffect } from "react";
import {
  servicePostVerifyEmail,
  servicePostResendVerificationCode,
  serviceGetOtpTimer
} from "../services/apiService";
import { toast } from "react-toastify";
import { useHistory, useParams, Link } from "react-router-dom";
import SmallLoadingSpinner from "../components/SmallLoadingSpinner";


const VerifyEmail = ({ role }) => {
  const verficationCode = useRef();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [seconds, setSeconds] = useState(60);
  const [showTimer, setShowTimer] = useState(true);
  const [resendLoader, setResendLoader] = useState(false)

  const history = useHistory();
  const { credentialID } = useParams();
  let countdown;

  async function handleSubmit(e) {
    e.preventDefault();
    try {
      setError("");
      setLoading(true);
      let data = {
        credentialID: credentialID,
        verificationCode: verficationCode.current.value,
      };
      const verificationData = await servicePostVerifyEmail(data);
      if (verificationData.status == 0) {
        toast.error(verificationData.message);
        setLoading(false);
      }
      if (verificationData.status == 1) {
        if (role == "general") {
          history.replace("/");
          history.push("/resetAccountPassword/"+credentialID);
        }
        if (role == "doctor") {
          history.replace("/doctorLogin");
          history.push("/doctor");
        }
        if (role == "patient") {
          history.replace("/patientLogin");
          history.push("/patient");
        }
        if (role == "clinic") {
          history.replace("/clinicLogin");
          history.push("/clinic");
        }
        toast.success("Verification successful");
      }
    } catch (e) {
      setError(e.message);

      setLoading(false);
    }
  }


  useEffect(() => {
    const fetchData = async () => {
      try {
        await getOtpTimer();

      } catch (error) {
        return toast.error(error.message);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    countdown = setInterval(() => {
      setSeconds((prevSeconds) => prevSeconds - 1);
    }, 1000);
    resendCode(countdown);
    return () => clearInterval(countdown);
  }, []);

  useEffect(() => {
    if (seconds === 0) {
      clearInterval(countdown);

      // resendCode(seconds);
      setShowTimer(false);
    }
  }, [seconds]);

  async function resendCode() {
    try {
      if (seconds <= 0) {
        setResendLoader(true)
        let data = {
          credentialID: credentialID,
        };
        const response = await servicePostResendVerificationCode(data);
        if (response.status) {
          toast.success("Please check your email,for new verification code");
          setSeconds(60);
          setShowTimer(true);
          setResendLoader(false);
        }
        else {
          setResendLoader(false);
          return toast.success("Something went wrong,Please try again");
        }
      }
    }
    catch (error) {
      setResendLoader(false);
      return toast.error(error.message);
    }
  }

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const remainingSeconds = time % 60;
    return `${minutes.toString().padStart(2, "0")}:${remainingSeconds
      .toString()
      .padStart(2, "0")}`;
  };

  const getOtpTimer = async () => {
    try {
      const response = await serviceGetOtpTimer();
      if (response.status) {
        setSeconds(response.data.value);
        return true
      } else {
        return toast.error("Something went wrong,Please try again");
      }
    } catch (error) {
      return toast.error(error.message);
    }
  }

  return (
    <div className="min-h-full flex flex-col items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8">
        <div>
          <img
            className="mx-auto h-12 w-auto"
            src="https://tailwindui.com/img/logos/workflow-mark-indigo-600.svg"
            alt="Workflow"
          />
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
            Verify Email
          </h2>
          <p className="mt-2 text-center text-sm text-gray-600">
            Please your verfication code here to verify your email
          </p>
        </div>
        <form
          onSubmit={handleSubmit}
          className="mt-8 space-y-6"
          action="#"
          method="POST"
        >
          <input type="hidden" name="remember" defaultValue="true" />
          <div className="rounded-md shadow-sm -space-y-px">
            <div>
              <label htmlFor="verficationCode" className="sr-only">
                verfication code
              </label>
              <input
                ref={verficationCode}
                name="verficationCode"
                type="text"
                required
                className="appearance-none rounded-md relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                placeholder="Verification Code"
              />
            </div>
          </div>

          <div>
            <button
              disabled={loading}
              type="submit"
              className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-50"
            >
              <span className="absolute left-0 inset-y-0 flex items-center pl-3"></span>
              {loading && <SmallLoadingSpinner className="h-5 w-5 mr-3" />} Verify
            </button>
          </div>

          <div className="max-w-md w-full block text-sm text-gray-600 flex items-center justify-center mt-10">
            {showTimer ? (
              <>
                <label class="form-label">
                  <p>{formatTime(seconds)}</p>
                </label>
              </>
            ) : (
              <>
                {resendLoader ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <SmallLoadingSpinner className="h-5 w-5" />
                  </div>
                ) : (
                  <button
                    disabled={showTimer}
                    onClick={() => resendCode()}
                    className="font-medium text-indigo-600 hover:text-indigo-500"
                  >
                    Resend verfication Code
                  </button>
                )}
              </>
            )}
          </div>
        </form>

        {/* {emailSent &&
                    <div className="bg-green-200 border-green-600 text-green-600 border-l-4 p-4" role="alert">
                        <p className="font-bold mb-2">
                            Password reset email sent
                        </p>
                        <p>
                            If a matching account was found, an email was sent to <b>{emailAddress}</b> to allow you to
                            reset your
                            password.
                        </p>
                    </div>
                } */}
      </div>

      <div className="max-w-md w-full block text-sm text-gray-600 flex items-center justify-center mt-10">
        <p>
          <Link
            to="/"
            className="font-medium text-indigo-600 hover:text-indigo-500"
          >
            Back to homepage
          </Link>
        </p>
      </div>
    </div>
  );
};

export default VerifyEmail;
