import React from "react";
import { useEffect } from "react";
import {
  serviceGetNotifications,
  serviceUpdateNotificationStatus,
} from "../../services/apiService";
import { toast } from "react-toastify";
import { useState } from "react";
import LoadingSpinner from "../../components/LoadingSpinner";
import { formatDate } from "../../components/FormatDate";
import RecordPlaceholder from "../../components/RecordPlaceholder";

const ClinicNotification = () => {
  const [loading, setLoading] = useState(true);
  const [isLoadMore, setIsLoadMore] = useState(true);
  const [notificationList, setNotificationList] = useState([]);
  const [page, setPage] = useState(0);

  useEffect(() => {
    getNotificationList(page);
  }, []);

  async function getNotificationList(page) {
    try {
      setLoading(true);
      let response = await serviceGetNotifications(page);
      if (response.status == 1) {
        setNotificationList([...notificationList, ...response.data]);
        if (response?.data?.length < 10) {
          setIsLoadMore(false);
        } else if (response?.data?.length == 10) {
          setPage(page + 1);
          setIsLoadMore(true);
        }
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      }
    } catch (error) {
      toast.error(error.message);
      setLoading(false);
    }
  }

  return (
    <>
      {/* <div className="row justify-content-end">
        <div className="col-md-4">
          <div className="search__notification">
            <div className="form-outline">
              <input
                type="search"
                id="form1"
                className="form-control"
                placeholder="Type query"
                aria-label="Search"
              />
            </div>
          </div>
        </div>
      </div> */}
      {!loading ? (
        <div className="row">
          <div className="col-md-12">
            {notificationList && notificationList?.length > 0 ? (
              notificationList?.map((notification) => (
                <div className="notification__box">
                  <div className="notification__name__date">
                    <div className="notification__content">
                      <p>{notification.message} </p>
                    </div>
                    {/* <div className="notification__user">
                      <h6>
                        (
                          notification
                          {notification?.from_user?.firstName}{" "}
                          {notification?.from_user?.lastName}
                        )
                      </h6>
                    </div> */}
                    <div className="notication__date">
                      <p>
                        {formatDate(notification.createdAt) +
                          " " +
                          new Date(notification.createdAt).toLocaleTimeString(
                            "en-US",
                            {
                              hour: "numeric",
                              minute: "2-digit",
                              hour12: true,
                            }
                          )}
                      </p>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <RecordPlaceholder table="false" />
            )}
          </div>
        </div>
      ) : (
        ""
      )}
      <div className="row">
        <div className="col-md-12 text-center">
          {!loading ? (
            isLoadMore && (
              <button
                onClick={() => {
                  getNotificationList(page);
                }}
                style={{
                  backgroundColor: "black",
                  color: "white",
                  fontWeight: 600,
                  height: 50,
                  width: 150,
                }}
              >
                Load More
              </button>
            )
          ) : (
            <LoadingSpinner />
          )}
        </div>
      </div>
    </>
  );
};

export default ClinicNotification;
