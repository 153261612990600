import React, { useEffect, useRef, useState } from "react";
import { useAuth } from "../../useAuth";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  servicePatientPostMedicalHistory,
  servicePatientGetMedicalHistory,
} from "../../services/apiService";
import LoadingSpinner from "../../components/LoadingSpinner";

const PatientMedicalHistory = () => {
  const [submitting, setSubmitting] = useState(false);
  const [loading, setLoading] = useState(false);
  const [medicalHistory, setMedicalHistory] = useState({});
  const [error, setError] = useState(false);
  const { userData } = useAuth();

  const validationSchema = Yup.object({
    medicalCondition: Yup.string(),
    surgeries: Yup.string(),
    description: Yup.string(),
  });

  const formik = useFormik({
    initialValues: {
      patientID: userData.uid,
      medicalCondition: "",
      surgeries: "",
      description: "",
    },
    validationSchema,
    onSubmit: (values) => {
      webHandleSubmit(values);
    },
  });

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await servicePatientGetMedicalHistory(userData.uid);
      setLoading(false);
      if (response.status == 0) {
        toast.error(response.message);
      }
      if (response.status == 1) {
        //toast.success(response.message);
        setMedicalHistory(response.data);
        const medicalHistoryData = response.data;
        const newValues = {
          patientID: userData.uid,
          medicalCondition: medicalHistoryData?.medicalCondition || "",
          surgeries: medicalHistoryData?.surgeries || "",
          description: medicalHistoryData?.description || "",
        };
        formik.setValues(newValues);
      }
    } catch (error) {
      setLoading(false);
      toast.error(error);
    }
  };

  async function webHandleSubmit(_values) {
    try {
      setError("");
      setLoading(true);
      let data = _values;
      const response = await servicePatientPostMedicalHistory(data);
      if (response.status == 0) {
        toast.error(response.message);
      }
      if (response.status == 1) {
        toast.success(response.message);
      }
      setLoading(false);
    } catch (e) {
      setError(e.message);
      setLoading(false);
    }
  }

  return (
    <>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <div className="container-fluid">
          <div className="docter__setting__header">
            <h3>Medical History</h3>
          </div>
          <div className="gap-3 setting__form">
            <div className="py-3 border-gray-400 col-span-3 grid grid-cols-2 gap-y-3 items-center">
              <form
                onSubmit={formik.handleSubmit}
                action="#"
                method="POST"
                className="col-span-2"
              >
                <div className="form__box__container">
                  <div className="grid grid-cols-4 gap-3">
                    <div className="col-span-2 sm:col-span-2">
                      <label className="form-label">
                        <b>Medical Condition</b>
                      </label>
                      <input
                        type="text"
                        className="form-control cust__form"
                        placeholder="Medical Condition"
                        {...formik.getFieldProps("medicalCondition")}
                      />

                      {formik.touched.medicalCondition &&
                        formik.errors.medicalCondition && (
                          <p className="block text-sm font-medium text-red-700 mt-2">
                            {formik.errors.medicalCondition}
                          </p>
                        )}
                    </div>
                    <div className="col-span-2 sm:col-span-2">
                      <label className="form-label">
                        <b>Surgeries</b>
                      </label>
                      <input
                        type="text"
                        className="form-control cust__form"
                        placeholder="surgeries"
                        {...formik.getFieldProps("surgeries")}
                      />

                      {formik.touched.surgeries && formik.errors.surgeries && (
                        <p className="block text-sm font-medium text-red-700 mt-2">
                          {formik.errors.surgeries}
                        </p>
                      )}
                    </div>
                    <div className="col-span-2 sm:col-span-4">
                      <label className="form-label">
                        <b>Description</b>
                      </label>
                      <textarea
                        rows={4}
                        className="form-control cust__form"
                        placeholder="Description"
                        {...formik.getFieldProps("description")}
                      />

                      {formik.touched.medicalCondition &&
                        formik.errors.medicalCondition && (
                          <p className="block text-sm font-medium text-red-700 mt-2">
                            {formik.errors.description}
                          </p>
                        )}
                    </div>

                    <div className="col-md-12 d-flex justify-content-between">
                      <button type="submit" className="setting__doctor__save">
                        Save
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default PatientMedicalHistory;
