import React, { useEffect, useState } from "react";
import {
  serviceGetClinicDetails,
  clinicInvitationAction,
  listClinicInvitations,
} from "../../services/clinic/clinicApiService";
import { toast } from "react-toastify";
import ToastConfirmation from "../../components/toastrConfirmation";
import { useAuth } from "../../useAuth";
import { Link } from "react-router-dom";
import LoadingDots from "../../components/LoadingDots";
import DefaultAvatar from "../../components/DefaultAvatar";
import { capitalizeFirstChar } from "../../helper/utils";
const ClinicHome = () => {
  const [loading, setLoading] = useState(true);
  const [clinicDetails, setClinicDetails] = useState(true);
  const [invitationList, setInvitationList] = useState([]);
  const { user } = useAuth();

  useEffect(() => {
    getClinicDetails(user.uid);
    getClinicList();
  }, []);

  async function getClinicDetails(_clinicId) {
    try {
      setLoading(true);
      const res = await serviceGetClinicDetails(_clinicId);
      if (res.status == 1) {
        console.log("res.data ============== ", res.data);
        setClinicDetails(res.data);
        setLoading(false);
      } else {
        setLoading(false);
        return toast.error("something went wrong");
      }
    } catch (error) {
      setLoading(false);
      return toast.error(error.message);
    }
  }

  async function getClinicList() {
    try {
      setLoading(true);
      let _page = 0;
      const res = await listClinicInvitations((_page = 0));
      if (res.status == 1) {
        setInvitationList(res.data);
        setLoading(false);
      } else {
        setLoading(false);
        return toast.error("something went wrong");
      }
    } catch (error) {
      setLoading(false);
      return toast.error(error.message);
    }
  }

  async function performInvitationAction(invitation) {
    setLoading(true);
    let data = {
      invitationID: invitation.id,
      isAccepted: invitation.isAccepted,
    };
    let action = await clinicInvitationAction(data);
    if (action.status == 1) {
      try {
        getClinicList();
        setLoading(false);
        return toast.success("Invitation updated successfully.");
      } catch (e) {
        setLoading(false);
        return toast.error("Something went wrong,please try again");
      }
    } else {
      setLoading(false);
      toast.error("Something went wrong,please try again");
    }
  }

  function renderSpeciality(_arr) {
    let speciality = "N/A";
    if (_arr.length > 0) {
      speciality = _arr.join(", ");
      return speciality;
    }
    return speciality;
  }
  function displayStarRating(ratingValue) {
    const maxRating = 5;
    const fullStar = "★";
    const emptyStar = "☆";

    const fullStars = Math.round(ratingValue);
    const emptyStars = maxRating - fullStars;

    const stars = fullStar.repeat(fullStars) + emptyStar.repeat(emptyStars);

    return `${stars}`;
  }

  const handleConfirmation = (invitation, isAccepted) => {
    invitation.isAccepted = isAccepted;
    const _value = JSON.stringify(invitation);
    toast(
      <ToastConfirmation
        prop1={_value}
        prop2={handleConfirm}
        prop3={handleCancel}
      />,
      { position: toast.POSITION.TOP_CENTER, closeButton: false }
    );
  };

  const handleConfirm = async (event) => {
    toast.dismiss();
    const data = JSON.parse(event.target.dataset.userid);
    // Perform the action
    performInvitationAction(data);
  };

  const handleCancel = () => {
    toast.dismiss();
  };

  return (
    <div className="row">
      {!loading ? (
        <div className="col-md-12">
          <div className="main__clinic__home">
            <div class="clinic__home__box">
              <div className="clinic__profile__box">
                {clinicDetails?.user?.avatarImageUrl ? (
                  <img
                    src={clinicDetails?.user?.avatarImageUrl}
                    style={{ maxWidth: "auto" }}
                  />
                ) : (
                  <div className="clinic__profile__img"></div>
                )}
              </div>
              <div className="clinic__home__type">
                <div className="clinic__home__head">
                  <h2>{clinicDetails?.name || "N/A"}</h2>
                  <h5>
                    {clinicDetails?.weekAvailability?.filter(
                      (item) =>
                        item?.day == new Date(Date.now()).getDay() &&
                        item.startTime != ""
                    ) == ""
                      ? "No slots available today"
                      : "Timings: " +
                        clinicDetails?.weekAvailability?.filter(
                          (item) =>
                            item?.day == new Date(Date.now()).getDay() &&
                            item.startTime != ""
                        )?.[0].startTime +
                        " - " +
                        clinicDetails?.weekAvailability?.filter(
                          (item) =>
                            item?.day == new Date(Date.now()).getDay() &&
                            item.startTime != ""
                        )?.[0].endTime}
                  </h5>
                </div>
              </div>
              {clinicDetails?.about ? (
                <div className="clinic__home__desc">
                  <div className="clinic__home__desc__box">
                    <div className="clinic__profile__img__box">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        class="w-6 h-6"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
                        />
                      </svg>
                    </div>

                    <div className="clinic__profile__desc__box">
                      <p>{clinicDetails?.about}</p>
                    </div>
                  </div>
                  {/* <div className="show__content__btn">
                <button className="btn btn_show__more">Show More</button>
              </div> */}
                </div>
              ) : (
                ""
              )}
              <div className="clinic__home__date__time">
                <div className="direction__parking__box">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-6 h-6"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z"
                    />
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z"
                    />
                  </svg>
                  <h6>Direction And Parking</h6>
                </div>
                <div className="direction__parking__content">
                  <p>{clinicDetails?.addressLine1 || ""}</p>
                </div>
                <div className="direction__parking__map">
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3385.2906520659753!2d115.85662481069919!3d-31.953010722288724!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2a32bad662095195%3A0x79028441e191c9de!2sMurray%20Street%20Mall%2C%20Perth%20WA%206000%2C%20Australia!5e0!3m2!1sen!2sin!4v1689679478114!5m2!1sen!2sin"
                    width="100%"
                    height="150px"
                    allowfullscreen=""
                    loading="lazy"
                    referrerpolicy="no-referrer-when-downgrade"
                  ></iframe>
                </div>
              </div>
              <div className="clinic__home__location">
                <div className="clinic__home__time__box mb-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-6 h-6"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z"
                    />
                  </svg>
                  <a href="tel:+86 8764876334">
                    {clinicDetails?.user?.contactNumber || "N/A"}
                  </a>
                </div>
                <div className="clinic__home__time__box">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-6 h-6"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75"
                    />
                  </svg>
                  <a href="">{clinicDetails?.user?.email || "N/A"}</a>
                </div>
              </div>
            </div>
          </div>
          <div className="incoming__request__container">
            <div className="incoming__request__head">
              <h3>Incoming Request</h3>
            </div>
            <div className="row">
              {invitationList.length > 0 ? (
                invitationList.map((invitation) => (
                  <>
                    <div className="col-md-3 mb-3">
                      <div className="incoming__request__wrap">
                        <div className="incoming__request__box">
                          <div className="incoming__request__profile">
                            <DefaultAvatar className="m-auto profile__img__setting" />
                          </div>
                          <div className="incoming__request__details">
                            <div class="star-rating">
                              {displayStarRating(
                                invitation?.doctor?.averageRating
                              ) || ""}
                            </div>
                            <div className="incoming__request__doc__name">
                              <p>
                                Dr.
                                {invitation?.doctor?.user["firstName"] +
                                  " " +
                                  invitation?.doctor?.user["lastName"] || "N/A"}
                              </p>
                            </div>
                            <div className="doc__exp__year__box">
                              <div className="doc__desig__box">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke-width="1.5"
                                  stroke="currentColor"
                                  class="w-6 h-6"
                                >
                                  <path
                                    stroke-linecap="round"
                                    d="M16.5 12a4.5 4.5 0 11-9 0 4.5 4.5 0 019 0zm0 0c0 1.657 1.007 3 2.25 3S21 13.657 21 12a9 9 0 10-2.636 6.364M16.5 12V8.25"
                                  />
                                </svg>
                                <p>
                                  {capitalizeFirstChar(
                                    invitation?.doctor?.specialities[0]
                                      ?.masterSpeciality.name
                                  )}
                                </p>
                              </div>
                              <div className="doct__exp__year__box">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke-width="1.5"
                                  stroke="currentColor"
                                  class="w-6 h-6"
                                >
                                  <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    d="M4.26 10.147a60.436 60.436 0 00-.491 6.347A48.627 48.627 0 0112 20.904a48.627 48.627 0 018.232-4.41 60.46 60.46 0 00-.491-6.347m-15.482 0a50.57 50.57 0 00-2.658-.813A59.905 59.905 0 0112 3.493a59.902 59.902 0 0110.399 5.84c-.896.248-1.783.52-2.658.814m-15.482 0A50.697 50.697 0 0112 13.489a50.702 50.702 0 017.74-3.342M6.75 15a.75.75 0 100-1.5.75.75 0 000 1.5zm0 0v-3.675A55.378 55.378 0 0112 8.443m-7.007 11.55A5.981 5.981 0 006.75 15.75v-1.5"
                                  />
                                </svg>
                                <p>
                                  {invitation?.doctor?.experinceInYears || "0"}{" "}
                                  Year{" "}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="incoming__request__btn__box">
                          <buttn
                            onClick={() => handleConfirmation(invitation, 1)}
                            className="btn btn__approved__request"
                          >
                            Accept
                          </buttn>
                          <buttn
                            onClick={() => handleConfirmation(invitation, 2)}
                            className="btn btn__cancel__request"
                          >
                            Cancel
                          </buttn>
                        </div>
                        <a href="#" className="crosss__btn__request">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            class="w-6 h-6"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M12 4.5v15m7.5-7.5h-15"
                            />
                          </svg>
                        </a>
                      </div>
                    </div>
                    <div className="show__content__btn">
                      <Link to="/clinic/invitations">
                        {" "}
                        <button className="btn btn_show__more">
                          Show More
                        </button>
                      </Link>
                    </div>
                  </>
                ))
              ) : (
                <p>No New Request...</p>
              )}
            </div>
          </div>
        </div>
      ) : (
        <LoadingDots />
      )}
    </div>
  );
};

export default ClinicHome;
