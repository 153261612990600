import React, { useEffect, useRef, useState } from "react";
import { useAuth } from "../useAuth";
import DefaultAvatar from "./DefaultAvatar";
import Button from "./Button";
import AvatarEditor from "react-avatar-editor";
import { ZoomInIcon, ZoomOutIcon } from "@heroicons/react/solid";
import Slider from "rc-slider";
import SmallLoadingSpinner from "./SmallLoadingSpinner";
import classnames from "classnames";
import ChangePasswordForm from "./ChangePasswordForm";
import {
  getDoctorProfile,
  updateDoctorProfile,
  updateDoctorProfileImage,
} from "../services/doctor/services";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useHistory } from "react-router-dom";
import { FormProvider, useForm } from "react-hook-form";
import { servicePostFileUpload } from "../services/clinic/clinicApiService";
import {
  serviceGetClinicList,
  serviceGetMasterSpecialityList,
} from "../services/apiService";
import FileDropzone from "./FileDropzone";

const GeneralAccountDoctorSettings = () => {
  // const { register: registerName, handleSubmit: handleNameSubmit, formState: { errors: nameErrors } } = useForm();
  // const { register: registerContact, handleSubmit: handleContactSubmit, formState: { errors: contactErrors } } = useForm();
  const methods = useForm({ mode: "onBlur" });
  const [submitting, setSubmitting] = useState(false);
  const [loading, setLoading] = useState(false);
  const [userProfile, setUserProfile] = useState({});
  const [avatarImage, setAvatarImage] = useState();
  const [avatarImageScale, setAvatarImageScale] = useState(1.2);
  const [salutation, setSalutation] = useState("Mr");
  const [item, setItem] = useState({});
  const [itemSpeciality, setItemSpeciality] = useState({});
  const [pageTable, setPageTable] = useState(0);
  const [clinic, setClinic] = useState("");
  const [specialty, setSpecialty] = useState("");
  const [doctorFile, setDoctorFile] = useState({});
  const [documentChanged, setDocumentChanged] = useState(false);
  const filezoneRef = useRef();

  const avatarImageInput = useRef(null);
  const avatarImageEditor = useRef(null);
  const history = useHistory();
  const [error, setError] = useState(false);
  const { user } = useAuth();

  const validationSchema = Yup.object({
    firstName: Yup.string()
      .matches(
        /^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*)$/gi,
        "First name can only contain Latin letters."
      )
      .required("First name is required")
      .max(25, "Max limit exceeded"),
    middleName: Yup.string()
      .matches(
        /^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*)$/gi,
        "Middle name can only contain Latin letters."
      )
      .max(25, "Max limit exceeded"),
    lastName: Yup.string()
      .matches(
        /^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*)$/gi,
        "Last name can only contain Latin letters."
      )
      .required("Last name is required")
      .max(25, "Max limit exceeded"),
    gender: Yup.mixed()
      .oneOf(["male", "female", "other"], "Invalid gender")
      .defined()
      .required(),
    dob: Yup.date()
      .nullable()
      .min(new Date(1900, 0, 1), "Invalid Date")
      .max(new Date(), "Invalid Date")
      .required("Date of birth is required."),
    doctor: Yup.object().shape({
      about: Yup.string().max(300, "Max limit exceeded").nullable().optional(),
      education: Yup.string()
        .max(50, "Max limit exceeded")
        .nullable()
        .required("Education is required."),
      experinceInYears: Yup.number()
        .nullable()
        .min(0, "Min Experience of doctor is 0.")
        .max(80, "Max Experience of doctor is 80."),
      licenceNumber: Yup.string().required(
        "Medical licence number is required"
      ),
      licenceExpiryDate: Yup.date().required(
        "Medical licence expiry date is required"
      ),
    }),
    // ["doctor.about"]: Yup.string().max(300, "Max limit exceeded").optional(),
    // ["doctor.education"]: Yup.string().max(50, "Max limit exceeded").optional(),
    // ["doctor.experinceInYears"]: Yup.number()
    //   .nullable()
    //   .min(0, "Min Experience of doctor is 0.")
    //   .max(80, "Max Experience of doctor is 80."),
    //   ["doctor.licenceNumber"]: Yup.string().required(),
    experinceInYears: Yup.number()
      .min(0, "Invalid experience")
      .max(100, "Invalid experience"),
    files: Yup.array(),
    // ["doctor.licenceExpiryDate"]: Yup.date().required(
    //   "Medical licence expiry date is required"
    // ),
  });

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      middleName: "",
      gender: "",
      age: "",
      dob: "",
      doctor: {
        about: "",
        education: "",
        experinceInYears: "",
        licenceExpiryDate: "",
        licenceNumber: "",
      },
      // clinics: [],
      // specialities: [],
      experinceInYears: "",
      files: [],
    },
    validationSchema,
    onSubmit: (values) => {
      webHandleSubmit(values);
    },
  });

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await getDoctorProfile();
      setLoading(false);
      if (response.status == 0) {
        toast.error(response.message);
      }
      if (response.status == 1) {
        //toast.success(response.message);
        setUserProfile(response.data);
        const userPro = response.data;
        setSalutation(userPro?.user?.salutation);
        const licenceExpiryDate = new Date(userPro?.licenceExpiryDate);
        const newValues = {
          salutation: salutation,
          firstName: userPro?.user?.firstName,
          lastName: userPro?.user?.lastName,
          middleName: userPro?.user?.middleName || "",
          gender: userPro?.user?.gender?.toLowerCase(),
          age: userPro?.user?.age,
          dob: userPro?.user?.dob,
          doctor: {
            about: userPro?.about,
            education: userPro?.education,
            experinceInYears: userPro?.experinceInYears,
            licenceExpiryDate: `${licenceExpiryDate.getFullYear()}-${(
              licenceExpiryDate.getMonth() + 1
            )
              .toString()
              .padStart(2, "0")}-${licenceExpiryDate.getDate()}`,
            licenceNumber: userPro?.licenceNumber,
          },
        };

        userPro?.specialities?.[0] &&
          handleOnSpecialitySelect(userPro?.specialities?.[0]);
        formik.setValues(newValues);
      }
    } catch (error) {
      setLoading(false);
      toast.error(error);
    }
  };

  function onScaleChange(value) {
    setAvatarImageScale(value);
  }

  async function uploadAvatarImage() {
    setSubmitting(true);
    let avatarImageUrl = "";
    if (avatarImageEditor.current) {
      // const avatarDataUrl = avatarImageEditor.current
      //   .getImageScaledToCanvas()
      //   .toDataURL();
      //upload file
      let fileData = {
        file: avatarImage,
        destination: "/avatar",
      };
      avatarImageUrl = await servicePostFileUpload(fileData);

      let data = {
        avatarImageUrl: avatarImageUrl?.data?.filePath,
      };
      const registerData = await updateDoctorProfileImage(data);

      if (registerData.status == 0) {
        setSubmitting(false);
        toast.error(registerData.message);
      }
      if (registerData.status == 1) {
        setSubmitting(false);
        setAvatarImage(null);
        toast.success(registerData.message);
        window.location.reload();
      }
    } else {
      return null;
    }
  }

  async function webHandleSubmit(_values) {
    try {
      setError("");
      setLoading(true);
      let data = _values;
      let fileResponse = undefined;

      //upload file
      if (doctorFile?.path) {
        let fileData = {
          file: doctorFile,
          destination: "/doctor",
        };
        fileResponse = await servicePostFileUpload(fileData);
        if (fileResponse.status == 0) {
          return toast.error(fileResponse.message);
        }
      }

      data = {
        salutation: salutation,
        firstName: _values.firstName,
        middleName: _values.middleName || "",
        lastName: _values.lastName,
        email: _values.email,
        contactNumber: _values.contactNumber,
        password: _values.password,
        gender: salutation == "Mr" ? "Male" : "Female",
        age: _values?.age,
        dob: _values?.dob,
        // clinics: clinic,
        // specialities: specialty,
        doctor: {
          ..._values.doctor,
          licenceExpiryDate: new Date(
            _values.doctor.licenceExpiryDate
          ).toISOString(),
        },
        documents: [
          {
            name: documentChanged
              ? fileResponse.data.filePath.substring(
                  fileResponse.data.filePath.lastIndexOf("/") + 1
                )
              : userProfile?.user?.documents?.[0]?.name,
            documentUrl: documentChanged
              ? fileResponse.data.filePath
              : userProfile?.user?.documents?.[0]?.documentUrl,
          },
        ],
        licenceExpiryDate: _values.licenceExpiryDate,
        licenceNumber: _values.licenceNumber,
      };

      const registerData = await updateDoctorProfile(data);

      if (registerData.status == 0) {
        toast.error(registerData.message);
      }
      if (registerData.status == 1) {
        toast.success(registerData.message);
        setDocumentChanged(false);
        filezoneRef?.current?.clearChild();
      }
      setLoading(false);
    } catch (e) {
      setError(e.message);
      setLoading(false);
    }
  }

  const handleSalutationChange = (event) => {
    setSalutation(event.target.value);
  };

  async function fetchClinics(keywords = "") {
    try {
      const res = await serviceGetClinicList(pageTable, keywords);

      setLoading(false);
      if (res.status == 0) {
        toast.error(res.message);
      }

      if (res.status == 1) {
        let _items = [];
        res.data?.forEach((element, index) => {
          if (element) {
            _items.push({ id: element.uid, name: element?.name });
          }
        });
        setItem(_items);
      }
    } catch (error) {
      toast.error(error.message);
      setLoading(false);
    }
  }

  async function fetchMasterSpeciality(keywords = "") {
    try {
      const res = await serviceGetMasterSpecialityList(keywords);

      setLoading(false);
      if (res.status == 0) {
        toast.error(res.message);
      }

      if (res.status == 1) {
        let _items = [];
        res.data?.forEach((element, index) => {
          if (element) {
            _items.push({ id: element.id, name: element?.name });
          }
        });
        setItemSpeciality(_items);
      }
    } catch (error) {
      toast.error(error.message);
      setLoading(false);
    }
  }

  const handleOnSelect = (item) => {
    setPageTable(0);
    let id = item.id.trim();
    let clincArr = [
      {
        clinicID: id,
      },
    ];
    setClinic([
      {
        clinicID: id,
      },
    ]);
    formik.setFieldValue("clinics", clincArr);
  };

  const handleOnSpecialitySelect = (item) => {
    setPageTable(0);
    let id = item.id.trim();
    let specialityArr = [
      {
        specialityId: id,
      },
    ];
    setSpecialty([
      {
        specialityId: id,
      },
    ]);
    formik.setFieldValue("specialities", specialityArr);
  };

  const onClear = () => {
    setClinic("");
    fetchClinics();
  };

  const onSpecialityClear = () => {
    setSpecialty("");
    fetchMasterSpeciality();
  };

  const formatResult = (item) => {
    return (
      <>
        {/* <span style={{ display: 'block', textAlign: 'left' }}>id: {item.id}</span> */}
        <span style={{ display: "block", textAlign: "left" }}>{item.name}</span>
      </>
    );
  };

  const handleOnSearch = (_value) => {
    fetchClinics(_value);
  };

  const handleOnSpecialitySearch = (_value) => {
    fetchMasterSpeciality(_value);
  };

  // useEffect(() => {
  //   fetchClinics();
  //   fetchMasterSpeciality();
  // }, [pageTable]);

  const handleFileSelect = async (_selectedFile) => {
    try {
      if (_selectedFile) {
        if (_selectedFile.size <= 5 * 1024 * 1024) {
          formik.setFieldValue("files", [_selectedFile]);
          setDoctorFile(_selectedFile);
          setDocumentChanged(true);
        } else {
          setDoctorFile(null);
          return toast.error("File size exceeds 5 MB");
        }
      }
    } catch (e) {
      toast.error(e.message);
    }
  };

  return (
    <div>
      {/* <SectionTitle>Account Settings</SectionTitle> */}
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <div className="profile__image__box">
              {!avatarImage ? (
                userProfile?.user?.avatarImageUrl ? (
                  <img
                    src={userProfile?.user.avatarImageUrl}
                    className="margin-auto rounded-full col-span-3 profile__img__setting"
                    alt="Avatar"
                  />
                ) : (
                  <DefaultAvatar className="m-auto profile__img__setting" />
                )
              ) : (
                <>
                  <div className="d-flex flex-column justify-content-center align-items-center">
                    <AvatarEditor
                      ref={avatarImageEditor}
                      className="justify-self-center w-full"
                      color={[0, 0, 0, 0.6]} // RGBA
                      scale={avatarImageScale}
                      rotate={0}
                      image={avatarImage}
                    />
                    <div className="flex items-center mt-2 w-[250px]">
                      <ZoomOutIcon className="h-5 w-5 text-gray-600 " />
                      <Slider
                        className="mx-3"
                        min={1}
                        max={2}
                        step={0.01}
                        defaultValue={1.2}
                        onChange={onScaleChange}
                      />
                      <ZoomInIcon className="h-5 w-5 text-gray-600" />
                    </div>
                  </div>
                </>
              )}
              {avatarImage ? (
                <>
                  <div className="row">
                    <div className="col-6 text-right">
                      <Button
                        onClick={() => setAvatarImage(null)}
                        layout="neutral"
                      >
                        Cancel
                      </Button>
                    </div>
                    <div className="col-6 text-left">
                      <Button
                        onClick={uploadAvatarImage}
                        layout="submit"
                        disabled={submitting}
                      >
                        {submitting && (
                          <SmallLoadingSpinner className="h-5 w-5 mr-2 -ml-1" />
                        )}
                        Save
                      </Button>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <Button
                    className="btn__profile__change"
                    onClick={() => avatarImageInput.current.click()}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="w-6 h-6"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
                      />
                    </svg>
                  </Button>
                  <input
                    type="file"
                    accept="image/*"
                    className="hidden"
                    ref={avatarImageInput}
                    onChange={(e) => setAvatarImage(e.target.files[0])}
                  />
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="gap-3 setting__form">
        <div className="py-3 border-gray-400 col-span-3 grid grid-cols-2 gap-y-3 items-center">
          <FormProvider {...methods}>
            <form
              onSubmit={formik.handleSubmit}
              action="#"
              method="POST"
              className="col-span-2"
            >
              <div className="form__box__container">
                <div className="grid grid-cols-4 gap-3">
                  <div className="col-span-2 sm:col-span-1">
                    <label className="form-label">Salutation</label>
                    <select
                      class="form-control cust__form"
                      name="salutation"
                      value={salutation}
                      onChange={handleSalutationChange}
                    >
                      <option value="Mr">Mr</option>
                      <option value="Mrs">Mrs</option>
                      <option value="Ms">Ms</option>
                    </select>
                  </div>
                  <div className="col-span-2 sm:col-span-1">
                    <label className="form-label">First Name</label>
                    <input
                      type="text"
                      name="firstName"
                      id="firstName"
                      style={{ textTransform: "capitalize" }}
                      placeholder="First Name"
                      className={classnames("form-control cust__form", {
                        "border-red-500":
                          formik.touched.firstName && formik.errors.firstName,
                      })}
                      {...formik.getFieldProps("firstName")}
                    />
                    {formik.touched.firstName && formik.errors.firstName && (
                      <p className="block text-sm font-medium text-red-700 mt-2">
                        {formik.errors.firstName}
                      </p>
                    )}
                  </div>
                  <div className="col-span-2 sm:col-span-1">
                    <label className="form-label">Middle Name</label>
                    <input
                      type="text"
                      name="middleName"
                      id="middleName"
                      placeholder="Middle Name"
                      className="form-control cust__form"
                      style={{ textTransform: "capitalize" }}
                      {...formik.getFieldProps("middleName")}
                    />
                    {formik.touched.middleName && formik.errors.middleName && (
                      <p className="block text-sm font-medium text-red-700 mt-2">
                        {formik.errors.middleName}
                      </p>
                    )}
                  </div>
                  <div className="col-span-2 sm:col-span-1">
                    <label className="form-label">Last Name</label>
                    <input
                      type="text"
                      name="lastName"
                      id="lastName"
                      placeholder="Last Name"
                      style={{ textTransform: "capitalize" }}
                      className={classnames("form-control cust__form", {
                        "border-red-500":
                          formik.touched.lastName && formik.errors.lastName,
                      })}
                      {...formik.getFieldProps("lastName")}
                    />
                    {formik.touched.lastName && formik.errors.lastName && (
                      <p className="block text-sm font-medium text-red-700 mt-2">
                        {formik.errors.lastName}
                      </p>
                    )}
                  </div>

                  <div className="col-span-2 sm:col-span-1">
                    <label className="form-label">Email</label>
                    <input
                      type="text"
                      name="email"
                      placeholder="Email"
                      className={classnames("form-control cust__form")}
                      disabled={true}
                      defaultValue={userProfile?.user?.email}
                    />
                  </div>
                  <div className="col-span-2 sm:col-span-1">
                    <label className="form-label">Mobile</label>
                    <input
                      type="text"
                      name="contactNumber"
                      placeholder="Mobile"
                      className={classnames("form-control cust__form")}
                      disabled={true}
                      defaultValue={userProfile?.user?.contactNumber}
                    />
                  </div>
                  <div className="col-span-2 sm:col-span-1">
                    <label className="form-label">Gender</label>
                    <select
                      name="gender"
                      id="gender"
                      placeholder="Gender"
                      {...formik.getFieldProps("gender")}
                      className={classnames("form-control cust__form", {
                        "border-red-500":
                          formik.touched.gender && formik.errors.gender,
                      })}
                    >
                      <option value="">Please select gender</option>
                      <option value={"male"}>Male</option>
                      <option value={"female"}>Female</option>
                    </select>
                    {formik.touched.gender && formik.errors.gender && (
                      <p className="block text-sm font-medium text-red-700 mt-2">
                        {formik.errors.gender}
                      </p>
                    )}
                  </div>
                  <div className="col-span-2 sm:col-span-1">
                    <label className="form-label">Date of Birth</label>
                    <input
                      type="date"
                      name="dob"
                      id="dob"
                      placeholder="dd-mm-yyyy"
                      max={new Date()}
                      className={classnames("form-control cust__form", {
                        "border-red-500":
                          formik.touched.dob && formik.errors.dob,
                      })}
                      {...formik.getFieldProps("dob")}
                    />
                    {formik.touched.dob && formik.errors.dob && (
                      <p className="block text-sm font-medium text-red-700 mt-2">
                        {formik.errors.dob}
                      </p>
                    )}
                  </div>
                  <div className="col-span-2 sm:col-span-1">
                    <label className="form-label">Education</label>
                    <input
                      type="text"
                      name="doctor.education"
                      id="doctor.education"
                      placeholder="Education"
                      className={classnames("form-control cust__form", {
                        "border-red-500":
                          formik.touched?.doctor?.education &&
                          formik.errors?.doctor?.education,
                      })}
                      {...formik.getFieldProps("doctor.education")}
                    />
                    {formik.touched?.doctor?.education &&
                      formik.errors?.doctor?.education && (
                        <p className="block text-sm font-medium text-red-700 mt-2">
                          {formik.errors?.doctor?.education}
                        </p>
                      )}
                  </div>
                  <div className="col-span-2 sm:col-span-1">
                    <label className="form-label">
                      Total Experience (In Years)
                    </label>
                    <input
                      type="text"
                      name="doctor.experinceInYears"
                      id="doctor.experinceInYears"
                      placeholder="Total Experience (Years)"
                      className={classnames("form-control cust__form", {
                        "border-red-500":
                          formik.touched?.doctor?.experinceInYears &&
                          formik.errors?.doctor?.experinceInYears,
                      })}
                      {...formik.getFieldProps("doctor.experinceInYears")}
                    />

                    {formik.touched?.doctor?.experinceInYears &&
                      formik.errors?.doctor?.experinceInYears && (
                        <p className="block text-sm font-medium text-red-700 mt-2">
                          {formik.errors?.doctor?.experinceInYears}
                        </p>
                      )}
                  </div>
                  <div className="col-span-2 sm:col-span-1">
                    <label className="form-label">About</label>
                    <input
                      type="text"
                      name="doctor.about"
                      id="doctor.about"
                      placeholder="About"
                      className={classnames("form-control cust__form", {
                        "border-red-500":
                          formik.touched?.doctor?.about &&
                          formik.errors?.doctor?.about,
                      })}
                      {...formik.getFieldProps("doctor.about")}
                    />
                    {formik.touched?.doctor?.about &&
                      formik.errors?.doctor?.about && (
                        <p className="block text-sm font-medium text-red-700 mt-2">
                          {formik.errors?.doctor?.about}
                        </p>
                      )}
                  </div>
                  <div className="col-span-2 sm:col-span-1">
                    <label className="form-label">Clinic</label>
                    <select className="form-control cust__form">
                      {userProfile?.clinics?.map((item, index) => (
                        <option key={index} value={item?.clinic?.name}>
                          {item?.clinic?.name}
                        </option>
                      ))}
                    </select>
                    {/* <ReactSearchAutocomplete
                      items={item}
                      onSearch={handleOnSearch}
                      //onHover={handleOnHover}
                      onSelect={handleOnSelect}
                      // onFocus={handleOnFocus}
                      onClear={onClear}
                      autoFocus
                      formatResult={formatResult}
                      placeholder="Select Clinic"
                      {...formik.getFieldProps("clinic")}
                    />
                    {formik.touched.clinic && formik.errors.clinic && (
                      <div className="form_errors">{formik.errors.clinic}</div>
                    )} */}
                  </div>
                  <div className="col-span-2 sm:col-span-1">
                    <label className="form-label">Specialty</label>
                    <input
                      type="text"
                      className="form-control cust__form"
                      value={userProfile?.specialities

                        ?.map((item) => item.masterSpeciality?.name)

                        .join(", ")}
                      disabled
                    />
                  </div>
                  {/* Licence expiry date */}
                  <div className="col-span-2 sm:col-span-1">
                    <label className="form-label">Licence Expiry Date</label>
                    <input
                      min={new Date().toISOString().split("T")[0]}
                      type="date"
                      name="doctor.licenceExpiryDate"
                      id="doctor.licenceExpiryDate"
                      className={classnames("form-control cust__form", {
                        "border-red-500":
                          formik.touched?.doctor?.licenceExpiryDate &&
                          formik.errors?.doctor?.licenceExpiryDate,
                      })}
                      {...formik.getFieldProps("doctor.licenceExpiryDate")}
                    />
                    {formik.touched?.doctor?.licenceExpiryDate &&
                      formik.errors?.doctor?.licenceExpiryDate && (
                        <p className="block text-sm font-medium text-red-700 mt-2">
                          {formik.errors?.doctor?.licenceExpiryDate}
                        </p>
                      )}
                  </div>
                  {/* End of licence expiry date */}
                  {/* Licence Number */}
                  <div className="col-span-2 sm:col-span-1">
                    <label className="form-label">Licence Number</label>
                    <input
                      type="text"
                      name="doctor.licenceNumber"
                      id="doctor.licenceNumber"
                      className={classnames("form-control cust__form", {
                        "border-red-500":
                          formik.touched?.doctor?.licenceNumber &&
                          formik.errors?.doctor?.licenceNumber,
                      })}
                      {...formik.getFieldProps("doctor.licenceNumber")}
                    />
                    {formik.touched?.doctor?.licenceNumber &&
                      formik.errors?.doctor?.licenceNumber && (
                        <p className="block text-sm font-medium text-red-700 mt-2">
                          {formik.errors?.doctor?.licenceNumber}
                        </p>
                      )}
                  </div>
                  {/* End of licence number */}
                  <div className="col-span-4 col-md-12 mb-3">
                    <label className="form-label">
                      Medical licence<span>&nbsp;</span>
                      {!documentChanged && (
                        <p>
                          <a
                            target="_blank"
                            href={
                              userProfile?.user?.documents?.[0]?.documentUrl
                            }
                          >
                            {userProfile?.user?.documents?.[0]?.name
                              ? userProfile?.user?.documents?.[0]?.name
                              : userProfile?.user?.documents?.[0]?.documentUrl}
                          </a>
                        </p>
                      )}
                      <input
                        type="hidden"
                        className="form-control"
                        name="files"
                      ></input>
                      {formik.touched.files && formik.errors.files && (
                        <div className="form_errors">{formik.errors.files}</div>
                      )}
                    </label>
                    <FileDropzone
                      ref={filezoneRef}
                      accept=".pdf, image/jpeg, image/png, image/jpg"
                      name={!documentChanged ? "idImage" : null}
                      handleFileSelect={handleFileSelect}
                      onClear={setDocumentChanged}
                      clear={documentChanged}
                    />
                    <div className="form_errors">
                      Note: Please upload image or PDF files only ,and the file
                      size should be below 5 MB{" "}
                    </div>
                  </div>
                  <div className="col-md-12 mt-3">
                    <Button type="submit" disabled={loading}>
                      {loading && (
                        <SmallLoadingSpinner className="h-5 w-5 mr-2 -ml-1" />
                      )}
                      Update
                    </Button>
                  </div>
                </div>
              </div>
            </form>
          </FormProvider>
        </div>
        {/* <div className='form__box__container mb-3'>
                    <div className='row'>
                        <div className='col-md-4'>
                            <label className="form-label">
                            Add Documents
                            </label>
                            <input
                                type="file"
                                name="Specialization"
                                placeholder="Add Documents"
                                className={classnames("form-control cust__form",
                                )}
                            />
                        </div>
                    </div>
                </div> */}
        {/* <div class="upcoming__head"><h2>Contact Number</h2></div>
                <form  className="form__box__container">
                
                        <div className='row'>
                            <div className='col-md-4'>
                                <input
                                    type="tel"
                                    name="contact-number"
                                    id="contact-number"
                                    placeholder="Enter contact number"
                                    className={classnames("form-control cust__form",
                                        {
                                            'border-red-500': false
                                        })}
                                    defaultValue={userProfile?.user?.contactNumber}
                                />
                            </div>
                            <div className='col-md-4'>
                                <div className="">
                                    <Button
                                        type="submit"
                                        layout="submit"
                                        disabled={submitting}>{submitting &&
                                        
                                        <SmallLoadingSpinner className="h-5 w-5 mr-2 -ml-1"/>}Update </Button>
                                </div>
                            </div>
                        </div>
                    </form> */}

        <div className="py-3 border-b-2 border-gray-400 items-center">
          <div class="upcoming__head">
            <h2>Change password</h2>
          </div>
          <div className="form__box__container">
            <ChangePasswordForm />
          </div>
        </div>
      </div>
    </div>
  );
};

export default GeneralAccountDoctorSettings;
