import React, { useRef, useState } from "react";
import { LockClosedIcon } from "@heroicons/react/solid";
import { useAuth } from "../../useAuth.js";
import { Link, useHistory, useLocation } from "react-router-dom";
import logo from "../../healthlink_logo.svg";
import SmallLoadingSpinner from "../../components/SmallLoadingSpinner";
import { getMessageFromErrorCode } from "../../common/constants";
import Button from "../../components/Button";
import { Helmet } from "react-helmet-async";

const DoctorLogin = () => {
  const emailRef = useRef();
  const passwordRef = useRef();
  const { signin } = useAuth();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  let location = useLocation();

  // async function webHandleSubmit()
  // {
  //   e.preventDefault();

  //   try {
  //     setError("");
  //     setLoading(true);
  //   }
  //   catch (e) {
  //     setError(getMessageFromErrorCode(e.code));
  //     setLoading(false);
  //   }

  // }

  async function handleSubmit(e) {
    e.preventDefault();

    try {
      setError("");
      setLoading(true);

      const response = await signin(
        emailRef.current.value,
        passwordRef.current.value
      );

      if (response?.user?.accessToken) {
        localStorage.setItem("uid", response.user.uid);
        localStorage.setItem("authToken", response.user.accessToken);
      }

      let { from } = location.state || { from: { pathname: "/" } };
      history.replace(from);

      //don't need to manually navigate to logged in page here, because handled by PrivateRoute
    } catch (e) {
      setError(getMessageFromErrorCode(e.code, e.message));
      setLoading(false);
    }
  }

  function onAlertCloseClick() {
    setError("");
  }

  return (
    <div className="min-h-full">
      <div className="container mt-4">
        <div className="row justify-content-center mb-3">
          <div className="col-md-12">
            <Link to="/" className="flex items-center justify-center">
              <img src={logo} className="h-10 w-10 mr-2" alt="logo" />
              <span className="font-extrabold text-indigo-600 text-3xl">
                HealthLink <sub className="text-base font-bold">Doctor</sub>
              </span>
            </Link>
            <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
              Sign in as Doctor
            </h2>
          </div>
        </div>
        <form
          onSubmit={handleSubmit}
          className="mt-8 space-y-6"
          action="#"
          method="POST"
        >
          <input type="hidden" name="remember" defaultValue="true" />
          <div className="row justify-content-center mb-3">
            <div className="col-md-4">
              <label htmlFor="email-address" class="form-label">
                Email
              </label>
              <input
                ref={emailRef}
                id="email-address"
                name="email"
                type="email"
                autoComplete="email"
                required
                className="form-control cust__form"
              />
            </div>
          </div>
          <div className="row justify-content-center mb-3">
            <div className="col-md-4">
              <label htmlFor="password" className="form-label">
                Password
              </label>
              <input
                ref={passwordRef}
                id="password"
                name="password"
                type="password"
                autoComplete="current-password"
                required
                className="form-control cust__form"
              />
            </div>
          </div>
          <div className="row justify-content-center mb-3">
            <div className="col-md-4 text-end">
              <Link
                to="/passwordReset"
                className="font-medium text-indigo-600 hover:text-indigo-500"
              >
                Forgot your password?
              </Link>
            </div>
          </div>
          <div className="row justify-content-center mb-3">
            <div className="col-md-4 d-flex align-item-center justify-content-center">
              <button
                disabled={loading}
                type="submit"
                className="btn save-btn w-100"
              >
                {loading ? (
                  <>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <SmallLoadingSpinner className="h-5 w-5" />
                    </div>
                  </>
                ) : (
                  "Sign in"
                )}
              </button>
            </div>
          </div>
          <div className="row justify-content-center mb-3">
            <div className="col-md-4">
              <div className="border-t-2 w-full text-center pt-2">Or</div>
            </div>
          </div>
          <div className="row justify-content-center mb-3">
            <div className="col-md-4">
              <Link to="/doctorRegister" className="">
                <button layout="submit" className="btn btn__green w-100">
                  Sign up for a Doctor account
                </button>
              </Link>
            </div>
          </div>
          <div className="row justify-content-center mb-3">
            <div className="col-md-4"></div>
          </div>
          <div className="row justify-content-center mb-3">
            <div className="col-md-4">
              <button className="btn sign-patient-btn w-100">
                Are you a patient? &nbsp;
                <Link
                  to="/patient"
                  className="font-medium text-indigo-600 hover:text-indigo-500"
                >
                  Sign in here instead.
                </Link>
              </button>
            </div>
          </div>
        </form>
      </div>
      <Helmet>
        <meta
          name="description"
          content="Login to your Doctor account from this login page"
        />
      </Helmet>
      <div className="max-w-md w-full space-y-8 center-div">
        {error && (
          <div className="text-white px-6 py-4 border-0 rounded relative mb-4 bg-red-600">
            <span className="inline-block align-middle mr-8">
              <b className="capitalize">Error:</b> {error}
            </span>
            <button
              onClick={onAlertCloseClick}
              className="absolute bg-transparent text-2xl font-semibold leading-none right-0 top-0 mt-4 mr-6 outline-none focus:outline-none"
            >
              <span>×</span>
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default DoctorLogin;
