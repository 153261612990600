import React, {useState} from 'react';
import classnames from "classnames";
import Button from "../../components/Button";
import {FormProvider, useForm} from "react-hook-form";
import SubTitle from "../../components/SubTitle";
import SmallLoadingSpinner from "../../components/SmallLoadingSpinner";
import {axiosInstance, useQuery} from "../../common/constants";
import {DateTime} from "luxon";
import {useHistory} from "react-router-dom";


const DoctorCreatePatient = () => {

    const methods = useForm({mode: "onBlur"});
    const {register, formState: {errors}, handleSubmit} = methods;
    const [submitting, setSubmitting] = useState(false)
    const [birthDateError, setBirthDateError] = useState(false)
    let query = useQuery();
    const defaultEmailInput = query.get("email")
    const history = useHistory()



    async function onSubmit(data) {

        // const userBirthDate = DateTime.fromObject({
        //     year: data.birthDateYear,
        //     month: data.birthDateMonth,
        //     day: data.birthDateDay
        // });

        // if (!userBirthDate.isValid) {
        //     setBirthDateError(true)
        //     return
        // } else {
        //     setBirthDateError(false)
        // }

        // setSubmitting(true)

        // const res = await axiosInstance.post("/doctor/customCreatePatient", {
        //     patientEmail: data.email,
        //     firstName: data.firstName,
        //     lastName: data.lastName,
        //     birthDate: userBirthDate.toSQLDate(),
        //     sex: data.sex,
        //     contactNumber: data.contactNumber
        // }).catch(function (error) {
        //     console.log(error.message)
        // })

        // setSubmitting(false)
        // history.push(`/doctor/patients/${res.data.uid}`)
    }

    return (
        <div className="max-w-6xl mt-5 md:mt-0 justify-self-center">

            <FormProvider {...methods} >
                <form onSubmit={handleSubmit(onSubmit)} method="POST">
                    <div className="shadow overflow-hidden sm:rounded-md">
                        <div className="px-4 py-5 bg-white sm:p-6">
                            <SubTitle>Create new patient</SubTitle>
                            <p className="mb-6 text-sm text-gray-600">Please fill in patient's details. First
                                name
                                and last name are optional.</p>
                            <div className="grid grid-cols-6 gap-6">

                                <div className="col-span-6 sm:col-span-3">
                                    <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                                        Patient's Email*
                                    </label>
                                    <input
                                        type="text"
                                        name="email"
                                        id="email"
                                        defaultValue={defaultEmailInput}
                                        aria-invalid={errors.email ? "true" : "false"}
                                        className={classnames('mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md', {
                                            'border-red-500': errors.email
                                        })}
                                        {...register("email", {
                                            required: "Enter the patient's email. This is necessary to identify the patient.",
                                            pattern: {
                                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                                message: "Enter a valid e-mail address"
                                            }
                                        })}
                                    />
                                    <span className="block text-sm font-medium text-red-700 mt-2">{errors.email?.message}</span>

                                </div>

                                <div className="col-span-6 sm:col-start-1 sm:col-end-4">
                                    <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
                                        First name
                                    </label>
                                    <input
                                        type="text"
                                        name="first-name"
                                        id="first-name"
                                        autoComplete="given-name"
                                        aria-invalid={errors.firstName ? "true" : "false"}
                                        className={classnames('mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md', {
                                            'border-red-500': errors.firstName
                                        })}
                                        {...register("firstName", {maxLength: 255})}
                                    />
                                    {errors.firstName && errors.firstName.type === "maxLength" &&
                                        <p className="block text-sm font-medium text-red-700 mt-2">Input is too
                                            long</p>}
                                </div>

                                <div className="col-span-6 sm:col-span-3">
                                    <label htmlFor="last-name" className="block text-sm font-medium text-gray-700">
                                        Last name
                                    </label>
                                    <input
                                        type="text"
                                        name="last-name"
                                        id="last-name"
                                        autoComplete="family-name"
                                        aria-invalid={errors.lastName ? "true" : "false"}
                                        className={classnames('mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md', {
                                            'border-red-500': errors.lastName
                                        })}
                                        {...register("lastName", {maxLength: 255})}
                                    />
                                    {errors.lastName && errors.lastName.type === "maxLength" &&
                                        <p className="block text-sm font-medium text-red-700 mt-2">Input is too
                                            long</p>}
                                </div>

                                <div className="col-span-6 sm:col-span-3">
                                    <label htmlFor="contact-number" className="block text-sm font-medium text-gray-700">
                                        Contact Number
                                    </label>
                                    <input
                                        type="tel"
                                        name="contact-number"
                                        id="contact-number"
                                        autoComplete="tel"
                                        aria-invalid={errors.contactNumber ? "true" : "false"}
                                        className={classnames('mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md', {
                                            'border-red-500': errors.contactNumber
                                        })}
                                        {...register("contactNumber", {maxLength: 255})}
                                    />
                                    {errors.contactNumber && errors.contactNumber.type === "maxLength" &&
                                        <p className="block text-sm font-medium text-red-700 mt-2">Input is too
                                            long</p>}
                                </div>

                                <div className="col-start-1 col-end-4">
                                    <label htmlFor="sex" className="block text-sm font-medium text-gray-700">
                                        Sex*
                                    </label>
                                    <select name="sex"
                                            className={classnames('mt-1 focus:ring-indigo-500 focus:border-indigo-500 block shadow-sm sm:text-sm border-gray-300 rounded-md', {
                                                'border-red-500': errors.sex
                                            })}
                                            {...register("sex", {required: "Please select one"})}>
                                        <option value=""/>
                                        <option value="MALE">Male</option>
                                        <option value="FEMALE">Female</option>
                                    </select>
                                    {errors.sex &&
                                        <p className="block text-sm font-medium text-red-700 mt-2">{errors.sex.message}</p>}
                                </div>

                                <div className="col-span-6 sm:col-span-3">
                                    <label htmlFor="birth-date" className="block text-sm font-medium text-gray-700">
                                        Patient's Date of Birth*
                                    </label>
                                    <div className="grid grid-cols-3 gap-x-6">
                                        <div className="col-span-1">
                                            <label htmlFor="birthDateDay"
                                                   className="block text-sm font-medium text-gray-700">
                                                Day
                                            </label>
                                            <input
                                                type="text"
                                                name="birthDateDay"
                                                id="birthDateDay"
                                                placeholder="DD"
                                                maxLength="2"
                                                className={classnames('mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md', {
                                                    'border-red-500': errors.birthDateDay || birthDateError
                                                })}
                                                {...register("birthDateDay", {
                                                    required: true,
                                                    pattern: /^[0-9]*$/,
                                                    min: 1,
                                                    max: 31,
                                                    onChange: () => setBirthDateError(false)
                                                })}
                                            />
                                        </div>
                                        <div className="col-span-1">
                                            <label htmlFor="birthDateMonth"
                                                   className="block text-sm font-medium text-gray-700">
                                                Month
                                            </label>
                                            <input
                                                type="text"
                                                name="birthDateMonth"
                                                id="birthDateMonth"
                                                placeholder="MM"
                                                maxLength="2"
                                                onChange={() => setBirthDateError(false)}
                                                inputMode="numeric"
                                                className={classnames('mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md', {
                                                    'border-red-500': errors.birthDateMonth || birthDateError
                                                })}
                                                {...register("birthDateMonth", {
                                                    required: true,
                                                    pattern: /^[0-9]*$/,
                                                    min: 1,
                                                    max: 12,
                                                    onChange: () => setBirthDateError(false)
                                                })}
                                            />
                                        </div>
                                        <div className="col-span-1">
                                            <label htmlFor="birthDateYear"
                                                   className="block text-sm font-medium text-gray-700">
                                                Year
                                            </label>
                                            <input
                                                type="text"
                                                name="birthDateYear"
                                                id="birthDateYear"
                                                placeholder="YYYY"
                                                maxLength="4"
                                                onChange={() => setBirthDateError(false)}
                                                className={classnames('mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md', {
                                                    'border-red-500': errors.birthDateYear || birthDateError
                                                })}
                                                {...register("birthDateYear", {
                                                    required: true,
                                                    pattern: /^[0-9]*$/,
                                                    min: 1900,
                                                    max: 2022,
                                                    onChange: () => setBirthDateError(false)
                                                })}
                                            />
                                        </div>

                                    </div>
                                    {(errors.birthDateYear || errors.birthDateMonth || errors.birthDateDay || birthDateError) &&
                                        <p className="block text-sm font-medium text-red-700 mt-2">Please check birth date</p>}
                                </div>
                            </div>
                        </div>
                        <div className="px-4 py-3 bg-gray-50 text-right flex justify-end items-center gap-3 sm:px-6">

                            <Button type="submit" disabled={submitting}>{submitting &&
                                <SmallLoadingSpinner className="h-5 w-5 mr-2 -ml-1"/>}Save</Button>

                        </div>
                    </div>
                </form>
            </FormProvider>
        </div>
    );
};

export default DoctorCreatePatient;