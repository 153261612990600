import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useParams, useHistory } from "react-router-dom";
import { serviceGetDoctorAppointmentsById } from "../../../services/apiService";
import { formatDate } from "../../../components/FormatDate";

const AppointmentDetail = () => {
  const { id } = useParams();
  const history = useHistory();
  // const history = useHistory();
  // const [loading, setLoading] = useState(false);
  // const [appointmentDetail, setAppointmentDetail] = useState(null);
  // const [isValueLoded, valueLoded] = useState(false);
  const [patientDetails, setPatientDetails] = useState(null);
  // // //------------------------- FUCNCTIONS ------------------
  // const goBack = () => {
  //   history.goBack();
  // };

  useEffect(() => {
    async function getAppointmentsById() {
      if (id) {
        let patientData = await serviceGetDoctorAppointmentsById(id);
        if (patientData.status == 1) {
          setPatientDetails(patientData.data);
        }
      }
    }
    getAppointmentsById();
  }, []);

  // const fetchData = async () => {
  //   try {
  //     setLoading(true);
  //     const response = await getDoctorDetails(appointmentID);
  //     setLoading(false);
  //     if (response.status == 0) {
  //       toast.error(response.message);
  //       setLoading(false);
  //     }
  //     if (response.status == 1) {
  //       setDetails(response.data.user);
  //     }
  //   } catch (error) {
  //     setLoading(false);
  //     toast.error(error);
  //   }
  // };

  return (
    <div>
      <h3 className="patient__details__heading">Appointment Details</h3>
      <div className="patient__details__box">
        <div className="patient__details__list">
          <div className="row">
            <div className="col-sm-4">
              <label className="patient__details__label">First Name : </label>
              <text className="patient__details__value">
                {patientDetails?.firstName}
              </text>
            </div>
            <div className="col-sm-4">
              <label className="patient__details__label">Last Name : </label>
              <text className="patient__details__value">
                {patientDetails?.lastName}
              </text>
            </div>
            <div className="col-sm-4">
              <label className="patient__details__label">Age : </label>
              <text className="patient__details__value">
                {patientDetails?.age || "N/A"}
              </text>
            </div>
            <div className="col-sm-4">
              <label className="patient__details__label">Phone Number : </label>
              <text className="patient__details__value">
                {patientDetails?.phoneNumber}
              </text>
            </div>
            <div className="col-sm-4">
              <label className="patient__details__label">Email : </label>
              <text className="patient__details__value">
                {patientDetails?.email}
              </text>
            </div>
            <div className="col-sm-4">
              <label className="patient__details__label">Gender : </label>
              <text className="patient__details__value">
                {patientDetails?.gender}
              </text>
            </div>
            <div className="col-sm-4">
              <label className="patient__details__label">Date : </label>
              <text className="patient__details__value">
                {formatDate(patientDetails?.date)}
              </text>
            </div>
            <div className="col-sm-4">
              <label className="patient__details__label">Start Time : </label>
              <text className="patient__details__value">
                {patientDetails?.startTime}
              </text>
            </div>
            <div className="col-sm-4">
              <label className="patient__details__label">End Time : </label>
              <text className="patient__details__value">
                {patientDetails?.endTime}
              </text>
            </div>
            <div className="col-sm-4">
              <label className="patient__details__label">Clinic Name : </label>
              <text className="patient__details__value">
                {patientDetails?.clinic?.name}
              </text>
            </div>
            {patientDetails?.consultationType == 1 &&
            patientDetails?.videoLink?.length > 0 ? (
              <div className="col-sm-4">
                <label className="patient__details__label">
                  Consultation Link :{" "}
                </label>
                <a href={patientDetails?.videoLink} target="_blank">
                  <text className="patient__details__value">
                    {patientDetails?.videoLink}
                  </text>
                </a>
                <button
                  className="btn btn-sm"
                  type="button"
                  onClick={() => {
                    navigator.clipboard.writeText(patientDetails?.videoLink);
                    toast.success("copied!");
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-6 h-6"
                  >
                    <title>Copy to clipboard</title>
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M15.666 3.888A2.25 2.25 0 0013.5 2.25h-3c-1.03 0-1.9.693-2.166 1.638m7.332 0c.055.194.084.4.084.612v0a.75.75 0 01-.75.75H9a.75.75 0 01-.75-.75v0c0-.212.03-.418.084-.612m7.332 0c.646.049 1.288.11 1.927.184 1.1.128 1.907 1.077 1.907 2.185V19.5a2.25 2.25 0 01-2.25 2.25H6.75A2.25 2.25 0 014.5 19.5V6.257c0-1.108.806-2.057 1.907-2.185a48.208 48.208 0 011.927-.184"
                    />
                  </svg>
                </button>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      <br></br>
      <div className="col-md-12 d-flex justify-content-between">
        <button
          className="btn cancel-btn ml-3"
          onClick={() => history.goBack()}
        >
          Back
        </button>
      </div>
    </div>
  );
};
export default AppointmentDetail;
