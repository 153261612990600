import React, { useEffect, useState, useRef } from "react";
import { useAuth } from "../../useAuth";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as Yup from "yup";
import classnames from "classnames";
import {
  servicePostMemoFollowups,
  serviceGetMemoFollowups,
} from "../../services/apiService";
import LoadingSpinner from "../../components/LoadingSpinner";
import { formatDate } from "../../components/FormatDate";

const MemoFollowups = ({ patientId, appointmentId }) => {
  const [submitting, setSubmitting] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const { userData } = useAuth();

  const validationSchema = Yup.object({
    nextAppointmentDate: Yup.string().required("Appointment Date is required"),
  });

  const formik = useFormik({
    initialValues: {
      patientID: patientId,
      doctorID: userData.uid,
      appointmentID: appointmentId,
      nextAppointmentDate: "",
    },
    validationSchema,
    onSubmit: (values) => {
      webHandleSubmit(values);
    },
  });

  const formatUIDate = (inputDate) => {
    const dateParts = inputDate.split("/");
    if (dateParts.length === 3) {
      const year = dateParts[2];
      const month = dateParts[0].padStart(2, "0");
      const day = dateParts[1].padStart(2, "0");
      return `${year}-${month}-${day}`;
    }
    return inputDate; // Return as is if not in the expected format
  };

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await serviceGetMemoFollowups(appointmentId);

      if (response.status == 0) {
        setLoading(false);
        toast.error(response.message);
      }
      if (response.status == 1) {
        setLoading(false);
        const followupData = response.data;
        let formattedDate = formatDate(followupData?.nextAppointmentDate);

        const newValues = {
          patientID: patientId,
          doctorID: userData.uid,
          appointmentID: appointmentId,
          nextAppointmentDate: formatUIDate(formattedDate) || "",
        };
        formik.setValues(newValues);
        // formik.setFieldValue('nextAppointmentDate', formatUIDate(newValues.nextAppointmentDate));
      }
    } catch (error) {
      setLoading(false);
      toast.error(error);
    }
  };

  async function webHandleSubmit(_values) {
    try {
      setError("");
      setLoading(true);
      let data = _values;
      const response = await servicePostMemoFollowups(data);
      if (response.status == 0) {
        toast.error(response.message);
      }
      if (response.status == 1) {
        toast.success(response.message);
      }
      setLoading(false);
    } catch (e) {
      setError(e.message);
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchData();
  }, []);
  return (
    <>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <div>
          <div className="gap-3 setting__form">
            <div className="py-3 border-gray-400 col-span-3 grid grid-cols-2 gap-y-3 items-center">
              <form
                onSubmit={formik.handleSubmit}
                action="#"
                method="POST"
                className="col-span-2"
              >
                <div className="form__box__container">
                  <div className="grid grid-cols-4 gap-3">
                    <div className="col-span-2 sm:col-span-2">
                      <label className="form-label">
                        <b>Next Appointment Date</b>
                      </label>
                      <input
                        type="date"
                        name="nextAppointmentDate"
                        id="nextAppointmentDate"
                        placeholder="dd-mm-yyyy"
                        max={new Date()}
                        className={classnames("form-control cust__form", {
                          "border-red-500":
                            formik.touched.nextAppointmentDate &&
                            formik.errors.nextAppointmentDate,
                        })}
                        {...formik.getFieldProps("nextAppointmentDate")}
                        value={formik.values.nextAppointmentDate}
                      />

                      {formik.touched.nextAppointmentDate &&
                        formik.errors.nextAppointmentDate && (
                          <p className="block text-sm font-medium text-red-700 mt-2">
                            {formik.errors.nextAppointmentDate}
                          </p>
                        )}
                    </div>
                    <div className="col-span-1 sm:col-span-1">
                      <div style={{ marginLeft: "20%", marginTop: "10%" }}>
                        <button type="submit" className="setting__doctor__save">
                          Save
                        </button>
                      </div>
                    </div>
                  </div>

                  <br></br>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default MemoFollowups;
