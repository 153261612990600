import React, {useState} from 'react';
import GeneralAccountSettings from "../../components/GeneralAccountSettings";
import {useAuth} from "../../useAuth";
import classnames from "classnames";
import {useForm} from "react-hook-form";
import {PencilIcon, XIcon} from "@heroicons/react/solid";
import Button from "../../components/Button";
import SmallLoadingSpinner from "../../components/SmallLoadingSpinner";
import {axiosInstance} from "../../common/constants";
import GeneralAccountDoctorSettings from '../../components/GeneralAccountDoctorSettings';

const DoctorAccountSettings = () => {
    const [practiceEdit, setPracticeEdit] = useState(false)
    const [practiceSubmitting, setPracticeSubmitting] = useState(false)
    const {userData} = useAuth()
    const {register, handleSubmit, formState: {errors}} = useForm();

    async function onMedicalPracticeSubmit(data) {
        setPracticeSubmitting(true)
        await axiosInstance.post("/doctor/updateMedicalPractice", {
            medicalPractice: data.medicalPractice
        })
        setPracticeSubmitting(false)
        window.location.reload();
    }

    return (<div className="w-100">
        <GeneralAccountDoctorSettings/>
        <form onSubmit={handleSubmit(onMedicalPracticeSubmit)} className="col-span-2">

            <div className="grid grid-cols-2 py-3 gap-y-3">
                <div className="col-span-2 sm:col-span-1">

                    {/* <label className="block font-medium text-gray-700">
                        Medical Practice
                    </label>

                    <div className="flex space-x-3 items-center">
                        {!practiceEdit && <span className="col-span-1">{userData.medicalPractice}</span>}
                        {practiceEdit && <input
                            type="text"
                            name="medical-practice"
                            id="medical-practice"
                            className={classnames("mt-1 col-span-1 focus:ring-indigo-500 focus:border-indigo-500 w-full shadow-sm sm:text-sm border-gray-300 rounded-md", {
                                'border-red-500': errors.medicalPractice
                            })} defaultValue={userData.medicalPractice}
                            {...register("medicalPractice", {required: true})}
                        />}
                        {practiceEdit ?

                            <Button size="small" layout="outline" icon={XIcon}
                                    onClick={() => setPracticeEdit(!practiceEdit)}/>

                            :

                            <Button size="small" layout="outline" icon={PencilIcon}
                                    onClick={() => setPracticeEdit(!practiceEdit)}/>

                        }


                    </div> */}
                    {practiceEdit && errors.medicalPractice &&
                        <p className="block text-sm font-medium text-red-700 mt-2">Please enter a medical practice name</p>}


                </div>
                {practiceEdit && <div className="col-start-1">
                    <Button
                        type="submit"
                        layout="submit"
                        disabled={practiceSubmitting}>{practiceSubmitting &&
                        <SmallLoadingSpinner className="h-5 w-5 mr-2 -ml-1"/>}Update Medical Practice</Button>
                </div>}
            </div>
        </form>


    </div>);
};

export default DoctorAccountSettings;