const apiCall = require('../../Api')

const regiter = async (data, type) => {
    try {
        let _response = await apiCall.postRawJsonData('/api/V1/admin/register/' + type, data)
        return _response
    } catch (error) {
        console.error('Error posting data:', error);
        throw error;
    }
};

const getDoctorDetails = async (uid) => {
    try {
        let _response = await apiCall.getData('/api/V1/admin/doctor/details?uid=' + uid)
        return _response
    } catch (error) {
        console.error('Error posting data:', error);
        throw error;
    }
};

const updateDoctor = async (data, uid) => {
    try {
        let _response = await apiCall.postRawJsonData('/api/V1/admin/doctor/update?uid=' + uid, data)
        return _response
    } catch (error) {
        console.error('Error posting data:', error);
        throw error;
    }
};

const getList = async (skip, take, search = "") => {
    try {
        let _response = await apiCall.getData('/api/V1/admin/doctor/list?skip=' + skip + '&take=' + take + '&search=' + search)
        return _response
    } catch (error) {
        console.error('Error posting data:', error);
        throw error;
    }
};

const deleteUser = async (data) => {
    try {
        let _response = await apiCall.postRawJsonData('/api/V1/admin/user/delete', data)
        return _response
    } catch (error) {
        console.error('Error posting data:', error);
        throw error;
    }
};

const viewAppoiments = async (skip, take, uid = "") => {
    try {
        let _response = await apiCall.getData('/api/V1/admin/get-doctor-appoiments?skip=' + skip + '&take=' + take + '&id=' + uid)
        return _response
    } catch (error) {
        console.error('Error posting data:', error);
        throw error;
    }
};

const getDoctorClinics = async (uid = "") => {
    try {
        let _response = await apiCall.getData('/api/V1/admin/get-doctor-clinics?id=' + uid)
        return _response
    } catch (error) {
        console.error('Error posting data:', error);
        throw error;
    }
};

export {
    regiter,
    getDoctorDetails,
    updateDoctor,
    getList,
    deleteUser,
    viewAppoiments,
    getDoctorClinics
}