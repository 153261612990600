import React from 'react'
import ResetPasswordForm from '../components/ResetPasswordForm'

const ResetAccountPassword = () => {
    return (
        <div className="min-h-full flex flex-col items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
            <div className="max-w-md w-full space-y-8">
                <div>
                    <img
                        className="mx-auto h-12 w-auto"
                        src="https://tailwindui.com/img/logos/workflow-mark-indigo-600.svg"
                        alt="Workflow"
                    />
                    <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">Set Password</h2>
                    <p className="mt-2 text-center text-sm text-gray-600">
                        Please enter your new password to reset your account.
                    </p>
                </div>
                <ResetPasswordForm />
            </div>
        </div>
    )
}


export default ResetAccountPassword
