import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { toast } from 'react-toastify';
import { getDoctorOverAllRating } from '../../services/doctor/services';
import RatingStars from '../ratings/RatingStars';
import { formatDate } from '../FormatDate';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    //marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

// Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
// Modal.setAppElement('#yourAppElement');

const DoctorDetailsModel = ({ doctor, modalIsOpen, closeModal }) => {
  const [overallRating, setOverallRating] = useState({});
  const [rate, setRate] = useState(0);

  useEffect(() => {
    if (doctor?.uid) {
      fetchData()
    }
  }, [doctor])

  const fetchData = async () => {
    try {
      const response = await getDoctorOverAllRating(doctor?.uid);
      if (response.status == 0) {
        toast.error(response.message);
      }
      if (response.status == 1) {
        setRate(response?.data?.averageRating && response?.data?.averageRating != "NaN" ? response?.data?.averageRating : 0);
        setOverallRating(response.data);
      }
    } catch (error) {
      toast.error(error);
    }
  };

  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel="Doctor Details"
    >
      <div id="doctor-modal">
        <div className="modal-header">

          <h5 className="modal-title">Doctor Details</h5>
          <button className="close-button" onClick={closeModal}>X</button>
        </div>

        <div className="modal-body">
          <div className="doctor-image">
            {
              doctor?.user?.avatarImageUrl ?
                <img src={doctor?.user?.avatarImageUrl} className="profile__img__details" alt="Doctor Image" />
                :
                <div className="profile__img__details"></div>
            }
          </div>

          <div className="doctor-contact">
            <h3 className="doctor-name">Dr. {doctor?.user?.firstName} {doctor?.user?.lastName}</h3>
            <p className="doctor-contact-info">Contact: {doctor?.user?.contactNumber}</p>
            <RatingStars rate={rate} setRate={setRate} readOnly={true} />
          </div>

          <table className="doctor-table">
            <tr>
              <th style={{ paddingRight: 20 }}>Gender</th>
              <td>{doctor?.user?.gender}</td>
            </tr>
            <tr>
              <th style={{ paddingRight: 20 }}>Age</th>
              <td>{doctor?.user?.age}</td>
            </tr>
            <tr>
              <th style={{ paddingRight: 20 }}>Date of Birth</th>
              <td>{formatDate(doctor?.user?.dob)}</td>
            </tr>
            <tr>
              <th style={{ paddingRight: 20 }}>Email</th>
              <td>{doctor?.user?.email}</td>
            </tr>
            <tr>
              <th style={{ paddingRight: 20 }}>About</th>
              <td>{doctor?.about}</td>
            </tr>
            <tr>
              <th style={{ paddingRight: 20 }}>Specialization</th>
              <td>{doctor?.specialization} years</td>
            </tr>
            <tr>
              <th style={{ paddingRight: 20 }}>Medical Practice</th>
              <td>{doctor?.medicalPractice}</td>
            </tr>
            <tr>
              <th style={{ paddingRight: 20 }}>Education</th>
              <td>{doctor?.education}</td>
            </tr>
            <tr>
              <th style={{ paddingRight: 20 }}>Experience</th>
              <td>{doctor?.experinceInYears} years</td>
            </tr>
          </table>

          {
            (doctor?.clinics)?.length > 0 &&
            <>
              <h4 className="clinic-details-title">Clinic Details</h4>

              <table className="clinic-table">
                <thead>
                  <tr>
                    <th style={{ paddingRight: 20 }}>Clinic Name</th>
                    <th>Address</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    (doctor?.clinics).map((clinic) => (
                      <tr>
                        <td style={{ paddingRight: 20 }}>{clinic?.clinicName}</td>
                        <td>
                          {
                            (clinic?.addressLine1 ? clinic?.addressLine1 + " " : "") +
                            (clinic?.landmark ? clinic?.landmark + " " : "") +
                            (clinic?.city ? clinic?.city + " " : "") +
                            (clinic?.state ? clinic?.state + " " : "") +
                            (clinic?.pincode ? clinic?.pincode + " " : "")
                          }
                        </td>
                      </tr>
                    ))
                  }
                </tbody>
              </table>
            </>
          }
        </div>
      </div>

    </Modal>
  );
};

export default DoctorDetailsModel;