const apiCall = require("../../Api");

/**
 *
 * @param {*} data
 * @returns
 */

async function getPatientProfile(uid) {
  let _response = await apiCall.getData(`/api/v1/patient/profile?uid=${uid}`);
  return _response;
}

async function updatePatientProfile(data) {
  let _response = await apiCall.postRawJsonData(
    "/api/V1/patient/profile/update/",
    data
  );
  return _response;
}

async function updatePatientProfileImage(data) {
  let _response = await apiCall.postRawJsonData(
    "/api/V1/patient/profile/updateImage/",
    data
  );
  return _response;
}

async function listPatientMemo(patientID, page, search) {
  let _response = await apiCall.getData(
    `/api/v1/patient/memo/list?patientID=${patientID}&page=${page}&search=${search}`
  );
  return _response;
}

export {
  getPatientProfile,
  updatePatientProfile,
  updatePatientProfileImage,
  listPatientMemo,
};
