import React, { useEffect, useState } from "react";
import Table from "../../components/table/Table";
import TableHeader from "../../components/table/TableHeader";
import TableCell from "../../components/table/TableCell";
import TableBody from "../../components/table/TableBody";
import TableRow from "../../components/table/TableRow";
import TableFooter from "../../components/table/TableFooter";
import TableContainer from "../../components/table/TableContainer";
import LoadingDots from "../../components/LoadingDots";
import Pagination from "../../components/Pagination";
import SectionTitle from "../../components/SectionTitle";
import { toast } from "react-toastify";
import ToastConfirmation from "../../components/toastrConfirmation";
import SearchIcon from "../../dashboard/sidenavigation/icons/search";
import RecordPlaceholder from "../../components/RecordPlaceholder";
import {
  serviceInvitationAction,
  serviceListDoctorInvitations,
} from "../../services/doctor/services";

const DoctorInvitationsList = () => {
  const [loading, setLoading] = useState(true);
  const [dataTable, setDataTable] = useState(true);
  const [pageTable, setPageTable] = useState(0);
  const [totalResults, setTotalResults] = useState(0);

  const resultsPerPage = 10;

  function onPageChangeTable(p) {
    setPageTable(p - 1);
  }

  useEffect(() => {
    listInvitations();
  }, [pageTable]);

  async function listInvitations(search = "") {
    const res = await serviceListDoctorInvitations(pageTable, search);
    setDataTable(res.data);
    setTotalResults(res.totalCount);
    setLoading(false);
  }

  const searchInvitation = (event) => {
    let search = document.getElementById("search").value;
    setLoading(true);
    listInvitations(search);
  };

  async function performInvitationAction(invitation) {
    setLoading(true);
    let data = {
      invitationID: invitation.id,
      isAccepted: invitation.isAccepted,
    };
    let action = await serviceInvitationAction(data);
    if (action.status == 1) {
      try {
        listInvitations();
        setLoading(false);
        return toast.success("Invitation updated successfully.");
      } catch (e) {
        setLoading(false);
        return toast.error("Something went wrong,please try again");
      }
    } else {
      setLoading(false);
      toast.error("Something went wrong,please try again");
    }
  }

  const handleConfirmation = (invitation, isAccepted) => {
    invitation.isAccepted = isAccepted;
    const _value = JSON.stringify(invitation);
    toast(
      <ToastConfirmation
        prop1={_value}
        prop2={handleConfirm}
        prop3={handleCancel}
      />,
      { position: toast.POSITION.TOP_CENTER, closeButton: false }
    );
  };

  const handleConfirm = async (event) => {
    toast.dismiss();
    const data = JSON.parse(event.target.dataset.userid);
    // Perform the action
    performInvitationAction(data);
  };

  const handleCancel = () => {
    toast.dismiss();
  };

  return (
    <>
      <SectionTitle>Invitations </SectionTitle>
      <div className="container-fluid">
        <div className="row">
          <div class="col-6 ps-0">
            <input
              type="search"
              class="form-control cust__form"
              id="search"
              placeholder="Search"
              // onKeyUp={(event) => searchReview(event)}
            />
          </div>
          <div class="col-6">
            <button
              type="button"
              class="btn d-flex"
              style={{ background: "#4F46E5", color: "white" }}
              onClick={() => searchInvitation()}
            >
              <SearchIcon />
              <p className="m-0">Search</p>
            </button>
          </div>
        </div>
      </div>
      {!loading ? (
        <TableContainer className="form__box__container mb-8 mt-3">
          <Table>
            <TableHeader>
              <TableRow>
                <TableCell>Sr.</TableCell>
                <TableCell>Clinic Name</TableCell>
                <TableCell>Date</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHeader>
            <TableBody>
              {dataTable && dataTable.length > 0 ? (
                dataTable.map((el, i) => (
                  <TableRow key={i}>
                    <TableCell>
                      <p className="font-semibold text-sm">{i + 1}</p>
                    </TableCell>
                    <TableCell>
                      <p className="font-semibold text-sm">
                        {el?.clinic?.name}
                      </p>
                    </TableCell>
                    <TableCell>
                      <p className="font-semibold text-sm">
                        {new Date(el?.createdAt).getMonth() + 1}/
                        {new Date(el?.createdAt).getDate()}/
                        {new Date(el?.createdAt).getFullYear()}
                      </p>
                    </TableCell>
                    <TableCell>
                      <p className="font-semibold text-sm">
                        {el?.isAccepted == 1 ? (
                          <p className="text-success">Accepted</p>
                        ) : el?.isAccepted == 2 ? (
                          <p className="text-danger">Rejected</p>
                        ) : (
                          <p className="text-warning">Pending</p>
                        )}
                      </p>
                    </TableCell>
                    <TableCell>
                      {el.status != 1 &&
                      (el?.isAccepted == 0 || el?.isAccepted == null) ? (
                        <div>
                          <button
                            className="btn btn-success"
                            onClick={() => handleConfirmation(el, 1)}
                          >
                            Accept
                          </button>
                          <button
                            className="btn btn-danger ms-2"
                            onClick={() => handleConfirmation(el, 2)}
                          >
                            Reject
                          </button>
                        </div>
                      ) : (
                        "-"
                      )}
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <RecordPlaceholder table="true" />
              )}
            </TableBody>
          </Table>
          {totalResults > 0 && (
            <TableFooter>
              <Pagination
                totalResults={totalResults}
                resultsPerPage={resultsPerPage}
                onChange={onPageChangeTable}
                label="Table navigation"
              />
            </TableFooter>
          )}
        </TableContainer>
      ) : (
        <LoadingDots />
      )}
    </>
  );
};

export default DoctorInvitationsList;
