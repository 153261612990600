const apiCall = require("../../Api");

/**
 *
 * @param {*} data
 * @returns
 */

async function getDoctorProfile() {
  let _response = await apiCall.getData(`/api/v1/doctor/profile`);
  return _response;
}

async function updateDoctorProfile(data) {
  let _response = await apiCall.postRawJsonData(
    "/api/V1/doctor/profile/update/",
    data
  );
  return _response;
}

async function updateDoctorProfileImage(data) {
  let _response = await apiCall.postRawJsonData(
    "/api/V1/doctor/profile/updateImage/",
    data
  );
  return _response;
}

async function getDoctorOverAllRating(doctorID) {
  let _response = await apiCall.getData(
    `/api/v1/doctor/${doctorID}/overallRatings`
  );
  return _response;
}

async function serviceListDoctorInvitations(page, search = "") {
  let _response = await apiCall.getData(
    `/api/V1/doctor/invitation?search=${search}`
  );
  return _response;
}

async function serviceInvitationAction(data) {
  let _response = await apiCall.postRawJsonData(
    `/api/V1/doctor/invitation/action`,
    data
  );
  return _response;
}

async function serviceListDoctorClinics(doctorID, forConfig = false) {
  let url = `/api/V1/doctor/clinic/list?doctorID=${doctorID}`;
  if (forConfig) {
    url += `&forConfig=true`;
  }
  let _response = await apiCall.getData(url);
  return _response;
}

async function serviceGetDoctorSettingAffectedRecords(data) {
  let _response = await apiCall.postRawJsonData(
    `/api/V1/doctor/configuration/affectedRecords`,
    data
  );
  return _response;
}

async function serviceListDoctorPatients(doctorID, page, clinicID, search) {
  let _response = await apiCall.getData(
    `/api/V1/doctor/patient/list?doctorID=${doctorID}&page=${page}&clinicID=${clinicID}&search=${search}`
  );
  return _response;
}

async function serviceGetDoctorPatientsList(doctorID, page, search = "") {
  let _response = await apiCall.getData(
    `/api/V1/doctor/patient/list?doctorID=${doctorID}&page=${page}&search=${search}`
  );
  return _response;
}

export {
  getDoctorProfile,
  updateDoctorProfile,
  updateDoctorProfileImage,
  getDoctorOverAllRating,
  serviceListDoctorInvitations,
  serviceInvitationAction,
  serviceListDoctorClinics,
  serviceGetDoctorSettingAffectedRecords,
  serviceGetDoctorPatientsList,
  serviceListDoctorPatients,
};
