const apiCall = require('../../Api')

const regiter = async (data, type) => {
    try {
        let _response = await apiCall.postRawJsonData('/api/V1/admin/register/' + type, data)
        return _response
    } catch (error) {
        console.error('Error posting data:', error);
        throw error;
    }
};

const getPatientDetails = async (uid) => {
    try {
        let _response = await apiCall.getData('/api/V1/admin/patient/details?uid=' + uid)
        return _response
    } catch (error) {
        console.error('Error posting data:', error);
        throw error;
    }
};

const updatePatient = async (data, uid) => {
    try {
        let _response = await apiCall.postRawJsonData('/api/V1/admin/patient/update?uid=' + uid, data)
        return _response
    } catch (error) {
        console.error('Error posting data:', error);
        throw error;
    }
};

const getList = async (skip, take, search = "") => {
    try {
        let _response = await apiCall.getData('/api/V1/admin/patient/list?skip=' + skip + '&take=' + take + '&search=' + search)
        return _response
    } catch (error) {
        console.error('Error posting data:', error);
        throw error;
    }
};

const deleteUser = async (data) => {
    try {
        let _response = await apiCall.postRawJsonData('/api/V1/admin/user/delete', data)
        return _response
    } catch (error) {
        console.error('Error posting data:', error);
        throw error;
    }
};

export {
    regiter,
    getPatientDetails,
    updatePatient,
    getList,
    deleteUser
}
