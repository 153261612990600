import React, { useEffect, useRef, useState } from 'react';
import { useParams, useHistory } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import LoadingDots from "../../../components/LoadingDots";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useFormik } from "formik";
import * as Yup from "yup";
import PhoneInput from "react-phone-number-input";
// Sevices
import { regiter } from "../../../services/admin/serviceDoctor";
import { FormProvider, useForm } from "react-hook-form";
import { servicePostFileUpload } from '../../../services/clinic/clinicApiService';
import { ReactSearchAutocomplete } from 'react-search-autocomplete';
import FileDropzone from '../../../components/FileDropzone';
import { serviceGetClinicList, serviceGetMasterSpecialityList } from '../../../services/apiService';

const Create = () => {
    const { uid } = useParams();
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [selectedDate, setSelectedDate] = useState("");
    const [contactNumber, setContactNumber] = useState("");
    const methods = useForm({ mode: "onBlur" });
    const [itemSpeciality, setItemSpeciality] = useState({});
    const [specialty, setSpecialty] = useState("");
    const [doctorFile, setDoctorFile] = useState({});
    const [item, setItem] = useState({});
    const [clinic, setClinic] = useState("");
    const [pageTable, setPageTable] = useState(0);
    //------------------------- FUCNCTIONS ------------------
    const goBack = () => {
        history.goBack();
    };

    const validationSchema = Yup.object({
        salutation: Yup.string().required("Salutation is required"),
        firstName: Yup.string().required("First name is required").matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field "),
        middleName: Yup.string().matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field "),
        lastName: Yup.string().required("Last name is required").matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field "),
        email: Yup.string().email("Invalid email address").required("Email is required"),
        contactNumber: Yup.string().required("Contact number is required").min(7, 'Contact number must be 7 charactor long').max(14, 'Contact number should not exceed 14 charactor').test('is-number', 'Contact number must be a valid number', value => {
            return !isNaN(Number(value));
        }),
        age: Yup.string().required("Age is required").min(1, 'Age must be 1 charactor long').max(3, 'Age should not exceed 3 charactor').test('is-number', 'Age must be a valid number', (value) => !isNaN(parseInt(Number(value))) && !/[eE+-]/.test(value.toString())),
        gender: Yup.string().required("Gender is required"),
        dob: Yup.string().required("Dob is required"),
        clinics: Yup.array().required("Clinic is required"),
        specialities: Yup.array().required("Speciality is required"),
        experinceInYears: Yup.number().min(0, "Invalid experience").max(100, "Invalid experience"),
        files: Yup.array()
            .min(1, "Doctor document is required")
            .required("Doctor document is required"),
    });

    const formik = useFormik({
        initialValues: {
            salutation: "Mr",
            firstName: "",
            middleName: "",
            lastName: "",
            email: "",
            contactNumber: "",
            age: "",
            gender: "",
            dob: "",
            clinics: [],
            specialities: [],
            experinceInYears: "",
            files: [],
        },
        validationSchema,
        onSubmit: (values) => {
            webHandleSubmit(values);
        },
    });

    // ---------- SUBMIT THE FORM -----------
    async function webHandleSubmit(_values) {
        try {
            setError("");
            setLoading(true);

            //upload file
            let fileData = {
                file: doctorFile,
                destination: "/doctor",
            };
            const fileResponse = await servicePostFileUpload(fileData);
            if (fileResponse.status == 0) {
                return toast.error(fileResponse.message);
            }

            let data = {
                salutation: _values.salutation,
                firstName: _values.firstName,
                middleName: _values.middleName,
                lastName: _values.lastName,
                email: _values.email,
                contactNumber: _values.contactNumber,
                age: parseInt(_values.age),
                gender: _values.gender,
                dob: String(_values.dob),
                clinics: clinic,
                specialities: specialty,
                experinceInYears: _values.experinceInYears,
                documents: [
                    {
                        name: fileResponse.data.filePath.substring(
                            fileResponse.data.filePath.lastIndexOf("/") + 1
                        ),
                        documentUrl: fileResponse.data.filePath,
                    },
                ],
            };
            const registerData = await regiter(data, "doctor");
            setLoading(false);
            if (registerData.status == 0) {
                toast.error(registerData.message);
            }
            if (registerData.status == 1) {
                toast.success(registerData.message);
                history.push("/admin/doctor/list");
            }
        } catch (e) {
            toast.error(e.message);
            setLoading(false);
        }
    }

    const handleDateChange = async (value) => {
        let date = moment(value);
        let formattedDate = date.format("MM-DD-YYYY");

        setSelectedDate(value);
        formik.setFieldValue("dob", formattedDate);
    };
    // ----------------------------------------- RENDER HTML ----------------------------



    async function fetchClinics(keywords = "") {
        try {
            const res = await serviceGetClinicList(pageTable, keywords);

            setLoading(false);
            if (res.status == 0) {
                toast.error(res.message);
            }

            if (res.status == 1) {
                let _items = [];
                res.data?.forEach((element, index) => {
                    if (element) {
                        _items.push({ id: element.uid, name: element?.name });
                    }
                });
                setItem(_items);
            }
        } catch (error) {
            toast.error(error.message);
            setLoading(false);
        }
    }

    async function fetchMasterSpeciality(keywords = "") {
        try {
            const res = await serviceGetMasterSpecialityList(keywords);

            setLoading(false);
            if (res.status == 0) {
                toast.error(res.message);
            }

            if (res.status == 1) {
                let _items = [];
                res.data?.forEach((element, index) => {
                    if (element) {
                        _items.push({ id: element.id, name: element?.name });
                    }
                });
                setItemSpeciality(_items);
            }
        } catch (error) {
            toast.error(error.message);
            setLoading(false);
        }
    }

    const handleOnSelect = (item) => {
        setPageTable(0);
        let id = item.id.trim();
        let clincArr = [
            {
                clinicID: id,
            },
        ];
        setClinic([
            {
                clinicID: id,
            },
        ]);
        formik.setFieldValue("clinics", clincArr);
    };

    const handleOnSpecialitySelect = (item) => {
        setPageTable(0);
        let id = item.id.trim();
        let specialityArr = [
            {
                specialityId: id,
            },
        ];
        setSpecialty([
            {
                specialityId: id,
            },
        ]);
        formik.setFieldValue("specialities", specialityArr);
    };

    const onClear = () => {
        setClinic("");
        fetchClinics();
    };

    const onSpecialityClear = () => {
        setSpecialty("");
        fetchMasterSpeciality();
    };

    const formatResult = (item) => {
        return (
            <>
                {/* <span style={{ display: 'block', textAlign: 'left' }}>id: {item.id}</span> */}
                <span style={{ display: "block", textAlign: "left" }}>{item.name}</span>
            </>
        );
    };


    const handleOnSearch = (_value) => {
        fetchClinics(_value);
    };

    const handleOnSpecialitySearch = (_value) => {
        fetchMasterSpeciality(_value);
    };

    useEffect(() => {
        fetchClinics();
        fetchMasterSpeciality();
    }, [pageTable]);

    const handleFileSelect = async (_selectedFile) => {
        try {

            if (_selectedFile) {
                if (_selectedFile.size <= 5 * 1024 * 1024) {
                    formik.setFieldValue("files", [_selectedFile]);
                    setDoctorFile(_selectedFile);
                } else {
                    setDoctorFile(null)
                    return toast.error('File size exceeds 5 MB');
                }
            }

        } catch (e) {
            toast.error(e.message);
        }
    };

    return (
        <>
            <div className='container-fluid'>
                <div className="form__box__container">
                    <FormProvider {...methods}>
                        <form onSubmit={formik.handleSubmit} action="#" method="POST">
                            <div className="row">
                                <h3 className="head__weekly__report">Register Doctor</h3>
                                <div className="col-md-2">
                                    <div className="mb-2">
                                        <label for="" className="form-label">
                                            Salutation
                                        </label>
                                        <select className="form-control cust__form" {...formik.getFieldProps("salutation")}>
                                            <option value="Mr">Mr</option>
                                            <option value="Mrs">Mrs</option>
                                            <option value="Miss">Miss</option>
                                        </select>

                                        {formik.touched.salutation && formik.errors.salutation && (
                                            <div className='form_errors'>{formik.errors.salutation}</div>
                                        )}
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="mb-2">
                                        <label for="" className="form-label">
                                            First Name
                                        </label>
                                        <input
                                            type="text"
                                            placeholder='Enter first name'
                                            className="form-control cust__form"
                                            {...formik.getFieldProps("firstName")}
                                        />
                                        {formik.touched.firstName && formik.errors.firstName && (
                                            <div className='form_errors'>{formik.errors.firstName}</div>
                                        )}
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="mb-2">
                                        <label for="" className="form-label">
                                            Middle Name
                                        </label>
                                        <input
                                            type="text"
                                            placeholder='Enter middle name'
                                            className="form-control cust__form"
                                            {...formik.getFieldProps("middleName")}
                                        />
                                        {formik.touched.middleName && formik.errors.middleName && (
                                            <div className='form_errors'>{formik.errors.middleName}</div>
                                        )}
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="mb-2">
                                        <label for="" className="form-label">
                                            Last Name
                                        </label>
                                        <input
                                            type="text"
                                            placeholder='Enter last name'
                                            className="form-control cust__form"
                                            {...formik.getFieldProps("lastName")}
                                        />
                                        {formik.touched.lastName && formik.errors.lastName && (
                                            <div className='form_errors'>{formik.errors.lastName}</div>
                                        )}
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="mb-2">
                                        <label for="" className="form-label">
                                            Email ID
                                        </label>
                                        <input
                                            type="email"
                                            placeholder='Enter email'
                                            className="form-control cust__form"
                                            {...formik.getFieldProps("email")}
                                        />
                                        {formik.touched.email && formik.errors.email && (
                                            <div className='form_errors'>{formik.errors.email}</div>
                                        )}
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="mb-2">
                                        <label for="" className="form-label">
                                            Age
                                        </label>
                                        <input
                                            type="text"
                                            placeholder='Enter age'
                                            className="form-control cust__form"
                                            {...formik.getFieldProps("age")}
                                        />
                                        {formik.touched.age && formik.errors.age && (
                                            <div className='form_errors'>{formik.errors.age}</div>
                                        )}
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="mb-2">
                                        <label for="" className="form-label">
                                            Contact No.
                                        </label>
                                        <PhoneInput
                                            className={"input-phone-number admin-contact-number"}
                                            placeholder="Enter phone number"
                                            name="contactNumber"
                                            value={contactNumber}
                                            onChange={(e) => formik.setFieldValue("contactNumber", e)}

                                        />
                                        {/* <input type="text" className="form-control cust__form" {...formik.getFieldProps("contactNumber")} /> */}
                                        {formik.touched.contactNumber && formik.errors.contactNumber && (
                                            <div className='form_errors'>{formik.errors.contactNumber}</div>
                                        )}
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="mb-2">
                                        <label for="" className="form-label">
                                            Gender
                                        </label>
                                        <div className="col-auto">
                                            <div className="form-check form-check-inline">
                                                <input
                                                    className="form-check-input"
                                                    type="radio"
                                                    {...formik.getFieldProps("gender")}
                                                    id="maleCheck"
                                                    value={"male"}
                                                    onChange={formik.handleChange}
                                                    checked={formik.values.gender === "male"}
                                                />

                                                <label className="form-check-label" for="maleCheck">Male</label>
                                            </div>
                                            <div className="form-check form-check-inline">
                                                <input
                                                    className="form-check-input"
                                                    type="radio"
                                                    {...formik.getFieldProps("gender")}
                                                    id="femalecheck"
                                                    value={"female"}
                                                    onChange={formik.handleChange}
                                                    checked={formik.values.gender === "female"}
                                                />
                                                <label className="form-check-label" for="femalecheck">Female</label>
                                            </div>
                                            {formik.touched.gender && formik.errors.gender && (
                                                <div className='form_errors'>{formik.errors.gender}</div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div class="mb-2">
                                        <label for="" class="form-label">
                                            Dob
                                        </label>
                                        <br></br>
                                        <DatePicker
                                            className="form-control cust__form"
                                            selected={selectedDate}
                                            onChange={handleDateChange}
                                            placeholderText="Select a date"
                                            dateFormat="MM-dd-yyyy"
                                            showMonthDropdown="true"
                                            showYearDropdown="true"
                                            maxDate={new Date()}
                                        />
                                        <input
                                            type="hidden"
                                            {...formik.getFieldProps("dob")}
                                            name="dob"
                                        ></input>
                                        {formik.touched.dob && formik.errors.dob && (
                                            <div className="form_errors">{formik.errors.dob}</div>
                                        )}
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <label className="form-label">Clinic</label>
                                    <ReactSearchAutocomplete
                                        items={item}
                                        onSearch={handleOnSearch}
                                        //onHover={handleOnHover}
                                        onSelect={handleOnSelect}
                                        // onFocus={handleOnFocus}
                                        onClear={onClear}
                                        autoFocus
                                        formatResult={formatResult}
                                        placeholder="Select Clinic"
                                        {...formik.getFieldProps("clinic")}
                                    />
                                    {formik.touched.clinic && formik.errors.clinic && (
                                        <div className="form_errors">{formik.errors.clinic}</div>
                                    )}
                                </div>
                                <div className="col-md-4">
                                    <label className="form-label">Total Experience ( in Years )</label>
                                    <input
                                        type="number"
                                        min={0}
                                        max={100}
                                        step={1}
                                        {...formik.getFieldProps("experinceInYears")}
                                        placeholder="Enter your exprience"
                                        className="form-control cust__form"
                                    />
                                    {formik.touched.experinceInYears && formik.errors.experinceInYears && (
                                        <div className="form_errors">{formik.errors.experinceInYears}</div>
                                    )}
                                </div>
                                <div className="col-md-4">
                                    <label className="form-label">Speciality</label>
                                    <ReactSearchAutocomplete
                                        items={itemSpeciality}
                                        onSearch={handleOnSpecialitySearch}
                                        //onHover={handleOnHover}
                                        onSelect={handleOnSpecialitySelect}
                                        // onFocus={handleOnFocus}
                                        onClear={onSpecialityClear}
                                        autoFocus
                                        formatResult={formatResult}
                                        placeholder="Select Speciality"
                                        {...formik.getFieldProps("specialities")}
                                    />
                                    {formik.touched.specialities && formik.errors.specialities && (
                                        <div className="form_errors">{formik.errors.specialities}</div>
                                    )}
                                </div>
                                <div className="col-md-12">
                                    <label className="form-label">
                                        Doctor Document<span>&nbsp;</span>
                                        <input
                                            type="hidden"
                                            className="form-control"
                                            name="files"
                                        ></input>
                                        {formik.touched.files && formik.errors.files && (
                                            <div className="form_errors">{formik.errors.files}</div>
                                        )}
                                    </label>
                                    <FileDropzone
                                        accept=".pdf, image/jpeg, image/png, image/jpg"
                                        name="idImage"
                                        handleFileSelect={handleFileSelect}
                                    />
                                    <div className="form_errors">
                                        Note: Please upload image or PDF files only,
                                        and the file size should be below 5 MB{" "}
                                    </div>
                                </div>
                                {!loading ?
                                    <div className="col-md-12 d-flex justify-content-between mt-3">
                                        <button type='submit' className="btn save-btn">Register</button>
                                        <button className="btn cancel-btn" onClick={goBack}>Back</button>
                                    </div>
                                    :
                                    <LoadingDots />
                                }
                            </div>
                        </form>
                    </FormProvider>
                </div>
            </div>

        </>
    );
};

export default Create;
