import React from 'react';

const Footer = () => {
    return (

        <footer className="bg-white dark:bg-gray-800 w-full py-8">
            <div className="max-w-screen-xl mx-auto px-4">
                <ul className="max-w-screen-md mx-auto text-lg font-light flex flex-wrap justify-between">
                    <li className="my-2">
                        <a className="text-gray-400 hover:text-gray-800 dark:text-gray-300 dark:hover:text-white transition-colors duration-200"
                           href="/pdpa">
                            PDPA Policy
                        </a>
                    </li>
                    <li className="my-2">
                        <a className="text-gray-400 hover:text-gray-800 dark:text-gray-300 dark:hover:text-white transition-colors duration-200"
                           href="/admin">
                            Admin Login
                        </a>
                    </li>
                    <li className="my-2">
                        <a className="text-gray-400 hover:text-gray-800 dark:text-gray-300 dark:hover:text-white transition-colors duration-200"
                           href="/clinicLogin">
                            Clinic Login
                        </a>
                    </li>
                    <li className="my-2">
                        <a className="text-gray-400 hover:text-gray-800 dark:text-gray-300 dark:hover:text-white transition-colors duration-200"
                           href="https://s3.us-west-2.amazonaws.com/public.healthlinkprotocol/HLP_terms.pdf" target="_blank" rel="noopener noreferrer">
                            Terms & Conditions
                        </a>
                    </li>
                </ul>
                <div
                    className="text-center text-gray-400 dark:text-gray-200 pt-10 sm:pt-12 font-light flex items-center justify-center">
                    ©Healthlink
                </div>
            </div>
        </footer>

    );
};

export default Footer;