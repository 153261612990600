import React, { useEffect, useState } from "react";
import Table from "../../../components/table/Table";
import TableHeader from "../../../components/table/TableHeader";
import TableCell from "../../../components/table/TableCell";
import TableBody from "../../../components/table/TableBody";
import TableRow from "../../../components/table/TableRow";
import TableFooter from "../../../components/table/TableFooter";
import RecordPlaceholder from "../../../components/RecordPlaceholder";
import TableContainer from "../../../components/table/TableContainer";
import LoadingDots from "../../../components/LoadingDots";
import ToastConfirmation from "../../../components/toastrConfirmation";
import Button from "../../../components/Button";
import { Link, useRouteMatch } from "react-router-dom";
import DefaultAvatar from "../../../components/DefaultAvatar";
import Pagination from "../../../components/Pagination";
import { axiosInstance } from "../../../common/constants";
import SectionTitle from "../../../components/SectionTitle";
import { ToastContainer, toast } from "react-toastify";
import { capitalizeFirstChar } from "../../../helper/utils";
// Sevices
import {
  getList,
  chageStatus,
} from "../../../services/admin/serviceSpecialist";

const List = () => {
  const [loading, setLoading] = useState(true);
  const [dataTable, setDataTable] = useState(true);
  const [pageTable, setPageTable] = useState(0);
  const [totalResults, setTotalResults] = useState(0);
  let image = "";
  const resultsPerPage = 10;

  function onPageChangeTable(p) {
    setPageTable((p - 1) * resultsPerPage);
  }

  useEffect(() => {
    fetchData();
  }, [pageTable]);

  async function fetchData(keywords = "") {
    try {
      const res = await getList(pageTable, resultsPerPage, keywords);
      setLoading(false);
      if (res.status == 0) {
        toast.error(res.message);
      }

      if (res.status == 1) {
        setDataTable(res.data);
        setTotalResults(res.count);
        setLoading(false);
      }
    } catch (error) {
      toast.error(error.message);
      setLoading(false);
    }
  }

  // ----------------------------------- Searh user
  const handleKeyUp = (event) => {
    setPageTable(0);
    let _keyword = event.target.value;
    fetchData(_keyword);
  };
  // ----------------------------------- Delte user
  const handleConfirmation = (event) => {
    let _status = Number(event.target.dataset.status);
    if (_status == 0) {
      _status = 1;
    } else {
      _status = 0;
    }

    const _data = { specialtyID: event.target.id, status: _status };
    let Data = JSON.stringify(_data);
    toast(
      <ToastConfirmation
        prop1={Data}
        prop2={handleConfirm}
        prop3={handleCancel}
      />,
      { position: toast.POSITION.TOP_CENTER, closeButton: false }
    );
  };

  const handleConfirm = async (event) => {
    toast.dismiss();
    const data = JSON.parse(event.target.dataset.userid);
    // Perform the action
    setLoading(true);
    const response = await chageStatus(data);

    if (response.status == 0) {
      toast.error(response.message);
    }
    if (response.status == 1) {
      toast.success(response.message);
      fetchData();
    }
    setLoading(false);
  };

  const handleCancel = () => {
    toast.dismiss();
  };

  //--------------------------------------------- RENDER HTML --------------------
  return (
    <>
      <SectionTitle>Specialist List </SectionTitle>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-4 p-0">
            <div class="mb-3">
              <input
                onKeyUp={handleKeyUp}
                type="search"
                class="form-control cust__form"
                id=""
                placeholder="Search "
              />
            </div>
          </div>
          <div className="col-md-8 p-0">
            <div className="mb-3">
              <a href="/admin/speicialist/create">
                <button class="btn btn__add__appointment">
                  Add Specialist
                </button>
              </a>
            </div>
          </div>
        </div>
      </div>
      {!loading ? (
        <TableContainer className="form__box__container mb-8">
          <Table>
            <TableHeader>
              <tr>
                <TableCell>Icon</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>status</TableCell>
                <TableCell>Action</TableCell>
              </tr>
            </TableHeader>
            <TableBody>
              {dataTable.length > 0 ? (
                dataTable.map((value, i) => (
                  <TableRow key={i}>
                    <TableCell>
                      {value?.icon ? (
                        <img
                          src={`${value.icon}`}
                          className="profile__img__setting"
                          alt="Icon"
                        />
                      ) : (
                        <DefaultAvatar className="profile__img__setting" />
                      )}
                    </TableCell>
                    <TableCell>
                      <div>
                        <p className="font-semibold">{`${capitalizeFirstChar(
                          value.name
                        )}`}</p>
                      </div>
                    </TableCell>
                    <TableCell>
                      <label className="switch__toggle">
                        <input
                          value={value?.status == 1 ? true : false}
                          checked={value?.status == 1 ? true : false}
                          onChange={handleConfirmation}
                          class="check-toggle__input"
                          type="checkbox"
                          id={value.id}
                          data-status={value?.status}
                        />
                        <span class="slider-checkbox round-50"></span>
                      </label>
                    </TableCell>
                    <TableCell>
                      <Link
                        to={{
                          pathname: `/admin/speicialist/edit/${value?.id}`,
                        }}
                      >
                        <Button> Edit </Button>
                      </Link>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <RecordPlaceholder table="true" />
              )}
            </TableBody>
          </Table>
          {totalResults > 0 && (
            <TableFooter>
              <Pagination
                totalResults={totalResults}
                resultsPerPage={resultsPerPage}
                onChange={onPageChangeTable}
                label="Table navigation"
              />
            </TableFooter>
          )}
        </TableContainer>
      ) : (
        <LoadingDots />
      )}
    </>
  );
};

export default List;
