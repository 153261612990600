import TableRow from "../components/table/TableRow";
import TableCell from "./table/TableCell";

const RecordPlaceholder = (data) => {
  return data.table ? (
    <TableRow>
      <TableCell colspan="6" className="text-center font-semibold">
        No Record Found...
      </TableCell>
    </TableRow>
  ) : (
    <div className="row text-center">
      <h4>No Record Found...</h4>
    </div>
  );
};

export default RecordPlaceholder;
