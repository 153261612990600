import React, { useEffect, useState } from "react";
import Table from "../../components/table/Table";
import TableHeader from "../../components/table/TableHeader";
import TableCell from "../../components/table/TableCell";
import TableBody from "../../components/table/TableBody";
import TableRow from "../../components/table/TableRow";
import TableFooter from "../../components/table/TableFooter";
import TableContainer from "../../components/table/TableContainer";
import LoadingDots from "../../components/LoadingDots";
import { Link } from "react-router-dom";
import Pagination from "../../components/Pagination";
import SectionTitle from "../../components/SectionTitle";
import { formatTime } from "../../services/dateService";
import SearchIcon from "../../dashboard/sidenavigation/icons/search";
import { formatDate } from "../../components/FormatDate";
import RecordPlaceholder from "../../components/RecordPlaceholder";
import {
  serviceGetAppointments,
  servicePatientCancelAppointment,
} from "../../services/apiService";
import { toast } from "react-toastify";
import ToastConfirmation from "../../components/toastrConfirmation";

const DoctorAppointmentList = () => {
  const [loading, setLoading] = useState(true);
  const [dataTable, setDataTable] = useState(true);
  const [pageTable, setPageTable] = useState(0);
  const [totalResults, setTotalResults] = useState(0);
  const [showModal, setShowModal] = useState(false);

  const resultsPerPage = 10;

  function onPageChangeTable(p) {
    setPageTable(p - 1);
  }

  useEffect(() => {
    listAppointments();
  }, [pageTable]);

  async function listAppointments(search = "") {
    const res = await serviceGetAppointments(pageTable);
    setDataTable(res.data);
    setTotalResults(res.totalCount);
    setLoading(false);
  }

  function getAppointmentStatus(_status) {
    let status = <p>N/A</p>;
    if (_status == "0") {
      status = <p style={{ color: "orange", margin: "auto" }}>Pending</p>;
    }
    if (_status == "1") {
      status = <p style={{ color: "blue", margin: "auto" }}>Accepted</p>;
    }
    if (_status == "2") {
      status = <p style={{ color: "red", margin: "auto" }}>Rejected</p>;
    }
    if (_status == "3") {
      status = <p style={{ color: "green", margin: "auto" }}>Completed</p>;
    }
    if (_status == "4") {
      status = <p style={{ color: "red", margin: "auto" }}>Cancelled</p>;
    }
    if (_status == "5") {
      status = (
        <p style={{ color: "Blue", margin: "auto" }}>ON Hold(by clinic)</p>
      );
    }
    if (_status == "6") {
      status = (
        <p style={{ color: "Blue", margin: "auto" }}>ON Hold(by doctor)</p>
      );
    }
    return status;
  }

  const handleConfirmation = (event, _appointmentID) => {
    const _value = JSON.stringify({
      appointmentID: _appointmentID,
    });

    toast(
      <ToastConfirmation
        prop1={_value}
        prop2={handleConfirm}
        prop3={handleCancel}
      />,
      { position: toast.POSITION.TOP_CENTER, closeButton: false }
    );
  };

  const handleConfirm = async (event) => {
    toast.dismiss();

    const data = JSON.parse(event.target.dataset.userid);
    // Perform the action
    setLoading(true);
    await cancelAppointMent(data.appointmentID);
    setLoading(false);
  };

  const handleCancel = () => {
    toast.dismiss();
  };

  async function cancelAppointMent(_id) {
    setLoading(true);
    try {
      let data = {
        appointmentID: _id,
      };
      const response = await servicePatientCancelAppointment(data);
      if (response.status == "1") {
        await listAppointments();
        setLoading(false);
        toast.success("Appointment cancelled!");
      }
    } catch (error) {
      setLoading(false);
      return toast.error("Something went wrong,please try again");
    }
  }

  // Function to handle opening the modal and setting the selected doctor
  const openModal = () => {
    setShowModal(!showModal);
  };

  // Function to handle closing the modal
  const closeModal = () => {
    setShowModal(false);
  };

  const searchAppointment = (event) => {
    let search = document.getElementById("search").value;
    setLoading(true);
    listAppointments(search);
  };

  return (
    <>
      <SectionTitle>Appointments </SectionTitle>
      {/* <div className="container-fluid">
        <div className="row">
          <div class="col-6 ps-0">
            <input
              type="search"
              class="form-control cust__form"
              id="search"
              placeholder="Search"
              // onKeyUp={(event) => searchReview(event)}
            />
          </div>
          <div class="col-6">
            <button
              type="button"
              class="btn d-flex"
              style={{ background: "#4F46E5", color: "white" }}
              onClick={() => searchAppointment()}
            >
              <SearchIcon />
              <p className="m-0">Search</p>
            </button>
          </div>
        </div>
      </div> */}
      {!loading ? (
        <TableContainer className="form__box__container mb-8 mt-3">
          <Table>
            <TableHeader>
              <tr className="head__th__appointment">
                <TableCell>Booking Id</TableCell>
                <TableCell>Doctor Name</TableCell>
                <TableCell>Date</TableCell>
                <TableCell>Time Slot</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Review</TableCell>
                <TableCell>Actions</TableCell>
              </tr>
            </TableHeader>
            <TableBody>
              {dataTable && dataTable.length > 0 ? (
                dataTable.map((appointment, i) => (
                  <TableRow key="1">
                    <TableCell>
                      <a href="#">{appointment.id || "N/A"}</a>{" "}
                    </TableCell>
                    <TableCell>
                      Dr.{" "}
                      {appointment.doctor["user"]["firstName"] +
                        " " +
                        appointment.doctor["user"]["lastName"]}
                    </TableCell>
                    <TableCell>
                      {formatDate(appointment.date) || "N/A"}
                    </TableCell>
                    <TableCell>
                      {formatTime(appointment.startTime) +
                        "-" +
                        formatTime(appointment.endTime) || "N/A"}
                    </TableCell>
                    <TableCell>
                      {getAppointmentStatus(appointment.status)}
                    </TableCell>
                    <TableCell>
                      {appointment.acceptedByDoctor == "3" &&
                      appointment.review == null ? (
                        <button>
                          <Link to={`/patient/postReview/${appointment.id}`}>
                            Add Review
                          </Link>
                        </button>
                      ) : appointment.review != null ? (
                        "Reviewed"
                      ) : (
                        "Not Applicable"
                      )}
                    </TableCell>
                    <TableCell>
                      <div className="row">
                        <div className="col-md-6 d-flex justify-content-between">
                          {appointment.acceptedByDoctor == "0" &&
                          appointment?.status == 0 ? (
                            <button>
                              <Link
                                to={`/patient/editAppointment/${appointment.id}`}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke-width="1.5"
                                  stroke="currentColor"
                                  class="w-6 h-6"
                                >
                                  <title>Edit Appointment</title>
                                  <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125"
                                  />
                                </svg>
                              </Link>
                            </button>
                          ) : (
                            <Link
                              to={`/patient/viewAppointment/${appointment.id}`}
                            >
                              <button>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke-width="1.5"
                                  stroke="currentColor"
                                  class="w-6 h-6"
                                >
                                  <title>View Appointment</title>
                                  <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
                                  />
                                  <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                                  />
                                </svg>
                              </button>
                            </Link>
                          )}
                          &nbsp;
                          {appointment?.status != 2 &&
                            appointment?.status != 3 &&
                            appointment?.status != 4 && (
                              <div>
                                <button
                                  onClick={(event) =>
                                    handleConfirmation(event, appointment.id)
                                  }
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke-width="1.5"
                                    stroke="currentColor"
                                    class="w-6 h-6"
                                  >
                                    <title>Cancel Appointment</title>
                                    <path
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                                    />
                                  </svg>
                                </button>
                              </div>
                            )}
                        </div>
                      </div>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <RecordPlaceholder table="true" />
              )}
            </TableBody>
          </Table>
          {totalResults > 0 && (
            <TableFooter>
              <Pagination
                totalResults={totalResults}
                resultsPerPage={resultsPerPage}
                onChange={onPageChangeTable}
                label="Table navigation"
              />
            </TableFooter>
          )}
        </TableContainer>
      ) : (
        <LoadingDots />
      )}
    </>
  );
};

export default DoctorAppointmentList;
