import React, { useEffect, useState } from "react";
import Table from "../../../components/table/Table";
import TableHeader from "../../../components/table/TableHeader";
import TableCell from "../../../components/table/TableCell";
import TableBody from "../../../components/table/TableBody";
import TableRow from "../../../components/table/TableRow";
import TableFooter from "../../../components/table/TableFooter";
import TableContainer from "../../../components/table/TableContainer";
import LoadingDots from "../../../components/LoadingDots";
import ToastConfirmation from "../../../components/toastrConfirmation";

import Button from "../../../components/Button";
import { Link, useRouteMatch } from "react-router-dom";
import DefaultAvatar from "../../../components/DefaultAvatar";
import Pagination from "../../../components/Pagination";
import { axiosInstance } from "../../../common/constants";
import SectionTitle from "../../../components/SectionTitle";
import { ToastContainer, toast } from "react-toastify";
import { useParams, useHistory } from "react-router-dom";
import { capitalizeFirstChar } from "../../../helper/utils";

// Sevices
import { viewAppoiments } from "../../../services/admin/serviceDoctor";
import { formatTime } from "../../../services/dateService";
import { formatDate } from "../../../components/FormatDate";
import RecordPlaceholder from "../../../components/RecordPlaceholder";

const List = () => {
  const { uid } = useParams();
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [dataTable, setDataTable] = useState(true);
  const [pageTable, setPageTable] = useState(0);
  const [totalResults, setTotalResults] = useState(0);
  const [doctorName, setDoctorName] = useState("");

  const resultsPerPage = 10;

  function onPageChangeTable(p) {
    setPageTable(p);
  }

  useEffect(() => {
    fetchData();
  }, []);

  async function fetchData(keywords = "") {
    try {
      const res = await viewAppoiments(pageTable, resultsPerPage, uid);
      setLoading(false);
      if (res.status == 0) {
        toast.error(res.message);
      }

      if (res.status == 1) {
        if (res.data?.response) {
          let _data = res;
          res.data?.response.forEach((element, index) => {
            let doctorName = 0;
            doctorName =
              element.doctor.user.firstName +
              " " +
              element.doctor.user.lastName;
            setDoctorName(doctorName);
          });
        }
        setDataTable(res.data.response);
        setTotalResults(res.data.count);
        setLoading(false);
      }
    } catch (error) {
      toast.error(error.message);
      setLoading(false);
    }
  }

  //--------------------------------------------- RENDER HTML --------------------
  return (
    <>
      <SectionTitle>Appointment List </SectionTitle>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-4 p-0">
            <div class="mb-3">
              {/* <input onKeyUp={handleKeyUp} type="search" class="form-control cust__form" id="" placeholder='Search ' /> */}
            </div>
          </div>
          <div className="col-md-8 p-0">
            <div className="mb-3">
              {/* <a href="/admin/doctor/create"><button class="btn btn__add__appointment">Add Doctor</button></a> */}
            </div>
          </div>
        </div>
      </div>
      {!loading ? (
        <TableContainer className="form__box__container mb-8">
          <Table>
            <TableHeader>
              <tr>
                <TableCell>Patient</TableCell>
                <TableCell>Contact Number</TableCell>
                <TableCell>Age</TableCell>
                <TableCell>Gender</TableCell>
                <TableCell>History</TableCell>
                <TableCell>Appointment Status</TableCell>
                <TableCell>Date & Slot</TableCell>
              </tr>
            </TableHeader>
            <TableBody>
              {dataTable && dataTable?.length > 0 ? (
                dataTable.map((value, i) => (
                  <TableRow key={i}>
                    <TableCell>
                      <div>
                        <p className="font-semibold">{`${value?.firstName} ${value?.lastName}`}</p>
                        <span className="list-email">{value?.email}</span>
                      </div>
                    </TableCell>
                    <TableCell>
                      <p className="font-semibold">{value?.phoneNumber}</p>
                    </TableCell>
                    <TableCell>
                      <div className="flex items-center text-sm">
                        <p className="font-semibold">{value?.age}</p>
                      </div>
                    </TableCell>
                    <TableCell>
                      <div className="flex items-center text-sm">
                        <p className="font-semibold">
                          {capitalizeFirstChar(value?.gender)}
                        </p>
                      </div>
                    </TableCell>
                    <TableCell>
                      <div className="flex items-center text-sm">
                        <p className="font-semibold">{value?.patientHistory}</p>
                      </div>
                    </TableCell>
                    <TableCell>
                      <div className="flex items-center text-sm">
                        <p className="font-semibold">
                          {value?.acceptedByDoctor == true
                            ? "Accepted"
                            : "Pending"}
                        </p>
                      </div>
                    </TableCell>
                    <TableCell>
                      <div className="flex items-center text-sm">
                        <p className="font-semibold">
                          {formatDate(value?.date) +
                            " [" +
                            formatTime(value?.startTime) +
                            " - " +
                            formatTime(value?.startTime) +
                            "]"}
                        </p>
                      </div>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <RecordPlaceholder table="true" />
              )}
            </TableBody>
          </Table>
          {totalResults > 0 && (
            <TableFooter>
              <Pagination
                totalResults={totalResults}
                resultsPerPage={resultsPerPage}
                onChange={onPageChangeTable}
                label="Table navigation"
              />
            </TableFooter>
          )}
        </TableContainer>
      ) : (
        <LoadingDots />
      )}
    </>
  );
};

export default List;
