import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { toast } from "react-toastify";
import { formatDate } from "../FormatDate";
import DoctorGenerateMemo from "./DoctorGenerateMemo";
import { servicePatientGetMedicalHistory } from "../../services/apiService";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    width: "75%",
    height: "70%",
    marginLeft: "8%",
    transform: "translate(-50%, -50%)",
  },
};

const DoctorGenerateMemoModal = ({
  patientId,
  appointmentId,
  patientName,
  modalIsOpen,
  closeModal,
}) => {
  const [loading, setLoading] = useState(false);
  const [medicalHistory, setMedicalHistory] = useState(null);
  const [error, setError] = useState(false);

  const fetchPatientHealthRecordchData = async () => {
    try {
      const response = await servicePatientGetMedicalHistory(patientId);
      setLoading(false);
      if (response.status == 0) {
        toast.error(response.message);
      }
      if (response.status == 1) {
        //toast.success(response.message);
        setMedicalHistory(response.data);
        const medicalHistoryData = response.data;
      }
    } catch (error) {
      setLoading(false);
      toast.error(error);
    }
  };

  useEffect(() => {
    async function fetchData() {
      await fetchPatientHealthRecordchData();
    }
    fetchData();
  }, []);

  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel="Generate Memo"
      shouldCloseOnOverlayClick={false}
    >
      <div id="doctor-modal">
        <div className="modal-header">
          <h5 className="modal-title">Generate Memo</h5>
          <button className="close-button" onClick={closeModal}>
            X
          </button>
        </div>
        <div>
          {/* <div>  <br></br>
          <div></div>
          <div className="row">
            <div className="col-6">
              <label for="" className="form-label">
                <b>Name: </b>{patientName || "N/A"}
              </label>
            </div>
            <div className="col-6">
              <label for="" className="form-label">
               <b>MedicalCondition: </b>{medicalHistory?.medicalCondition || "N/A"}
              </label>
            </div>
            <div className="col-6">
              <label for="" className="form-label">
                <b>Surgeries: </b>{medicalHistory?.surgeries || "N/A"}
              </label>
            </div>
            <div className="col-6">
              <label for="" className="form-label">
              <b>Description: </b>{medicalHistory?.description || "N/A"}
              </label>
            </div>
          </div></div> */}
          <DoctorGenerateMemo
            patientId={patientId}
            appointmentId={appointmentId}
            closeModal={closeModal}
          />
        </div>
      </div>
    </Modal>
  );
};

export default DoctorGenerateMemoModal;
