import React from 'react';
import {Link} from "react-router-dom";

const PatientUnverifiedError = () => {
    return (
        <div className="bg-red-200 border-red-600 text-red-600 border-l-4 p-4" role="alert">
            <p className="text-lg font-bold">
                You have not completed account registration
            </p>
            <p>
                <Link className="font-semibold underline" to="/patient">Please complete this form</Link> before using Healthlink features
            </p>
        </div>

    );
};

export default PatientUnverifiedError;